import { useController, useForm, useFormContext } from "react-hook-form";

import type {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseControllerReturn,
} from "react-hook-form";

export const useControllerSafe = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: UseControllerProps<TFieldValues, TName>
): UseControllerReturn<TFieldValues, TName> | null => {
  const form = useFormContext();

  const { control: dummyControl } = useForm();

  const controller = useController<TFieldValues, TName>({
    ...props,
    control: form ? form.control : (dummyControl as any),
  });

  if (!form) {
    return null;
  }

  return controller;
};
