import { AuthState } from "./slice";

export const getCurrentUserEmail = (state: AuthState) => state.email;
export const isAuthenticated = (state: AuthState) => state.authenticated;

export const wasLoginRequested = (state: AuthState) => state.requests.login.active;
export const wasAuthenticateRequested = (state: AuthState) => state.requests.authenticate.active;
export const wasLogoutRequested = (state: AuthState) => state.requests.logout.active;

export const wasLoginEmailSent = (state: AuthState) => state.requests.login.success;

export const getLoginError = (state: AuthState) => state.requests.login.error;
export const getAuthenticateError = (state: AuthState) => state.requests.authenticate.error;
export const didAuthenticateSucceed = (state: AuthState) => state.requests.authenticate.success;

export const getLoginTypes = (state: AuthState) => state.loginTypes;
export const wereLoginTypesRequested = (state: AuthState) => state.requests.loginTypes.active;
export const didLoginTypesSucceed = (state: AuthState) => state.requests.loginTypes.success;
export const getLoginTypeError = (state: AuthState) => state.requests.loginTypes.error;
