import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import React from "react";
import { tv } from "tailwind-variants";

import { Button } from "../button/Button";
import { Skeleton } from "../skeleton/Skeleton";
import { Text } from "../typography/Text";

import type { FC } from "react";
import type { VariantProps } from "tailwind-variants";

// NOTE(sam): this is a legacy type and doesn't have className because it's used in a few things
// that would need to be refactored
export interface PaginationProps {
  currentPageNumber: number;
  totalCount?: number;
  pageSize: number;
  hasPrevPage: boolean;
  onPrevPage: () => void;
  hasNextPage: boolean;
  onNextPage: () => void;
}

const paginationVariants = tv({
  slots: {
    base: "flex flex-row items-center justify-between text-gray-850 dark:text-white",
    count: "font-medium text-gray-600 dark:text-gray-500",
    pagination: "flex flex-row items-center gap-x-2",
    currentPage: "text-center",
  },
  variants: {
    variant: {
      table: {
        base: "py-1.5 pl-3 pr-1.5",
      },
      default: {},
    },
    largeCount: {
      true: {
        currentPage: "w-[60px]",
      },
      false: {
        currentPage: "w-10",
      },
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface Props extends PaginationProps, Pick<VariantProps<typeof paginationVariants>, "variant"> {
  className?: string;
}

export const Pagination: FC<Props> & { Skeleton: typeof PaginationSkeleton } = ({
  className,
  currentPageNumber,
  totalCount = 0,
  pageSize,
  hasPrevPage,
  onPrevPage,
  hasNextPage,
  onNextPage,
  variant,
}) => {
  const low = currentPageNumber * pageSize + 1;
  const high = Math.min((currentPageNumber + 1) * pageSize, totalCount);

  const slots = paginationVariants({ variant, largeCount: totalCount >= 100 });

  return (
    <div className={slots.base({ className })}>
      <Text size="sm" emphasis="secondary" className={slots.count()}>
        Results: {totalCount}
      </Text>
      {hasPrevPage || hasNextPage ? (
        <div className={slots.pagination()}>
          <Button isIconOnly ghost variant="light" isDisabled={!hasPrevPage} onClick={onPrevPage}>
            <Icon icon={faChevronLeft} />
          </Button>
          <Text emphasis="secondary" className={slots.currentPage()}>
            {low}-{high}
          </Text>
          <Button isIconOnly ghost variant="light" isDisabled={!hasNextPage} onClick={onNextPage}>
            <Icon icon={faChevronRight} />
          </Button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const PaginationSkeleton: FC<Pick<Props, "className" | "variant">> = ({ className, variant }) => {
  const { base } = paginationVariants({ variant });

  return (
    <div className={base({ className })}>
      <Skeleton className="h-7 w-16" />
      <Skeleton className="h-7 w-28" />
    </div>
  );
};

Pagination.Skeleton = PaginationSkeleton;
