import { createSlice, combineReducers, PayloadAction } from "@reduxjs/toolkit";

import {
  createClearableIndexedRequestReducer,
  reduceReducers,
  createIndexedRequestReducerFromThunk,
  createRequestReducerFromThunk,
} from "@skydio/redux_util/src";
import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";

import { fetchTokens, createToken, updateToken, deleteToken } from "./asyncThunks";

import {
  TokensPrimaryState,
  Token,
  APIToken,
  TokenUpdate,
  NEW_TOKEN_PLACEHOLDER_ID,
} from "./types";

const initialState: TokensPrimaryState = {
  tokens: [],
  dispatchTimeout: null,
  requestedIds: [],
  addModalOpen: false,
};

const initialTokenState: Token = {
  uuid: "",
  name: "",
  secret: "",
  enabled: false,
  organizationId: "",
  scope: enums_pb.ApiTokenScope.ScopeEnum.UNKNOWN,
  scopesList: [],
  modified: {},
};

export interface FieldUpdatePayload {
  uuid: string;
  name: keyof TokenUpdate;
  value: TokenUpdate[keyof TokenUpdate] | null;
}

const updateTokenState = (state: Token[], apiToken: APIToken) => {
  const tokenIndex = state.findIndex(t => t.uuid === apiToken.uuid);
  const updatedToken: Token = {
    ...initialTokenState,
    ...apiToken,
  };
  if (tokenIndex >= 0) {
    state[tokenIndex] = {
      ...updatedToken,
      secret: state[tokenIndex]!.secret || updatedToken.secret, // preserve secret until refresh
    };
  } else {
    state.push(updatedToken);
  }
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    setTokensQueryTimeout(state, { payload }: PayloadAction<number>) {
      state.dispatchTimeout = payload;
    },
    clearTokensQueryTimeout(state) {
      state.dispatchTimeout = null;
    },
    updateTokenField(state, { payload }: PayloadAction<FieldUpdatePayload>) {
      const token = state.tokens.find(t => t.uuid === payload.uuid);
      if (token) {
        if (payload.value === null) {
          delete token.modified[payload.name];
        } else {
          token.modified = {
            ...token.modified,
            [payload.name]: payload.value,
          };
        }
      }
    },
    clearTokenModifications(state, { payload }: PayloadAction<string>) {
      const token = state.tokens.find(token => token.uuid === payload);
      if (token) {
        token.modified = {};
      }
    },
    setAddModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.addModalOpen = payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchTokens.fulfilled, (state, { payload }) => {
        state.tokens = payload.apiTokensList.map(token => ({
          ...initialTokenState,
          ...token,
        }));
        state.requestedIds = payload.apiTokensList.map(({ uuid }) => uuid);
      })
      .addCase(fetchTokens.rejected, state => {
        state.requestedIds = [];
      })
      .addCase(createToken.fulfilled, (state, { payload }) => {
        updateTokenState(state.tokens, payload);
        state.addModalOpen = false;
      })
      .addCase(updateToken.fulfilled, (state, { payload }) => {
        updateTokenState(state.tokens, payload);
      })
      .addCase(deleteToken.fulfilled, (state, { meta }) => {
        state.tokens = state.tokens.filter(t => t.uuid !== meta.arg);
      }),
});

const { clearAction: clearTokenRequest, clearableReducer } = createClearableIndexedRequestReducer(
  reduceReducers(
    createIndexedRequestReducerFromThunk(createToken, () => NEW_TOKEN_PLACEHOLDER_ID),
    createIndexedRequestReducerFromThunk(updateToken, "uuid"),
    createIndexedRequestReducerFromThunk(deleteToken)
  ),
  "tokens"
);

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    tokens: createRequestReducerFromThunk(fetchTokens),
    token: clearableReducer,
  }),
});
export type TokensState = ReturnType<typeof reducer>;

export const tokenActions = { ...actions, clearTokenRequest };
export default reducer;
