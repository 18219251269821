import { createAsyncThunk } from "@reduxjs/toolkit";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";

import api_token_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/api_token_pb";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import { TokensRequest, NewToken, TokenUpdate } from "./types";

const createUpdateProto = (
  callback: (tokenProto: api_token_pb.ApiToken, updateMask: field_mask_pb.FieldMask) => void
) => {
  const tokenProto = new api_token_pb.ApiToken();
  const updateMask = new field_mask_pb.FieldMask();
  callback(tokenProto, updateMask);
  const updateProto = new api_token_pb.ApiTokenUpdateRequest();
  updateProto.setApiToken(tokenProto);
  updateProto.setUpdateMask(updateMask);
  return updateProto;
};

export const fetchTokens = createAsyncThunk("api_token/fetchAll", async (args?: TokensRequest) => {
  const responseProto = await sendRequest(endpoints.GET_TOKENS, args);
  return responseProto.toObject();
});

export const deleteToken = createAsyncThunk("api_token/deleteOne", async (uuid: string) => {
  const reponse = await sendRequest(endpoints.DELETE_TOKEN, { path: { uuid } });
  return reponse.toObject();
});

export const createToken = createAsyncThunk(
  "api_token/create",
  async ({ name, organizationId, scope, enabled }: NewToken) => {
    const updateProto = createUpdateProto((tokenProto, updateMask) => {
      updateMask.setPathsList(["scope", "name", "organization_id", "enabled"]);
      tokenProto.setName(name);
      tokenProto.setOrganizationId(organizationId);
      tokenProto.setScope(scope);
      tokenProto.setEnabled(enabled);
    });
    const token = await sendRequest(endpoints.ADD_TOKEN, { protobuf: updateProto });
    return token.toObject();
  }
);

export interface UpdateArgs {
  uuid: string;
  update: TokenUpdate;
}

export const updateToken = createAsyncThunk(
  "api_token/update",
  async ({ uuid, update }: UpdateArgs) => {
    const updateProto = createUpdateProto((tokenProto, updateMask) => {
      if ("name" in update) {
        updateMask.addPaths("name");
        if (update.name) tokenProto.setName(update.name);
      }
      if ("enabled" in update) {
        updateMask.addPaths("enabled");
        if (update.enabled) tokenProto.setEnabled(update.enabled);
      }
      if ("scope" in update) {
        updateMask.addPaths("scope");
        if (update.scope) tokenProto.setScope(update.scope);
      }
    });
    const token = await sendRequest(endpoints.UPDATE_TOKEN, {
      path: { uuid },
      protobuf: updateProto,
    });
    return token.toObject();
  }
);
