import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Button } from "../button/Button";

import type { HTMLAttributes } from "react";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { ButtonProps } from "../button/Button";

export const EmptyPage = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div className={cn("flex flex-col items-center gap-y-2", className)} {...props} ref={ref} />
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Icon: typeof EmptyPageIcon;
    Title: typeof EmptyPageTitle;
    Subtitle: typeof EmptyPageSubtitle;
    Button: typeof EmptyPageButton;
  }
>;
EmptyPage.displayName = "EmptyPage";

const EmptyPageIcon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={cn(
          "mb-2 flex h-24 w-24 items-center justify-center rounded-full bg-blue-50 text-4xl text-blue-400 dark:bg-gray-800",
          className
        )}
        {...props}
        ref={ref}
      />
    );
  }
);
EmptyPageIcon.displayName = "EmptyPageIcon";
EmptyPage.Icon = EmptyPageIcon;

const EmptyPageTitle = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => {
    return (
      // TODO(sam): Replace with rivet heading component
      <h2
        className={cn(
          "m-0 text-center text-2xl font-bold text-gray-900 dark:text-white",
          className
        )}
        {...props}
        ref={ref}
      />
    );
  }
);
EmptyPageTitle.displayName = "EmptyPageTitle";
EmptyPage.Title = EmptyPageTitle;

const EmptyPageSubtitle = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    // TODO(sam): Replace with rivet text component
    return (
      <p
        className={cn("max-w-1/2 m-0 text-center text-gray-800 dark:text-gray-400", className)}
        {...props}
        ref={ref}
      />
    );
  }
);
EmptyPageSubtitle.displayName = "EmptyPageSubtitle";
EmptyPage.Subtitle = EmptyPageSubtitle;

const EmptyPageButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => (
    <Button variant="primary" className="mt-2" {...props} ref={ref} />
  )
);
EmptyPageButton.displayName = "EmptyPageButton";
EmptyPage.Button = EmptyPageButton;
