import { createSelector } from "reselect";

import { INITIAL_REQUEST_STATE } from "@skydio/redux_util/src";

import { paginationStateToProps } from "../pagination/utils";
import { NEW_USER_PLACEHOLDER_EMAIL } from "./types";

import { UsersState, initialUserState } from "./slice";

export const getUsersRequest = ({ requests }: UsersState) => requests.users;

export const getUsersQueryTimeout = ({ state }: UsersState) => state.dispatchTimeout;

export const isUsersRequestActive = (state: UsersState) => getUsersRequest(state).active;

export const getUserRequest = (state: UsersState, email: string) =>
  state.requests.user[email] ?? INITIAL_REQUEST_STATE;

export const isUserRequestActive = (state: UsersState, email: string) =>
  getUserRequest(state, email)?.active;

export const getNewUserRequest = (state: UsersState) =>
  getUserRequest(state, NEW_USER_PLACEHOLDER_EMAIL);

export const getAllUsers = ({ state }: UsersState) => state.users;

export const getAllUserEmails = createSelector(getAllUsers, users => Object.keys(users));

export const getUser = (state: UsersState, email: string) =>
  getAllUsers(state)[email] ?? initialUserState;

export const getUserModifications = (state: UsersState, email: string) =>
  getUser(state, email).modified;

const getUsersRequestEmails = ({ state }: UsersState) => state.requestedIds;

export const getUsersForRequest = createSelector(
  getUsersRequestEmails,
  getAllUsers,
  (userEmails, users) => userEmails.map(email => users[email])
);

export const getUsersPagination = ({ state }: UsersState) => state.pagination;

export const getUsersPaginationProps = createSelector(getUsersPagination, paginationStateToProps);

export const getPilots = createSelector(getAllUsers, users =>
  Object.values(users).filter(user => user.organizationFlightCount > 0)
);
export const getOrgInviteRequest = (state: UsersState, email: string) =>
  state.requests.invites[email] || INITIAL_REQUEST_STATE;

export const getOrgRemovalRequest = (state: UsersState, email: string) =>
  state.requests.orgRemovals[email] || INITIAL_REQUEST_STATE;

export const getAcceptOrgInviteRequest = (state: UsersState) => state.requests.acceptedOrgInvite;
