import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";

import { fetchTokens, updateToken, deleteToken } from "./asyncThunks";
import {
  getTokensQueryTimeout,
  isTokensRequestActive,
  isTokenRequestActive,
  getTokenModifications,
} from "./selectors";
import { tokenActions, TokensState } from "./slice";
import { prepareTokensRequest } from "./utils";

import { ThunkCreator, AsyncThunkCreator } from "@skydio/redux_util/src";
import { TokensQueryState } from "./types";

const { clearTokensQueryTimeout, setTokensQueryTimeout } = tokenActions;

export const updateTokensQuery: ThunkCreator<TokensState, [TokensQueryState, boolean?]> =
  (
    fields,
    // this immediate arg allows us to specify whether to fetch right away or delay/debounce fetching
    // (which is the default)
    immediate
  ) =>
  (dispatch, getState) => {
    const newArgs = prepareTokensRequest(fields);

    if (immediate) {
      dispatch(fetchTokens(newArgs));
    } else {
      const state = getState();

      clearTimeout(getTokensQueryTimeout(state)!);
      dispatch(clearTokensQueryTimeout());

      const timeout = window?.setTimeout(() => dispatch(fetchTokens(newArgs)), QUERY_CHANGE_DELAY);
      dispatch(setTokensQueryTimeout(timeout));
    }

    dispatch(setSearchParams(newArgs));
  };

export const fetchTokensIfAble: AsyncThunkCreator<TokensState, [TokensQueryState]> =
  fields => async (dispatch, getState) => {
    const state = getState();
    if (!isTokensRequestActive(state)) {
      await dispatch(fetchTokens(prepareTokensRequest(fields)));
    }
  };

export const fetchAllTokensIfAble: AsyncThunkCreator<TokensState> =
  () => async (dispatch, getState) => {
    const state = getState();
    if (!isTokensRequestActive(state)) {
      // @ts-ignore TS2554
      await dispatch(fetchTokens());
    }
  };

export const updateTokenIfAble: AsyncThunkCreator<TokensState, [string]> =
  uuid => async (dispatch, getState) => {
    const state = getState();
    if (!isTokenRequestActive(state, uuid)) {
      const update = getTokenModifications(state, uuid);
      if (update) {
        dispatch(updateToken({ uuid, update }));
      }
    }
  };

export const deleteTokenIfAble: ThunkCreator<TokensState, [string]> =
  uuid => (dispatch, getState) => {
    if (!isTokenRequestActive(getState(), uuid)) {
      dispatch(deleteToken(uuid));
    }
  };
