import { useState } from "react";

import { useInfiniteScroll } from "./useInfiniteScroll";

import type { RefObject } from "react";

export interface UsePopoverInfiniteScrollProps {
  hasMore?: boolean;
  onLoadMore?: () => void;
}

/**
 * A specialized version of the useInfiniteScroll hook for use with data entry components that
 * use a listbox within a popover; i.e. Select, ComboBox, and SelectFilter.
 *
 * Spread the props returned from this hook directly onto the data entry component.
 */
export const usePopoverInfiniteScroll = ({
  hasMore = true,
  onLoadMore,
}: UsePopoverInfiniteScrollProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [loaderRef, scrollRef] = useInfiniteScroll({
    // Because the ListBox isn't being rendered until the popover is open, we need to toggle
    // isEnabled - this will trigger the observer inside useInfiniteScroll to start observing
    // the scroll container.
    isEnabled: isOpen,
    hasMore,
    onLoadMore,
  });

  return {
    scrollRef: scrollRef as RefObject<HTMLDivElement>,
    onOpenChange: setIsOpen as () => void,
    listboxProps: {
      isLoading: hasMore,
      loaderRef: loaderRef as RefObject<HTMLDivElement>,
    },
  };
};
