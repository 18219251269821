// source: pbtypes/mobile/phone/mobile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_flir_boson_driver_agc_settings_pb = require('../../../pbtypes/gen/flir_boson_driver/agc_settings_pb.js');
goog.object.extend(proto, pbtypes_gen_flir_boson_driver_agc_settings_pb);
var pbtypes_gen_phone_advanced_setting_pb = require('../../../pbtypes/gen/phone/advanced_setting_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_advanced_setting_pb);
var pbtypes_gen_phone_cellular_download_rule_pb = require('../../../pbtypes/gen/phone/cellular_download_rule_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_cellular_download_rule_pb);
var pbtypes_gen_phone_controls_preference_pb = require('../../../pbtypes/gen/phone/controls_preference_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_controls_preference_pb);
var pbtypes_gen_phone_app_theme_option_pb = require('../../../pbtypes/gen/phone/app_theme_option_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_app_theme_option_pb);
var pbtypes_gen_phone_display_units_pb = require('../../../pbtypes/gen/phone/display_units_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_display_units_pb);
var pbtypes_gen_phone_language_preference_pb = require('../../../pbtypes/gen/phone/language_preference_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_language_preference_pb);
var pbtypes_gen_phone_reality_pb = require('../../../pbtypes/gen/phone/reality_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_reality_pb);
var pbtypes_gen_phone_button_action_pb = require('../../../pbtypes/gen/phone/button_action_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_button_action_pb);
var pbtypes_gen_phone_map_coordinate_system_pb = require('../../../pbtypes/gen/phone/map_coordinate_system_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_map_coordinate_system_pb);
var pbtypes_gen_skills_ui_input_axis_pb = require('../../../pbtypes/gen/skills/ui_input_axis_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_ui_input_axis_pb);
var pbtypes_mobile_phone_location_pb = require('../../../pbtypes/mobile/phone/location_pb.js');
goog.object.extend(proto, pbtypes_mobile_phone_location_pb);
var pbtypes_mobile_phone_phone_stream_pb = require('../../../pbtypes/mobile/phone/phone_stream_pb.js');
goog.object.extend(proto, pbtypes_mobile_phone_phone_stream_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var pbtypes_tools_cloud_config_cloud_config_pb = require('../../../pbtypes/tools/cloud_config/cloud_config_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_config_cloud_config_pb);
var pbtypes_tools_cloud_api_ucon_pb = require('../../../pbtypes/tools/cloud_api/ucon_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_ucon_pb);
var pbtypes_tools_cloud_api_release_pb = require('../../../pbtypes/tools/cloud_api/release_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_release_pb);
var pbtypes_vehicle_infrastructure_flight_deck_media_pb = require('../../../pbtypes/vehicle/infrastructure/flight_deck/media_pb.js');
goog.object.extend(proto, pbtypes_vehicle_infrastructure_flight_deck_media_pb);
var pbtypes_gen_handhawk_main_radio_type_pb = require('../../../pbtypes/gen/handhawk_main/radio_type_pb.js');
goog.object.extend(proto, pbtypes_gen_handhawk_main_radio_type_pb);
var pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb = require('../../../pbtypes/experimental/webrtc_c_impl/webrtc_stats_pb.js');
goog.object.extend(proto, pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb);
goog.exportSymbol('proto.skydio.mobile.AgcPreset', null, global);
goog.exportSymbol('proto.skydio.mobile.BeaconInfo', null, global);
goog.exportSymbol('proto.skydio.mobile.ButtonMapping', null, global);
goog.exportSymbol('proto.skydio.mobile.ButtonMappingEntry', null, global);
goog.exportSymbol('proto.skydio.mobile.CloudConfigData', null, global);
goog.exportSymbol('proto.skydio.mobile.CloudConfigsByOrgData', null, global);
goog.exportSymbol('proto.skydio.mobile.ConnectedVehicleInfo', null, global);
goog.exportSymbol('proto.skydio.mobile.ControllerOta', null, global);
goog.exportSymbol('proto.skydio.mobile.CustomAgcProfile', null, global);
goog.exportSymbol('proto.skydio.mobile.DeveloperFlags', null, global);
goog.exportSymbol('proto.skydio.mobile.DroneData', null, global);
goog.exportSymbol('proto.skydio.mobile.FastCloudConfig', null, global);
goog.exportSymbol('proto.skydio.mobile.FlightData', null, global);
goog.exportSymbol('proto.skydio.mobile.FlightInfo', null, global);
goog.exportSymbol('proto.skydio.mobile.GpsAssistData', null, global);
goog.exportSymbol('proto.skydio.mobile.NetworkInfo', null, global);
goog.exportSymbol('proto.skydio.mobile.NetworkInterfaceInfo', null, global);
goog.exportSymbol('proto.skydio.mobile.NetworkInterfaceInfo.Transport', null, global);
goog.exportSymbol('proto.skydio.mobile.NetworkInterfaceInfo.Type', null, global);
goog.exportSymbol('proto.skydio.mobile.Notifications', null, global);
goog.exportSymbol('proto.skydio.mobile.OaVoxelRenderDistance', null, global);
goog.exportSymbol('proto.skydio.mobile.OaVoxelRenderStyle', null, global);
goog.exportSymbol('proto.skydio.mobile.OtaStatus', null, global);
goog.exportSymbol('proto.skydio.mobile.QueuedFile', null, global);
goog.exportSymbol('proto.skydio.mobile.QueuedFile.AudioDetail', null, global);
goog.exportSymbol('proto.skydio.mobile.QueuedFile.LogDetail', null, global);
goog.exportSymbol('proto.skydio.mobile.QueuedFile.OneofDetailCase', null, global);
goog.exportSymbol('proto.skydio.mobile.RemoteStreamingBitrate', null, global);
goog.exportSymbol('proto.skydio.mobile.RemoteStreamingStats', null, global);
goog.exportSymbol('proto.skydio.mobile.RequestMetadata', null, global);
goog.exportSymbol('proto.skydio.mobile.Settings', null, global);
goog.exportSymbol('proto.skydio.mobile.Settings.Advanced', null, global);
goog.exportSymbol('proto.skydio.mobile.Settings.Basic', null, global);
goog.exportSymbol('proto.skydio.mobile.Settings.Radio', null, global);
goog.exportSymbol('proto.skydio.mobile.SkillSession', null, global);
goog.exportSymbol('proto.skydio.mobile.Skills', null, global);
goog.exportSymbol('proto.skydio.mobile.StreamRecording', null, global);
goog.exportSymbol('proto.skydio.mobile.TelemetryConfig', null, global);
goog.exportSymbol('proto.skydio.mobile.TeleopStreamingStats', null, global);
goog.exportSymbol('proto.skydio.mobile.UconData', null, global);
goog.exportSymbol('proto.skydio.mobile.UconError', null, global);
goog.exportSymbol('proto.skydio.mobile.UconErrorCode', null, global);
goog.exportSymbol('proto.skydio.mobile.UconFileLocation', null, global);
goog.exportSymbol('proto.skydio.mobile.UconFileReceipt', null, global);
goog.exportSymbol('proto.skydio.mobile.UconFileStatus', null, global);
goog.exportSymbol('proto.skydio.mobile.UploadQueue', null, global);
goog.exportSymbol('proto.skydio.mobile.VehicleAddressPb', null, global);
goog.exportSymbol('proto.skydio.mobile.VehicleInfo', null, global);
goog.exportSymbol('proto.skydio.mobile.VehicleUpdate', null, global);
goog.exportSymbol('proto.skydio.mobile.WirePairedVehicle', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.ControllerOta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.ControllerOta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.ControllerOta.displayName = 'proto.skydio.mobile.ControllerOta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.StreamRecording = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.StreamRecording, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.StreamRecording.displayName = 'proto.skydio.mobile.StreamRecording';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.Notifications = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.Notifications, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.Notifications.displayName = 'proto.skydio.mobile.Notifications';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.SkillSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.SkillSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.SkillSession.displayName = 'proto.skydio.mobile.SkillSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.Skills = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skydio.mobile.Skills.repeatedFields_, null);
};
goog.inherits(proto.skydio.mobile.Skills, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.Skills.displayName = 'proto.skydio.mobile.Skills';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.FlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.FlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.FlightInfo.displayName = 'proto.skydio.mobile.FlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.FlightData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skydio.mobile.FlightData.repeatedFields_, null);
};
goog.inherits(proto.skydio.mobile.FlightData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.FlightData.displayName = 'proto.skydio.mobile.FlightData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.GpsAssistData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.GpsAssistData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.GpsAssistData.displayName = 'proto.skydio.mobile.GpsAssistData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.UconError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.UconError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.UconError.displayName = 'proto.skydio.mobile.UconError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.UconFileReceipt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skydio.mobile.UconFileReceipt.repeatedFields_, null);
};
goog.inherits(proto.skydio.mobile.UconFileReceipt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.UconFileReceipt.displayName = 'proto.skydio.mobile.UconFileReceipt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.UconData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.UconData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.UconData.displayName = 'proto.skydio.mobile.UconData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.CloudConfigData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.CloudConfigData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.CloudConfigData.displayName = 'proto.skydio.mobile.CloudConfigData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.CloudConfigsByOrgData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.CloudConfigsByOrgData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.CloudConfigsByOrgData.displayName = 'proto.skydio.mobile.CloudConfigsByOrgData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.FastCloudConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.FastCloudConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.FastCloudConfig.displayName = 'proto.skydio.mobile.FastCloudConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.RequestMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.RequestMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.RequestMetadata.displayName = 'proto.skydio.mobile.RequestMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.UploadQueue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skydio.mobile.UploadQueue.repeatedFields_, null);
};
goog.inherits(proto.skydio.mobile.UploadQueue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.UploadQueue.displayName = 'proto.skydio.mobile.UploadQueue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.QueuedFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skydio.mobile.QueuedFile.oneofGroups_);
};
goog.inherits(proto.skydio.mobile.QueuedFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.QueuedFile.displayName = 'proto.skydio.mobile.QueuedFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.QueuedFile.AudioDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.QueuedFile.AudioDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.QueuedFile.AudioDetail.displayName = 'proto.skydio.mobile.QueuedFile.AudioDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.QueuedFile.LogDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.QueuedFile.LogDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.QueuedFile.LogDetail.displayName = 'proto.skydio.mobile.QueuedFile.LogDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.ButtonMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.ButtonMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.ButtonMapping.displayName = 'proto.skydio.mobile.ButtonMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.ButtonMappingEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.ButtonMappingEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.ButtonMappingEntry.displayName = 'proto.skydio.mobile.ButtonMappingEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skydio.mobile.Settings.repeatedFields_, null);
};
goog.inherits(proto.skydio.mobile.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.Settings.displayName = 'proto.skydio.mobile.Settings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.Settings.Basic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.Settings.Basic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.Settings.Basic.displayName = 'proto.skydio.mobile.Settings.Basic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.Settings.Advanced = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.Settings.Advanced, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.Settings.Advanced.displayName = 'proto.skydio.mobile.Settings.Advanced';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.Settings.Radio = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.Settings.Radio, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.Settings.Radio.displayName = 'proto.skydio.mobile.Settings.Radio';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.CustomAgcProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.CustomAgcProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.CustomAgcProfile.displayName = 'proto.skydio.mobile.CustomAgcProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.TelemetryConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.TelemetryConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.TelemetryConfig.displayName = 'proto.skydio.mobile.TelemetryConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.DroneData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.DroneData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.DroneData.displayName = 'proto.skydio.mobile.DroneData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.VehicleAddressPb = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.VehicleAddressPb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.VehicleAddressPb.displayName = 'proto.skydio.mobile.VehicleAddressPb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.VehicleUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.VehicleUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.VehicleUpdate.displayName = 'proto.skydio.mobile.VehicleUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.VehicleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.VehicleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.VehicleInfo.displayName = 'proto.skydio.mobile.VehicleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.WirePairedVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.WirePairedVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.WirePairedVehicle.displayName = 'proto.skydio.mobile.WirePairedVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.BeaconInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.BeaconInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.BeaconInfo.displayName = 'proto.skydio.mobile.BeaconInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.DeveloperFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skydio.mobile.DeveloperFlags.repeatedFields_, null);
};
goog.inherits(proto.skydio.mobile.DeveloperFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.DeveloperFlags.displayName = 'proto.skydio.mobile.DeveloperFlags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.RemoteStreamingStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.RemoteStreamingStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.RemoteStreamingStats.displayName = 'proto.skydio.mobile.RemoteStreamingStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.TeleopStreamingStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.TeleopStreamingStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.TeleopStreamingStats.displayName = 'proto.skydio.mobile.TeleopStreamingStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.NetworkInterfaceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.NetworkInterfaceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.NetworkInterfaceInfo.displayName = 'proto.skydio.mobile.NetworkInterfaceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.NetworkInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skydio.mobile.NetworkInfo.repeatedFields_, null);
};
goog.inherits(proto.skydio.mobile.NetworkInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.NetworkInfo.displayName = 'proto.skydio.mobile.NetworkInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.ConnectedVehicleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.ConnectedVehicleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.ConnectedVehicleInfo.displayName = 'proto.skydio.mobile.ConnectedVehicleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.mobile.RemoteStreamingBitrate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.mobile.RemoteStreamingBitrate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.mobile.RemoteStreamingBitrate.displayName = 'proto.skydio.mobile.RemoteStreamingBitrate';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.ControllerOta.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.ControllerOta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.ControllerOta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.ControllerOta.toObject = function(includeInstance, msg) {
  var f, obj = {
status: jspb.Message.getFieldWithDefault(msg, 1, 0),
progress: jspb.Message.getFieldWithDefault(msg, 2, 0),
usbDisconnects: jspb.Message.getFieldWithDefault(msg, 3, 0),
message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.ControllerOta}
 */
proto.skydio.mobile.ControllerOta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.ControllerOta;
  return proto.skydio.mobile.ControllerOta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.ControllerOta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.ControllerOta}
 */
proto.skydio.mobile.ControllerOta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.skydio.mobile.OtaStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsbDisconnects(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.ControllerOta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.ControllerOta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.ControllerOta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.ControllerOta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUsbDisconnects();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional OtaStatus status = 1;
 * @return {!proto.skydio.mobile.OtaStatus}
 */
proto.skydio.mobile.ControllerOta.prototype.getStatus = function() {
  return /** @type {!proto.skydio.mobile.OtaStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skydio.mobile.OtaStatus} value
 * @return {!proto.skydio.mobile.ControllerOta} returns this
 */
proto.skydio.mobile.ControllerOta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 progress = 2;
 * @return {number}
 */
proto.skydio.mobile.ControllerOta.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.ControllerOta} returns this
 */
proto.skydio.mobile.ControllerOta.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 usb_disconnects = 3;
 * @return {number}
 */
proto.skydio.mobile.ControllerOta.prototype.getUsbDisconnects = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.ControllerOta} returns this
 */
proto.skydio.mobile.ControllerOta.prototype.setUsbDisconnects = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.skydio.mobile.ControllerOta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.ControllerOta} returns this
 */
proto.skydio.mobile.ControllerOta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.StreamRecording.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.StreamRecording.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.StreamRecording} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.StreamRecording.toObject = function(includeInstance, msg) {
  var f, obj = {
flightId: jspb.Message.getFieldWithDefault(msg, 5, ""),
mediaId: jspb.Message.getFieldWithDefault(msg, 3, ""),
filePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
startUtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.StreamRecording}
 */
proto.skydio.mobile.StreamRecording.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.StreamRecording;
  return proto.skydio.mobile.StreamRecording.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.StreamRecording} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.StreamRecording}
 */
proto.skydio.mobile.StreamRecording.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediaId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUtime(value);
      break;
    case 4:
      var value = /** @type {!proto.flight_deck.Media.MediaStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.StreamRecording.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.StreamRecording.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.StreamRecording} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.StreamRecording.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMediaId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartUtime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string flight_id = 5;
 * @return {string}
 */
proto.skydio.mobile.StreamRecording.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.StreamRecording} returns this
 */
proto.skydio.mobile.StreamRecording.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string media_id = 3;
 * @return {string}
 */
proto.skydio.mobile.StreamRecording.prototype.getMediaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.StreamRecording} returns this
 */
proto.skydio.mobile.StreamRecording.prototype.setMediaId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_path = 1;
 * @return {string}
 */
proto.skydio.mobile.StreamRecording.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.StreamRecording} returns this
 */
proto.skydio.mobile.StreamRecording.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_utime = 2;
 * @return {number}
 */
proto.skydio.mobile.StreamRecording.prototype.getStartUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.StreamRecording} returns this
 */
proto.skydio.mobile.StreamRecording.prototype.setStartUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional flight_deck.Media.MediaStatus status = 4;
 * @return {!proto.flight_deck.Media.MediaStatus}
 */
proto.skydio.mobile.StreamRecording.prototype.getStatus = function() {
  return /** @type {!proto.flight_deck.Media.MediaStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.flight_deck.Media.MediaStatus} value
 * @return {!proto.skydio.mobile.StreamRecording} returns this
 */
proto.skydio.mobile.StreamRecording.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.Notifications.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.Notifications.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.Notifications} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Notifications.toObject = function(includeInstance, msg) {
  var f, obj = {
completedUclockMap: (f = msg.getCompletedUclockMap()) ? f.toObject(includeInstance, undefined) : [],
completedTaggedNotificationsMap: (f = msg.getCompletedTaggedNotificationsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.Notifications}
 */
proto.skydio.mobile.Notifications.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.Notifications;
  return proto.skydio.mobile.Notifications.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.Notifications} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.Notifications}
 */
proto.skydio.mobile.Notifications.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = msg.getCompletedUclockMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readInt64, null, 0, 0);
         });
      break;
    case 3:
      var value = msg.getCompletedTaggedNotificationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.Notifications.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.Notifications.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.Notifications} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Notifications.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompletedUclockMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getCompletedTaggedNotificationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
};


/**
 * map<int32, int64> completed_uclock = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.skydio.mobile.Notifications.prototype.getCompletedUclockMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.Notifications} returns this
 */
proto.skydio.mobile.Notifications.prototype.clearCompletedUclockMap = function() {
  this.getCompletedUclockMap().clear();
  return this;
};


/**
 * map<string, int64> completed_tagged_notifications = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.skydio.mobile.Notifications.prototype.getCompletedTaggedNotificationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.Notifications} returns this
 */
proto.skydio.mobile.Notifications.prototype.clearCompletedTaggedNotificationsMap = function() {
  this.getCompletedTaggedNotificationsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.SkillSession.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.SkillSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.SkillSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.SkillSession.toObject = function(includeInstance, msg) {
  var f, obj = {
skillKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.SkillSession}
 */
proto.skydio.mobile.SkillSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.SkillSession;
  return proto.skydio.mobile.SkillSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.SkillSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.SkillSession}
 */
proto.skydio.mobile.SkillSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.SkillSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.SkillSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.SkillSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.SkillSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string skill_key = 1;
 * @return {string}
 */
proto.skydio.mobile.SkillSession.prototype.getSkillKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.SkillSession} returns this
 */
proto.skydio.mobile.SkillSession.prototype.setSkillKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skydio.mobile.Skills.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.Skills.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.Skills.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.Skills} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Skills.toObject = function(includeInstance, msg) {
  var f, obj = {
skillHistoryList: jspb.Message.toObjectList(msg.getSkillHistoryList(),
    proto.skydio.mobile.SkillSession.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.Skills}
 */
proto.skydio.mobile.Skills.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.Skills;
  return proto.skydio.mobile.Skills.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.Skills} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.Skills}
 */
proto.skydio.mobile.Skills.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skydio.mobile.SkillSession;
      reader.readMessage(value,proto.skydio.mobile.SkillSession.deserializeBinaryFromReader);
      msg.addSkillHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.Skills.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.Skills.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.Skills} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Skills.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.skydio.mobile.SkillSession.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SkillSession skill_history = 1;
 * @return {!Array<!proto.skydio.mobile.SkillSession>}
 */
proto.skydio.mobile.Skills.prototype.getSkillHistoryList = function() {
  return /** @type{!Array<!proto.skydio.mobile.SkillSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skydio.mobile.SkillSession, 1));
};


/**
 * @param {!Array<!proto.skydio.mobile.SkillSession>} value
 * @return {!proto.skydio.mobile.Skills} returns this
*/
proto.skydio.mobile.Skills.prototype.setSkillHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skydio.mobile.SkillSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skydio.mobile.SkillSession}
 */
proto.skydio.mobile.Skills.prototype.addSkillHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skydio.mobile.SkillSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skydio.mobile.Skills} returns this
 */
proto.skydio.mobile.Skills.prototype.clearSkillHistoryList = function() {
  return this.setSkillHistoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.FlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.FlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.FlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.FlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
beaconName: jspb.Message.getFieldWithDefault(msg, 4, ""),
beaconSerial: jspb.Message.getFieldWithDefault(msg, 3, ""),
takeoffPhoneId: jspb.Message.getFieldWithDefault(msg, 6, ""),
takeoffUserId: jspb.Message.getFieldWithDefault(msg, 11, ""),
takeoffUclock: jspb.Message.getFieldWithDefault(msg, 5, 0),
landingUclock: jspb.Message.getFieldWithDefault(msg, 10, 0),
deployCommit: jspb.Message.getFieldWithDefault(msg, 12, ""),
releaseKey: jspb.Message.getFieldWithDefault(msg, 13, ""),
lastCloudSaveUclock: jspb.Message.getFieldWithDefault(msg, 9, 0),
onBeacon: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
onVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.FlightInfo}
 */
proto.skydio.mobile.FlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.FlightInfo;
  return proto.skydio.mobile.FlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.FlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.FlightInfo}
 */
proto.skydio.mobile.FlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeaconName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeaconSerial(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffPhoneId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffUserId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTakeoffUclock(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLandingUclock(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeployCommit(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastCloudSaveUclock(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnBeacon(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.FlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.FlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.FlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.FlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBeaconName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBeaconSerial();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTakeoffPhoneId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTakeoffUserId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTakeoffUclock();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLandingUclock();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDeployCommit();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLastCloudSaveUclock();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getOnBeacon();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOnVehicle();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.skydio.mobile.FlightInfo.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.skydio.mobile.FlightInfo.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string beacon_name = 4;
 * @return {string}
 */
proto.skydio.mobile.FlightInfo.prototype.getBeaconName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setBeaconName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string beacon_serial = 3;
 * @return {string}
 */
proto.skydio.mobile.FlightInfo.prototype.getBeaconSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setBeaconSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string takeoff_phone_id = 6;
 * @return {string}
 */
proto.skydio.mobile.FlightInfo.prototype.getTakeoffPhoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setTakeoffPhoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string takeoff_user_id = 11;
 * @return {string}
 */
proto.skydio.mobile.FlightInfo.prototype.getTakeoffUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setTakeoffUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 takeoff_uclock = 5;
 * @return {number}
 */
proto.skydio.mobile.FlightInfo.prototype.getTakeoffUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setTakeoffUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 landing_uclock = 10;
 * @return {number}
 */
proto.skydio.mobile.FlightInfo.prototype.getLandingUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setLandingUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string deploy_commit = 12;
 * @return {string}
 */
proto.skydio.mobile.FlightInfo.prototype.getDeployCommit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setDeployCommit = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string release_key = 13;
 * @return {string}
 */
proto.skydio.mobile.FlightInfo.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 last_cloud_save_uclock = 9;
 * @return {number}
 */
proto.skydio.mobile.FlightInfo.prototype.getLastCloudSaveUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setLastCloudSaveUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool on_beacon = 7;
 * @return {boolean}
 */
proto.skydio.mobile.FlightInfo.prototype.getOnBeacon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setOnBeacon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool on_vehicle = 8;
 * @return {boolean}
 */
proto.skydio.mobile.FlightInfo.prototype.getOnVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.FlightInfo} returns this
 */
proto.skydio.mobile.FlightInfo.prototype.setOnVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skydio.mobile.FlightData.repeatedFields_ = [3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.FlightData.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.FlightData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.FlightData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.FlightData.toObject = function(includeInstance, msg) {
  var f, obj = {
recordingsList: jspb.Message.toObjectList(msg.getRecordingsList(),
    proto.skydio.mobile.StreamRecording.toObject, includeInstance),
notifications: (f = msg.getNotifications()) && proto.skydio.mobile.Notifications.toObject(includeInstance, f),
skills: (f = msg.getSkills()) && proto.skydio.mobile.Skills.toObject(includeInstance, f),
flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.skydio.mobile.FlightInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.FlightData}
 */
proto.skydio.mobile.FlightData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.FlightData;
  return proto.skydio.mobile.FlightData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.FlightData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.FlightData}
 */
proto.skydio.mobile.FlightData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.skydio.mobile.StreamRecording;
      reader.readMessage(value,proto.skydio.mobile.StreamRecording.deserializeBinaryFromReader);
      msg.addRecordings(value);
      break;
    case 5:
      var value = new proto.skydio.mobile.Notifications;
      reader.readMessage(value,proto.skydio.mobile.Notifications.deserializeBinaryFromReader);
      msg.setNotifications(value);
      break;
    case 6:
      var value = new proto.skydio.mobile.Skills;
      reader.readMessage(value,proto.skydio.mobile.Skills.deserializeBinaryFromReader);
      msg.setSkills(value);
      break;
    case 7:
      var value = new proto.skydio.mobile.FlightInfo;
      reader.readMessage(value,proto.skydio.mobile.FlightInfo.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.FlightData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.FlightData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.FlightData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.FlightData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skydio.mobile.StreamRecording.serializeBinaryToWriter
    );
  }
  f = message.getNotifications();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skydio.mobile.Notifications.serializeBinaryToWriter
    );
  }
  f = message.getSkills();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skydio.mobile.Skills.serializeBinaryToWriter
    );
  }
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.skydio.mobile.FlightInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StreamRecording recordings = 3;
 * @return {!Array<!proto.skydio.mobile.StreamRecording>}
 */
proto.skydio.mobile.FlightData.prototype.getRecordingsList = function() {
  return /** @type{!Array<!proto.skydio.mobile.StreamRecording>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skydio.mobile.StreamRecording, 3));
};


/**
 * @param {!Array<!proto.skydio.mobile.StreamRecording>} value
 * @return {!proto.skydio.mobile.FlightData} returns this
*/
proto.skydio.mobile.FlightData.prototype.setRecordingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skydio.mobile.StreamRecording=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skydio.mobile.StreamRecording}
 */
proto.skydio.mobile.FlightData.prototype.addRecordings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skydio.mobile.StreamRecording, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skydio.mobile.FlightData} returns this
 */
proto.skydio.mobile.FlightData.prototype.clearRecordingsList = function() {
  return this.setRecordingsList([]);
};


/**
 * optional Notifications notifications = 5;
 * @return {?proto.skydio.mobile.Notifications}
 */
proto.skydio.mobile.FlightData.prototype.getNotifications = function() {
  return /** @type{?proto.skydio.mobile.Notifications} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.Notifications, 5));
};


/**
 * @param {?proto.skydio.mobile.Notifications|undefined} value
 * @return {!proto.skydio.mobile.FlightData} returns this
*/
proto.skydio.mobile.FlightData.prototype.setNotifications = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.FlightData} returns this
 */
proto.skydio.mobile.FlightData.prototype.clearNotifications = function() {
  return this.setNotifications(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.FlightData.prototype.hasNotifications = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Skills skills = 6;
 * @return {?proto.skydio.mobile.Skills}
 */
proto.skydio.mobile.FlightData.prototype.getSkills = function() {
  return /** @type{?proto.skydio.mobile.Skills} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.Skills, 6));
};


/**
 * @param {?proto.skydio.mobile.Skills|undefined} value
 * @return {!proto.skydio.mobile.FlightData} returns this
*/
proto.skydio.mobile.FlightData.prototype.setSkills = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.FlightData} returns this
 */
proto.skydio.mobile.FlightData.prototype.clearSkills = function() {
  return this.setSkills(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.FlightData.prototype.hasSkills = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated FlightInfo flights = 7;
 * @return {!Array<!proto.skydio.mobile.FlightInfo>}
 */
proto.skydio.mobile.FlightData.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.skydio.mobile.FlightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skydio.mobile.FlightInfo, 7));
};


/**
 * @param {!Array<!proto.skydio.mobile.FlightInfo>} value
 * @return {!proto.skydio.mobile.FlightData} returns this
*/
proto.skydio.mobile.FlightData.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.skydio.mobile.FlightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skydio.mobile.FlightInfo}
 */
proto.skydio.mobile.FlightData.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.skydio.mobile.FlightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skydio.mobile.FlightData} returns this
 */
proto.skydio.mobile.FlightData.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.GpsAssistData.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.GpsAssistData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.GpsAssistData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.GpsAssistData.toObject = function(includeInstance, msg) {
  var f, obj = {
offlineFileUclock: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.GpsAssistData}
 */
proto.skydio.mobile.GpsAssistData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.GpsAssistData;
  return proto.skydio.mobile.GpsAssistData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.GpsAssistData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.GpsAssistData}
 */
proto.skydio.mobile.GpsAssistData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfflineFileUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.GpsAssistData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.GpsAssistData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.GpsAssistData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.GpsAssistData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfflineFileUclock();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 offline_file_uclock = 1;
 * @return {number}
 */
proto.skydio.mobile.GpsAssistData.prototype.getOfflineFileUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.GpsAssistData} returns this
 */
proto.skydio.mobile.GpsAssistData.prototype.setOfflineFileUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.UconError.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.UconError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.UconError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.UconError.toObject = function(includeInstance, msg) {
  var f, obj = {
timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
code: jspb.Message.getFieldWithDefault(msg, 2, 0),
uuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
taskId: jspb.Message.getFieldWithDefault(msg, 4, 0),
detailsMap: (f = msg.getDetailsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.UconError}
 */
proto.skydio.mobile.UconError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.UconError;
  return proto.skydio.mobile.UconError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.UconError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.UconError}
 */
proto.skydio.mobile.UconError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {!proto.skydio.mobile.UconErrorCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 5:
      var value = msg.getDetailsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.UconError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.UconError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.UconError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.UconError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDetailsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.skydio.mobile.UconError.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.UconError} returns this
 */
proto.skydio.mobile.UconError.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UconErrorCode code = 2;
 * @return {!proto.skydio.mobile.UconErrorCode}
 */
proto.skydio.mobile.UconError.prototype.getCode = function() {
  return /** @type {!proto.skydio.mobile.UconErrorCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skydio.mobile.UconErrorCode} value
 * @return {!proto.skydio.mobile.UconError} returns this
 */
proto.skydio.mobile.UconError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string uuid = 3;
 * @return {string}
 */
proto.skydio.mobile.UconError.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.UconError} returns this
 */
proto.skydio.mobile.UconError.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 task_id = 4;
 * @return {number}
 */
proto.skydio.mobile.UconError.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.UconError} returns this
 */
proto.skydio.mobile.UconError.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * map<string, string> details = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.skydio.mobile.UconError.prototype.getDetailsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconError} returns this
 */
proto.skydio.mobile.UconError.prototype.clearDetailsMap = function() {
  this.getDetailsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skydio.mobile.UconFileReceipt.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.UconFileReceipt.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.UconFileReceipt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.UconFileReceipt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.UconFileReceipt.toObject = function(includeInstance, msg) {
  var f, obj = {
filepath: jspb.Message.getFieldWithDefault(msg, 1, ""),
uuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
kind: jspb.Message.getFieldWithDefault(msg, 3, ""),
size: jspb.Message.getFieldWithDefault(msg, 4, 0),
location: jspb.Message.getFieldWithDefault(msg, 5, 0),
status: jspb.Message.getFieldWithDefault(msg, 6, 0),
vehicleId: jspb.Message.getFieldWithDefault(msg, 7, ""),
flightId: jspb.Message.getFieldWithDefault(msg, 8, ""),
isPhoneFile: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
completionTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
isErrored: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
errorsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.UconFileReceipt}
 */
proto.skydio.mobile.UconFileReceipt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.UconFileReceipt;
  return proto.skydio.mobile.UconFileReceipt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.UconFileReceipt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.UconFileReceipt}
 */
proto.skydio.mobile.UconFileReceipt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilepath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 5:
      var value = /** @type {!proto.skydio.mobile.UconFileLocation} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {!proto.skydio.mobile.UconFileStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPhoneFile(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletionTime(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsErrored(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.UconFileReceipt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.UconFileReceipt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.UconFileReceipt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.UconFileReceipt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilepath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsPhoneFile();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCompletionTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIsErrored();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
};


/**
 * optional string filepath = 1;
 * @return {string}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getFilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setFilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uuid = 2;
 * @return {string}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string kind = 3;
 * @return {string}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 size = 4;
 * @return {number}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional UconFileLocation location = 5;
 * @return {!proto.skydio.mobile.UconFileLocation}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getLocation = function() {
  return /** @type {!proto.skydio.mobile.UconFileLocation} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skydio.mobile.UconFileLocation} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional UconFileStatus status = 6;
 * @return {!proto.skydio.mobile.UconFileStatus}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getStatus = function() {
  return /** @type {!proto.skydio.mobile.UconFileStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.skydio.mobile.UconFileStatus} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string vehicle_id = 7;
 * @return {string}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string flight_id = 8;
 * @return {string}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_phone_file = 9;
 * @return {boolean}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getIsPhoneFile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setIsPhoneFile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 completion_time = 10;
 * @return {number}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getCompletionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setCompletionTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool is_errored = 11;
 * @return {boolean}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getIsErrored = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setIsErrored = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated string errors = 12;
 * @return {!Array<string>}
 */
proto.skydio.mobile.UconFileReceipt.prototype.getErrorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.setErrorsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.addErrors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skydio.mobile.UconFileReceipt} returns this
 */
proto.skydio.mobile.UconFileReceipt.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.UconData.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.UconData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.UconData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.UconData.toObject = function(includeInstance, msg) {
  var f, obj = {
pendingFileByUuidMap: (f = msg.getPendingFileByUuidMap()) ? f.toObject(includeInstance, proto.cloud_api.FileDeliveryInfo.toObject) : [],
deliveredFileByUuidMap: (f = msg.getDeliveredFileByUuidMap()) ? f.toObject(includeInstance, proto.cloud_api.FileDeliveryInfo.toObject) : [],
availableFileByUuidMap: (f = msg.getAvailableFileByUuidMap()) ? f.toObject(includeInstance, proto.cloud_api.FileDeliveryInfo.toObject) : [],
userFacingQueueFileReceiptByUuidMap: (f = msg.getUserFacingQueueFileReceiptByUuidMap()) ? f.toObject(includeInstance, proto.skydio.mobile.UconFileReceipt.toObject) : [],
historyFileReceiptByUuidMap: (f = msg.getHistoryFileReceiptByUuidMap()) ? f.toObject(includeInstance, proto.skydio.mobile.UconFileReceipt.toObject) : [],
totalExpectedSizeByKindMap: (f = msg.getTotalExpectedSizeByKindMap()) ? f.toObject(includeInstance, undefined) : [],
cellularUsageByKindMap: (f = msg.getCellularUsageByKindMap()) ? f.toObject(includeInstance, undefined) : [],
phoneFileByUuidMap: (f = msg.getPhoneFileByUuidMap()) ? f.toObject(includeInstance, proto.cloud_api.FileDeliveryInfo.toObject) : [],
phoneFilepathsByUuidMap: (f = msg.getPhoneFilepathsByUuidMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.UconData}
 */
proto.skydio.mobile.UconData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.UconData;
  return proto.skydio.mobile.UconData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.UconData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.UconData}
 */
proto.skydio.mobile.UconData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = msg.getPendingFileByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader, "", new proto.cloud_api.FileDeliveryInfo());
         });
      break;
    case 3:
      var value = msg.getDeliveredFileByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader, "", new proto.cloud_api.FileDeliveryInfo());
         });
      break;
    case 4:
      var value = msg.getAvailableFileByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader, "", new proto.cloud_api.FileDeliveryInfo());
         });
      break;
    case 9:
      var value = msg.getUserFacingQueueFileReceiptByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.skydio.mobile.UconFileReceipt.deserializeBinaryFromReader, "", new proto.skydio.mobile.UconFileReceipt());
         });
      break;
    case 10:
      var value = msg.getHistoryFileReceiptByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.skydio.mobile.UconFileReceipt.deserializeBinaryFromReader, "", new proto.skydio.mobile.UconFileReceipt());
         });
      break;
    case 5:
      var value = msg.getTotalExpectedSizeByKindMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 6:
      var value = msg.getCellularUsageByKindMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 7:
      var value = msg.getPhoneFileByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader, "", new proto.cloud_api.FileDeliveryInfo());
         });
      break;
    case 8:
      var value = msg.getPhoneFilepathsByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.UconData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.UconData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.UconData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.UconData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingFileByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter);
  }
  f = message.getDeliveredFileByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter);
  }
  f = message.getAvailableFileByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter);
  }
  f = message.getUserFacingQueueFileReceiptByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.skydio.mobile.UconFileReceipt.serializeBinaryToWriter);
  }
  f = message.getHistoryFileReceiptByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.skydio.mobile.UconFileReceipt.serializeBinaryToWriter);
  }
  f = message.getTotalExpectedSizeByKindMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getCellularUsageByKindMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getPhoneFileByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter);
  }
  f = message.getPhoneFilepathsByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, cloud_api.FileDeliveryInfo> pending_file_by_uuid = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>}
 */
proto.skydio.mobile.UconData.prototype.getPendingFileByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.cloud_api.FileDeliveryInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearPendingFileByUuidMap = function() {
  this.getPendingFileByUuidMap().clear();
  return this;
};


/**
 * map<string, cloud_api.FileDeliveryInfo> delivered_file_by_uuid = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>}
 */
proto.skydio.mobile.UconData.prototype.getDeliveredFileByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.cloud_api.FileDeliveryInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearDeliveredFileByUuidMap = function() {
  this.getDeliveredFileByUuidMap().clear();
  return this;
};


/**
 * map<string, cloud_api.FileDeliveryInfo> available_file_by_uuid = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>}
 */
proto.skydio.mobile.UconData.prototype.getAvailableFileByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.cloud_api.FileDeliveryInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearAvailableFileByUuidMap = function() {
  this.getAvailableFileByUuidMap().clear();
  return this;
};


/**
 * map<string, UconFileReceipt> user_facing_queue_file_receipt_by_uuid = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.skydio.mobile.UconFileReceipt>}
 */
proto.skydio.mobile.UconData.prototype.getUserFacingQueueFileReceiptByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.skydio.mobile.UconFileReceipt>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      proto.skydio.mobile.UconFileReceipt));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearUserFacingQueueFileReceiptByUuidMap = function() {
  this.getUserFacingQueueFileReceiptByUuidMap().clear();
  return this;
};


/**
 * map<string, UconFileReceipt> history_file_receipt_by_uuid = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.skydio.mobile.UconFileReceipt>}
 */
proto.skydio.mobile.UconData.prototype.getHistoryFileReceiptByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.skydio.mobile.UconFileReceipt>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      proto.skydio.mobile.UconFileReceipt));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearHistoryFileReceiptByUuidMap = function() {
  this.getHistoryFileReceiptByUuidMap().clear();
  return this;
};


/**
 * map<string, int64> total_expected_size_by_kind = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.skydio.mobile.UconData.prototype.getTotalExpectedSizeByKindMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearTotalExpectedSizeByKindMap = function() {
  this.getTotalExpectedSizeByKindMap().clear();
  return this;
};


/**
 * map<string, int64> cellular_usage_by_kind = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.skydio.mobile.UconData.prototype.getCellularUsageByKindMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearCellularUsageByKindMap = function() {
  this.getCellularUsageByKindMap().clear();
  return this;
};


/**
 * map<string, cloud_api.FileDeliveryInfo> phone_file_by_uuid = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>}
 */
proto.skydio.mobile.UconData.prototype.getPhoneFileByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.cloud_api.FileDeliveryInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearPhoneFileByUuidMap = function() {
  this.getPhoneFileByUuidMap().clear();
  return this;
};


/**
 * map<string, string> phone_filepaths_by_uuid = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.skydio.mobile.UconData.prototype.getPhoneFilepathsByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.UconData} returns this
 */
proto.skydio.mobile.UconData.prototype.clearPhoneFilepathsByUuidMap = function() {
  this.getPhoneFilepathsByUuidMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.CloudConfigData.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.CloudConfigData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.CloudConfigData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.CloudConfigData.toObject = function(includeInstance, msg) {
  var f, obj = {
cloudConfig: (f = msg.getCloudConfig()) && pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.toObject(includeInstance, f),
bundledConfigUclock: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.CloudConfigData}
 */
proto.skydio.mobile.CloudConfigData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.CloudConfigData;
  return proto.skydio.mobile.CloudConfigData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.CloudConfigData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.CloudConfigData}
 */
proto.skydio.mobile.CloudConfigData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig;
      reader.readMessage(value,pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.deserializeBinaryFromReader);
      msg.setCloudConfig(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBundledConfigUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.CloudConfigData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.CloudConfigData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.CloudConfigData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.CloudConfigData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloudConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.serializeBinaryToWriter
    );
  }
  f = message.getBundledConfigUclock();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional cloud_config.CloudConfig cloud_config = 1;
 * @return {?proto.cloud_config.CloudConfig}
 */
proto.skydio.mobile.CloudConfigData.prototype.getCloudConfig = function() {
  return /** @type{?proto.cloud_config.CloudConfig} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig, 1));
};


/**
 * @param {?proto.cloud_config.CloudConfig|undefined} value
 * @return {!proto.skydio.mobile.CloudConfigData} returns this
*/
proto.skydio.mobile.CloudConfigData.prototype.setCloudConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.CloudConfigData} returns this
 */
proto.skydio.mobile.CloudConfigData.prototype.clearCloudConfig = function() {
  return this.setCloudConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.CloudConfigData.prototype.hasCloudConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 bundled_config_uclock = 2;
 * @return {number}
 */
proto.skydio.mobile.CloudConfigData.prototype.getBundledConfigUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.CloudConfigData} returns this
 */
proto.skydio.mobile.CloudConfigData.prototype.setBundledConfigUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.CloudConfigsByOrgData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.CloudConfigsByOrgData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.CloudConfigsByOrgData.toObject = function(includeInstance, msg) {
  var f, obj = {
configsByOrg: (f = msg.getConfigsByOrg()) && pbtypes_tools_cloud_config_cloud_config_pb.VehicleCloudConfigsResponse.toObject(includeInstance, f),
activeConfig: (f = msg.getActiveConfig()) && pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.toObject(includeInstance, f),
defaultConfig: (f = msg.getDefaultConfig()) && pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.toObject(includeInstance, f),
configActivated: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
bundledConfigUclock: jspb.Message.getFieldWithDefault(msg, 5, 0),
configMigrated: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
cloudSyncedUclock: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData}
 */
proto.skydio.mobile.CloudConfigsByOrgData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.CloudConfigsByOrgData;
  return proto.skydio.mobile.CloudConfigsByOrgData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.CloudConfigsByOrgData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData}
 */
proto.skydio.mobile.CloudConfigsByOrgData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_tools_cloud_config_cloud_config_pb.VehicleCloudConfigsResponse;
      reader.readMessage(value,pbtypes_tools_cloud_config_cloud_config_pb.VehicleCloudConfigsResponse.deserializeBinaryFromReader);
      msg.setConfigsByOrg(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig;
      reader.readMessage(value,pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.deserializeBinaryFromReader);
      msg.setActiveConfig(value);
      break;
    case 3:
      var value = new pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig;
      reader.readMessage(value,pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.deserializeBinaryFromReader);
      msg.setDefaultConfig(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigActivated(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBundledConfigUclock(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigMigrated(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudSyncedUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.CloudConfigsByOrgData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.CloudConfigsByOrgData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.CloudConfigsByOrgData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigsByOrg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_tools_cloud_config_cloud_config_pb.VehicleCloudConfigsResponse.serializeBinaryToWriter
    );
  }
  f = message.getActiveConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.serializeBinaryToWriter
    );
  }
  f = message.getDefaultConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig.serializeBinaryToWriter
    );
  }
  f = message.getConfigActivated();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBundledConfigUclock();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getConfigMigrated();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCloudSyncedUclock();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional cloud_config.VehicleCloudConfigsResponse configs_by_org = 1;
 * @return {?proto.cloud_config.VehicleCloudConfigsResponse}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.getConfigsByOrg = function() {
  return /** @type{?proto.cloud_config.VehicleCloudConfigsResponse} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_config_cloud_config_pb.VehicleCloudConfigsResponse, 1));
};


/**
 * @param {?proto.cloud_config.VehicleCloudConfigsResponse|undefined} value
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
*/
proto.skydio.mobile.CloudConfigsByOrgData.prototype.setConfigsByOrg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.clearConfigsByOrg = function() {
  return this.setConfigsByOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.hasConfigsByOrg = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cloud_config.CloudConfig active_config = 2;
 * @return {?proto.cloud_config.CloudConfig}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.getActiveConfig = function() {
  return /** @type{?proto.cloud_config.CloudConfig} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig, 2));
};


/**
 * @param {?proto.cloud_config.CloudConfig|undefined} value
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
*/
proto.skydio.mobile.CloudConfigsByOrgData.prototype.setActiveConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.clearActiveConfig = function() {
  return this.setActiveConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.hasActiveConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional cloud_config.CloudConfig default_config = 3;
 * @return {?proto.cloud_config.CloudConfig}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.getDefaultConfig = function() {
  return /** @type{?proto.cloud_config.CloudConfig} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_config_cloud_config_pb.CloudConfig, 3));
};


/**
 * @param {?proto.cloud_config.CloudConfig|undefined} value
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
*/
proto.skydio.mobile.CloudConfigsByOrgData.prototype.setDefaultConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.clearDefaultConfig = function() {
  return this.setDefaultConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.hasDefaultConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool config_activated = 4;
 * @return {boolean}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.getConfigActivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.setConfigActivated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 bundled_config_uclock = 5;
 * @return {number}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.getBundledConfigUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.setBundledConfigUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool config_migrated = 6;
 * @return {boolean}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.getConfigMigrated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.setConfigMigrated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 cloud_synced_uclock = 7;
 * @return {number}
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.getCloudSyncedUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.CloudConfigsByOrgData} returns this
 */
proto.skydio.mobile.CloudConfigsByOrgData.prototype.setCloudSyncedUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.FastCloudConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.FastCloudConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.FastCloudConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.FastCloudConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
uclock: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.FastCloudConfig}
 */
proto.skydio.mobile.FastCloudConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.FastCloudConfig;
  return proto.skydio.mobile.FastCloudConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.FastCloudConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.FastCloudConfig}
 */
proto.skydio.mobile.FastCloudConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.FastCloudConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.FastCloudConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.FastCloudConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.FastCloudConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUclock();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 uclock = 1;
 * @return {number}
 */
proto.skydio.mobile.FastCloudConfig.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.FastCloudConfig} returns this
 */
proto.skydio.mobile.FastCloudConfig.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.RequestMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.RequestMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.RequestMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.RequestMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
succeeded: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
attempts: jspb.Message.getFieldWithDefault(msg, 2, 0),
lastAttemptUclock: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.RequestMetadata}
 */
proto.skydio.mobile.RequestMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.RequestMetadata;
  return proto.skydio.mobile.RequestMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.RequestMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.RequestMetadata}
 */
proto.skydio.mobile.RequestMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSucceeded(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttempts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastAttemptUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.RequestMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.RequestMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.RequestMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.RequestMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSucceeded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAttempts();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastAttemptUclock();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional bool succeeded = 1;
 * @return {boolean}
 */
proto.skydio.mobile.RequestMetadata.prototype.getSucceeded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.RequestMetadata} returns this
 */
proto.skydio.mobile.RequestMetadata.prototype.setSucceeded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 attempts = 2;
 * @return {number}
 */
proto.skydio.mobile.RequestMetadata.prototype.getAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.RequestMetadata} returns this
 */
proto.skydio.mobile.RequestMetadata.prototype.setAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_attempt_uclock = 3;
 * @return {number}
 */
proto.skydio.mobile.RequestMetadata.prototype.getLastAttemptUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.RequestMetadata} returns this
 */
proto.skydio.mobile.RequestMetadata.prototype.setLastAttemptUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skydio.mobile.UploadQueue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.UploadQueue.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.UploadQueue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.UploadQueue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.UploadQueue.toObject = function(includeInstance, msg) {
  var f, obj = {
uploadQueueList: jspb.Message.toObjectList(msg.getUploadQueueList(),
    proto.skydio.mobile.QueuedFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.UploadQueue}
 */
proto.skydio.mobile.UploadQueue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.UploadQueue;
  return proto.skydio.mobile.UploadQueue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.UploadQueue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.UploadQueue}
 */
proto.skydio.mobile.UploadQueue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skydio.mobile.QueuedFile;
      reader.readMessage(value,proto.skydio.mobile.QueuedFile.deserializeBinaryFromReader);
      msg.addUploadQueue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.UploadQueue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.UploadQueue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.UploadQueue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.UploadQueue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadQueueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.skydio.mobile.QueuedFile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QueuedFile upload_queue = 1;
 * @return {!Array<!proto.skydio.mobile.QueuedFile>}
 */
proto.skydio.mobile.UploadQueue.prototype.getUploadQueueList = function() {
  return /** @type{!Array<!proto.skydio.mobile.QueuedFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skydio.mobile.QueuedFile, 1));
};


/**
 * @param {!Array<!proto.skydio.mobile.QueuedFile>} value
 * @return {!proto.skydio.mobile.UploadQueue} returns this
*/
proto.skydio.mobile.UploadQueue.prototype.setUploadQueueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skydio.mobile.QueuedFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skydio.mobile.QueuedFile}
 */
proto.skydio.mobile.UploadQueue.prototype.addUploadQueue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skydio.mobile.QueuedFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skydio.mobile.UploadQueue} returns this
 */
proto.skydio.mobile.UploadQueue.prototype.clearUploadQueueList = function() {
  return this.setUploadQueueList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skydio.mobile.QueuedFile.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.skydio.mobile.QueuedFile.OneofDetailCase = {
  ONEOF_DETAIL_NOT_SET: 0,
  AUDIO_DETAIL: 6,
  LOG_DETAIL: 7
};

/**
 * @return {proto.skydio.mobile.QueuedFile.OneofDetailCase}
 */
proto.skydio.mobile.QueuedFile.prototype.getOneofDetailCase = function() {
  return /** @type {proto.skydio.mobile.QueuedFile.OneofDetailCase} */(jspb.Message.computeOneofCase(this, proto.skydio.mobile.QueuedFile.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.QueuedFile.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.QueuedFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.QueuedFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.QueuedFile.toObject = function(includeInstance, msg) {
  var f, obj = {
uclock: jspb.Message.getFieldWithDefault(msg, 1, 0),
filePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
flightId: jspb.Message.getFieldWithDefault(msg, 4, ""),
uploadRequestMetadata: (f = msg.getUploadRequestMetadata()) && proto.skydio.mobile.RequestMetadata.toObject(includeInstance, f),
audioDetail: (f = msg.getAudioDetail()) && proto.skydio.mobile.QueuedFile.AudioDetail.toObject(includeInstance, f),
logDetail: (f = msg.getLogDetail()) && proto.skydio.mobile.QueuedFile.LogDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.QueuedFile}
 */
proto.skydio.mobile.QueuedFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.QueuedFile;
  return proto.skydio.mobile.QueuedFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.QueuedFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.QueuedFile}
 */
proto.skydio.mobile.QueuedFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUclock(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 5:
      var value = new proto.skydio.mobile.RequestMetadata;
      reader.readMessage(value,proto.skydio.mobile.RequestMetadata.deserializeBinaryFromReader);
      msg.setUploadRequestMetadata(value);
      break;
    case 6:
      var value = new proto.skydio.mobile.QueuedFile.AudioDetail;
      reader.readMessage(value,proto.skydio.mobile.QueuedFile.AudioDetail.deserializeBinaryFromReader);
      msg.setAudioDetail(value);
      break;
    case 7:
      var value = new proto.skydio.mobile.QueuedFile.LogDetail;
      reader.readMessage(value,proto.skydio.mobile.QueuedFile.LogDetail.deserializeBinaryFromReader);
      msg.setLogDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.QueuedFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.QueuedFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.QueuedFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.QueuedFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUclock();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUploadRequestMetadata();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skydio.mobile.RequestMetadata.serializeBinaryToWriter
    );
  }
  f = message.getAudioDetail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skydio.mobile.QueuedFile.AudioDetail.serializeBinaryToWriter
    );
  }
  f = message.getLogDetail();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skydio.mobile.QueuedFile.LogDetail.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.QueuedFile.AudioDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.QueuedFile.AudioDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.QueuedFile.AudioDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
startUtime: jspb.Message.getFieldWithDefault(msg, 1, 0),
promiseId: jspb.Message.getFieldWithDefault(msg, 2, ""),
promiseRequestMetadata: (f = msg.getPromiseRequestMetadata()) && proto.skydio.mobile.RequestMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.QueuedFile.AudioDetail}
 */
proto.skydio.mobile.QueuedFile.AudioDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.QueuedFile.AudioDetail;
  return proto.skydio.mobile.QueuedFile.AudioDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.QueuedFile.AudioDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.QueuedFile.AudioDetail}
 */
proto.skydio.mobile.QueuedFile.AudioDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromiseId(value);
      break;
    case 3:
      var value = new proto.skydio.mobile.RequestMetadata;
      reader.readMessage(value,proto.skydio.mobile.RequestMetadata.deserializeBinaryFromReader);
      msg.setPromiseRequestMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.QueuedFile.AudioDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.QueuedFile.AudioDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.QueuedFile.AudioDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPromiseId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPromiseRequestMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skydio.mobile.RequestMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 start_utime = 1;
 * @return {number}
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.getStartUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.QueuedFile.AudioDetail} returns this
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.setStartUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string promise_id = 2;
 * @return {string}
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.getPromiseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.QueuedFile.AudioDetail} returns this
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.setPromiseId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RequestMetadata promise_request_metadata = 3;
 * @return {?proto.skydio.mobile.RequestMetadata}
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.getPromiseRequestMetadata = function() {
  return /** @type{?proto.skydio.mobile.RequestMetadata} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.RequestMetadata, 3));
};


/**
 * @param {?proto.skydio.mobile.RequestMetadata|undefined} value
 * @return {!proto.skydio.mobile.QueuedFile.AudioDetail} returns this
*/
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.setPromiseRequestMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.QueuedFile.AudioDetail} returns this
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.clearPromiseRequestMetadata = function() {
  return this.setPromiseRequestMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.QueuedFile.AudioDetail.prototype.hasPromiseRequestMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.QueuedFile.LogDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.QueuedFile.LogDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.QueuedFile.LogDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.QueuedFile.LogDetail.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.QueuedFile.LogDetail}
 */
proto.skydio.mobile.QueuedFile.LogDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.QueuedFile.LogDetail;
  return proto.skydio.mobile.QueuedFile.LogDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.QueuedFile.LogDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.QueuedFile.LogDetail}
 */
proto.skydio.mobile.QueuedFile.LogDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.QueuedFile.LogDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.QueuedFile.LogDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.QueuedFile.LogDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.QueuedFile.LogDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional int64 uclock = 1;
 * @return {number}
 */
proto.skydio.mobile.QueuedFile.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.QueuedFile} returns this
 */
proto.skydio.mobile.QueuedFile.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string file_path = 2;
 * @return {string}
 */
proto.skydio.mobile.QueuedFile.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.QueuedFile} returns this
 */
proto.skydio.mobile.QueuedFile.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.skydio.mobile.QueuedFile.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.QueuedFile} returns this
 */
proto.skydio.mobile.QueuedFile.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string flight_id = 4;
 * @return {string}
 */
proto.skydio.mobile.QueuedFile.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.QueuedFile} returns this
 */
proto.skydio.mobile.QueuedFile.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RequestMetadata upload_request_metadata = 5;
 * @return {?proto.skydio.mobile.RequestMetadata}
 */
proto.skydio.mobile.QueuedFile.prototype.getUploadRequestMetadata = function() {
  return /** @type{?proto.skydio.mobile.RequestMetadata} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.RequestMetadata, 5));
};


/**
 * @param {?proto.skydio.mobile.RequestMetadata|undefined} value
 * @return {!proto.skydio.mobile.QueuedFile} returns this
*/
proto.skydio.mobile.QueuedFile.prototype.setUploadRequestMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.QueuedFile} returns this
 */
proto.skydio.mobile.QueuedFile.prototype.clearUploadRequestMetadata = function() {
  return this.setUploadRequestMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.QueuedFile.prototype.hasUploadRequestMetadata = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AudioDetail audio_detail = 6;
 * @return {?proto.skydio.mobile.QueuedFile.AudioDetail}
 */
proto.skydio.mobile.QueuedFile.prototype.getAudioDetail = function() {
  return /** @type{?proto.skydio.mobile.QueuedFile.AudioDetail} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.QueuedFile.AudioDetail, 6));
};


/**
 * @param {?proto.skydio.mobile.QueuedFile.AudioDetail|undefined} value
 * @return {!proto.skydio.mobile.QueuedFile} returns this
*/
proto.skydio.mobile.QueuedFile.prototype.setAudioDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.skydio.mobile.QueuedFile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.QueuedFile} returns this
 */
proto.skydio.mobile.QueuedFile.prototype.clearAudioDetail = function() {
  return this.setAudioDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.QueuedFile.prototype.hasAudioDetail = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LogDetail log_detail = 7;
 * @return {?proto.skydio.mobile.QueuedFile.LogDetail}
 */
proto.skydio.mobile.QueuedFile.prototype.getLogDetail = function() {
  return /** @type{?proto.skydio.mobile.QueuedFile.LogDetail} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.QueuedFile.LogDetail, 7));
};


/**
 * @param {?proto.skydio.mobile.QueuedFile.LogDetail|undefined} value
 * @return {!proto.skydio.mobile.QueuedFile} returns this
*/
proto.skydio.mobile.QueuedFile.prototype.setLogDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.skydio.mobile.QueuedFile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.QueuedFile} returns this
 */
proto.skydio.mobile.QueuedFile.prototype.clearLogDetail = function() {
  return this.setLogDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.QueuedFile.prototype.hasLogDetail = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.ButtonMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.ButtonMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.ButtonMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.ButtonMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
buttonActionMapMap: (f = msg.getButtonActionMapMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.ButtonMapping}
 */
proto.skydio.mobile.ButtonMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.ButtonMapping;
  return proto.skydio.mobile.ButtonMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.ButtonMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.ButtonMapping}
 */
proto.skydio.mobile.ButtonMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getButtonActionMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readInt32, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.ButtonMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.ButtonMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.ButtonMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.ButtonMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getButtonActionMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * map<int32, int32> button_action_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.skydio.mobile.ButtonMapping.prototype.getButtonActionMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.ButtonMapping} returns this
 */
proto.skydio.mobile.ButtonMapping.prototype.clearButtonActionMapMap = function() {
  this.getButtonActionMapMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.ButtonMappingEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.ButtonMappingEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.ButtonMappingEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.ButtonMappingEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
button: jspb.Message.getFieldWithDefault(msg, 1, 0),
action: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.ButtonMappingEntry}
 */
proto.skydio.mobile.ButtonMappingEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.ButtonMappingEntry;
  return proto.skydio.mobile.ButtonMappingEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.ButtonMappingEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.ButtonMappingEntry}
 */
proto.skydio.mobile.ButtonMappingEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.skills.UiInputAxis.Enum} */ (reader.readEnum());
      msg.setButton(value);
      break;
    case 2:
      var value = /** @type {!proto.phone.ButtonAction.Enum} */ (reader.readEnum());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.ButtonMappingEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.ButtonMappingEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.ButtonMappingEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.ButtonMappingEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getButton();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional skills.UiInputAxis.Enum button = 1;
 * @return {!proto.skills.UiInputAxis.Enum}
 */
proto.skydio.mobile.ButtonMappingEntry.prototype.getButton = function() {
  return /** @type {!proto.skills.UiInputAxis.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skills.UiInputAxis.Enum} value
 * @return {!proto.skydio.mobile.ButtonMappingEntry} returns this
 */
proto.skydio.mobile.ButtonMappingEntry.prototype.setButton = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional phone.ButtonAction.Enum action = 2;
 * @return {!proto.phone.ButtonAction.Enum}
 */
proto.skydio.mobile.ButtonMappingEntry.prototype.getAction = function() {
  return /** @type {!proto.phone.ButtonAction.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.phone.ButtonAction.Enum} value
 * @return {!proto.skydio.mobile.ButtonMappingEntry} returns this
 */
proto.skydio.mobile.ButtonMappingEntry.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skydio.mobile.Settings.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.Settings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
basic: (f = msg.getBasic()) && proto.skydio.mobile.Settings.Basic.toObject(includeInstance, f),
advancedList: jspb.Message.toObjectList(msg.getAdvancedList(),
    proto.skydio.mobile.Settings.Advanced.toObject, includeInstance),
radio: (f = msg.getRadio()) && proto.skydio.mobile.Settings.Radio.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.Settings}
 */
proto.skydio.mobile.Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.Settings;
  return proto.skydio.mobile.Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.Settings}
 */
proto.skydio.mobile.Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skydio.mobile.Settings.Basic;
      reader.readMessage(value,proto.skydio.mobile.Settings.Basic.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    case 2:
      var value = new proto.skydio.mobile.Settings.Advanced;
      reader.readMessage(value,proto.skydio.mobile.Settings.Advanced.deserializeBinaryFromReader);
      msg.addAdvanced(value);
      break;
    case 3:
      var value = new proto.skydio.mobile.Settings.Radio;
      reader.readMessage(value,proto.skydio.mobile.Settings.Radio.deserializeBinaryFromReader);
      msg.setRadio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Settings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skydio.mobile.Settings.Basic.serializeBinaryToWriter
    );
  }
  f = message.getAdvancedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.skydio.mobile.Settings.Advanced.serializeBinaryToWriter
    );
  }
  f = message.getRadio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skydio.mobile.Settings.Radio.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.Settings.Basic.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.Settings.Basic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.Settings.Basic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Settings.Basic.toObject = function(includeInstance, msg) {
  var f, obj = {
invertGamepad: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
naturalDragging: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
recordPhoneAudio: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
showErrorStats: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
simulatorRenderMode: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
isPrecisionModeEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
cellularDownloadRule: jspb.Message.getFieldWithDefault(msg, 10, 0),
displayUnits: jspb.Message.getFieldWithDefault(msg, 14, 0),
controlsPreference: jspb.Message.getFieldWithDefault(msg, 15, 0),
showThermal: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
skysticksInvertGimbalAxis: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
skysticksInvertZoomAxis: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
pinchToFlyPreferred: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
doubleTapToFlyDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
showMapCoordinates: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
showMapCenterCrosshairs: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
mapCoordinateSystem: jspb.Message.getFieldWithDefault(msg, 22, 0),
consumerHeightCeilingEditable: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
languagePreference: jspb.Message.getFieldWithDefault(msg, 24, 0),
buttonMapping: (f = msg.getButtonMapping()) && proto.skydio.mobile.ButtonMapping.toObject(includeInstance, f),
thermalRegionSize: jspb.Message.getFieldWithDefault(msg, 27, 0),
agcPreset: jspb.Message.getFieldWithDefault(msg, 28, 0),
customAgcProfile: (f = msg.getCustomAgcProfile()) && proto.skydio.mobile.CustomAgcProfile.toObject(includeInstance, f),
showCockpitCrosshair: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
streamConfig: (f = msg.getStreamConfig()) && pbtypes_mobile_phone_phone_stream_pb.StreamConfig.toObject(includeInstance, f),
telemetryConfig: (f = msg.getTelemetryConfig()) && proto.skydio.mobile.TelemetryConfig.toObject(includeInstance, f),
appTheme: jspb.Message.getFieldWithDefault(msg, 34, 0),
showOaVoxels: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
oaVoxelRenderDistance: jspb.Message.getFieldWithDefault(msg, 36, 0),
oaVoxelRenderStyle: jspb.Message.getFieldWithDefault(msg, 37, 0),
showVirtualGimbal: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
displayBrightnessPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
show2ghzToggle: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
dimControllerLeds: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
enableFlightScreenrecord: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
txPowerSlider: jspb.Message.getFieldWithDefault(msg, 44, 0),
txpowerOverride: jspb.Message.getBooleanFieldWithDefault(msg, 45, false),
externalRadioSelected: jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
rtkMode: jspb.Message.getBooleanFieldWithDefault(msg, 47, false),
disableSkysticksJoystickInputs: jspb.Message.getBooleanFieldWithDefault(msg, 48, false),
volumePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
enableParachuteAdvancedStatus: jspb.Message.getBooleanFieldWithDefault(msg, 51, false),
attiReturnCancelToggle: jspb.Message.getBooleanFieldWithDefault(msg, 52, false),
attiReturnCancelSlider: jspb.Message.getFieldWithDefault(msg, 53, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.Settings.Basic}
 */
proto.skydio.mobile.Settings.Basic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.Settings.Basic;
  return proto.skydio.mobile.Settings.Basic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.Settings.Basic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.Settings.Basic}
 */
proto.skydio.mobile.Settings.Basic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvertGamepad(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNaturalDragging(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecordPhoneAudio(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowErrorStats(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSimulatorRenderMode(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrecisionModeEnabled(value);
      break;
    case 10:
      var value = /** @type {!proto.phone.CellularDownloadRule.Enum} */ (reader.readEnum());
      msg.setCellularDownloadRule(value);
      break;
    case 14:
      var value = /** @type {!proto.phone.DisplayUnits.Enum} */ (reader.readEnum());
      msg.setDisplayUnits(value);
      break;
    case 15:
      var value = /** @type {!proto.phone.ControlsPreference.Enum} */ (reader.readEnum());
      msg.setControlsPreference(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowThermal(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkysticksInvertGimbalAxis(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkysticksInvertZoomAxis(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPinchToFlyPreferred(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoubleTapToFlyDisabled(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowMapCoordinates(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowMapCenterCrosshairs(value);
      break;
    case 22:
      var value = /** @type {!proto.phone.MapCoordinateSystem.Enum} */ (reader.readEnum());
      msg.setMapCoordinateSystem(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsumerHeightCeilingEditable(value);
      break;
    case 24:
      var value = /** @type {!proto.phone.LanguagePreference.Enum} */ (reader.readEnum());
      msg.setLanguagePreference(value);
      break;
    case 25:
      var value = new proto.skydio.mobile.ButtonMapping;
      reader.readMessage(value,proto.skydio.mobile.ButtonMapping.deserializeBinaryFromReader);
      msg.setButtonMapping(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setThermalRegionSize(value);
      break;
    case 28:
      var value = /** @type {!proto.skydio.mobile.AgcPreset} */ (reader.readEnum());
      msg.setAgcPreset(value);
      break;
    case 29:
      var value = new proto.skydio.mobile.CustomAgcProfile;
      reader.readMessage(value,proto.skydio.mobile.CustomAgcProfile.deserializeBinaryFromReader);
      msg.setCustomAgcProfile(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowCockpitCrosshair(value);
      break;
    case 31:
      var value = new pbtypes_mobile_phone_phone_stream_pb.StreamConfig;
      reader.readMessage(value,pbtypes_mobile_phone_phone_stream_pb.StreamConfig.deserializeBinaryFromReader);
      msg.setStreamConfig(value);
      break;
    case 33:
      var value = new proto.skydio.mobile.TelemetryConfig;
      reader.readMessage(value,proto.skydio.mobile.TelemetryConfig.deserializeBinaryFromReader);
      msg.setTelemetryConfig(value);
      break;
    case 34:
      var value = /** @type {!proto.phone.AppThemeOption.Enum} */ (reader.readEnum());
      msg.setAppTheme(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowOaVoxels(value);
      break;
    case 36:
      var value = /** @type {!proto.skydio.mobile.OaVoxelRenderDistance} */ (reader.readEnum());
      msg.setOaVoxelRenderDistance(value);
      break;
    case 37:
      var value = /** @type {!proto.skydio.mobile.OaVoxelRenderStyle} */ (reader.readEnum());
      msg.setOaVoxelRenderStyle(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowVirtualGimbal(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDisplayBrightnessPercent(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShow2ghzToggle(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDimControllerLeds(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableFlightScreenrecord(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTxPowerSlider(value);
      break;
    case 45:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTxpowerOverride(value);
      break;
    case 46:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternalRadioSelected(value);
      break;
    case 47:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRtkMode(value);
      break;
    case 48:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableSkysticksJoystickInputs(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVolumePercent(value);
      break;
    case 51:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableParachuteAdvancedStatus(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttiReturnCancelToggle(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttiReturnCancelSlider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.Settings.Basic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.Settings.Basic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.Settings.Basic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Settings.Basic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvertGamepad();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getNaturalDragging();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRecordPhoneAudio();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getShowErrorStats();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSimulatorRenderMode();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsPrecisionModeEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCellularDownloadRule();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getDisplayUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getControlsPreference();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getShowThermal();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getSkysticksInvertGimbalAxis();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getSkysticksInvertZoomAxis();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getPinchToFlyPreferred();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getDoubleTapToFlyDisabled();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getShowMapCoordinates();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getShowMapCenterCrosshairs();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getMapCoordinateSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getConsumerHeightCeilingEditable();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getLanguagePreference();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getButtonMapping();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.skydio.mobile.ButtonMapping.serializeBinaryToWriter
    );
  }
  f = message.getThermalRegionSize();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getAgcPreset();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getCustomAgcProfile();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.skydio.mobile.CustomAgcProfile.serializeBinaryToWriter
    );
  }
  f = message.getShowCockpitCrosshair();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getStreamConfig();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      pbtypes_mobile_phone_phone_stream_pb.StreamConfig.serializeBinaryToWriter
    );
  }
  f = message.getTelemetryConfig();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.skydio.mobile.TelemetryConfig.serializeBinaryToWriter
    );
  }
  f = message.getAppTheme();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = message.getShowOaVoxels();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getOaVoxelRenderDistance();
  if (f !== 0.0) {
    writer.writeEnum(
      36,
      f
    );
  }
  f = message.getOaVoxelRenderStyle();
  if (f !== 0.0) {
    writer.writeEnum(
      37,
      f
    );
  }
  f = message.getShowVirtualGimbal();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getDisplayBrightnessPercent();
  if (f !== 0.0) {
    writer.writeFloat(
      40,
      f
    );
  }
  f = message.getShow2ghzToggle();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getDimControllerLeds();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getEnableFlightScreenrecord();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getTxPowerSlider();
  if (f !== 0) {
    writer.writeInt32(
      44,
      f
    );
  }
  f = message.getTxpowerOverride();
  if (f) {
    writer.writeBool(
      45,
      f
    );
  }
  f = message.getExternalRadioSelected();
  if (f) {
    writer.writeBool(
      46,
      f
    );
  }
  f = message.getRtkMode();
  if (f) {
    writer.writeBool(
      47,
      f
    );
  }
  f = message.getDisableSkysticksJoystickInputs();
  if (f) {
    writer.writeBool(
      48,
      f
    );
  }
  f = message.getVolumePercent();
  if (f !== 0.0) {
    writer.writeFloat(
      49,
      f
    );
  }
  f = message.getEnableParachuteAdvancedStatus();
  if (f) {
    writer.writeBool(
      51,
      f
    );
  }
  f = message.getAttiReturnCancelToggle();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
  f = message.getAttiReturnCancelSlider();
  if (f !== 0) {
    writer.writeInt32(
      53,
      f
    );
  }
};


/**
 * optional bool invert_gamepad = 1;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getInvertGamepad = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setInvertGamepad = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool natural_dragging = 2;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getNaturalDragging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setNaturalDragging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool record_phone_audio = 3;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getRecordPhoneAudio = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setRecordPhoneAudio = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool show_error_stats = 4;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getShowErrorStats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setShowErrorStats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool simulator_render_mode = 5;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getSimulatorRenderMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setSimulatorRenderMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_precision_mode_enabled = 7;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getIsPrecisionModeEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setIsPrecisionModeEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional phone.CellularDownloadRule.Enum cellular_download_rule = 10;
 * @return {!proto.phone.CellularDownloadRule.Enum}
 */
proto.skydio.mobile.Settings.Basic.prototype.getCellularDownloadRule = function() {
  return /** @type {!proto.phone.CellularDownloadRule.Enum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.phone.CellularDownloadRule.Enum} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setCellularDownloadRule = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional phone.DisplayUnits.Enum display_units = 14;
 * @return {!proto.phone.DisplayUnits.Enum}
 */
proto.skydio.mobile.Settings.Basic.prototype.getDisplayUnits = function() {
  return /** @type {!proto.phone.DisplayUnits.Enum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.phone.DisplayUnits.Enum} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setDisplayUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional phone.ControlsPreference.Enum controls_preference = 15;
 * @return {!proto.phone.ControlsPreference.Enum}
 */
proto.skydio.mobile.Settings.Basic.prototype.getControlsPreference = function() {
  return /** @type {!proto.phone.ControlsPreference.Enum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.phone.ControlsPreference.Enum} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setControlsPreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool show_thermal = 16;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getShowThermal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setShowThermal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool skysticks_invert_gimbal_axis = 17;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getSkysticksInvertGimbalAxis = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setSkysticksInvertGimbalAxis = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool skysticks_invert_zoom_axis = 18;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getSkysticksInvertZoomAxis = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setSkysticksInvertZoomAxis = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool pinch_to_fly_preferred = 19;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getPinchToFlyPreferred = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setPinchToFlyPreferred = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool double_tap_to_fly_disabled = 20;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getDoubleTapToFlyDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setDoubleTapToFlyDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool show_map_coordinates = 21;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getShowMapCoordinates = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setShowMapCoordinates = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool show_map_center_crosshairs = 32;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getShowMapCenterCrosshairs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setShowMapCenterCrosshairs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional phone.MapCoordinateSystem.Enum map_coordinate_system = 22;
 * @return {!proto.phone.MapCoordinateSystem.Enum}
 */
proto.skydio.mobile.Settings.Basic.prototype.getMapCoordinateSystem = function() {
  return /** @type {!proto.phone.MapCoordinateSystem.Enum} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.phone.MapCoordinateSystem.Enum} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setMapCoordinateSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional bool consumer_height_ceiling_editable = 23;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getConsumerHeightCeilingEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setConsumerHeightCeilingEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional phone.LanguagePreference.Enum language_preference = 24;
 * @return {!proto.phone.LanguagePreference.Enum}
 */
proto.skydio.mobile.Settings.Basic.prototype.getLanguagePreference = function() {
  return /** @type {!proto.phone.LanguagePreference.Enum} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.phone.LanguagePreference.Enum} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setLanguagePreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional ButtonMapping button_mapping = 25;
 * @return {?proto.skydio.mobile.ButtonMapping}
 */
proto.skydio.mobile.Settings.Basic.prototype.getButtonMapping = function() {
  return /** @type{?proto.skydio.mobile.ButtonMapping} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.ButtonMapping, 25));
};


/**
 * @param {?proto.skydio.mobile.ButtonMapping|undefined} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
*/
proto.skydio.mobile.Settings.Basic.prototype.setButtonMapping = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.clearButtonMapping = function() {
  return this.setButtonMapping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.hasButtonMapping = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional int32 thermal_region_size = 27;
 * @return {number}
 */
proto.skydio.mobile.Settings.Basic.prototype.getThermalRegionSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setThermalRegionSize = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional AgcPreset agc_preset = 28;
 * @return {!proto.skydio.mobile.AgcPreset}
 */
proto.skydio.mobile.Settings.Basic.prototype.getAgcPreset = function() {
  return /** @type {!proto.skydio.mobile.AgcPreset} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.skydio.mobile.AgcPreset} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setAgcPreset = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional CustomAgcProfile custom_agc_profile = 29;
 * @return {?proto.skydio.mobile.CustomAgcProfile}
 */
proto.skydio.mobile.Settings.Basic.prototype.getCustomAgcProfile = function() {
  return /** @type{?proto.skydio.mobile.CustomAgcProfile} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.CustomAgcProfile, 29));
};


/**
 * @param {?proto.skydio.mobile.CustomAgcProfile|undefined} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
*/
proto.skydio.mobile.Settings.Basic.prototype.setCustomAgcProfile = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.clearCustomAgcProfile = function() {
  return this.setCustomAgcProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.hasCustomAgcProfile = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional bool show_cockpit_crosshair = 30;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getShowCockpitCrosshair = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setShowCockpitCrosshair = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional skydio.phone_stream.StreamConfig stream_config = 31;
 * @return {?proto.skydio.phone_stream.StreamConfig}
 */
proto.skydio.mobile.Settings.Basic.prototype.getStreamConfig = function() {
  return /** @type{?proto.skydio.phone_stream.StreamConfig} */ (
    jspb.Message.getWrapperField(this, pbtypes_mobile_phone_phone_stream_pb.StreamConfig, 31));
};


/**
 * @param {?proto.skydio.phone_stream.StreamConfig|undefined} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
*/
proto.skydio.mobile.Settings.Basic.prototype.setStreamConfig = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.clearStreamConfig = function() {
  return this.setStreamConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.hasStreamConfig = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional TelemetryConfig telemetry_config = 33;
 * @return {?proto.skydio.mobile.TelemetryConfig}
 */
proto.skydio.mobile.Settings.Basic.prototype.getTelemetryConfig = function() {
  return /** @type{?proto.skydio.mobile.TelemetryConfig} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.TelemetryConfig, 33));
};


/**
 * @param {?proto.skydio.mobile.TelemetryConfig|undefined} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
*/
proto.skydio.mobile.Settings.Basic.prototype.setTelemetryConfig = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.clearTelemetryConfig = function() {
  return this.setTelemetryConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.hasTelemetryConfig = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional phone.AppThemeOption.Enum app_theme = 34;
 * @return {!proto.phone.AppThemeOption.Enum}
 */
proto.skydio.mobile.Settings.Basic.prototype.getAppTheme = function() {
  return /** @type {!proto.phone.AppThemeOption.Enum} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.phone.AppThemeOption.Enum} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setAppTheme = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional bool show_oa_voxels = 35;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getShowOaVoxels = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setShowOaVoxels = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional OaVoxelRenderDistance oa_voxel_render_distance = 36;
 * @return {!proto.skydio.mobile.OaVoxelRenderDistance}
 */
proto.skydio.mobile.Settings.Basic.prototype.getOaVoxelRenderDistance = function() {
  return /** @type {!proto.skydio.mobile.OaVoxelRenderDistance} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {!proto.skydio.mobile.OaVoxelRenderDistance} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setOaVoxelRenderDistance = function(value) {
  return jspb.Message.setProto3EnumField(this, 36, value);
};


/**
 * optional OaVoxelRenderStyle oa_voxel_render_style = 37;
 * @return {!proto.skydio.mobile.OaVoxelRenderStyle}
 */
proto.skydio.mobile.Settings.Basic.prototype.getOaVoxelRenderStyle = function() {
  return /** @type {!proto.skydio.mobile.OaVoxelRenderStyle} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {!proto.skydio.mobile.OaVoxelRenderStyle} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setOaVoxelRenderStyle = function(value) {
  return jspb.Message.setProto3EnumField(this, 37, value);
};


/**
 * optional bool show_virtual_gimbal = 39;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getShowVirtualGimbal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setShowVirtualGimbal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional float display_brightness_percent = 40;
 * @return {number}
 */
proto.skydio.mobile.Settings.Basic.prototype.getDisplayBrightnessPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setDisplayBrightnessPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};


/**
 * optional bool show_2ghz_toggle = 41;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getShow2ghzToggle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setShow2ghzToggle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool dim_controller_leds = 42;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getDimControllerLeds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setDimControllerLeds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool enable_flight_screenrecord = 43;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getEnableFlightScreenrecord = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setEnableFlightScreenrecord = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional int32 tx_power_slider = 44;
 * @return {number}
 */
proto.skydio.mobile.Settings.Basic.prototype.getTxPowerSlider = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setTxPowerSlider = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional bool txpower_override = 45;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getTxpowerOverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 45, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setTxpowerOverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 45, value);
};


/**
 * optional bool external_radio_selected = 46;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getExternalRadioSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 46, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setExternalRadioSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 46, value);
};


/**
 * optional bool rtk_mode = 47;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getRtkMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 47, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setRtkMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 47, value);
};


/**
 * optional bool disable_skysticks_joystick_inputs = 48;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getDisableSkysticksJoystickInputs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 48, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setDisableSkysticksJoystickInputs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 48, value);
};


/**
 * optional float volume_percent = 49;
 * @return {number}
 */
proto.skydio.mobile.Settings.Basic.prototype.getVolumePercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setVolumePercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional bool enable_parachute_advanced_status = 51;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getEnableParachuteAdvancedStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 51, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setEnableParachuteAdvancedStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 51, value);
};


/**
 * optional bool atti_return_cancel_toggle = 52;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Basic.prototype.getAttiReturnCancelToggle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setAttiReturnCancelToggle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};


/**
 * optional int32 atti_return_cancel_slider = 53;
 * @return {number}
 */
proto.skydio.mobile.Settings.Basic.prototype.getAttiReturnCancelSlider = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.Settings.Basic} returns this
 */
proto.skydio.mobile.Settings.Basic.prototype.setAttiReturnCancelSlider = function(value) {
  return jspb.Message.setProto3IntField(this, 53, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.Settings.Advanced.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.Settings.Advanced.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.Settings.Advanced} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Settings.Advanced.toObject = function(includeInstance, msg) {
  var f, obj = {
setting: jspb.Message.getFieldWithDefault(msg, 1, 0),
enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.Settings.Advanced}
 */
proto.skydio.mobile.Settings.Advanced.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.Settings.Advanced;
  return proto.skydio.mobile.Settings.Advanced.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.Settings.Advanced} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.Settings.Advanced}
 */
proto.skydio.mobile.Settings.Advanced.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.phone.AdvancedSetting.Enum} */ (reader.readEnum());
      msg.setSetting(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.Settings.Advanced.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.Settings.Advanced.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.Settings.Advanced} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Settings.Advanced.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional phone.AdvancedSetting.Enum setting = 1;
 * @return {!proto.phone.AdvancedSetting.Enum}
 */
proto.skydio.mobile.Settings.Advanced.prototype.getSetting = function() {
  return /** @type {!proto.phone.AdvancedSetting.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.phone.AdvancedSetting.Enum} value
 * @return {!proto.skydio.mobile.Settings.Advanced} returns this
 */
proto.skydio.mobile.Settings.Advanced.prototype.setSetting = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool enabled = 2;
 * @return {boolean}
 */
proto.skydio.mobile.Settings.Advanced.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.Settings.Advanced} returns this
 */
proto.skydio.mobile.Settings.Advanced.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.Settings.Radio.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.Settings.Radio.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.Settings.Radio} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Settings.Radio.toObject = function(includeInstance, msg) {
  var f, obj = {
bandwidth: jspb.Message.getFieldWithDefault(msg, 1, 0),
channel: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.Settings.Radio}
 */
proto.skydio.mobile.Settings.Radio.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.Settings.Radio;
  return proto.skydio.mobile.Settings.Radio.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.Settings.Radio} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.Settings.Radio}
 */
proto.skydio.mobile.Settings.Radio.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBandwidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.Settings.Radio.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.Settings.Radio.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.Settings.Radio} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.Settings.Radio.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBandwidth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChannel();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 bandwidth = 1;
 * @return {number}
 */
proto.skydio.mobile.Settings.Radio.prototype.getBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.Settings.Radio} returns this
 */
proto.skydio.mobile.Settings.Radio.prototype.setBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 channel = 2;
 * @return {number}
 */
proto.skydio.mobile.Settings.Radio.prototype.getChannel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.Settings.Radio} returns this
 */
proto.skydio.mobile.Settings.Radio.prototype.setChannel = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Basic basic = 1;
 * @return {?proto.skydio.mobile.Settings.Basic}
 */
proto.skydio.mobile.Settings.prototype.getBasic = function() {
  return /** @type{?proto.skydio.mobile.Settings.Basic} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.Settings.Basic, 1));
};


/**
 * @param {?proto.skydio.mobile.Settings.Basic|undefined} value
 * @return {!proto.skydio.mobile.Settings} returns this
*/
proto.skydio.mobile.Settings.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.Settings} returns this
 */
proto.skydio.mobile.Settings.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.Settings.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Advanced advanced = 2;
 * @return {!Array<!proto.skydio.mobile.Settings.Advanced>}
 */
proto.skydio.mobile.Settings.prototype.getAdvancedList = function() {
  return /** @type{!Array<!proto.skydio.mobile.Settings.Advanced>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skydio.mobile.Settings.Advanced, 2));
};


/**
 * @param {!Array<!proto.skydio.mobile.Settings.Advanced>} value
 * @return {!proto.skydio.mobile.Settings} returns this
*/
proto.skydio.mobile.Settings.prototype.setAdvancedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.skydio.mobile.Settings.Advanced=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skydio.mobile.Settings.Advanced}
 */
proto.skydio.mobile.Settings.prototype.addAdvanced = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.skydio.mobile.Settings.Advanced, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skydio.mobile.Settings} returns this
 */
proto.skydio.mobile.Settings.prototype.clearAdvancedList = function() {
  return this.setAdvancedList([]);
};


/**
 * optional Radio radio = 3;
 * @return {?proto.skydio.mobile.Settings.Radio}
 */
proto.skydio.mobile.Settings.prototype.getRadio = function() {
  return /** @type{?proto.skydio.mobile.Settings.Radio} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.Settings.Radio, 3));
};


/**
 * @param {?proto.skydio.mobile.Settings.Radio|undefined} value
 * @return {!proto.skydio.mobile.Settings} returns this
*/
proto.skydio.mobile.Settings.prototype.setRadio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.Settings} returns this
 */
proto.skydio.mobile.Settings.prototype.clearRadio = function() {
  return this.setRadio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.Settings.prototype.hasRadio = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.CustomAgcProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.CustomAgcProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.CustomAgcProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.CustomAgcProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
agcSettings: (f = msg.getAgcSettings()) && pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings.toObject(includeInstance, f),
basePreset: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.CustomAgcProfile}
 */
proto.skydio.mobile.CustomAgcProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.CustomAgcProfile;
  return proto.skydio.mobile.CustomAgcProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.CustomAgcProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.CustomAgcProfile}
 */
proto.skydio.mobile.CustomAgcProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings;
      reader.readMessage(value,pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings.deserializeBinaryFromReader);
      msg.setAgcSettings(value);
      break;
    case 4:
      var value = /** @type {!proto.skydio.mobile.AgcPreset} */ (reader.readEnum());
      msg.setBasePreset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.CustomAgcProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.CustomAgcProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.CustomAgcProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.CustomAgcProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAgcSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings.serializeBinaryToWriter
    );
  }
  f = message.getBasePreset();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.skydio.mobile.CustomAgcProfile.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.CustomAgcProfile} returns this
 */
proto.skydio.mobile.CustomAgcProfile.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.skydio.mobile.CustomAgcProfile.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.CustomAgcProfile} returns this
 */
proto.skydio.mobile.CustomAgcProfile.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional flir_boson_driver.AgcSettings agc_settings = 3;
 * @return {?proto.flir_boson_driver.AgcSettings}
 */
proto.skydio.mobile.CustomAgcProfile.prototype.getAgcSettings = function() {
  return /** @type{?proto.flir_boson_driver.AgcSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings, 3));
};


/**
 * @param {?proto.flir_boson_driver.AgcSettings|undefined} value
 * @return {!proto.skydio.mobile.CustomAgcProfile} returns this
*/
proto.skydio.mobile.CustomAgcProfile.prototype.setAgcSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.CustomAgcProfile} returns this
 */
proto.skydio.mobile.CustomAgcProfile.prototype.clearAgcSettings = function() {
  return this.setAgcSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.CustomAgcProfile.prototype.hasAgcSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AgcPreset base_preset = 4;
 * @return {!proto.skydio.mobile.AgcPreset}
 */
proto.skydio.mobile.CustomAgcProfile.prototype.getBasePreset = function() {
  return /** @type {!proto.skydio.mobile.AgcPreset} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.skydio.mobile.AgcPreset} value
 * @return {!proto.skydio.mobile.CustomAgcProfile} returns this
 */
proto.skydio.mobile.CustomAgcProfile.prototype.setBasePreset = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.TelemetryConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.TelemetryConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.TelemetryConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.TelemetryConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
altitudeEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
distanceFromLaunchEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
headingEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
groundSpeedEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
gimbalPitchEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
distanceFromControllerEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
gpsSatellitesEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
obstacleAvoidanceEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
mhChannelEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
slChannelEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.TelemetryConfig}
 */
proto.skydio.mobile.TelemetryConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.TelemetryConfig;
  return proto.skydio.mobile.TelemetryConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.TelemetryConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.TelemetryConfig}
 */
proto.skydio.mobile.TelemetryConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAltitudeEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDistanceFromLaunchEnabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHeadingEnabled(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGroundSpeedEnabled(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGimbalPitchEnabled(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDistanceFromControllerEnabled(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGpsSatellitesEnabled(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setObstacleAvoidanceEnabled(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMhChannelEnabled(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlChannelEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.TelemetryConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.TelemetryConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.TelemetryConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.TelemetryConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAltitudeEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDistanceFromLaunchEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHeadingEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGroundSpeedEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getGimbalPitchEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDistanceFromControllerEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getGpsSatellitesEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getObstacleAvoidanceEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMhChannelEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getSlChannelEnabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional bool altitude_enabled = 1;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getAltitudeEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setAltitudeEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool distance_from_launch_enabled = 2;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getDistanceFromLaunchEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setDistanceFromLaunchEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool heading_enabled = 3;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getHeadingEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setHeadingEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool ground_speed_enabled = 4;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getGroundSpeedEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setGroundSpeedEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool gimbal_pitch_enabled = 5;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getGimbalPitchEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setGimbalPitchEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool distance_from_controller_enabled = 6;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getDistanceFromControllerEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setDistanceFromControllerEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool gps_satellites_enabled = 7;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getGpsSatellitesEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setGpsSatellitesEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool obstacle_avoidance_enabled = 8;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getObstacleAvoidanceEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setObstacleAvoidanceEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool mh_channel_enabled = 10;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getMhChannelEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setMhChannelEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool sl_channel_enabled = 11;
 * @return {boolean}
 */
proto.skydio.mobile.TelemetryConfig.prototype.getSlChannelEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TelemetryConfig} returns this
 */
proto.skydio.mobile.TelemetryConfig.prototype.setSlChannelEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.DroneData.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.DroneData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.DroneData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.DroneData.toObject = function(includeInstance, msg) {
  var f, obj = {
simulatorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
iotVehicleId: jspb.Message.getFieldWithDefault(msg, 8, ""),
staticAddress: (f = msg.getStaticAddress()) && proto.skydio.mobile.VehicleAddressPb.toObject(includeInstance, f),
wirePairedVehicle: (f = msg.getWirePairedVehicle()) && proto.skydio.mobile.WirePairedVehicle.toObject(includeInstance, f),
homeOverridesMap: (f = msg.getHomeOverridesMap()) ? f.toObject(includeInstance, proto.skydio.mobile.VehicleAddressPb.toObject) : [],
vehicleByIdMap: (f = msg.getVehicleByIdMap()) ? f.toObject(includeInstance, proto.skydio.mobile.VehicleInfo.toObject) : [],
beaconBySerialMap: (f = msg.getBeaconBySerialMap()) ? f.toObject(includeInstance, proto.skydio.mobile.BeaconInfo.toObject) : [],
vehiclePwBySsidMap: (f = msg.getVehiclePwBySsidMap()) ? f.toObject(includeInstance, undefined) : [],
beaconPwBySsidMap: (f = msg.getBeaconPwBySsidMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.DroneData}
 */
proto.skydio.mobile.DroneData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.DroneData;
  return proto.skydio.mobile.DroneData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.DroneData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.DroneData}
 */
proto.skydio.mobile.DroneData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSimulatorId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIotVehicleId(value);
      break;
    case 4:
      var value = new proto.skydio.mobile.VehicleAddressPb;
      reader.readMessage(value,proto.skydio.mobile.VehicleAddressPb.deserializeBinaryFromReader);
      msg.setStaticAddress(value);
      break;
    case 9:
      var value = new proto.skydio.mobile.WirePairedVehicle;
      reader.readMessage(value,proto.skydio.mobile.WirePairedVehicle.deserializeBinaryFromReader);
      msg.setWirePairedVehicle(value);
      break;
    case 2:
      var value = msg.getHomeOverridesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.skydio.mobile.VehicleAddressPb.deserializeBinaryFromReader, "", new proto.skydio.mobile.VehicleAddressPb());
         });
      break;
    case 3:
      var value = msg.getVehicleByIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.skydio.mobile.VehicleInfo.deserializeBinaryFromReader, "", new proto.skydio.mobile.VehicleInfo());
         });
      break;
    case 5:
      var value = msg.getBeaconBySerialMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.skydio.mobile.BeaconInfo.deserializeBinaryFromReader, "", new proto.skydio.mobile.BeaconInfo());
         });
      break;
    case 6:
      var value = msg.getVehiclePwBySsidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = msg.getBeaconPwBySsidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.DroneData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.DroneData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.DroneData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.DroneData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimulatorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIotVehicleId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStaticAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skydio.mobile.VehicleAddressPb.serializeBinaryToWriter
    );
  }
  f = message.getWirePairedVehicle();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.skydio.mobile.WirePairedVehicle.serializeBinaryToWriter
    );
  }
  f = message.getHomeOverridesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.skydio.mobile.VehicleAddressPb.serializeBinaryToWriter);
  }
  f = message.getVehicleByIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.skydio.mobile.VehicleInfo.serializeBinaryToWriter);
  }
  f = message.getBeaconBySerialMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.skydio.mobile.BeaconInfo.serializeBinaryToWriter);
  }
  f = message.getVehiclePwBySsidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getBeaconPwBySsidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string simulator_id = 1;
 * @return {string}
 */
proto.skydio.mobile.DroneData.prototype.getSimulatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.setSimulatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iot_vehicle_id = 8;
 * @return {string}
 */
proto.skydio.mobile.DroneData.prototype.getIotVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.setIotVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional VehicleAddressPb static_address = 4;
 * @return {?proto.skydio.mobile.VehicleAddressPb}
 */
proto.skydio.mobile.DroneData.prototype.getStaticAddress = function() {
  return /** @type{?proto.skydio.mobile.VehicleAddressPb} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.VehicleAddressPb, 4));
};


/**
 * @param {?proto.skydio.mobile.VehicleAddressPb|undefined} value
 * @return {!proto.skydio.mobile.DroneData} returns this
*/
proto.skydio.mobile.DroneData.prototype.setStaticAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.clearStaticAddress = function() {
  return this.setStaticAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.DroneData.prototype.hasStaticAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WirePairedVehicle wire_paired_vehicle = 9;
 * @return {?proto.skydio.mobile.WirePairedVehicle}
 */
proto.skydio.mobile.DroneData.prototype.getWirePairedVehicle = function() {
  return /** @type{?proto.skydio.mobile.WirePairedVehicle} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.WirePairedVehicle, 9));
};


/**
 * @param {?proto.skydio.mobile.WirePairedVehicle|undefined} value
 * @return {!proto.skydio.mobile.DroneData} returns this
*/
proto.skydio.mobile.DroneData.prototype.setWirePairedVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.clearWirePairedVehicle = function() {
  return this.setWirePairedVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.DroneData.prototype.hasWirePairedVehicle = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * map<string, VehicleAddressPb> home_overrides = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.skydio.mobile.VehicleAddressPb>}
 */
proto.skydio.mobile.DroneData.prototype.getHomeOverridesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.skydio.mobile.VehicleAddressPb>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.skydio.mobile.VehicleAddressPb));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.clearHomeOverridesMap = function() {
  this.getHomeOverridesMap().clear();
  return this;
};


/**
 * map<string, VehicleInfo> vehicle_by_id = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.skydio.mobile.VehicleInfo>}
 */
proto.skydio.mobile.DroneData.prototype.getVehicleByIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.skydio.mobile.VehicleInfo>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.skydio.mobile.VehicleInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.clearVehicleByIdMap = function() {
  this.getVehicleByIdMap().clear();
  return this;
};


/**
 * map<string, BeaconInfo> beacon_by_serial = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.skydio.mobile.BeaconInfo>}
 */
proto.skydio.mobile.DroneData.prototype.getBeaconBySerialMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.skydio.mobile.BeaconInfo>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.skydio.mobile.BeaconInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.clearBeaconBySerialMap = function() {
  this.getBeaconBySerialMap().clear();
  return this;
};


/**
 * map<string, string> vehicle_pw_by_ssid = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.skydio.mobile.DroneData.prototype.getVehiclePwBySsidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.clearVehiclePwBySsidMap = function() {
  this.getVehiclePwBySsidMap().clear();
  return this;
};


/**
 * map<string, string> beacon_pw_by_ssid = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.skydio.mobile.DroneData.prototype.getBeaconPwBySsidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skydio.mobile.DroneData} returns this
 */
proto.skydio.mobile.DroneData.prototype.clearBeaconPwBySsidMap = function() {
  this.getBeaconPwBySsidMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.VehicleAddressPb.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.VehicleAddressPb} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.VehicleAddressPb.toObject = function(includeInstance, msg) {
  var f, obj = {
title: jspb.Message.getFieldWithDefault(msg, 1, ""),
hostname: jspb.Message.getFieldWithDefault(msg, 2, ""),
port: jspb.Message.getFieldWithDefault(msg, 3, 0),
secure: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
tunnelHostname: jspb.Message.getFieldWithDefault(msg, 5, ""),
tunnelPort: jspb.Message.getFieldWithDefault(msg, 6, 0),
webrtcSupported: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
webrtcChannelName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.VehicleAddressPb}
 */
proto.skydio.mobile.VehicleAddressPb.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.VehicleAddressPb;
  return proto.skydio.mobile.VehicleAddressPb.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.VehicleAddressPb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.VehicleAddressPb}
 */
proto.skydio.mobile.VehicleAddressPb.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSecure(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTunnelHostname(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTunnelPort(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWebrtcSupported(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebrtcChannelName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.VehicleAddressPb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.VehicleAddressPb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.VehicleAddressPb.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSecure();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTunnelHostname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTunnelPort();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getWebrtcSupported();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getWebrtcChannelName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.VehicleAddressPb} returns this
 */
proto.skydio.mobile.VehicleAddressPb.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hostname = 2;
 * @return {string}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.VehicleAddressPb} returns this
 */
proto.skydio.mobile.VehicleAddressPb.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 port = 3;
 * @return {number}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.VehicleAddressPb} returns this
 */
proto.skydio.mobile.VehicleAddressPb.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool secure = 4;
 * @return {boolean}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.getSecure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.VehicleAddressPb} returns this
 */
proto.skydio.mobile.VehicleAddressPb.prototype.setSecure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string tunnel_hostname = 5;
 * @return {string}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.getTunnelHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.VehicleAddressPb} returns this
 */
proto.skydio.mobile.VehicleAddressPb.prototype.setTunnelHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 tunnel_port = 6;
 * @return {number}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.getTunnelPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.VehicleAddressPb} returns this
 */
proto.skydio.mobile.VehicleAddressPb.prototype.setTunnelPort = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool webrtc_supported = 7;
 * @return {boolean}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.getWebrtcSupported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.VehicleAddressPb} returns this
 */
proto.skydio.mobile.VehicleAddressPb.prototype.setWebrtcSupported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string webrtc_channel_name = 8;
 * @return {string}
 */
proto.skydio.mobile.VehicleAddressPb.prototype.getWebrtcChannelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.VehicleAddressPb} returns this
 */
proto.skydio.mobile.VehicleAddressPb.prototype.setWebrtcChannelName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.VehicleUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.VehicleUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.VehicleUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.VehicleUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
releaseFile: (f = msg.getReleaseFile()) && pbtypes_tools_cloud_api_release_pb.ReleaseFile.toObject(includeInstance, f),
warnToUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.VehicleUpdate}
 */
proto.skydio.mobile.VehicleUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.VehicleUpdate;
  return proto.skydio.mobile.VehicleUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.VehicleUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.VehicleUpdate}
 */
proto.skydio.mobile.VehicleUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_tools_cloud_api_release_pb.ReleaseFile;
      reader.readMessage(value,pbtypes_tools_cloud_api_release_pb.ReleaseFile.deserializeBinaryFromReader);
      msg.setReleaseFile(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWarnToUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.VehicleUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.VehicleUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.VehicleUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.VehicleUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleaseFile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_tools_cloud_api_release_pb.ReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getWarnToUpdate();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional cloud_api.ReleaseFile release_file = 1;
 * @return {?proto.cloud_api.ReleaseFile}
 */
proto.skydio.mobile.VehicleUpdate.prototype.getReleaseFile = function() {
  return /** @type{?proto.cloud_api.ReleaseFile} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_release_pb.ReleaseFile, 1));
};


/**
 * @param {?proto.cloud_api.ReleaseFile|undefined} value
 * @return {!proto.skydio.mobile.VehicleUpdate} returns this
*/
proto.skydio.mobile.VehicleUpdate.prototype.setReleaseFile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.VehicleUpdate} returns this
 */
proto.skydio.mobile.VehicleUpdate.prototype.clearReleaseFile = function() {
  return this.setReleaseFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.VehicleUpdate.prototype.hasReleaseFile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool warn_to_update = 2;
 * @return {boolean}
 */
proto.skydio.mobile.VehicleUpdate.prototype.getWarnToUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.VehicleUpdate} returns this
 */
proto.skydio.mobile.VehicleUpdate.prototype.setWarnToUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.VehicleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.VehicleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.VehicleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.VehicleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
lastConnectedUclock: jspb.Message.getFieldWithDefault(msg, 2, 0),
releaseKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
reality: jspb.Message.getFieldWithDefault(msg, 4, 0),
apiVersionMajor: jspb.Message.getFieldWithDefault(msg, 5, 0),
apiVersionMinor: jspb.Message.getFieldWithDefault(msg, 6, 0),
apiMinPhoneMinorVersion: (f = msg.getApiMinPhoneMinorVersion()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
otaRelatedMetadata: jspb.Message.getFieldWithDefault(msg, 8, ""),
vehicleRecoveryInfo: (f = msg.getVehicleRecoveryInfo()) && pbtypes_mobile_phone_location_pb.VehicleRecoveryInfo.toObject(includeInstance, f),
releaseFile: (f = msg.getReleaseFile()) && pbtypes_tools_cloud_api_release_pb.ReleaseFile.toObject(includeInstance, f),
availableRelease: (f = msg.getAvailableRelease()) && proto.skydio.mobile.VehicleUpdate.toObject(includeInstance, f),
gitTag: jspb.Message.getFieldWithDefault(msg, 13, ""),
cloudConfigUpdatedUclock: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.VehicleInfo}
 */
proto.skydio.mobile.VehicleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.VehicleInfo;
  return proto.skydio.mobile.VehicleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.VehicleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.VehicleInfo}
 */
proto.skydio.mobile.VehicleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastConnectedUclock(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 4:
      var value = /** @type {!proto.phone.Reality.Enum} */ (reader.readEnum());
      msg.setReality(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setApiVersionMajor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setApiVersionMinor(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setApiMinPhoneMinorVersion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtaRelatedMetadata(value);
      break;
    case 10:
      var value = new pbtypes_mobile_phone_location_pb.VehicleRecoveryInfo;
      reader.readMessage(value,pbtypes_mobile_phone_location_pb.VehicleRecoveryInfo.deserializeBinaryFromReader);
      msg.setVehicleRecoveryInfo(value);
      break;
    case 11:
      var value = new pbtypes_tools_cloud_api_release_pb.ReleaseFile;
      reader.readMessage(value,pbtypes_tools_cloud_api_release_pb.ReleaseFile.deserializeBinaryFromReader);
      msg.setReleaseFile(value);
      break;
    case 12:
      var value = new proto.skydio.mobile.VehicleUpdate;
      reader.readMessage(value,proto.skydio.mobile.VehicleUpdate.deserializeBinaryFromReader);
      msg.setAvailableRelease(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGitTag(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudConfigUpdatedUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.VehicleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.VehicleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.VehicleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.VehicleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastConnectedUclock();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReality();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getApiVersionMajor();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getApiVersionMinor();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getApiMinPhoneMinorVersion();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getOtaRelatedMetadata();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVehicleRecoveryInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_mobile_phone_location_pb.VehicleRecoveryInfo.serializeBinaryToWriter
    );
  }
  f = message.getReleaseFile();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      pbtypes_tools_cloud_api_release_pb.ReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getAvailableRelease();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.skydio.mobile.VehicleUpdate.serializeBinaryToWriter
    );
  }
  f = message.getGitTag();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCloudConfigUpdatedUclock();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.skydio.mobile.VehicleInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 last_connected_uclock = 2;
 * @return {number}
 */
proto.skydio.mobile.VehicleInfo.prototype.getLastConnectedUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setLastConnectedUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string release_key = 3;
 * @return {string}
 */
proto.skydio.mobile.VehicleInfo.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional phone.Reality.Enum reality = 4;
 * @return {!proto.phone.Reality.Enum}
 */
proto.skydio.mobile.VehicleInfo.prototype.getReality = function() {
  return /** @type {!proto.phone.Reality.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.phone.Reality.Enum} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setReality = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 api_version_major = 5;
 * @return {number}
 */
proto.skydio.mobile.VehicleInfo.prototype.getApiVersionMajor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setApiVersionMajor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 api_version_minor = 6;
 * @return {number}
 */
proto.skydio.mobile.VehicleInfo.prototype.getApiVersionMinor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setApiVersionMinor = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Int64Value api_min_phone_minor_version = 7;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.skydio.mobile.VehicleInfo.prototype.getApiMinPhoneMinorVersion = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
*/
proto.skydio.mobile.VehicleInfo.prototype.setApiMinPhoneMinorVersion = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.clearApiMinPhoneMinorVersion = function() {
  return this.setApiMinPhoneMinorVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.VehicleInfo.prototype.hasApiMinPhoneMinorVersion = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string ota_related_metadata = 8;
 * @return {string}
 */
proto.skydio.mobile.VehicleInfo.prototype.getOtaRelatedMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setOtaRelatedMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional phone.VehicleRecoveryInfo vehicle_recovery_info = 10;
 * @return {?proto.phone.VehicleRecoveryInfo}
 */
proto.skydio.mobile.VehicleInfo.prototype.getVehicleRecoveryInfo = function() {
  return /** @type{?proto.phone.VehicleRecoveryInfo} */ (
    jspb.Message.getWrapperField(this, pbtypes_mobile_phone_location_pb.VehicleRecoveryInfo, 10));
};


/**
 * @param {?proto.phone.VehicleRecoveryInfo|undefined} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
*/
proto.skydio.mobile.VehicleInfo.prototype.setVehicleRecoveryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.clearVehicleRecoveryInfo = function() {
  return this.setVehicleRecoveryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.VehicleInfo.prototype.hasVehicleRecoveryInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional cloud_api.ReleaseFile release_file = 11;
 * @return {?proto.cloud_api.ReleaseFile}
 */
proto.skydio.mobile.VehicleInfo.prototype.getReleaseFile = function() {
  return /** @type{?proto.cloud_api.ReleaseFile} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_release_pb.ReleaseFile, 11));
};


/**
 * @param {?proto.cloud_api.ReleaseFile|undefined} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
*/
proto.skydio.mobile.VehicleInfo.prototype.setReleaseFile = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.clearReleaseFile = function() {
  return this.setReleaseFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.VehicleInfo.prototype.hasReleaseFile = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional VehicleUpdate available_release = 12;
 * @return {?proto.skydio.mobile.VehicleUpdate}
 */
proto.skydio.mobile.VehicleInfo.prototype.getAvailableRelease = function() {
  return /** @type{?proto.skydio.mobile.VehicleUpdate} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.VehicleUpdate, 12));
};


/**
 * @param {?proto.skydio.mobile.VehicleUpdate|undefined} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
*/
proto.skydio.mobile.VehicleInfo.prototype.setAvailableRelease = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.clearAvailableRelease = function() {
  return this.setAvailableRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.VehicleInfo.prototype.hasAvailableRelease = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string git_tag = 13;
 * @return {string}
 */
proto.skydio.mobile.VehicleInfo.prototype.getGitTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setGitTag = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 cloud_config_updated_uclock = 14;
 * @return {number}
 */
proto.skydio.mobile.VehicleInfo.prototype.getCloudConfigUpdatedUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.VehicleInfo} returns this
 */
proto.skydio.mobile.VehicleInfo.prototype.setCloudConfigUpdatedUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.WirePairedVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.WirePairedVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.WirePairedVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.WirePairedVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
lastConnectedUclock: jspb.Message.getFieldWithDefault(msg, 2, 0),
radioType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.WirePairedVehicle}
 */
proto.skydio.mobile.WirePairedVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.WirePairedVehicle;
  return proto.skydio.mobile.WirePairedVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.WirePairedVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.WirePairedVehicle}
 */
proto.skydio.mobile.WirePairedVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastConnectedUclock(value);
      break;
    case 3:
      var value = /** @type {!proto.handhawk_main.RadioType.Enum} */ (reader.readEnum());
      msg.setRadioType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.WirePairedVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.WirePairedVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.WirePairedVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.WirePairedVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastConnectedUclock();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRadioType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.skydio.mobile.WirePairedVehicle.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.WirePairedVehicle} returns this
 */
proto.skydio.mobile.WirePairedVehicle.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 last_connected_uclock = 2;
 * @return {number}
 */
proto.skydio.mobile.WirePairedVehicle.prototype.getLastConnectedUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.WirePairedVehicle} returns this
 */
proto.skydio.mobile.WirePairedVehicle.prototype.setLastConnectedUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional handhawk_main.RadioType.Enum radio_type = 3;
 * @return {!proto.handhawk_main.RadioType.Enum}
 */
proto.skydio.mobile.WirePairedVehicle.prototype.getRadioType = function() {
  return /** @type {!proto.handhawk_main.RadioType.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.handhawk_main.RadioType.Enum} value
 * @return {!proto.skydio.mobile.WirePairedVehicle} returns this
 */
proto.skydio.mobile.WirePairedVehicle.prototype.setRadioType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.BeaconInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.BeaconInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.BeaconInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.BeaconInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
version: jspb.Message.getFieldWithDefault(msg, 3, ""),
lastConnectedUclock: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.BeaconInfo}
 */
proto.skydio.mobile.BeaconInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.BeaconInfo;
  return proto.skydio.mobile.BeaconInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.BeaconInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.BeaconInfo}
 */
proto.skydio.mobile.BeaconInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastConnectedUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.BeaconInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.BeaconInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.BeaconInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.BeaconInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastConnectedUclock();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.skydio.mobile.BeaconInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.BeaconInfo} returns this
 */
proto.skydio.mobile.BeaconInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.skydio.mobile.BeaconInfo.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.BeaconInfo} returns this
 */
proto.skydio.mobile.BeaconInfo.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 last_connected_uclock = 4;
 * @return {number}
 */
proto.skydio.mobile.BeaconInfo.prototype.getLastConnectedUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.BeaconInfo} returns this
 */
proto.skydio.mobile.BeaconInfo.prototype.setLastConnectedUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skydio.mobile.DeveloperFlags.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.DeveloperFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.DeveloperFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.DeveloperFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.DeveloperFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
salt: jspb.Message.getFieldWithDefault(msg, 2, ""),
signature: msg.getSignature_asB64(),
devMenu: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
coreLogThreshold: jspb.Message.getFieldWithDefault(msg, 5, 0),
platformLogThreshold: jspb.Message.getFieldWithDefault(msg, 6, 0),
crashToolVerbose: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
startupTracing: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
instabugEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
groupList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.DeveloperFlags}
 */
proto.skydio.mobile.DeveloperFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.DeveloperFlags;
  return proto.skydio.mobile.DeveloperFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.DeveloperFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.DeveloperFlags}
 */
proto.skydio.mobile.DeveloperFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalt(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDevMenu(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCoreLogThreshold(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlatformLogThreshold(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCrashToolVerbose(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStartupTracing(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInstabugEnabled(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.DeveloperFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.DeveloperFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.DeveloperFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.DeveloperFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSalt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getDevMenu();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCoreLogThreshold();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPlatformLogThreshold();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCrashToolVerbose();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getStartupTracing();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getInstabugEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getGroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string salt = 2;
 * @return {string}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getSalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setSalt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes signature = 3;
 * @return {!(string|Uint8Array)}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes signature = 3;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setSignature = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bool dev_menu = 4;
 * @return {boolean}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getDevMenu = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setDevMenu = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 core_log_threshold = 5;
 * @return {number}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getCoreLogThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setCoreLogThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 platform_log_threshold = 6;
 * @return {number}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getPlatformLogThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setPlatformLogThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool crash_tool_verbose = 7;
 * @return {boolean}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getCrashToolVerbose = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setCrashToolVerbose = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool startup_tracing = 8;
 * @return {boolean}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getStartupTracing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setStartupTracing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool instabug_enabled = 9;
 * @return {boolean}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getInstabugEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setInstabugEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated string group = 10;
 * @return {!Array<string>}
 */
proto.skydio.mobile.DeveloperFlags.prototype.getGroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.setGroupList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.addGroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skydio.mobile.DeveloperFlags} returns this
 */
proto.skydio.mobile.DeveloperFlags.prototype.clearGroupList = function() {
  return this.setGroupList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.RemoteStreamingStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.RemoteStreamingStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.RemoteStreamingStats.toObject = function(includeInstance, msg) {
  var f, obj = {
livekitStats: (f = msg.getLivekitStats()) && pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats.toObject(includeInstance, f),
streaming: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
primaryVideoStream: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.RemoteStreamingStats}
 */
proto.skydio.mobile.RemoteStreamingStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.RemoteStreamingStats;
  return proto.skydio.mobile.RemoteStreamingStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.RemoteStreamingStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.RemoteStreamingStats}
 */
proto.skydio.mobile.RemoteStreamingStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats;
      reader.readMessage(value,pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats.deserializeBinaryFromReader);
      msg.setLivekitStats(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryVideoStream(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.RemoteStreamingStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.RemoteStreamingStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.RemoteStreamingStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivekitStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats.serializeBinaryToWriter
    );
  }
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPrimaryVideoStream();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional webrtc.LivekitClientStats livekit_stats = 1;
 * @return {?proto.webrtc.LivekitClientStats}
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.getLivekitStats = function() {
  return /** @type{?proto.webrtc.LivekitClientStats} */ (
    jspb.Message.getWrapperField(this, pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats, 1));
};


/**
 * @param {?proto.webrtc.LivekitClientStats|undefined} value
 * @return {!proto.skydio.mobile.RemoteStreamingStats} returns this
*/
proto.skydio.mobile.RemoteStreamingStats.prototype.setLivekitStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.RemoteStreamingStats} returns this
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.clearLivekitStats = function() {
  return this.setLivekitStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.hasLivekitStats = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool streaming = 2;
 * @return {boolean}
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.RemoteStreamingStats} returns this
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string primary_video_stream = 3;
 * @return {string}
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.getPrimaryVideoStream = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.RemoteStreamingStats} returns this
 */
proto.skydio.mobile.RemoteStreamingStats.prototype.setPrimaryVideoStream = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.TeleopStreamingStats.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.TeleopStreamingStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.TeleopStreamingStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.TeleopStreamingStats.toObject = function(includeInstance, msg) {
  var f, obj = {
peerStats: (f = msg.getPeerStats()) && pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.PeerConnectionStats.toObject(includeInstance, f),
streaming: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.TeleopStreamingStats}
 */
proto.skydio.mobile.TeleopStreamingStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.TeleopStreamingStats;
  return proto.skydio.mobile.TeleopStreamingStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.TeleopStreamingStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.TeleopStreamingStats}
 */
proto.skydio.mobile.TeleopStreamingStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.PeerConnectionStats;
      reader.readMessage(value,pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.PeerConnectionStats.deserializeBinaryFromReader);
      msg.setPeerStats(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.TeleopStreamingStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.TeleopStreamingStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.TeleopStreamingStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.TeleopStreamingStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeerStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.PeerConnectionStats.serializeBinaryToWriter
    );
  }
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional webrtc.PeerConnectionStats peer_stats = 1;
 * @return {?proto.webrtc.PeerConnectionStats}
 */
proto.skydio.mobile.TeleopStreamingStats.prototype.getPeerStats = function() {
  return /** @type{?proto.webrtc.PeerConnectionStats} */ (
    jspb.Message.getWrapperField(this, pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.PeerConnectionStats, 1));
};


/**
 * @param {?proto.webrtc.PeerConnectionStats|undefined} value
 * @return {!proto.skydio.mobile.TeleopStreamingStats} returns this
*/
proto.skydio.mobile.TeleopStreamingStats.prototype.setPeerStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.TeleopStreamingStats} returns this
 */
proto.skydio.mobile.TeleopStreamingStats.prototype.clearPeerStats = function() {
  return this.setPeerStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.TeleopStreamingStats.prototype.hasPeerStats = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool streaming = 2;
 * @return {boolean}
 */
proto.skydio.mobile.TeleopStreamingStats.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.TeleopStreamingStats} returns this
 */
proto.skydio.mobile.TeleopStreamingStats.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.NetworkInterfaceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.NetworkInterfaceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.NetworkInterfaceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.NetworkInterfaceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
transport: jspb.Message.getFieldWithDefault(msg, 2, 0),
type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.NetworkInterfaceInfo}
 */
proto.skydio.mobile.NetworkInterfaceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.NetworkInterfaceInfo;
  return proto.skydio.mobile.NetworkInterfaceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.NetworkInterfaceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.NetworkInterfaceInfo}
 */
proto.skydio.mobile.NetworkInterfaceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.skydio.mobile.NetworkInterfaceInfo.Transport} */ (reader.readEnum());
      msg.setTransport(value);
      break;
    case 3:
      var value = /** @type {!proto.skydio.mobile.NetworkInterfaceInfo.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.NetworkInterfaceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.NetworkInterfaceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.NetworkInterfaceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.NetworkInterfaceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransport();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skydio.mobile.NetworkInterfaceInfo.Transport = {
  UNKNOWN_TRANSPORT: 0,
  WIFI: 1,
  LTE: 2,
  ETHERNET: 3
};

/**
 * @enum {number}
 */
proto.skydio.mobile.NetworkInterfaceInfo.Type = {
  UNKNOWN_TYPE: 0,
  VEHICLE: 1,
  INTERNET: 2
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.skydio.mobile.NetworkInterfaceInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.NetworkInterfaceInfo} returns this
 */
proto.skydio.mobile.NetworkInterfaceInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Transport transport = 2;
 * @return {!proto.skydio.mobile.NetworkInterfaceInfo.Transport}
 */
proto.skydio.mobile.NetworkInterfaceInfo.prototype.getTransport = function() {
  return /** @type {!proto.skydio.mobile.NetworkInterfaceInfo.Transport} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skydio.mobile.NetworkInterfaceInfo.Transport} value
 * @return {!proto.skydio.mobile.NetworkInterfaceInfo} returns this
 */
proto.skydio.mobile.NetworkInterfaceInfo.prototype.setTransport = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Type type = 3;
 * @return {!proto.skydio.mobile.NetworkInterfaceInfo.Type}
 */
proto.skydio.mobile.NetworkInterfaceInfo.prototype.getType = function() {
  return /** @type {!proto.skydio.mobile.NetworkInterfaceInfo.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skydio.mobile.NetworkInterfaceInfo.Type} value
 * @return {!proto.skydio.mobile.NetworkInterfaceInfo} returns this
 */
proto.skydio.mobile.NetworkInterfaceInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skydio.mobile.NetworkInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.NetworkInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.NetworkInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.NetworkInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.NetworkInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
networksList: jspb.Message.toObjectList(msg.getNetworksList(),
    proto.skydio.mobile.NetworkInterfaceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.NetworkInfo}
 */
proto.skydio.mobile.NetworkInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.NetworkInfo;
  return proto.skydio.mobile.NetworkInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.NetworkInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.NetworkInfo}
 */
proto.skydio.mobile.NetworkInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skydio.mobile.NetworkInterfaceInfo;
      reader.readMessage(value,proto.skydio.mobile.NetworkInterfaceInfo.deserializeBinaryFromReader);
      msg.addNetworks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.NetworkInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.NetworkInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.NetworkInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.NetworkInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNetworksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.skydio.mobile.NetworkInterfaceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NetworkInterfaceInfo networks = 1;
 * @return {!Array<!proto.skydio.mobile.NetworkInterfaceInfo>}
 */
proto.skydio.mobile.NetworkInfo.prototype.getNetworksList = function() {
  return /** @type{!Array<!proto.skydio.mobile.NetworkInterfaceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skydio.mobile.NetworkInterfaceInfo, 1));
};


/**
 * @param {!Array<!proto.skydio.mobile.NetworkInterfaceInfo>} value
 * @return {!proto.skydio.mobile.NetworkInfo} returns this
*/
proto.skydio.mobile.NetworkInfo.prototype.setNetworksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skydio.mobile.NetworkInterfaceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skydio.mobile.NetworkInterfaceInfo}
 */
proto.skydio.mobile.NetworkInfo.prototype.addNetworks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skydio.mobile.NetworkInterfaceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skydio.mobile.NetworkInfo} returns this
 */
proto.skydio.mobile.NetworkInfo.prototype.clearNetworksList = function() {
  return this.setNetworksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.ConnectedVehicleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.ConnectedVehicleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.ConnectedVehicleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
isConnected: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
vehicleInfo: (f = msg.getVehicleInfo()) && proto.skydio.mobile.VehicleInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.ConnectedVehicleInfo}
 */
proto.skydio.mobile.ConnectedVehicleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.ConnectedVehicleInfo;
  return proto.skydio.mobile.ConnectedVehicleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.ConnectedVehicleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.ConnectedVehicleInfo}
 */
proto.skydio.mobile.ConnectedVehicleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConnected(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = new proto.skydio.mobile.VehicleInfo;
      reader.readMessage(value,proto.skydio.mobile.VehicleInfo.deserializeBinaryFromReader);
      msg.setVehicleInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.ConnectedVehicleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.ConnectedVehicleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.ConnectedVehicleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsConnected();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skydio.mobile.VehicleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_connected = 1;
 * @return {boolean}
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.getIsConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.mobile.ConnectedVehicleInfo} returns this
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.setIsConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skydio.mobile.ConnectedVehicleInfo} returns this
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional VehicleInfo vehicle_info = 3;
 * @return {?proto.skydio.mobile.VehicleInfo}
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.getVehicleInfo = function() {
  return /** @type{?proto.skydio.mobile.VehicleInfo} */ (
    jspb.Message.getWrapperField(this, proto.skydio.mobile.VehicleInfo, 3));
};


/**
 * @param {?proto.skydio.mobile.VehicleInfo|undefined} value
 * @return {!proto.skydio.mobile.ConnectedVehicleInfo} returns this
*/
proto.skydio.mobile.ConnectedVehicleInfo.prototype.setVehicleInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skydio.mobile.ConnectedVehicleInfo} returns this
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.clearVehicleInfo = function() {
  return this.setVehicleInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skydio.mobile.ConnectedVehicleInfo.prototype.hasVehicleInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.mobile.RemoteStreamingBitrate.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.mobile.RemoteStreamingBitrate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.mobile.RemoteStreamingBitrate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.RemoteStreamingBitrate.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
bitrate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.mobile.RemoteStreamingBitrate}
 */
proto.skydio.mobile.RemoteStreamingBitrate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.mobile.RemoteStreamingBitrate;
  return proto.skydio.mobile.RemoteStreamingBitrate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.mobile.RemoteStreamingBitrate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.mobile.RemoteStreamingBitrate}
 */
proto.skydio.mobile.RemoteStreamingBitrate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBitrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.mobile.RemoteStreamingBitrate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.mobile.RemoteStreamingBitrate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.mobile.RemoteStreamingBitrate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.mobile.RemoteStreamingBitrate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBitrate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skydio.mobile.RemoteStreamingBitrate.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.RemoteStreamingBitrate} returns this
 */
proto.skydio.mobile.RemoteStreamingBitrate.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 bitrate = 2;
 * @return {number}
 */
proto.skydio.mobile.RemoteStreamingBitrate.prototype.getBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.mobile.RemoteStreamingBitrate} returns this
 */
proto.skydio.mobile.RemoteStreamingBitrate.prototype.setBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.skydio.mobile.OtaStatus = {
  OTA_UNKNOWN: 0,
  OTA_STARTED: 1,
  OTA_SUCCESS: 2,
  OTA_FAILED: 3,
  OTA_MH_PROGRESS: 4,
  OTA_FLASH_PROGRESS: 5
};

/**
 * @enum {number}
 */
proto.skydio.mobile.UconErrorCode = {
  UNDEFINED: 0,
  NO_FLIGHT_DECK_CLIENT: 100,
  AVAILABLE_FILES_FETCH_FAILED: 101,
  REPORT_FILES_FAILED: 102,
  VEHICLE_ID_MISMATCH: 103,
  CHECK_DELIVERIES_FAILED: 200,
  DELIVER_FILE_FAILED: 201,
  UNKNOWN_UPLOAD_DESTINATION: 202,
  FILE_OVERWRITE_PROTECTED: 203,
  FILE_DOWNLOAD_FAILED: 300,
  FILE_UPLOAD_FAILED: 301,
  FILE_COULD_NOT_BE_OPENED: 302,
  FILE_DOES_NOT_EXIST: 303,
  FILE_COULD_NOT_BE_RENAMED: 304,
  VALIDATION_PARAMETER_MISMATCH: 400,
  VALIDATION_HASHING_FAILED: 401
};

/**
 * @enum {number}
 */
proto.skydio.mobile.UconFileLocation = {
  UNKNOWN: 0,
  VEHICLE: 1,
  PHONE: 2,
  CLOUD: 3
};

/**
 * @enum {number}
 */
proto.skydio.mobile.UconFileStatus = {
  AVAILABLE: 0,
  PENDING: 1,
  DELIVERED: 2
};

/**
 * @enum {number}
 */
proto.skydio.mobile.AgcPreset = {
  DEFAULT: 0,
  RECONNAISSANCE: 1,
  LOW_CONTRAST: 2,
  MEDIUM_CONTRAST: 3,
  HIGH_CONTRAST: 4,
  CUSTOM: 5,
  IQ_TESTING: 6
};

/**
 * @enum {number}
 */
proto.skydio.mobile.OaVoxelRenderDistance = {
  MINIMAL: 0,
  CLOSE: 1
};

/**
 * @enum {number}
 */
proto.skydio.mobile.OaVoxelRenderStyle = {
  OUTLINE: 0,
  SOLID: 1
};

goog.object.extend(exports, proto.skydio.mobile);
