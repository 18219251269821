import { findKey } from "lodash";
import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";
import {
  parseSearchState,
  makeQuerySerializable,
  convertToNumber,
  convertToArray,
} from "@skydio/uri_util/src/query";

import { Release, ReleaseFile, ReleasesQueryState, ReleasesQueryParams } from "./types";
import { ControllerRelease } from "../controller_releases/types";

export const releasesQueryInitialState: ReleasesQueryState = {
  key: "",
  minVersion: "",
  maxVersion: "",
  group: [],
  notGroup: [],
  name: "",
  vehicleType: "",
  description: "",
  comment: "",
};

export const parseReleasesQueryState = (searchString: string) =>
  parseSearchState(searchString, releasesQueryInitialState, (params: ReleasesQueryParams) => ({
    ...params,
    group: convertToArray(params.group),
    notGroup: convertToArray(params.notGroup),
    limit: convertToNumber(params.limit),
    perPage: convertToNumber(params.perPage),
    pageNumber: convertToNumber(params.pageNumber),
  }));

export const prepareReleasesRequest = (state: ReleasesQueryState) =>
  makeQuerySerializable(state, releasesQueryInitialState, releaseState => {
    const vehicleType = parseInt(releaseState.vehicleType);
    if (vehicleType >= 0) {
      return {
        ...releaseState,
        vehicleType: getVehicleTypeEnum(vehicleType),
      };
    }
    return releaseState;
  });

export const vehicleTypeEnum = enums_pb.VehicleType.VehicleTypeEnum;

export const ubuntuSuiteEnum = enums_pb.UbuntuSuite.UbuntuSuiteEnum;

export const getVehicleTypeEnum = (vehicleType: number) =>
  findKey(enums_pb.VehicleType.VehicleTypeEnum, o => o === vehicleType);

export function getVersionSortFunc<T extends Release | ReleaseFile | ControllerRelease>(
  sortKey: keyof T
) {
  return (a: T, b: T) => {
    if (!a[sortKey]) {
      return -1;
    } else if (!b[sortKey]) {
      return 1;
    } else {
      const aver = (a[sortKey] as unknown as string).split(".");
      const bver = (b[sortKey] as unknown as string).split(".");
      for (let i = 0; i < 3; i++) {
        let res = Number(bver[i]) - Number(aver[i]);
        if (res !== 0) return res;
      }
      return 0;
    }
  };
}
