/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "waypoints";

/**
 * This file automatically generated by skymarshal.
 * DO NOT MODIFY BY HAND!!!!
 */

export interface LandingPadAnchorPoseSource {
}

export enum LandingPadAnchorPoseSource_Enum {
  INVALID = 0,
  NAV = 1,
  STRICT_WORLD_FRAME_FILTER = 2,
  USER_PROVIDED_NAV = 3,
  NAV_CAM_POSE_GRAPH = 4,
  SITE_BASED_GPS = 5,
  DOCK_WAYPOINT_TEGRA_REBOOT = 6,
  DOCK_WORLD_WAYPOINT_TEGRA_REBOOT = 7,
  FUSED_VIO_GPS_ESTIMATOR = 8,
  LAST_CONVERGED_WORLD_FRAME_FILTER = 9,
  UNRECOGNIZED = -1,
}

export function landingPadAnchorPoseSource_EnumFromJSON(object: any): LandingPadAnchorPoseSource_Enum {
  switch (object) {
    case 0:
    case "INVALID":
      return LandingPadAnchorPoseSource_Enum.INVALID;
    case 1:
    case "NAV":
      return LandingPadAnchorPoseSource_Enum.NAV;
    case 2:
    case "STRICT_WORLD_FRAME_FILTER":
      return LandingPadAnchorPoseSource_Enum.STRICT_WORLD_FRAME_FILTER;
    case 3:
    case "USER_PROVIDED_NAV":
      return LandingPadAnchorPoseSource_Enum.USER_PROVIDED_NAV;
    case 4:
    case "NAV_CAM_POSE_GRAPH":
      return LandingPadAnchorPoseSource_Enum.NAV_CAM_POSE_GRAPH;
    case 5:
    case "SITE_BASED_GPS":
      return LandingPadAnchorPoseSource_Enum.SITE_BASED_GPS;
    case 6:
    case "DOCK_WAYPOINT_TEGRA_REBOOT":
      return LandingPadAnchorPoseSource_Enum.DOCK_WAYPOINT_TEGRA_REBOOT;
    case 7:
    case "DOCK_WORLD_WAYPOINT_TEGRA_REBOOT":
      return LandingPadAnchorPoseSource_Enum.DOCK_WORLD_WAYPOINT_TEGRA_REBOOT;
    case 8:
    case "FUSED_VIO_GPS_ESTIMATOR":
      return LandingPadAnchorPoseSource_Enum.FUSED_VIO_GPS_ESTIMATOR;
    case 9:
    case "LAST_CONVERGED_WORLD_FRAME_FILTER":
      return LandingPadAnchorPoseSource_Enum.LAST_CONVERGED_WORLD_FRAME_FILTER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LandingPadAnchorPoseSource_Enum.UNRECOGNIZED;
  }
}

export function landingPadAnchorPoseSource_EnumToJSON(object: LandingPadAnchorPoseSource_Enum): string {
  switch (object) {
    case LandingPadAnchorPoseSource_Enum.INVALID:
      return "INVALID";
    case LandingPadAnchorPoseSource_Enum.NAV:
      return "NAV";
    case LandingPadAnchorPoseSource_Enum.STRICT_WORLD_FRAME_FILTER:
      return "STRICT_WORLD_FRAME_FILTER";
    case LandingPadAnchorPoseSource_Enum.USER_PROVIDED_NAV:
      return "USER_PROVIDED_NAV";
    case LandingPadAnchorPoseSource_Enum.NAV_CAM_POSE_GRAPH:
      return "NAV_CAM_POSE_GRAPH";
    case LandingPadAnchorPoseSource_Enum.SITE_BASED_GPS:
      return "SITE_BASED_GPS";
    case LandingPadAnchorPoseSource_Enum.DOCK_WAYPOINT_TEGRA_REBOOT:
      return "DOCK_WAYPOINT_TEGRA_REBOOT";
    case LandingPadAnchorPoseSource_Enum.DOCK_WORLD_WAYPOINT_TEGRA_REBOOT:
      return "DOCK_WORLD_WAYPOINT_TEGRA_REBOOT";
    case LandingPadAnchorPoseSource_Enum.FUSED_VIO_GPS_ESTIMATOR:
      return "FUSED_VIO_GPS_ESTIMATOR";
    case LandingPadAnchorPoseSource_Enum.LAST_CONVERGED_WORLD_FRAME_FILTER:
      return "LAST_CONVERGED_WORLD_FRAME_FILTER";
    case LandingPadAnchorPoseSource_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseLandingPadAnchorPoseSource(): LandingPadAnchorPoseSource {
  return {};
}

export const LandingPadAnchorPoseSource = {
  encode(_: LandingPadAnchorPoseSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LandingPadAnchorPoseSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLandingPadAnchorPoseSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LandingPadAnchorPoseSource {
    return {};
  },

  toJSON(_: LandingPadAnchorPoseSource): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LandingPadAnchorPoseSource>, I>>(base?: I): LandingPadAnchorPoseSource {
    return LandingPadAnchorPoseSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LandingPadAnchorPoseSource>, I>>(_: I): LandingPadAnchorPoseSource {
    const message = createBaseLandingPadAnchorPoseSource();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
