import { faCheck, faChevronRight, faCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import React, { forwardRef } from "react";

import { cn } from "../../utils/cn";
import * as DropdownMenuPrimitive from "./RadixDropdownMenu";

import type { ComponentPropsWithoutRef, ElementRef, HTMLAttributes } from "react";

export const DropdownMenu = DropdownMenuPrimitive.Root as typeof DropdownMenuPrimitive.Root & {
  Trigger: typeof DropdownMenuPrimitive.Trigger;
  Group: typeof DropdownMenuPrimitive.Group;
  Portal: typeof DropdownMenuPrimitive.Portal;
  Sub: typeof DropdownMenuPrimitive.Sub;
  RadioGroup: typeof DropdownMenuPrimitive.RadioGroup;
  SubTrigger: typeof DropdownMenuSubTrigger;
  SubContent: typeof DropdownMenuSubContent;
  Content: typeof DropdownMenuContent;
  Item: typeof DropdownMenuItem;
  CheckboxItem: typeof DropdownMenuCheckboxItem;
  RadioItem: typeof DropdownMenuRadioItem;
  Label: typeof DropdownMenuLabel;
  Separator: typeof DropdownMenuSeparator;
  Shortcut: typeof DropdownMenuShortcut;
};

DropdownMenu.Trigger = DropdownMenuPrimitive.Trigger;
DropdownMenu.Group = DropdownMenuPrimitive.Group;
DropdownMenu.Portal = DropdownMenuPrimitive.Portal;
DropdownMenu.Sub = DropdownMenuPrimitive.Sub;
DropdownMenu.RadioGroup = DropdownMenuPrimitive.RadioGroup;

const DropdownMenuSubTrigger = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      "flex",
      "cursor-default",
      "select-none",
      "items-center",
      "rounded-sm",
      "px-2",
      "py-1.5",
      "text-sm",
      "outline-none",
      "focus:bg-gray-20",
      "dark:focus:bg-gray-700",
      "data-[state=open]:bg-gray-20",
      "dark:data-[state=open]:bg-gray-700",
      inset && "pl-8",
      className
    )}
    {...props}
  >
    {children}
    <Icon icon={faChevronRight} className="ml-auto h-4 w-4" />
  </DropdownMenuPrimitive.SubTrigger>
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;
DropdownMenu.SubTrigger = DropdownMenuSubTrigger;

const DropdownMenuSubContent = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={cn(
      "z-50",
      "min-w-[8rem]",
      "overflow-hidden",
      "rounded-md",
      "border",
      "bg-white",
      "dark:bg-gray-800",
      "p-1",
      "text-gray-700",
      "dark:text-white",
      "shadow-cardHover",
      "data-[state=open]:animate-in",
      "data-[state=closed]:animate-out",
      "data-[state=closed]:fade-out-0",
      "data-[state=open]:fade-in-0",
      "data-[state=closed]:zoom-out-95",
      "data-[state=open]:zoom-in-95",
      "data-[side=bottom]:slide-in-from-top-2",
      "data-[side=left]:slide-in-from-right-2",
      "data-[side=right]:slide-in-from-left-2",
      "data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;
DropdownMenu.SubContent = DropdownMenuSubContent;

const DropdownMenuContent = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "z-50",
        "min-w-[8rem]",
        "overflow-hidden",
        "rounded-sm",
        "border",
        "bg-white",
        "dark:bg-gray-800",
        "shadow-cardHover",
        "p-1",
        "text-gray-700",
        "dark:text-white",
        "data-[state=open]:animate-in",
        "data-[state=closed]:animate-out",
        "data-[state=closed]:fade-out-0",
        "data-[state=open]:fade-in-0",
        "data-[state=closed]:zoom-out-95",
        "data-[state=open]:zoom-in-95",
        "data-[side=bottom]:slide-in-from-top-2",
        "data-[side=left]:slide-in-from-right-2",
        "data-[side=right]:slide-in-from-left-2",
        "data-[side=top]:slide-in-from-bottom-2",
        className
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
DropdownMenu.Content = DropdownMenuContent;

const DropdownMenuItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Item>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      "relative",
      "flex",
      "cursor-pointer",
      "select-none",
      "items-center",
      "gap-x-2",
      "rounded-sm",
      "px-2",
      "py-1.5",
      "text-sm",
      "outline-none",
      "transition-colors",
      "focus:bg-gray-20",
      "dark:focus:bg-gray-700",
      "data-[disabled]:pointer-events-none",
      "data-[disabled]:cursor-default",
      "data-[disabled]:opacity-50",
      inset && "pl-8",
      className
    )}
    {...props}
  />
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
DropdownMenu.Item = DropdownMenuItem;

const DropdownMenuCheckboxItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      "relative",
      "flex",
      "cursor-default",
      "select-none",
      "items-center",
      "rounded-sm",
      "py-1.5",
      "pl-8",
      "pr-2",
      "text-sm",
      "outline-none",
      "transition-colors",
      "focus:bg-gray-50",
      "dark:focus:bg-gray-700",
      "focus:text-blue",
      "data-[disabled]:pointer-events-none",
      "data-[disabled]:opacity-50",
      className
    )}
    checked={checked}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <Icon icon={faCheck} className="h-4 w-4" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
DropdownMenu.CheckboxItem = DropdownMenuCheckboxItem;

const DropdownMenuRadioItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={cn(
      "relative",
      "flex",
      "cursor-default",
      "select-none",
      "items-center",
      "rounded-sm",
      "py-1.5",
      "pl-8",
      "pr-2",
      "text-sm",
      "outline-none",
      "transition-colors",
      "focus:bg-gray-50",
      "dark:focus:bg-gray-700",
      "focus:text-blue",
      "data-[disabled]:pointer-events-none",
      "data-[disabled]:opacity-50",
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <Icon icon={faCircle} className="h-2 w-2 fill-current" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.RadioItem>
));
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;
DropdownMenu.RadioItem = DropdownMenuRadioItem;

const DropdownMenuLabel = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Label>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn("px-2 py-1.5 text-sm font-semibold", inset && "pl-8", className)}
    {...props}
  />
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;
DropdownMenu.Label = DropdownMenuLabel;

const DropdownMenuSeparator = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-gray-50 dark:bg-gray-700", className)}
    {...props}
  />
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
DropdownMenu.Separator = DropdownMenuSeparator;

const DropdownMenuShortcut = ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span className={cn("ml-auto text-xs tracking-widest opacity-60", className)} {...props} />
  );
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";
DropdownMenu.Shortcut = DropdownMenuShortcut;
