import api_token_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/api_token_pb";
import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";

import { SearchableState } from "../../../common/types";

export const NEW_TOKEN_PLACEHOLDER_ID = "NEW_TOKEN_PLACEHOLDER_ID";

export type APIToken = api_token_pb.ApiToken.AsObject;

export const TokenScopeEnum = enums_pb.ApiTokenScope.ScopeEnum;

export interface Token extends APIToken {
  modified: TokenUpdate;
}

export type NewToken = Pick<Token, "name" | "organizationId" | "enabled" | "scope">;

export type TokenUpdate = Partial<Pick<Token, "name" | "enabled" | "scope">>;

export interface TokensPrimaryState extends SearchableState {
  tokens: Token[];
  addModalOpen: boolean;
}

export interface TokensQueryState {
  uuid: string;
  name: string;
  enabled: boolean;
  organizationId: string;
  scope: enums_pb.ApiTokenScope.ScopeEnum;
  limit?: number;
}

export type TokensRequest = Partial<TokensQueryState>;

export type TokensQueryParams = TokensRequest;

export interface TokenRequest {
  path: { uuid: string };
}

export interface AddTokenRequest {
  protobuf?: api_token_pb.ApiTokenUpdateRequest;
}

export type UpdateTokenRequest = AddTokenRequest & TokenRequest;

export type DeleteTokenRequest = TokenRequest;
