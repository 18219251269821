import { faChevronDown, faSpinnerThird, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useButton } from "@react-aria/button";
import { useFocusRing } from "@react-aria/focus";
import { useHover, usePress } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { AnimatePresence } from "framer-motion";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { filterDOMProps } from "../../utils/filterDomProps";
import { formValueFromSelectedKeys, selectedKeysFromFormValue } from "../../utils/misc";
import { mergeRefs } from "../../utils/refs";
import { formLabel } from "../../utils/tailwindClasses";
import { ScrollShadow } from "../extra/ScrollShadow";
import { useControllerSafe } from "../form/useControllerSafe";
import { ListBox } from "../listbox/ListBox";
import StandalonePopover from "../popover/StandalonePopover";
import { MultiSelectTags } from "../tag/MultiSelectTags";
import { HiddenSelect } from "./HiddenSelect";
import { useMultiSelect } from "./useMultiSelect";
import { useMultiSelectState } from "./useMultiSelectState";

import type { CollectionChildren } from "@react-types/shared";
import type {
  ComponentPropsWithoutRef,
  ForwardedRef,
  Key,
  ReactElement,
  ReactNode,
  Ref,
} from "react";
import type { VariantProps } from "tailwind-variants";
import type { ReactRef, SlotsToClasses } from "../../utils/types";
import type { ScrollShadowProps } from "../extra/ScrollShadow";
import type { ListBoxProps } from "../listbox/ListBox";
import type { UsePopoverProps } from "../popover/usePopover";
import type { AriaHiddenSelectProps, HiddenSelectProps } from "./HiddenSelect";
import type { MultiSelectProps } from "./useMultiSelectState";

// eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
const selectVariants = tv({
  slots: {
    base: "group relative inline-flex flex-col",
    label: [...formLabel],
    mainWrapper: "flex w-full flex-col",
    trigger: [
      "cursor-pointer",
      "relative",
      "px-3",
      "gap-3",
      "w-full",
      "inline-flex",
      "flex-row",
      "items-center",
      "shadow-sm",
      "outline-none",
      "tap-highlight-transparent",
      "rounded",
      "bg-white",
      "dark:bg-gray-800",
      "border",
      "border-solid",
      "border-gray-150",
      "dark:border-gray-700",
      "data-[hover=true]:border-gray-300",
      "dark:data-[hover=true]:border-gray-600",
      "data-[open=true]:border-blue-400",
      "data-[focus-visible=true]:border-blue-400",
      "dark:data-[open=true]:border-blue-700",
      "dark:data-[focus-visible=true]:border-blue-700",
    ],
    innerWrapper:
      "min-h-4 box-border inline-flex h-full w-[calc(100%_-_theme(spacing.10))] items-center gap-1.5",
    selectorIcon:
      "absolute right-3 h-4 w-4 text-xs text-gray-500 hover:text-gray-900 dark:hover:text-gray-300",
    spinner:
      "absolute right-3 text-xs text-gray-300 [--fa-animation-duration:0.5s] dark:text-gray-600",
    value: [
      "w-full",
      "text-left",
      "font-normal",
      "text-gray-500",
      "dark:text-gray-300",
      "group-data-[has-value=true]:text-gray-900",
      "dark:group-data-[has-value=true]:text-white",
    ],
    listboxWrapper: "max-h-64 w-full scroll-py-6",
    listbox: "",
    popoverContent: "w-full overflow-hidden p-1",
    helperWrapper: "relative flex flex-col gap-1.5 p-1",
    description: "text-xs text-gray-400",
    errorMessage: "text-xs text-red",
    clearButton: [
      "p-2",
      "absolute",
      "right-8",
      "appearance-none",
      "outline-none",
      "cursor-pointer",
      "hidden",
      "group-data-[has-value=true]:block",
      "text-gray-500",
      "hover:text-gray-900",
      "dark:hover:text-gray-300",
      "transition",
    ],
  },
  variants: {
    size: {
      sm: {
        trigger: "min-h-7 h-7 rounded px-2",
        value: "text-sm",
        clearButton: "text-xs",
      },
      md: {
        trigger: "min-h-8 h-8 rounded",
        value: "text-sm",
        clearButton: "text-sm",
      },
      lg: {
        trigger: "min-h-10 h-10 rounded-md",
        value: "text-md",
        clearButton: "text-sm",
      },
    },
    labelPlacement: {
      top: {
        base: "flex flex-col",
        label: [
          "pe-2",
          "max-w-full",
          "text-ellipsis",
          "overflow-hidden",
          "group-data-[filled=true]:text-gray-900",
          "dark:group-data-[filled=true]:text-gray-100",
          "group-data-[filled=true]:pointer-events-auto",
        ],
      },
      left: {
        base: "flex-row flex-nowrap items-center",
        label: "text-foreground relative pr-2",
      },
    },
    fullWidth: {
      true: {
        base: "w-full",
      },
      false: {
        base: "min-w-[256px]",
      },
    },
    isDisabled: {
      true: {
        base: "pointer-events-none opacity-60",
        trigger: "pointer-events-none",
      },
    },
    isInvalid: {
      true: {
        label: "!text-red",
        value: "!text-red",
        selectorIcon: "text-red",
        trigger: "!border-red group-data-[focus=true]:border-red",
      },
    },
    isRequired: {
      true: {
        label: "after:ml-0.5 after:text-red after:content-['*']",
      },
    },
    isMultiline: {
      true: {
        label: "relative",
        trigger: "!h-auto",
      },
      false: {
        value: "truncate",
      },
    },
    disableAnimation: {
      true: {
        trigger: "after:transition-none",
        base: "transition-none",
        label: "transition-none",
        selectorIcon: "transition-none",
      },
      false: {
        base: "transition-background !duration-150 motion-reduce:transition-none",
        label: [
          "will-change-auto",
          "origin-top-left",
          "!duration-200",
          "!ease-out",
          "transition-[transform,color,left,opacity]",
          "motion-reduce:transition-none",
        ],
        selectorIcon: "ease transition-transform duration-150 motion-reduce:transition-none",
        trigger: "transition-colors motion-reduce:transition-none",
      },
    },
    disableSelectorIconRotation: {
      true: {},
      false: {
        selectorIcon: "data-[open=true]:rotate-180",
      },
    },
  },
  compoundVariants: [
    {
      labelPlacement: "top",
      isMultiline: false,
      class: {
        base: "group relative justify-end",
        label: ["pb-0", "z-20", "top-1/2", "-translate-y-1/2", "group-data-[filled=true]:left-0"],
      },
    },
    // top & size
    {
      labelPlacement: "top",
      size: "sm",
      isMultiline: false,
      class: {
        label: [
          "left-2",
          "group-data-[filled=true]:-translate-y-[calc(100%_+_theme(fontSize.xs)/2_+_16px)]",
        ],
        base: "data-[has-label=true]:mt-[calc(theme(fontSize.sm)_+_8px)]",
      },
    },
    {
      labelPlacement: "top",
      isMultiline: false,
      size: "md",
      class: {
        label: [
          "left-3",
          "group-data-[filled=true]:-translate-y-[calc(100%_+_theme(fontSize.xs)/2_+_16px)]",
        ],
        base: "data-[has-label=true]:mt-[calc(theme(fontSize.sm)_+_10px)]",
      },
    },
    {
      labelPlacement: "top",
      isMultiline: false,
      size: "lg",
      class: {
        label: [
          "left-3",
          "text-sm",
          "group-data-[filled=true]:-translate-y-[calc(100%_+_theme(fontSize.sm)/2_+_24px)]",
        ],
        base: "data-[has-label=true]:mt-[calc(theme(fontSize.sm)_+_12px)]",
      },
    },
    {
      labelPlacement: "left",
      size: "sm",
      class: {
        label: "pt-0.5",
      },
    },
    {
      labelPlacement: "left",
      size: "md",
      class: {
        label: "pt-1",
      },
    },
    {
      labelPlacement: "left",
      size: "lg",
      class: {
        label: "pt-1.5",
      },
    },
    // isMultiline & labelPlacement="top"
    {
      labelPlacement: "top",
      isMultiline: true,
      class: {
        label: "pb-1.5",
      },
    },
  ],
  defaultVariants: {
    size: "md",
    labelPlacement: "top",
    fullWidth: false,
    isDisabled: false,
    isMultiline: false,
    disableAnimation: false,
    disableSelectorIconRotation: false,
  },
});

export type SelectVariantProps = VariantProps<typeof selectVariants>;
export type SelectSlots = keyof ReturnType<typeof selectVariants>;

export type SelectedItemProps<T = object> = {
  /** A unique key for the item. */
  key?: Key;
  /** The props passed to the item. */
  props?: Record<string, any>;
  /** The item data. */
  data?: T | null;
  /** An accessibility label for this item. */
  "aria-label"?: string;
  /** The rendered contents of this item (e.g. JSX). */
  rendered?: ReactNode;
  /** A string value for this item, used for features like typeahead. */
  textValue?: string;
  /** The type of item this item represents. */
  type?: string;
};

export type SelectedItems<T = object> = Array<SelectedItemProps<T>>;

interface Props<T> extends Omit<ComponentPropsWithoutRef<"select">, keyof SelectVariantProps> {
  /**
   * The ref to the scroll element. Useful when having async loading of items.
   */
  scrollRef?: ReactRef<HTMLElement | null>;
  /**
   * Whether the select is required.
   * @default false
   */
  isRequired?: boolean;
  /**
   * Whether to show the clear button.
   */
  isClearable?: boolean;
  /**
   * Element to be rendered in the left side of the select.
   */
  startContent?: React.ReactNode;
  /**
   * Element to be rendered in the right side of the select.
   */
  endContent?: ReactNode;
  /**
   * The placeholder for the select to display when no option is selected.
   * @default "Select an option"
   */
  placeholder?: string;
  /**
   * Whether to display a top and bottom arrow indicators when the listbox is scrollable.
   * @default true
   */
  showScrollIndicators?: boolean;
  /**
   * Props to be passed to the popover component.
   *
   * @default { placement: "bottom", triggerScaleOnOpen: false, offset: 5 }
   */
  popoverProps?: Partial<UsePopoverProps>;
  /**
   * Props to be passed to the listbox component.
   *
   * @default { disableAnimation: false }
   */
  listboxProps?: Partial<ListBoxProps<T>>;
  /**
   * Props to be passed to the scroll shadow component. This component
   * adds a shadow to the top and bottom of the listbox when it is scrollable.
   *
   * @default { hideScrollBar: true, offset: 15 }
   */
  scrollShadowProps?: Partial<ScrollShadowProps>;
  /**
   * Function to render the value of the select. It renders the selected item by default.
   * @param value
   * @returns
   */
  renderValue?: (items: SelectedItems<T>) => ReactNode;
  /**
   * Callback fired when the select menu is closed.
   */
  onClose?: () => void;
  /**
   * Callback fired when the value is cleared.
   * if you pass this prop, the clear button will be shown.
   */
  onClear?: () => void;
  /**
   * Classes object to style the select and its children.
   */
  classNames?: SlotsToClasses<SelectSlots>;
}

export type SelectProps<T> = Omit<Props<T>, keyof MultiSelectProps<T>> &
  AriaHiddenSelectProps &
  MultiSelectProps<T> &
  SelectVariantProps;

function SelectImpl<T extends object>(props: SelectProps<T>, ref: ForwardedRef<HTMLSelectElement>) {
  const {
    isOpen,
    label,
    name,
    isLoading,
    defaultOpen,
    onOpenChange,
    startContent,
    endContent,
    description,
    errorMessage: errorMessageProp,
    renderValue,
    onSelectionChange,
    placeholder,
    children,
    disallowEmptySelection = false,
    selectionMode = "single",
    scrollRef: scrollRefProp,
    popoverProps: popoverPropsProp = {},
    scrollShadowProps = {},
    listboxProps: listboxPropsProp = {},
    validationState,
    onChange,
    onClose,
    onClear,
    className,
    classNames,
    size,
    labelPlacement: labelPlacementProp,
    fullWidth,
    isDisabled: isDisabledProp,
    isInvalid: isInvalidProp,
    isMultiline,
    disableAnimation = false,
    disableSelectorIconRotation,
    ...otherProps
  } = props;

  const formController = useControllerSafe({ name: name ?? "" });

  if (formController && !name) {
    throw new Error("Select used inside a Form component must have a name prop");
  }

  const formError = formController ? formController.fieldState.error : undefined;

  const errorMessage = formError
    ? String(formError.message)
    : typeof errorMessageProp === "function"
      ? null
      : errorMessageProp;
  const isDisabled = formController ? formController.field.disabled : isDisabledProp;

  const scrollShadowRef = useDomRef(scrollRefProp);

  const slotsProps: {
    popoverProps: SelectProps<T>["popoverProps"];
    scrollShadowProps: SelectProps<T>["scrollShadowProps"];
    listboxProps: SelectProps<T>["listboxProps"];
  } = {
    popoverProps: mergeProps(
      {
        placement: "bottom",
        triggerScaleOnOpen: false,
        offset: 5,
        disableAnimation,
      },
      popoverPropsProp
    ),
    scrollShadowProps: mergeProps(
      {
        ref: scrollShadowRef,
        isEnabled: props.showScrollIndicators ?? true,
        hideScrollBar: true,
        offset: 15,
      },
      scrollShadowProps
    ),
    listboxProps: mergeProps(
      {
        disableAnimation,
      },
      listboxPropsProp
    ),
  };

  const domRef = useDomRef(ref);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const state = useMultiSelectState<T>({
    ...props,
    isOpen,
    selectionMode,
    disallowEmptySelection,
    children: children as CollectionChildren<T>,
    isRequired: props.isRequired,
    isDisabled,
    defaultOpen,
    selectedKeys: formController
      ? selectedKeysFromFormValue(formController.field.value, selectionMode)
      : props.selectedKeys,
    onOpenChange: open => {
      onOpenChange?.(open);
      if (!open) {
        onClose?.();
        if (formController) {
          formController.field.onBlur();
        }
      }
    },
    onSelectionChange: keys => {
      onSelectionChange?.(keys);
      if (onChange && typeof onChange === "function" && domRef.current) {
        const event = {
          target: {
            ...domRef.current,
            value: Array.from(keys).join(","),
            name: domRef.current.name,
          },
        } as React.ChangeEvent<HTMLSelectElement>;

        onChange(event);
      }
      if (formController) {
        formController.field.onChange(formValueFromSelectedKeys(keys, selectionMode));
      }
    },
  });

  const {
    labelProps: ariaLabelProps,
    triggerProps: ariaTriggerProps,
    valueProps: ariaValueProps,
    menuProps,
    descriptionProps: ariaDescriptionProps,
    errorMessageProps: ariaErrorMessageProps,
  } = useMultiSelect({ ...props, disallowEmptySelection, isDisabled }, state, triggerRef);

  const { isPressed, buttonProps } = useButton(ariaTriggerProps, triggerRef);

  const { focusProps, isFocused, isFocusVisible } = useFocusRing();
  const { isHovered, hoverProps } = useHover({ isDisabled });

  const { focusProps: clearFocusProps, isFocusVisible: isClearButtonFocusVisible } = useFocusRing();

  const { pressProps: clearPressProps } = usePress({
    isDisabled: !!isDisabled,
    onPress: () => {
      state.setSelectedKeys(new Set());
      onClear?.();
    },
  });

  const isClearable = !!onClear || props.isClearable;

  const labelPlacement = useMemo<SelectVariantProps["labelPlacement"]>(() => {
    if (!labelPlacementProp && !label) {
      return "top";
    }

    return labelPlacementProp ?? "top";
  }, [labelPlacementProp, label]);

  const hasHelper = !!description || !!errorMessage;
  const hasPlaceholder = !!placeholder;
  const isInvalid = formError ? true : validationState === "invalid" || isInvalidProp;

  const isFilled =
    state.isOpen ||
    hasPlaceholder ||
    !!state.selectedItems ||
    !!startContent ||
    !!endContent ||
    !!isMultiline;
  const hasValue = !!state.selectedItems;
  const hasLabel = !!label;

  const slots = useMemo(
    () =>
      selectVariants({
        size,
        fullWidth,
        isDisabled,
        isMultiline,
        disableAnimation,
        isInvalid,
        labelPlacement,
        disableSelectorIconRotation,
        className,
      }),
    [
      size,
      fullWidth,
      isDisabled,
      isMultiline,
      disableAnimation,
      isInvalid,
      labelPlacement,
      disableSelectorIconRotation,
      className,
    ]
  );

  // scroll the listbox to the selected item
  useEffect(() => {
    if (state.isOpen && popoverRef.current && listBoxRef.current) {
      const selectedItem = listBoxRef.current.querySelector(
        "[aria-selected=true] [data-label=true]"
      );
      const scrollShadow = scrollShadowRef.current;

      // scroll the listbox to the selected item
      if (selectedItem && scrollShadow && selectedItem.parentElement) {
        const scrollShadowRect = scrollShadow?.getBoundingClientRect();
        const scrollShadowHeight = scrollShadowRect.height;

        scrollShadow.scrollTop =
          selectedItem.parentElement.offsetTop -
          scrollShadowHeight / 2 +
          selectedItem.parentElement.clientHeight / 2;
      }
    }
  }, [state.isOpen, disableAnimation]);

  // apply the same with to the popover as the select
  useEffect(() => {
    if (state.isOpen && popoverRef.current && triggerRef.current) {
      const selectRect = triggerRef.current.getBoundingClientRect();
      const popover = popoverRef.current;

      popover.style.width = selectRect.width + "px";
    }
  }, [state.isOpen]);

  const baseClassName = cn(classNames?.base, className);

  const baseProps = useMemo(
    () => ({
      "data-slot": "base",
      "data-filled": isFilled,
      "data-has-value": hasValue,
      "data-has-label": hasLabel,
      "data-has-helper": hasHelper,
      className: slots.base({ className: baseClassName }),
    }),
    [slots, hasHelper, hasValue, hasLabel, isFilled, baseClassName]
  );

  const triggerProps = useMemo(() => {
    return {
      ref: triggerRef,
      "data-slot": "trigger",
      "data-open": state.isOpen,
      "data-disabled": isDisabled,
      "data-focus": isFocused,
      "data-pressed": isPressed,
      "data-focus-visible": isFocusVisible,
      "data-hover": isHovered,
      className: slots.trigger({
        className: cn(
          classNames?.trigger,
          state.selectionMode === "multiple" && !!state.selectedItems?.length && "pl-1"
        ),
      }),
      ...mergeProps(buttonProps, focusProps, hoverProps, filterDOMProps(otherProps)),
    };
  }, [
    slots,
    triggerRef,
    state.isOpen,
    state.selectionMode,
    state.selectedItems,
    classNames?.trigger,
    isDisabled,
    isFocused,
    isPressed,
    isFocusVisible,
    isHovered,
    buttonProps,
    focusProps,
    hoverProps,
    otherProps,
  ]);

  const hiddenSelectProps = useMemo(
    () =>
      ({
        state,
        triggerRef,
        selectRef: formController ? mergeRefs(domRef, formController.field.ref) : domRef,
        selectionMode,
        label: props?.label,
        name: props?.name,
        isRequired: props?.isRequired,
        autoComplete: props?.autoComplete,
        isDisabled,
        onChange,
        value: formController?.field.value,
      }) as HiddenSelectProps<T>,
    [
      state,
      selectionMode,
      props?.label,
      props?.autoComplete,
      props?.name,
      isDisabled,
      triggerRef,
      formController,
    ]
  );

  const labelProps = useMemo(
    () => ({
      "data-slot": "label",
      className: slots.label({
        className: classNames?.label,
      }),
      ...ariaLabelProps,
    }),
    [slots, classNames?.label, ariaLabelProps]
  );

  const valueProps = useMemo(
    () => ({
      "data-slot": "value",
      className: slots.value({
        className: classNames?.value,
      }),
      ...ariaValueProps,
    }),
    [slots, classNames?.value, ariaValueProps]
  );

  const listboxWrapperProps = useMemo(
    () => ({
      "data-slot": "listboxWrapper",
      className: slots.listboxWrapper({
        class: classNames?.listboxWrapper,
      }),
      ...slotsProps.scrollShadowProps,
    }),
    [slots.listboxWrapper, classNames?.listboxWrapper, slotsProps.scrollShadowProps]
  );

  const listboxProps = useMemo(
    () =>
      ({
        state,
        ref: listBoxRef,
        "data-slot": "listbox",
        className: slots.listbox({
          className: classNames?.listbox,
        }),
        ...mergeProps(slotsProps.listboxProps, menuProps),
      }) as ListBoxProps<T>,
    [
      listBoxRef,
      slots.listbox,
      classNames?.listbox,
      props?.className,
      slotsProps.listboxProps,
      menuProps,
    ]
  );

  const popoverProps = useMemo(
    () => ({
      state,
      "data-slot": "popover",
      scrollRef: listBoxRef,
      triggerType: "listbox" as UsePopoverProps["triggerType"],
      classNames: {
        content: slots.popoverContent({
          class: classNames?.popoverContent,
        }),
      },
      ...slotsProps.popoverProps,
      ref: popoverRef,
      offset:
        state.selectedItems && state.selectedItems.length > 0
          ? // forces the popover to update its position when the selected items change
            state.selectedItems.length * 0.00000001 + (slotsProps.popoverProps?.offset || 0)
          : slotsProps.popoverProps?.offset,
    }),
    [
      slots.popoverContent,
      classNames?.popoverContent,
      slotsProps.popoverProps,
      triggerRef,
      state,
      state.selectedItems,
    ]
  );

  const innerWrapperProps = useMemo(
    () => ({
      "data-slot": "innerWrapper",
      className: slots.innerWrapper({
        className: classNames?.innerWrapper,
      }),
    }),
    [slots.innerWrapper, classNames?.innerWrapper]
  );

  const helperWrapperProps = useMemo(
    () => ({
      "data-slot": "helperWrapper",
      className: slots.helperWrapper({
        className: classNames?.helperWrapper,
      }),
    }),
    [slots.helperWrapper, classNames?.helperWrapper]
  );

  const descriptionProps = useMemo(
    () => ({
      ...ariaDescriptionProps,
      "data-slot": "description",
      className: slots.description({ className: classNames?.description }),
    }),
    [slots.description, classNames?.description]
  );

  const clearButtonProps = useMemo(
    () => ({
      role: "button",
      tabIndex: 0,
      "data-slot": "clearButton",
      "data-focus-visible": isClearButtonFocusVisible,
      ...mergeProps(clearPressProps, clearFocusProps),
      className: slots.clearButton(),
    }),
    [isClearButtonFocusVisible, clearPressProps, clearFocusProps, slots.clearButton]
  );

  const mainWrapperProps = useMemo(() => {
    return {
      "data-slot": "mainWrapper",
      className: slots.mainWrapper({
        className: classNames?.mainWrapper,
      }),
    };
  }, [slots.mainWrapper, classNames?.mainWrapper]);

  const errorMessageProps = useMemo(
    () => ({
      ...ariaErrorMessageProps,
      "data-slot": "errorMessage",
      className: slots.errorMessage({ className: classNames?.errorMessage }),
    }),
    [slots.errorMessage, ariaErrorMessageProps, classNames?.errorMessage]
  );

  const selectorIconProps = useMemo(
    () => ({
      "data-slot": "selectorIcon",
      "aria-hidden": true,
      "data-open": state.isOpen,
      className: slots.selectorIcon({ class: classNames?.selectorIcon }),
    }),
    [slots.selectorIcon, classNames?.selectorIcon, state.isOpen]
  );

  const spinnerProps = useMemo(
    () => ({
      "data-slot": "spinner",
      "aria-hidden": true,
      className: slots.spinner({ className: classNames?.spinner }),
    }),
    [slots.spinner, classNames?.spinner]
  );

  const helperWrapper = useMemo(() => {
    if (!hasHelper) return null;

    return (
      <div {...helperWrapperProps}>
        {errorMessage ? (
          <div {...errorMessageProps}>{errorMessage}</div>
        ) : description ? (
          <div {...descriptionProps}>{description}</div>
        ) : null}
      </div>
    );
  }, [
    hasHelper,
    errorMessage,
    description,
    helperWrapperProps,
    errorMessageProps,
    descriptionProps,
  ]);

  const selectedItems = useMemo(() => {
    if (!state.selectedItems) {
      return <span {...valueProps}>{placeholder}</span>;
    }

    if (renderValue && typeof renderValue === "function") {
      const mappedItems = [...state.selectedItems].map(item => ({
        key: item.key,
        data: item.value,
        type: item.type,
        props: item.props,
        textValue: item.textValue,
        rendered: item.rendered,
        "aria-label": item["aria-label"],
      }));

      return <span {...valueProps}>{renderValue(mappedItems)}</span>;
    }

    if (state.selectionMode === "multiple") {
      return <MultiSelectTags state={state} {...valueProps} />;
    }
    return (
      <span {...valueProps}>{state.selectedItems.map(item => item.textValue).join(", ")}</span>
    );
  }, [state, state.selectedItems, state.selectionMode, renderValue, placeholder]);

  const renderIndicator = useMemo(() => {
    if (isLoading) {
      return (
        <span {...spinnerProps}>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </span>
      );
    }

    return (
      <span {...selectorIconProps}>
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
    );
  }, [isLoading, spinnerProps, selectorIconProps]);

  const popoverContent = useMemo(
    () =>
      state.isOpen ? (
        <StandalonePopover {...popoverProps} state={state} triggerRef={triggerRef}>
          <ScrollShadow {...listboxWrapperProps}>
            <ListBox {...listboxProps} />
          </ScrollShadow>
        </StandalonePopover>
      ) : null,
    [state.isOpen, popoverProps, state, triggerRef, listboxWrapperProps, listboxProps]
  );

  const end = useMemo(() => {
    if (isClearable) {
      return <span {...clearButtonProps}>{endContent || <FontAwesomeIcon icon={faXmark} />}</span>;
    }

    return endContent;
  }, [isClearable, clearButtonProps, endContent]);

  return (
    <div {...baseProps}>
      <HiddenSelect {...hiddenSelectProps} />
      <div {...mainWrapperProps}>
        <button {...triggerProps}>
          {label ? <label {...labelProps}>{label}</label> : null}
          <div {...innerWrapperProps}>
            {startContent}
            {selectedItems}
            {end}
          </div>
          {renderIndicator}
        </button>
        {helperWrapper}
      </div>
      {disableAnimation ? popoverContent : <AnimatePresence>{popoverContent}</AnimatePresence>}
    </div>
  );
}

/**
 * A collapsible list of options.
 */
const WrappedSelect = forwardRef(SelectImpl) as <T = object>(
  props: SelectProps<T> & { ref?: Ref<HTMLElement> }
) => ReactElement;

export const Select = WrappedSelect as typeof WrappedSelect & {
  displayName: string;
  Item: typeof ListBox.Item;
  Section: typeof ListBox.Section;
};

Select.displayName = "Select";
Select.Item = ListBox.Item;
Select.Section = ListBox.Section;

export type { ListBoxItemProps as SelectItemProps } from "../listbox/ListBoxItem";
export type { ListBoxSectionProps as SelectSectionProps } from "../listbox/ListBoxSection";
