import { useState } from "react";

import { cn } from "../../utils/cn";
import { Button } from "../button/Button";

import type { HTMLAttributes, ReactNode } from "react";

export interface ShowMoreContainerProps<T> extends HTMLAttributes<HTMLDivElement> {
  items: T[];
  renderItem: (item: T) => ReactNode;
  maxItems?: number;
}

/**
 * A collapsible container that shows a constrained number of items, with a button to expand.
 */
export const ShowMoreContainer = <T,>({
  className,
  items,
  renderItem,
  maxItems = 3,
  ...props
}: ShowMoreContainerProps<T>) => {
  const [expanded, setExpanded] = useState(false);

  const itemsToRender = expanded || items.length <= maxItems ? items : items.slice(0, maxItems - 1);

  return (
    <div {...props} className={cn("flex flex-col items-start", className)}>
      {itemsToRender.map(renderItem)}
      {items.length > maxItems && (
        <Button
          variant="link"
          className="h-auto p-0 leading-6"
          onClick={() => setExpanded(val => !val)}
        >
          {expanded ? "Show less" : `+${items.length - maxItems + 1} more`}
        </Button>
      )}
    </div>
  );
};
