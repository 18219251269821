import { createSlice, combineReducers, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import {
  createClearableIndexedRequestReducer,
  reduceReducers,
  createIndexedRequestReducerFromThunk,
  createRequestReducerFromThunk,
} from "@skydio/redux_util/src";

import { fetchSimulators, fetchSimulator, updateSimulator, createSimulator } from "./asyncThunks";

import { APIPagination } from "../pagination/types";
import {
  NEW_SIM_PLACEHOLDER_ID,
  SimulatorsPrimaryState,
  Simulator,
  SimulatorsMap,
  APISimulator,
  SimulatorUpdate,
} from "./types";

const defaultPagination: APIPagination = {
  maxPerPage: 25,
  currentPage: 1,
  totalPages: 1,
};

const initialState: SimulatorsPrimaryState = {
  simulators: {},
  dispatchTimeout: null,
  requestedIds: [],
  pagination: defaultPagination,
};

const simulatorInitialState: Simulator = {
  uuid: "",
  name: "",
  vehicleId: "",
  httpPort: 0,
  udpPort: 0,
  imageTag: "",
  defaultRunmode: "",
  hostname: "",
  host: "",
  insecure: false,
  connectWithoutWifi: false,
  externalHttpPort: 0,
  userEmails: [],
  region: null,
  pooled: false,
  lastPooledAssignmentTime: null,
  modified: {},
};

export interface FieldUpdatePayload {
  uuid: string;
  name: keyof SimulatorUpdate;
  value: SimulatorUpdate[keyof SimulatorUpdate];
}

const updateSimulatorState = (state: SimulatorsMap, simulator: APISimulator) => {
  if (!(simulator.uuid in state)) {
    state[simulator.uuid] = { ...simulatorInitialState };
  }
  Object.assign(state[simulator.uuid]!, simulator, {
    lastPooledAssignmentTime: simulator.lastPooledAssignmentTime
      ? moment(simulator.lastPooledAssignmentTime * 1000)
      : null,
  });
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "simulators",
  initialState,
  reducers: {
    setSimulatorsQueryTimeout(state, { payload }: PayloadAction<number>) {
      state.dispatchTimeout = payload;
    },
    clearSimulatorsQueryTimeout(state) {
      state.dispatchTimeout = null;
    },
    updateSimulatorField(state, { payload }: PayloadAction<FieldUpdatePayload>) {
      state.simulators[payload.uuid]!.modified = {
        ...state.simulators[payload.uuid]!.modified,
        [payload.name]: payload.value,
      };
    },
    clearSimulatorModifications(state, { payload }: PayloadAction<string>) {
      state.simulators[payload]!.modified = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchSimulators.fulfilled, (state, { payload }) => {
        payload.simulatorsList.forEach(simulator => {
          updateSimulatorState(state.simulators as SimulatorsMap, simulator);
        });
        state.requestedIds = payload.simulatorsList.map(({ uuid }) => uuid);
        state.pagination = {
          maxPerPage: payload.pagination!.maxPerPage,
          currentPage: payload.pagination!.currentPage,
          totalPages: payload.pagination!.totalPages,
        };
      })
      .addCase(fetchSimulators.rejected, state => {
        state.requestedIds = [];
      })
      .addCase(fetchSimulator.fulfilled, (state, { payload }) => {
        updateSimulatorState(state.simulators as SimulatorsMap, payload);
      })
      .addCase(createSimulator.fulfilled, (state, { payload }) => {
        updateSimulatorState(state.simulators as SimulatorsMap, payload);
      })
      .addCase(updateSimulator.fulfilled, (state, { payload }) => {
        updateSimulatorState(state.simulators as SimulatorsMap, payload);
        state.simulators[payload.uuid]!.modified = {};
      }),
});

const { clearAction: clearSimulatorRequest, clearableReducer } =
  createClearableIndexedRequestReducer(
    reduceReducers(
      createIndexedRequestReducerFromThunk(fetchSimulator),
      createIndexedRequestReducerFromThunk(updateSimulator, "uuid"),
      createIndexedRequestReducerFromThunk(createSimulator, () => NEW_SIM_PLACEHOLDER_ID)
    ),
    "simulators"
  );

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    simulators: createRequestReducerFromThunk(fetchSimulators),
    simulator: clearableReducer,
  }),
});
export type SimulatorsState = ReturnType<typeof reducer>;

export const simulatorActions = { ...actions, clearSimulatorRequest };
export default reducer;
