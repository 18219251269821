// source: pbtypes/gen/common/fault.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.common.VehicleFault', null, global);
goog.exportSymbol('proto.common.VehicleFault.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.VehicleFault = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.VehicleFault, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.VehicleFault.displayName = 'proto.common.VehicleFault';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.VehicleFault.prototype.toObject = function(opt_includeInstance) {
  return proto.common.VehicleFault.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.VehicleFault} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.VehicleFault.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.VehicleFault}
 */
proto.common.VehicleFault.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.VehicleFault;
  return proto.common.VehicleFault.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.VehicleFault} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.VehicleFault}
 */
proto.common.VehicleFault.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.VehicleFault.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.VehicleFault.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.VehicleFault} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.VehicleFault.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.VehicleFault.Enum = {
  INVALID: 0,
  TASK_QUEUE_RUNNING: 59,
  LIGHTING_ATTACHMENT_DISCONNECTED: 316,
  PLANNER_UNRELIABLE: 4,
  POSE_POLISHER_UNRELIABLE: 5,
  NIGHT_MODE_HEADING_UNINITIALIZED: 214,
  VIO_UNRELIABLE: 6,
  VIO_DEGRADED: 148,
  GPS_DEGRADED: 381,
  VIO_UNRELIABLE_REDUCED_OBSTACLES: 198,
  VIO_STATIC_INITIALIZATION_FAILED: 216,
  VEHICLE_UNCALIBRATED: 248,
  VOXELS_UNRELIABLE: 7,
  STEREO_UNRELIABLE: 8,
  SUBJECT_UNRELIABLE: 9,
  IGNORE_OBSTACLES: 11,
  IGNORE_SOME_FAULTS: 12,
  LOGGING_SPACE_LOW: 13,
  LOGGING_SPACE_CRITICAL: 54,
  LOGGING_SPACE_LOW_BETA: 196,
  EMMC_SPACE_LOW: 293,
  EMMC_SPACE_LOW_QCU: 428,
  EMMC_SPACE_CRITICAL: 437,
  EMMC_SPACE_CRITICAL_QCU: 438,
  FLIGHT_LOGGER_UNRELIABLE: 14,
  MOTOR_NOT_SPINNING: 15,
  LOST_RC: 16,
  SUBJECT_CAMERA_UNRELIABLE: 17,
  SUBJECT_CAMERA_STOPPED: 358,
  BASE_PROCESSES_STOPPED: 18,
  FLIGHT_PROCESSES_STOPPED: 19,
  NOT_ARMED: 25,
  MOTOR_SYNC_LOST: 26,
  MISSING_IMAGES: 30,
  VEHICLE_STUCK: 31,
  IMU_DRIVER_UNRELIABLE: 32,
  BARO_DRIVER_UNRELIABLE: 33,
  NAV_CAMERAS_UNRELIABLE: 34,
  AMBARELLA_DRIVER_UNRELIABLE: 36,
  SKILLS_UNRELIABLE: 110,
  HAND_TAKEOFF_OBSTRUCTED: 38,
  HAND_TAKEOFF_SHALLOW_OBSTRUCTED: 116,
  HAND_TAKEOFF_VERTICAL_OBSTRUCTED: 118,
  BAD_CPU_FREQUENCY: 39,
  BAD_GPU_FREQUENCY: 40,
  HIGH_WINDS_WARN: 41,
  HIGH_WINDS_SEVERE: 103,
  HIGH_WINDS_LAND: 104,
  DIRTY_CAMERAS_LOW: 23,
  DIRTY_CAMERAS_HIGH: 24,
  COLLISION: 49,
  MOTOR_HOT_WARNING: 51,
  MOTOR_HOT_CRITICAL: 52,
  PLANNER_STATE_UNRELIABLE: 57,
  EXECUTIVE_MANAGER_UNRELIABLE: 58,
  TASK_QUEUE_RUNNING_UNINTERRUPTIBLE: 420,
  TASK_QUEUE_RUNNING_INTERRUPTIBLE: 421,
  AMBARELLA_SD_STILL_MOUNTED: 60,
  BATTERY_TOO_COLD: 63,
  BATTERY_NEEDS_HEATING: 344,
  BATTERY_HEATER_ON: 345,
  BATTERY_TOO_COLD_BUT_IS_HEATING: 348,
  BATTERY_TOO_COLD_LIMP_OK: 349,
  USBC_IN_USE: 65,
  USBC_SECURITY_KEY_IN_USE: 249,
  CPU_TOO_HIGH: 66,
  GPU_TOO_HIGH: 289,
  VEHICLE_TOO_HOT_CRITICAL: 48,
  VEHICLE_TOO_HOT_WARNING: 67,
  AMBARELLA_TOO_HOT: 68,
  GPS_UNRELIABLE: 69,
  GPS_AWAITING_HEADING: 375,
  PRE_FLIGHT_TASKS_REQUIRED: 71,
  GAP_TOO_SMALL: 73,
  HAND_TAKEOFF_DANGEROUS: 74,
  ENVIRONMENT_DANGEROUS: 76,
  HAND_TAKEOFF_DANGEROUS_HIGH_GRADIENT: 91,
  HAND_TAKEOFF_DANGEROUS_DARK: 97,
  MOTOR_FLASH_IN_PROGRESS: 77,
  MOTOR_FLASH_FAILURE: 78,
  TEGRA_STATE_ESTIMATION_FAILED: 79,
  EMERGENCY_LANDING_AIRHAWK_ONLY: 80,
  EMERGENCY_LANDING_TEGRA_POSES: 81,
  VELOCITY_CONTROL_DIVERGED: 82,
  POSITION_CONTROL_DIVERGED: 93,
  YAW_CONTROL_DIVERGED: 105,
  PILOT_NOT_READY_TO_FLY: 83,
  PILOT_WAITING_TO_FLY: 84,
  PILOT_WAITING_TO_BROWSE: 85,
  GPS_UNRELIABLE_HOVER: 86,
  TEGRA_STATE_ESTIMATION_HOVER: 87,
  MOTOR_QUALITY: 90,
  GIMBAL_IMU_UNRELIABLE: 240,
  GIMBAL_UNCALIBRATED: 241,
  GIMBAL_CONTROLLER_UNRELIABLE: 242,
  GIMBAL_ENCODER_UNRELIABLE: 286,
  GIMBAL_ESTIMATOR_FAILED: 243,
  GIMBAL_ESTIMATOR_MISSING_IMU_DATA: 244,
  GIMBAL_ESTIMATOR_ACCEL_NORM_TOO_HIGH: 245,
  GIMBAL_ESTIMATOR_NEEDS_INITIALIZER: 246,
  GIMBAL_ESTIMATOR_INSUFFICIENT_TIME: 247,
  GIMBAL_ESTIMATOR_COMPUTING_MOTOR_ANGLES: 269,
  GIMBAL_UNRELIABLE: 35,
  FALCON_HOOD: 88,
  GIMBAL_OBSTRUCTED: 89,
  GIMBAL_FREAK_OUT: 102,
  GIMBAL_EMBEDDED_ESTIMATOR_NOT_INITIALIZED: 251,
  GIMBAL_EMBEDDED_ESTIMATOR_STALE_REFERENCE: 252,
  GIMBAL_VISION_ESTIMATOR_UNRELIABLE: 94,
  GIMBAL_FLASHLIGHT_TOO_HOT: 372,
  EXTERNAL_TORQUE_NORM_TOO_HIGH: 95,
  ROTOR_SPEED_CONTROL_DIVERGED: 96,
  AUTOEXPOSURE_HAS_NOT_CONVERGED: 99,
  MEDIA_TRANSCODE_POSSIBLE: 100,
  NOT_ACHIEVING_MOVEMENT_COMMAND: 106,
  CONTACT: 107,
  BATTERY_TOO_HOT_WARNING: 108,
  BATTERY_TOO_HOT_CRITICAL: 109,
  BATTERY_NOT_CHARGING_TOO_HOT: 302,
  BATTERY_NOT_CHARGING_TOO_COLD: 303,
  MEMORY_LOW: 112,
  TOO_MANY_PAGE_FAULTS: 113,
  BATTERY_GAS_GAUGE_ERROR: 114,
  BATTERY_GAS_GAUGE_VERSION_MISMATCH: 350,
  VEHICLE_IN_HAND: 115,
  FLIGHT_LOGS_NOT_MOUNTED: 119,
  MOTOR_SPINUP_FAILURE: 120,
  MEDIA_SD_MISSING: 122,
  MEDIA_SD_UNFORMATTED: 124,
  MEDIA_SD_CORRUPTED: 131,
  MEDIA_SD_SLOW: 126,
  MEDIA_SPACE_LOW: 50,
  MEDIA_SPACE_CRITICAL: 55,
  MEDIA_SPACE_FULL: 294,
  INSUFFICIENT_MEDIA_SD_STORAGE_FOR_MISSION: 312,
  MEDIA_SD_NEEDS_TO_AUTODELETE: 224,
  MEDIA_SD_WRONG_ENCRYPTION_KEY: 226,
  MEDIA_SD_MISSING_NOT_DISMISSED: 123,
  MEDIA_SD_UNFORMATTED_NOT_DISMISSED: 125,
  MEDIA_SD_CORRUPTED_NOT_DISMISSED: 132,
  MEDIA_SD_SLOW_NOT_DISMISSED: 127,
  MEDIA_SPACE_LOW_NOT_DISMISSED: 128,
  MEDIA_SD_LOADING_DTED_FILES: 298,
  LOGGING_SD_MISSING: 193,
  LOGGING_SD_WRONG_FILESYSTEM: 151,
  LOGGING_SD_WRONG_FILESYSTEM_IN_USE: 152,
  LOGGING_SD_SLOW: 222,
  LOGGING_SD_NOT_ENCRYPTED: 225,
  LOGGING_SD_LOCKED: 232,
  LOGGING_SD_SECURITY_KEY_REQUIRED: 233,
  LOGGING_SD_SECURITY_ACTIVATION_IN_PROGRESS: 234,
  LOGGING_SD_SECURITY_ACTIVATION_FAILED: 235,
  BATTERY_CRITICAL: 1,
  BATTERY_CRITICAL_FORCE_FAST_DESCENT: 365,
  BATTERY_LOW_LAND: 56,
  BATTERY_LOW: 70,
  BATTERY_LOW_RETURN: 351,
  BATTERY_LOW_RETURN_TO_DOCK: 210,
  BATTERY_LOW_RETURN_TO_LOST_CONNECTION: 206,
  BATTERY_LOW_SAFE_LANDING_UNREACHABLE: 367,
  BATTERY_LOW_DISABLE_AUTONOMOUS: 223,
  BATTERY_VOLTAGE_IMBALANCE: 282,
  BATTERY_VOLTAGE_IMBALANCE_LAND: 287,
  BATTERY_HIGH_CURRENT: 154,
  BATTERY_HIGH_CURRENT_CRITICAL: 155,
  PAGE_ALLOCATION_FAILURE: 133,
  LOST_PHONE_COMM_SHORT: 2,
  LOST_CONNECTION_RETURN: 432,
  LOST_CONNECTION_RTH: 139,
  LOST_CONNECTION_RTD: 209,
  LOST_CONNECTION_LAND: 140,
  LOST_CONNECTION_STOP_TRACK: 208,
  PHONE_GPS_QUALITY_INSUFFICIENT: 134,
  VEHICLE_GPS_QUALITY_INSUFFICIENT: 135,
  VEHICLE_GPS_QUALITY_BLOCKING: 150,
  VEHICLE_GPS_INOPERATIVE: 283,
  BEACON_GPS_QUALITY_BLOCKING: 136,
  BEACON_NEEDS_ASSISTNOW: 137,
  BEACON_GPS_QUALITY_INSUFFICIENT: 141,
  RID_BEACON_GPS_INOPERATIVE: 290,
  USER_CAMERA_MODE_SWITCH: 144,
  UNCANCELLABLE_LANDING: 142,
  PILOT_STILL_WAITING_TO_FLY: 143,
  BEACON_UPDATE_IN_PROGRESS: 147,
  JOYSTICKS_UPDATE_IN_PROGRESS: 149,
  SHOULD_CONNECT_THROUGH_BEACON: 145,
  SHOULD_CONNECT_THROUGH_JOYSTICKS: 146,
  SHOULD_CONNECT_SKYSTICKS: 280,
  COMMANDING_BEYOND_SAFETY_HEIGHT: 153,
  PHONE_BATTERY_LOW: 27,
  PHONE_BATTERY_CRITICAL: 28,
  BEACON_BATTERY_LOW: 157,
  BEACON_BATTERY_CRITICAL: 167,
  JOYSTICKS_BATTERY_LOW: 158,
  SKYSTICKS_BATTERY_LOW: 354,
  PHONE_BATTERY_LOW_NOT_DISMISSED: 156,
  BEACON_BATTERY_LOW_NOT_DISMISSED: 159,
  JOYSTICKS_BATTERY_LOW_NOT_DISMISSED: 160,
  SKYSTICKS_BATTERY_LOW_NOT_DISMISSED: 355,
  FLIGHT_DECK_OUT_OF_SYNC: 161,
  BEACON_UPDATE_READY: 162,
  JOYSTICKS_UPDATE_READY: 163,
  STUCK_SAFETY_HEIGHT: 164,
  BEACON_GPS_QUALITY_INSUFFICIENT_TRACKING_PAUSED: 165,
  BEACON_GPS_QUALITY_INSUFFICIENT_WITH_VISUAL: 166,
  MIND_THE_GAP: 168,
  MIND_THE_STUCK_SAFETY_HEIGHT: 169,
  BATTERY_MESSAGES_UNRELIABLE: 53,
  BATTERY_RESET_REQUIRED: 170,
  TEGRA_REBOOTED_IN_FLIGHT: 171,
  MAYBE_TEGRA_REBOOTED_IN_FLIGHT: 313,
  BACKTRACK_UNAVAILABLE_TEGRA_REBOOTED: 329,
  TOO_DARK_CRITICAL: 29,
  TOO_DARK_HOVER: 61,
  TOO_DARK_WARNING: 62,
  TOO_DARK_FOR_VISION: 172,
  TOO_DARK_TRY_NIGHT_AUTONOMY: 378,
  TAKEOFF_NEEDS_MOTION: 174,
  HAND_WAVE_CAL_NEEDS_STOP_WAVING: 253,
  HAND_WAVE_UNAVAILABLE: 260,
  TAKEOFF_BLOCKED_BY_POSE_POLISHER: 175,
  NOT_STABLE_FOR_TAKEOFF: 21,
  TOO_FAST_FOR_TAKEOFF: 22,
  TOO_FAST_FOR_TAKEOFF_NIGHT: 176,
  NOT_STABLE_FOR_TAKEOFF_NIGHT: 177,
  TOO_FAST_FOR_TAKEOFF_VESSEL: 258,
  NOT_STABLE_FOR_TAKEOFF_VESSEL: 259,
  TEGRA_REQUIRES_EMERGENCY_LAND: 179,
  IS_FIRST_PILOTED_EMERGENCY_LAND: 368,
  IS_PILOTED_EMERGENCY_LAND: 299,
  COMMANDED_EMERGENCY_LAND: 366,
  EMERGENCY_LAND_MOTOR_ARMING_COMMAND: 180,
  EMERGENCY_LAND_LOST_RC_LINK: 181,
  EMERGENCY_LAND_TEGRA_HEALTH_MONITOR_STALE: 182,
  EMERGENCY_LAND_TEGRA_ATTITUDE_COMMAND_STALE: 183,
  EMERGENCY_LAND_RC_EMERGENCY_LAND_COMMAND: 184,
  EMERGENCY_LAND_SYMPC_CONTROLLER_FAILED: 185,
  EMERGENCY_LAND_NO_STATE_ESTIMATE_AVAILABLE: 186,
  JOYSTICKS_INPUT_PRE_TAKEOFF: 187,
  TRIGGER_OR_ROLLER_INPUT_PRE_TAKEOFF: 219,
  JOYSTICKS_INPUT_ERRATIC: 386,
  MAGNETOMETER_NEEDS_CALIBRATION: 188,
  MAGNETOMETER_NEEDS_GPS: 250,
  ATTI_RTX_REQUIRES_MAG_CAL: 423,
  ATTI_RTX_MAG_REQUIRES_LOCATION: 425,
  USER_CAMERA_PHOTO_CAPTURE: 190,
  COMMANDED_PAST_POOR_GPS_HEIGHT: 191,
  COMMANDED_PAST_MAX_HEIGHT: 192,
  COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT: 229,
  COMMANDED_PAST_DENSITY_ALTITUDE: 415,
  COMMANDED_PAST_POOR_GPS_HEIGHT_NOT_DISMISSED: 227,
  COMMANDED_PAST_MAX_HEIGHT_NOT_DISMISSED: 228,
  COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT_NOT_DISMISSED: 230,
  AT_GEOFENCE_EDGE: 276,
  AT_GEOFENCE_EDGE_NOT_DISMISSED: 277,
  RETURNING_TO_GEOFENCE: 278,
  RETURNING_TO_GEOFENCE_NOT_DISMISSED: 279,
  FAR_OUTSIDE_GEOFENCE: 308,
  GEOFENCE_UNRELIABLE: 318,
  VEHICLE_NEEDS_ASSISTNOW: 194,
  SKILLS_LATENCY_HIGH: 195,
  DOCK_NOT_READY_FOR_TAKEOFF: 197,
  WEBRTC_OFFLINE: 199,
  DOCK_DISCONNECTED: 200,
  DOCK_DETECTED: 201,
  DOCK_CRADLE_EXTENDED_PRE_TAKEOFF: 305,
  VEHICLE_ON_SOME_TAKEOFF_SURFACE: 306,
  DOCK_IN_INSPECTION_MODE: 309,
  DIGITAL_ZOOM_UNAVAILABLE: 202,
  SUPERZOOM_UNAVAILABLE_SUBJECT_TRACKING: 203,
  MOTOR_CURRENT_WARNING: 204,
  MOTOR_CURRENT_CRITICAL: 205,
  MOTOR_IDLE_OVERCURRENT_CRITICAL: 429,
  MOTOR_IDLE_OVERCURRENT_WARNING: 442,
  AIRHAWK_ATTITUDE_CONTROL_REQ_RESTART: 207,
  DEFAULT_WIFI_AP_CREDENTIALS: 211,
  IMPERFECT_CONTACT_WITH_DOCK: 212,
  LOST_CONNECTION_DROP_VIDEO: 217,
  ARM_IMPROPER_POSITION_VISION: 218,
  ARM_IMPROPER_POSITION_CRITICAL: 301,
  ARM_IMPROPER_POSITION_VISION_BLOCK_TAKEOFF: 304,
  VIO_CALIBRATIONS_NOT_CONVERGED: 220,
  AUTO_GPS_INITIALIZATION_FAILED: 221,
  BEACON_BARO_OFFSET_NEEDED: 231,
  DOCK_VEHICLE_PAIRING_MISMATCH: 261,
  RID_KEY_REQUIRED: 255,
  MOTOR_VIBE_CHECK_PENDING: 256,
  EXCESSIVE_MOTOR_VIBE: 257,
  MANUAL_MODE_RECOMMENDED: 262,
  RID_CHECK_STARTED: 263,
  WIFI_NO_CONFIGURED_SSIDS_FOUND: 264,
  WIFI_AUTHENTICATION_ERROR: 265,
  WIFI_CONNECTION_TIMEOUT: 266,
  CONTROLLER_GPS_QUALITY_INSUFFICIENT: 267,
  RID_CONTROLLER_GPS_INOPERATIVE: 291,
  RID_RPIC_GPS_INOPERATIVE: 307,
  IS_BASESTATION_LANDING_SURFACE: 281,
  LANDED_OUTSIDE_DOCK: 296,
  AIRHAWK_RT_LOSS: 268,
  LIVEKIT_NO_CONFIG: 270,
  LIVEKIT_CONFIG_MISMATCH: 271,
  LIVEKIT_NOT_ENABLED: 272,
  LIVEKIT_CONNECTED: 273,
  LIVEKIT_CONNECTION_FAILED: 274,
  VEHICLE_GPS_QUALITY_BLOCKING_TWILIGHT: 285,
  FLIGHT_TERMINATED: 284,
  NAV_CAMERA_LOGGING_DEGRADED: 288,
  SKILLS_TASKS_PENDING: 292,
  DOCK_COMMS_UNRELIABLE: 295,
  CLOUD_ENV_OUT_OF_SYNC: 376,
  TRACK_TRAJECTORY_STUCK_ON_OBSTACLES: 297,
  SKILLS_TASK_NEEDS_DECRYPTION: 363,
  USER_CAMERA_SLOW_MODE_SWITCH: 300,
  NVU_DISCONNECTED: 310,
  QCU_DISCONNECTED: 311,
  BARO_MEASUREMENT_UNRELIABLE: 314,
  WAYPOINTS_UNRELIABLE: 315,
  WAYPOINT_TASK_STUCK_ON_OBSTACLE: 352,
  WAYPOINT_TASK_STUCK_ON_GEOFENCE: 353,
  DOCK_CLIENT_UNRELIABLE: 317,
  SPURIOUS_VOXELS_DAY: 319,
  SPURIOUS_VOXELS_NIGHT: 356,
  SPURIOUS_VOXELS_DEBUG: 361,
  BAD_GPU_FREQUENCY_QCU: 320,
  CPU_TOO_HIGH_QCU: 321,
  GPU_TOO_HIGH_QCU: 322,
  BASE_PROCESSES_STOPPED_QCU: 323,
  FLIGHT_PROCESSES_STOPPED_QCU: 324,
  MEMORY_LOW_QCU: 325,
  TOO_MANY_PAGE_FAULTS_QCU: 326,
  FLIGHT_DECK_OUT_OF_SYNC_QCU: 327,
  TOF_UNRELIABLE: 328,
  TOF_UNRELIABLE_FOR_FAST_DESCENT_LANDING: 332,
  INFRARED_CAMERA_UNRELIABLE: 330,
  TOO_FAST_NIGHT_AUTONOMY: 331,
  VEHICLE_RADIO_UPDATE_IN_PROGRESS: 333,
  ATTACHMENT_CHANGE_DETECTED: 334,
  MISMATCHED_NIGHTSENSE_ATTACHMENT_DETECTED: 400,
  MIXED_NIGHTSENSE_ATTACHMENTS_DETECTED: 401,
  RTK_PPK_ATTACHMENT_CONNECTED: 402,
  RTK_PPK_ATTACHMENT_CONNECTED_NOT_DISMISSED: 413,
  NIGHTSENSE_ATTACHMENT_DEGRADED: 403,
  NIGHTSENSE_ATTACHMENT_DEGRADED_NOT_DISMISSED: 404,
  NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE: 405,
  NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE_NOT_DISMISSED: 406,
  NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED: 407,
  NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED_NOT_DISMISSED: 408,
  NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING: 409,
  NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING_NOT_DISMISSED: 410,
  FLASH_DRIVE_REQUIRED: 335,
  HOUSEKEEPER_MCU_VERSION_MISMATCH: 336,
  AIRHAWK_MCU_VERSION_MISMATCH: 337,
  GIMBAL_MCU_VERSION_MISMATCH: 338,
  SOC_FLASHPACK_MISMATCH: 339,
  SOC_CONFIG_MISMATCH: 340,
  SOC_SERIAL_NUMBER_MISMATCH: 341,
  OTA_IN_PROGRESS: 342,
  FIRMWARE_MISCONFIGURED: 343,
  AIRHAWK_SERIAL_CONNECTION_LOST: 346,
  AIRHAWK_SERIAL_CONNECTION_LOST_QCU: 347,
  OBSTACLE_MARGINS_CANNOT_EXPAND: 357,
  MOTOR_TAKEOFF_CURRENT_CRITICAL: 359,
  RELATIVE_HEADING_ESTIMATE_UNAVAILABLE: 360,
  IMU_SATURATION_ON_TAKEOFF: 362,
  VEHICLE_GPS_RESET: 364,
  PRE_DOCK_TAKEOFF_VEHICLE_GPS_RESET_UNSUCCESSFUL: 369,
  GIMBAL_FLASH_FAILED: 370,
  INFRARED_CAMERA_POOR_ACCURACY: 371,
  GIMBAL_TYPE_INCOMPATIBLE: 373,
  FRONT_UNRELIABLE: 374,
  MOTOR_OSCILLATION_DETECTED: 377,
  ATTACHMENT_OLD_SIDE_ATTACHMENT_HARDWARE: 388,
  ATTACHMENT_WRONG_CABLE_ORIENTATION: 389,
  ATTACHMENT_ENUMERATION_IN_PROGRESS: 439,
  ATTACHMENT_IN_INVALID_PORT_TOP: 390,
  ATTACHMENT_IN_INVALID_PORT_BOTTOM: 391,
  ATTACHMENT_IN_INVALID_PORT_LEFT: 392,
  ATTACHMENT_IN_INVALID_PORT_RIGHT: 393,
  ATTACHMENT_INSPECT_OR_REPLACE_TOP: 394,
  ATTACHMENT_INSPECT_OR_REPLACE_BOTTOM: 395,
  ATTACHMENT_INSPECT_OR_REPLACE_LEFT: 396,
  ATTACHMENT_INSPECT_OR_REPLACE_RIGHT: 397,
  ATTACHMENT_NIGHTSENSE_VERSION_MISMATCH: 379,
  ATTACHMENT_NIGHTSENSE_UPDATE_FAILED: 384,
  ATTACHMENT_NIGHTSENSE_UPDATE_FAILED_NOT_DISMISSED: 385,
  ATTACHMENT_SPOTLIGHT_BRIGHTNESS_THROTTLING: 398,
  ATTACHMENT_SPOTLIGHT_THERMAL_SHUTDOWN: 416,
  ATTACHMENT_SPOTLIGHT_ABOVE_TOUCH_TEMP: 399,
  ATTACHMENT_PARACHUTE_DEPLOYED: 441,
  ATTACHMENT_RTK_COM_FAILURE: 422,
  ATTACHMENT_SPEAKERMIC_LOW_VOLUME: 440,
  ATTACHMENT_SPOTLIGHT_TWO_SPOTLIGHTS_UNSUPPORTED: 417,
  ATTACHMENT_INVALID_COMBINATION: 427,
  RID_TX_FAILURE: 380,
  PINE_HIGH_INTERFERENCE: 435,
  PINE_SU_PKTS_IN_OFDMA: 436,
  NCPG_SAVE_IN_PROGRESS: 382,
  MISSION_CONDUCTOR_DISABLE_PILOTSHIP: 383,
  GROUND_AVOIDANCE_ACTIVE: 387,
  GPS_SPOOFED: 411,
  EVENT_LOOP_FD_OVERFLOW: 412,
  MICROHARD_DCS_JAMMING_FAULT: 414,
  BATTERY_NOT_ARMED: 418,
  VEHICLE_TOO_HOT_CRITICAL_FINAL: 419,
  IMU_NEAR_SATURATION: 424,
  ATTI_TAKEOFF_AWAITING_USER_INPUT: 426,
  VEHICLE_CLOCK_STALE: 430,
  VEHICLE_CLOCK_STALE_NOT_DISMISSED: 431,
  FLIR_FIRMWARE_UPDATE_IN_PROGRESS: 433,
  FLIR_FIRMWARE_VERSION_MISMATCH: 434,
  MAX_NUM_FAULTS: 500
};

goog.object.extend(exports, proto.common);
