import { Moment } from "moment";

import flight_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/flight_pb";

import { Overwrite } from "@skydio/common_util/src";
import { PaginationQueryState, StringifyPagination } from "../pagination/types";

export type APIFlight = flight_pb.Flight.AsObject;

export interface Flight extends Omit<APIFlight, "takeoff" | "dataFilesList"> {
  takeoff: Moment | null;
  landing: Moment | null;
  dataFiles: string[];
}

export interface FlightsMap {
  [flightId: string]: Flight;
}

export type AggregateFlights = Omit<flight_pb.AggregateFlights.AsObject, "id">;
export interface AggregateFlightsMap {
  [id: string]: AggregateFlights;
}

export const FlightStatsIdType = flight_pb.FlightStats.IdTypeEnum;
export type FlightStatsIdType = flight_pb.FlightStats.IdTypeEnum;
export type FlightStatsIdTypeKey = keyof typeof FlightStatsIdType;
export type FlightStatsDuration = "day" | "week" | "month" | "year";
export type FlightOrderBy = "duration" | "takeoff";

export type FlightStatsState = Partial<Record<FlightStatsIdTypeKey, AggregateFlightsMap>>;

export interface FlightsQueryState extends PaginationQueryState {
  vehicleId: string;
  userEmail: string;
  takeoffPhoneId: string;
  deployCommit: string;
  takeoffSince?: Moment;
  takeoffBefore?: Moment;
  releaseKey: string;
  handle: string;
  organizationId: string;
  userGroup: string[];
  userNotGroup: string[];
  limit?: number;
  hasDataFiles?: boolean;
  orderAscending?: boolean;
  orderBy?: FlightOrderBy;
  includeInvalidFlights?: boolean;
}

export interface FlightsRequest
  extends Overwrite<
    Partial<FlightsQueryState>,
    {
      takeoffSince?: string;
      takeoffBefore?: string;
      hasDataFiles?: string;
      orderAscending?: string;
      includeInvalidFlights?: "1" | "0";
    }
  > {}

export interface FlightStatsRequest extends FlightsRequest {
  aggregateType: FlightStatsIdType;
}

export type FlightsQueryParams = Overwrite<
  StringifyPagination<FlightsRequest>,
  {
    userGroup?: string | string[];
    userNotGroup?: string | string[];
    limit?: string;
  }
>;

export interface FlightRequest {
  path: { flightId: string };
}
