import { forwardRef } from "react";

import { cn } from "../../utils/cn";

import type { HTMLAttributes } from "react";

/**
 * A placeholder to show a loading state in the expected shape of a component.
 */
export const Skeleton = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={cn(
          "relative",
          "isolate",
          "overflow-hidden",
          "rounded-md",
          "bg-gray-100/20",
          "dark:bg-gray-850",
          "dark:before:via-gray-400/20",
          "before:content-['']",
          "before:absolute",
          "before:inset-0",
          "before:-translate-x-full",
          "before:animate-[shimmer_2s_infinite]",
          "before:bg-gradient-to-r",
          "before:from-transparent",
          "before:to-transparent",
          "before:via-gray-100",
          "before:border-gray-100",
          className
        )}
        {...props}
        ref={ref}
      />
    );
  }
);
Skeleton.displayName = "Skeleton";
