import cloud_error_code_pb from "@skydio/pbtypes/pbtypes/gen/cloud_api/cloud_error_code_pb";

import * as alerts from "./alerts";
import * as alerts_v2 from "./alerts_v2";
import * as auth from "./auth";
import * as cloudConfig from "./cloud_config";
import * as dataFiles from "./data_files";
import * as featureFlags from "./feature_flags";
import * as fileKinds from "./file_kinds";
import * as flights from "./flights";
import * as groups from "./groups";
import * as organizations from "./organizations";
import * as pagination from "./pagination";
import * as releases from "./releases";
import * as devReleases from "./dev_releases";
import * as controllerReleases from "./controller_releases";
import * as simulators from "./simulators";
import * as stats from "./stats";
import * as tokens from "./tokens";
import * as uconUploadConfig from "./ucon_upload_config";
import * as users from "./users";
import * as vehicles from "./vehicles";
import * as wifiSettings from "./wifi_settings";

export const modules = {
  alerts,
  alerts_v2,
  auth,
  cloudConfig,
  dataFiles,
  featureFlags,
  fileKinds,
  flights,
  groups,
  organizations,
  pagination,
  releases,
  devReleases,
  controllerReleases,
  simulators,
  stats,
  tokens,
  uconUploadConfig,
  users,
  vehicles,
  wifiSettings,
};

export { default as endpoints } from "./endpoints";
export { sendRequest, sendRequestPreserveKeys } from "./requests-browser";
export const ErrorCode = cloud_error_code_pb.CloudErrorCode.Enum;
export type ErrorCode = cloud_error_code_pb.CloudErrorCode.Enum;

export * from "./analytics/types";
export * from "./auth/types";
export * from "./data_files/types";
export * from "./feature_flags/types";
export * from "./flights/types";
export * from "./groups/types";
export * from "./organizations/types";
export * from "./pagination/types";
export * from "./releases/types";
export * from "./dev_releases/types";
export * from "./controller_releases/types";
export * from "./simulators/types";
export * from "./stats/types";
export * from "./tokens/types";
export * from "./ucon_upload_config/types";
export * from "./users/types";
export * from "./vehicles/types";
export * from "./webrtc/types";
export * from "./wifi_settings/types";
