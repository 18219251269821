import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";
import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";
import { fetchOrganization } from "../organizations/asyncThunks";
import { fetchUsers, fetchUser, updateUser } from "./asyncThunks";
import {
  getUsersQueryTimeout,
  isUsersRequestActive,
  isUserRequestActive,
  getUserModifications,
} from "./selectors";
import { userActions, UsersState } from "./slice";
import { prepareUsersRequest } from "./utils";

import { ThunkCreator, AsyncThunkCreator } from "@skydio/redux_util/src";
import { UsersQueryState } from "./types";

const { clearUsersQueryTimeout, setUsersQueryTimeout, updateUserField } = userActions;

export const updateUsersQuery: ThunkCreator<UsersState, [UsersQueryState, boolean?]> =
  (
    fields,
    // this immediate arg allows us to specify whether to fetch right away or delay/debounce fetching
    // (which is the default)
    immediate
  ) =>
  (dispatch, getState) => {
    const newArgs = prepareUsersRequest(fields);

    if (immediate) {
      dispatch(fetchUsers(newArgs));
    } else {
      const state = getState();

      clearTimeout(getUsersQueryTimeout(state)!);
      dispatch(clearUsersQueryTimeout());

      const timeout = window?.setTimeout(() => dispatch(fetchUsers(newArgs)), QUERY_CHANGE_DELAY);
      dispatch(setUsersQueryTimeout(timeout));
    }

    dispatch(setSearchParams(newArgs));
  };

export const fetchUsersIfAble: AsyncThunkCreator<UsersState, [UsersQueryState]> =
  fields => async (dispatch, getState) => {
    const state = getState();
    if (!isUsersRequestActive(state)) {
      await dispatch(fetchUsers(prepareUsersRequest(fields)));
    }
  };

export const fetchUserIfAble: AsyncThunkCreator<UsersState, [string]> =
  email => async (dispatch, getState) => {
    if (!isUserRequestActive(getState(), email)) {
      await dispatch(fetchUser(email));
    }
  };

export const updateUserIfAble: AsyncThunkCreator<UsersState, [string]> =
  email => async (dispatch, getState) => {
    const state = getState();
    if (!isUserRequestActive(state, email)) {
      const update = getUserModifications(state, email);
      dispatch(updateUser({ email, update }));
    }
  };

export const updateUserOrg: AsyncThunkCreator<
  UsersState,
  [string, string, enums_pb.OrgPermission.PermEnum, boolean?]
> =
  (email, orgUuid, orgPermission, removeFromOrg = false) =>
  async (dispatch, getState) => {
    if (!isUserRequestActive(getState(), email)) {
      try {
        await dispatch(fetchUser(email));
        dispatch(
          updateUserField({
            email: email,
            name: "organizationId",
            value: removeFromOrg ? "" : orgUuid,
          })
        );
        dispatch(
          updateUserField({
            email: email,
            name: "organizationPermission",
            value: orgPermission,
          })
        );
        await dispatch(updateUserIfAble(email));
        if (orgUuid) {
          dispatch(
            fetchOrganization({
              uuid: orgUuid,
              includeUsers: true,
              includeGroupLinks: true,
              includeSkillsetLinks: true,
              includeSwitches: true,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
