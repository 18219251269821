import {
  parseSearchState,
  makeQuerySerializable,
  convertToNumber,
  convertToArray,
  convertToBoolean,
  convertFromBoolean,
} from "@skydio/uri_util/src/query";

import { UsersQueryState, UsersQueryParams, UsersRequest } from "./types";

export const usersQueryInitialState: UsersQueryState = {
  email: "",
  uuid: "",
  group: [],
  notGroup: [],
  firstName: "",
  lastName: "",
  emailStatus: "",
  organizationId: "",
  organizationPermission: "",
  includeOrganizationFlightCount: false,
};

export const parseUsersQueryState = (searchString: string): UsersQueryState =>
  parseSearchState(searchString, usersQueryInitialState, (params: UsersQueryParams) => ({
    ...params,
    group: convertToArray(params.group),
    notGroup: convertToArray(params.notGroup),
    limit: convertToNumber(params.limit),
    pageNumber: convertToNumber(params.pageNumber),
    perPage: convertToNumber(params.perPage),
    includeOrganizationFlightCount: convertToBoolean(params.includeOrganizationFlightCount),
  }));

export const prepareUsersRequest = (state: UsersQueryState): UsersRequest =>
  // @ts-ignore TS2322
  makeQuerySerializable(state, usersQueryInitialState, state => ({
    ...state,
    includeOrganizationFlightCount: convertFromBoolean(state.includeOrganizationFlightCount),
  }));
