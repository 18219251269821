import { INITIAL_REQUEST_STATE } from "@skydio/redux_util/src";

import { TokensState } from "./slice";
import { NEW_TOKEN_PLACEHOLDER_ID } from "./types";

export const getTokensRequest = ({ requests }: TokensState) => requests.tokens;

export const getTokensQueryTimeout = ({ state }: TokensState) => state.dispatchTimeout;

export const isTokensRequestActive = (state: TokensState) => getTokensRequest(state).active;

export const getTokenRequest = (state: TokensState, uuid: string) =>
  state.requests.token[uuid] ?? INITIAL_REQUEST_STATE;

export const isTokenRequestActive = (state: TokensState, uuid: string) =>
  getTokenRequest(state, uuid)?.active;

export const getNewTokenRequest = (state: TokensState) =>
  getTokenRequest(state, NEW_TOKEN_PLACEHOLDER_ID);

export const getAllTokens = ({ state }: TokensState) => state.tokens;

export const getToken = (state: TokensState, uuid: string) =>
  getAllTokens(state).find(t => t.uuid === uuid);

export const getTokenModifications = (state: TokensState, uuid: string) =>
  getToken(state, uuid)?.modified;

export const getAddModalOpen = ({ state }: TokensState) => state.addModalOpen;
