import { forwardRef } from "react";
import { tv } from "tailwind-variants";

import { textVariants } from "./Text";

import type { HTMLAttributes } from "react";
import type { VariantProps } from "tailwind-variants";

const paragraphVariants = tv({
  extend: textVariants,
  base: "mb-0",
});

export interface ParagraphProps
  extends HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof paragraphVariants> {}

export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ className, size, bold, emphasis, ...props }, ref) => {
    return (
      <p ref={ref} className={paragraphVariants({ className, size, bold, emphasis })} {...props} />
    );
  }
);
Paragraph.displayName = "Paragraph";
