// source: pbtypes/tools/cloud_api/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var pbtypes_tools_cloud_api_enums_pb = require('../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
goog.exportSymbol('proto.cloud_api.DeleteUserRequest', null, global);
goog.exportSymbol('proto.cloud_api.DeleteUserResponse', null, global);
goog.exportSymbol('proto.cloud_api.Group', null, global);
goog.exportSymbol('proto.cloud_api.Groups', null, global);
goog.exportSymbol('proto.cloud_api.UpdateGroupRequest', null, global);
goog.exportSymbol('proto.cloud_api.UpdateUserRequest', null, global);
goog.exportSymbol('proto.cloud_api.User', null, global);
goog.exportSymbol('proto.cloud_api.UserReleaseOverride', null, global);
goog.exportSymbol('proto.cloud_api.UserResourceGroupLink', null, global);
goog.exportSymbol('proto.cloud_api.Users', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.User.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.User.displayName = 'proto.cloud_api.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UserResourceGroupLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UserResourceGroupLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UserResourceGroupLink.displayName = 'proto.cloud_api.UserResourceGroupLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Group.displayName = 'proto.cloud_api.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateGroupRequest.displayName = 'proto.cloud_api.UpdateGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Groups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Groups.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Groups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Groups.displayName = 'proto.cloud_api.Groups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Users = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Users.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Users, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Users.displayName = 'proto.cloud_api.Users';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateUserRequest.displayName = 'proto.cloud_api.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UserReleaseOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UserReleaseOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UserReleaseOverride.displayName = 'proto.cloud_api.UserReleaseOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DeleteUserRequest.displayName = 'proto.cloud_api.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DeleteUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DeleteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DeleteUserResponse.displayName = 'proto.cloud_api.DeleteUserResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.User.repeatedFields_ = [6,10,13,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.User.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.User.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
email: jspb.Message.getFieldWithDefault(msg, 4, ""),
active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
emailStatus: jspb.Message.getFieldWithDefault(msg, 14, ""),
groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.cloud_api.Group.toObject, includeInstance),
organizationName: jspb.Message.getFieldWithDefault(msg, 7, ""),
organizationId: jspb.Message.getFieldWithDefault(msg, 8, ""),
organizationPermission: jspb.Message.getFieldWithDefault(msg, 9, 0),
organizationGroupsList: jspb.Message.toObjectList(msg.getOrganizationGroupsList(),
    proto.cloud_api.Group.toObject, includeInstance),
organizationNotes: jspb.Message.getFieldWithDefault(msg, 11, ""),
organizationFlightCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
releaseOverridesList: jspb.Message.toObjectList(msg.getReleaseOverridesList(),
    proto.cloud_api.UserReleaseOverride.toObject, includeInstance),
resourceGroupLinksList: jspb.Message.toObjectList(msg.getResourceGroupLinksList(),
    proto.cloud_api.UserResourceGroupLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.User}
 */
proto.cloud_api.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.User;
  return proto.cloud_api.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.User}
 */
proto.cloud_api.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailStatus(value);
      break;
    case 6:
      var value = new proto.cloud_api.Group;
      reader.readMessage(value,proto.cloud_api.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 9:
      var value = /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (reader.readEnum());
      msg.setOrganizationPermission(value);
      break;
    case 10:
      var value = new proto.cloud_api.Group;
      reader.readMessage(value,proto.cloud_api.Group.deserializeBinaryFromReader);
      msg.addOrganizationGroups(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationNotes(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganizationFlightCount(value);
      break;
    case 13:
      var value = new proto.cloud_api.UserReleaseOverride;
      reader.readMessage(value,proto.cloud_api.UserReleaseOverride.deserializeBinaryFromReader);
      msg.addReleaseOverrides(value);
      break;
    case 15:
      var value = new proto.cloud_api.UserResourceGroupLink;
      reader.readMessage(value,proto.cloud_api.UserResourceGroupLink.deserializeBinaryFromReader);
      msg.addResourceGroupLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getEmailStatus();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.cloud_api.Group.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOrganizationPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getOrganizationGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.cloud_api.Group.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationNotes();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOrganizationFlightCount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getReleaseOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.cloud_api.UserReleaseOverride.serializeBinaryToWriter
    );
  }
  f = message.getResourceGroupLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.cloud_api.UserResourceGroupLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.User.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.cloud_api.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.cloud_api.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.cloud_api.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool active = 5;
 * @return {boolean}
 */
proto.cloud_api.User.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string email_status = 14;
 * @return {string}
 */
proto.cloud_api.User.prototype.getEmailStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setEmailStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated Group groups = 6;
 * @return {!Array<!proto.cloud_api.Group>}
 */
proto.cloud_api.User.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.cloud_api.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.Group, 6));
};


/**
 * @param {!Array<!proto.cloud_api.Group>} value
 * @return {!proto.cloud_api.User} returns this
*/
proto.cloud_api.User.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cloud_api.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Group}
 */
proto.cloud_api.User.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cloud_api.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional string organization_name = 7;
 * @return {string}
 */
proto.cloud_api.User.prototype.getOrganizationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setOrganizationName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string organization_id = 8;
 * @return {string}
 */
proto.cloud_api.User.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional OrgPermission.PermEnum organization_permission = 9;
 * @return {!proto.cloud_api.OrgPermission.PermEnum}
 */
proto.cloud_api.User.prototype.getOrganizationPermission = function() {
  return /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.cloud_api.OrgPermission.PermEnum} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setOrganizationPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated Group organization_groups = 10;
 * @return {!Array<!proto.cloud_api.Group>}
 */
proto.cloud_api.User.prototype.getOrganizationGroupsList = function() {
  return /** @type{!Array<!proto.cloud_api.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.Group, 10));
};


/**
 * @param {!Array<!proto.cloud_api.Group>} value
 * @return {!proto.cloud_api.User} returns this
*/
proto.cloud_api.User.prototype.setOrganizationGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.cloud_api.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Group}
 */
proto.cloud_api.User.prototype.addOrganizationGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.cloud_api.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.clearOrganizationGroupsList = function() {
  return this.setOrganizationGroupsList([]);
};


/**
 * optional string organization_notes = 11;
 * @return {string}
 */
proto.cloud_api.User.prototype.getOrganizationNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setOrganizationNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 organization_flight_count = 12;
 * @return {number}
 */
proto.cloud_api.User.prototype.getOrganizationFlightCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.setOrganizationFlightCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated UserReleaseOverride release_overrides = 13;
 * @return {!Array<!proto.cloud_api.UserReleaseOverride>}
 */
proto.cloud_api.User.prototype.getReleaseOverridesList = function() {
  return /** @type{!Array<!proto.cloud_api.UserReleaseOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.UserReleaseOverride, 13));
};


/**
 * @param {!Array<!proto.cloud_api.UserReleaseOverride>} value
 * @return {!proto.cloud_api.User} returns this
*/
proto.cloud_api.User.prototype.setReleaseOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.cloud_api.UserReleaseOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.UserReleaseOverride}
 */
proto.cloud_api.User.prototype.addReleaseOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.cloud_api.UserReleaseOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.clearReleaseOverridesList = function() {
  return this.setReleaseOverridesList([]);
};


/**
 * repeated UserResourceGroupLink resource_group_links = 15;
 * @return {!Array<!proto.cloud_api.UserResourceGroupLink>}
 */
proto.cloud_api.User.prototype.getResourceGroupLinksList = function() {
  return /** @type{!Array<!proto.cloud_api.UserResourceGroupLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.UserResourceGroupLink, 15));
};


/**
 * @param {!Array<!proto.cloud_api.UserResourceGroupLink>} value
 * @return {!proto.cloud_api.User} returns this
*/
proto.cloud_api.User.prototype.setResourceGroupLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.cloud_api.UserResourceGroupLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.UserResourceGroupLink}
 */
proto.cloud_api.User.prototype.addResourceGroupLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.cloud_api.UserResourceGroupLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.User} returns this
 */
proto.cloud_api.User.prototype.clearResourceGroupLinksList = function() {
  return this.setResourceGroupLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UserResourceGroupLink.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UserResourceGroupLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UserResourceGroupLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UserResourceGroupLink.toObject = function(includeInstance, msg) {
  var f, obj = {
resourceGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
resourceGroupPermission: jspb.Message.getFieldWithDefault(msg, 2, 0),
isOrganizationDefault: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UserResourceGroupLink}
 */
proto.cloud_api.UserResourceGroupLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UserResourceGroupLink;
  return proto.cloud_api.UserResourceGroupLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UserResourceGroupLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UserResourceGroupLink}
 */
proto.cloud_api.UserResourceGroupLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceGroupId(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (reader.readEnum());
      msg.setResourceGroupPermission(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOrganizationDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UserResourceGroupLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UserResourceGroupLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UserResourceGroupLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UserResourceGroupLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceGroupPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIsOrganizationDefault();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string resource_group_id = 1;
 * @return {string}
 */
proto.cloud_api.UserResourceGroupLink.prototype.getResourceGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.UserResourceGroupLink} returns this
 */
proto.cloud_api.UserResourceGroupLink.prototype.setResourceGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgPermission.PermEnum resource_group_permission = 2;
 * @return {!proto.cloud_api.OrgPermission.PermEnum}
 */
proto.cloud_api.UserResourceGroupLink.prototype.getResourceGroupPermission = function() {
  return /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.OrgPermission.PermEnum} value
 * @return {!proto.cloud_api.UserResourceGroupLink} returns this
 */
proto.cloud_api.UserResourceGroupLink.prototype.setResourceGroupPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool is_organization_default = 3;
 * @return {boolean}
 */
proto.cloud_api.UserResourceGroupLink.prototype.getIsOrganizationDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.UserResourceGroupLink} returns this
 */
proto.cloud_api.UserResourceGroupLink.prototype.setIsOrganizationDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Group}
 */
proto.cloud_api.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Group;
  return proto.cloud_api.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Group}
 */
proto.cloud_api.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cloud_api.Group.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Group} returns this
 */
proto.cloud_api.Group.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
group: (f = msg.getGroup()) && proto.cloud_api.Group.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateGroupRequest}
 */
proto.cloud_api.UpdateGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateGroupRequest;
  return proto.cloud_api.UpdateGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateGroupRequest}
 */
proto.cloud_api.UpdateGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Group;
      reader.readMessage(value,proto.cloud_api.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.Group.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Group group = 1;
 * @return {?proto.cloud_api.Group}
 */
proto.cloud_api.UpdateGroupRequest.prototype.getGroup = function() {
  return /** @type{?proto.cloud_api.Group} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.Group, 1));
};


/**
 * @param {?proto.cloud_api.Group|undefined} value
 * @return {!proto.cloud_api.UpdateGroupRequest} returns this
*/
proto.cloud_api.UpdateGroupRequest.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateGroupRequest} returns this
 */
proto.cloud_api.UpdateGroupRequest.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateGroupRequest.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateGroupRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateGroupRequest} returns this
*/
proto.cloud_api.UpdateGroupRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateGroupRequest} returns this
 */
proto.cloud_api.UpdateGroupRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateGroupRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Groups.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Groups.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Groups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Groups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Groups.toObject = function(includeInstance, msg) {
  var f, obj = {
groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.cloud_api.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Groups}
 */
proto.cloud_api.Groups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Groups;
  return proto.cloud_api.Groups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Groups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Groups}
 */
proto.cloud_api.Groups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Group;
      reader.readMessage(value,proto.cloud_api.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Groups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Groups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Groups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Groups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.Group.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Group groups = 1;
 * @return {!Array<!proto.cloud_api.Group>}
 */
proto.cloud_api.Groups.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.cloud_api.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.Group, 1));
};


/**
 * @param {!Array<!proto.cloud_api.Group>} value
 * @return {!proto.cloud_api.Groups} returns this
*/
proto.cloud_api.Groups.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Group}
 */
proto.cloud_api.Groups.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Groups} returns this
 */
proto.cloud_api.Groups.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Users.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Users.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Users.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Users} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Users.toObject = function(includeInstance, msg) {
  var f, obj = {
usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.cloud_api.User.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Users}
 */
proto.cloud_api.Users.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Users;
  return proto.cloud_api.Users.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Users} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Users}
 */
proto.cloud_api.Users.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.User;
      reader.readMessage(value,proto.cloud_api.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Users.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Users.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Users} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Users.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.User.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.cloud_api.User>}
 */
proto.cloud_api.Users.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.cloud_api.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.User, 1));
};


/**
 * @param {!Array<!proto.cloud_api.User>} value
 * @return {!proto.cloud_api.Users} returns this
*/
proto.cloud_api.Users.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.User}
 */
proto.cloud_api.Users.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Users} returns this
 */
proto.cloud_api.Users.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.Users.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.Users} returns this
*/
proto.cloud_api.Users.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Users} returns this
 */
proto.cloud_api.Users.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Users.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
user: (f = msg.getUser()) && proto.cloud_api.User.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateUserRequest}
 */
proto.cloud_api.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateUserRequest;
  return proto.cloud_api.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateUserRequest}
 */
proto.cloud_api.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.User;
      reader.readMessage(value,proto.cloud_api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.User.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.cloud_api.User}
 */
proto.cloud_api.UpdateUserRequest.prototype.getUser = function() {
  return /** @type{?proto.cloud_api.User} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.User, 1));
};


/**
 * @param {?proto.cloud_api.User|undefined} value
 * @return {!proto.cloud_api.UpdateUserRequest} returns this
*/
proto.cloud_api.UpdateUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateUserRequest} returns this
 */
proto.cloud_api.UpdateUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateUserRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateUserRequest} returns this
*/
proto.cloud_api.UpdateUserRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateUserRequest} returns this
 */
proto.cloud_api.UpdateUserRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateUserRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UserReleaseOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UserReleaseOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UserReleaseOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UserReleaseOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
email: jspb.Message.getFieldWithDefault(msg, 1, ""),
releaseKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
validUntil: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UserReleaseOverride}
 */
proto.cloud_api.UserReleaseOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UserReleaseOverride;
  return proto.cloud_api.UserReleaseOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UserReleaseOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UserReleaseOverride}
 */
proto.cloud_api.UserReleaseOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UserReleaseOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UserReleaseOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UserReleaseOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UserReleaseOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValidUntil();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.cloud_api.UserReleaseOverride.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.UserReleaseOverride} returns this
 */
proto.cloud_api.UserReleaseOverride.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string release_key = 2;
 * @return {string}
 */
proto.cloud_api.UserReleaseOverride.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.UserReleaseOverride} returns this
 */
proto.cloud_api.UserReleaseOverride.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 valid_until = 3;
 * @return {number}
 */
proto.cloud_api.UserReleaseOverride.prototype.getValidUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.UserReleaseOverride} returns this
 */
proto.cloud_api.UserReleaseOverride.prototype.setValidUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DeleteUserRequest}
 */
proto.cloud_api.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DeleteUserRequest;
  return proto.cloud_api.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DeleteUserRequest}
 */
proto.cloud_api.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.cloud_api.DeleteUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeleteUserRequest} returns this
 */
proto.cloud_api.DeleteUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DeleteUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DeleteUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DeleteUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeleteUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
deleted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
customerErrorMsg: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DeleteUserResponse}
 */
proto.cloud_api.DeleteUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DeleteUserResponse;
  return proto.cloud_api.DeleteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DeleteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DeleteUserResponse}
 */
proto.cloud_api.DeleteUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerErrorMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DeleteUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DeleteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DeleteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeleteUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCustomerErrorMsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool deleted = 1;
 * @return {boolean}
 */
proto.cloud_api.DeleteUserResponse.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.DeleteUserResponse} returns this
 */
proto.cloud_api.DeleteUserResponse.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string customer_error_msg = 2;
 * @return {string}
 */
proto.cloud_api.DeleteUserResponse.prototype.getCustomerErrorMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeleteUserResponse} returns this
 */
proto.cloud_api.DeleteUserResponse.prototype.setCustomerErrorMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.cloud_api);
