import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface DownloadFilesState {
  isDownloadingFilesVisible: boolean;
  isDownloadingFilesMinimized: boolean;
}

const initialState: DownloadFilesState = {
  isDownloadingFilesVisible: false,
  isDownloadingFilesMinimized: false,
};

const { reducer, actions } = createSlice({
  name: "downloadFiles",
  initialState,
  reducers: {
    setIsDownloadingFilesVisible(state, action: PayloadAction<boolean>) {
      state.isDownloadingFilesVisible = action.payload;
    },
    toggleIsDownloadingFilesVisible(state) {
      state.isDownloadingFilesVisible = !state.isDownloadingFilesVisible;
    },
    setIsDownloadingFilesMinimized(state, action: PayloadAction<boolean>) {
      state.isDownloadingFilesMinimized = action.payload;
    },
    toggleIsDownloadingFilesMinimized(state) {
      state.isDownloadingFilesMinimized = !state.isDownloadingFilesMinimized;
    },
  },
});

export default reducer;
export { actions as downloadFilesActions };
