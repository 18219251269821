import { createSlice, combineReducers, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";

import {
  createRequestReducerFromThunk,
  createIndexedRequestReducerFromThunk,
  createClearableIndexedRequestReducer,
  reduceReducers,
} from "@skydio/redux_util/src";

import { fetchFlights, fetchFlight } from "../flights/asyncThunks";
import { fetchVehicles, fetchVehicle } from "../vehicles/asyncThunks";
import {
  fetchReleases,
  fetchRelease,
  updateRelease,
  fetchReleaseFiles,
  updateOverride,
  updateDeviceOverride,
} from "./asyncThunks";

import { APIVehicle } from "../vehicles/types";
import { APIFlight } from "../flights/types";
import {
  ReleasesPrimaryState,
  Release,
  ReleaseFile,
  ReleaseUpdate,
  APIRelease,
  ReleasesMap,
  ReleaseFilesMap,
  APIReleaseFile,
} from "./types";
import { APIDataFile } from "../data_files/types";
import { APIPagination } from "../pagination/types";
import { APIDeviceReleaseOverride } from "..";

export const defaultPagination: APIPagination = {
  totalPages: 1,
  currentPage: 1,
  maxPerPage: 100,
};

export const defaultReleaseFilePagination: APIPagination = {
  totalPages: 1,
  currentPage: 1,
  maxPerPage: 20,
};

const initialState: ReleasesPrimaryState = {
  releases: {},
  releaseFiles: {},
  dispatchTimeout: null,
  requestedIds: [],
  pagination: defaultPagination,
};

const initialFileState: ReleaseFilesMap = {
  files: {},
  requestedIds: [],
  pagination: defaultReleaseFilePagination,
  dispatchTimeout: null,
};

export const initialFile: APIDataFile = {
  uuid: "",
  kind: "",
  sha1: "",
  uploadUrl: "",
  bucket: "",
  key: "",
  filename: "",
  signature: "",
  size: 0,
  uploaded: 0,
  deleted: 0,
  encrypted: false,
  created: 0,
  etag: "",
  bucketType: 0,
  vehicleId: "",
  dockId: "",
  flightId: "",
  userId: "",
  organizationId: "",
  mediaCapturedAt: 0, // This is always 0 for releases
  canDownload: false, // This is unused for releases
  fileUsage: 0, // This is unused for releases
};

const releaseInitialState: Release = {
  ...initialFile,
  releaseKey: "",
  name: "",
  vehicleType: 0,
  description: "",
  comment: "",
  version: "",
  active: true,
  warn: false,
  releaseFilesSignature: "",
  groups: [],
  minIosVersion: "",
  maxIosVersion: "",
  minAndroidVersion: "",
  maxAndroidVersion: "",
  userReleaseOverridesList: [],
  deviceReleaseOverridesList: [],
  modified: {},
  isTemporary: false,
};

export const releaseFileInitialState: ReleaseFile = {
  ...releaseInitialState,
  toReleaseKey: "",
  fromReleaseKey: "",
  fromReleaseVersion: "",
  smallEnoughSize: false,
  uploaded: null,
  fpinfo: "",
  otaMeta: "",
  ubuntuSuite: "",
  s3Key: "",
};

export const initialDeviceReleaseOverride: APIDeviceReleaseOverride = {
  releaseKey: "",
  deviceId: "",
  vehicleType: 0,
  validUntil: 0,
};

export interface FieldUpdatePayload {
  key: string;
  name: keyof ReleaseUpdate;
  value: any;
}

const updateReleaseState = (state: ReleasesMap, { file = initialFile, ...release }: APIRelease) => {
  if (!(release.releaseKey in state)) {
    state[release.releaseKey] = { ...releaseInitialState };
  }

  Object.assign(state[release.releaseKey]!, file, release, {
    uploaded: file.uploaded ? moment(file.uploaded * 1000) : state.uploaded,
    groups: release.groupsList ? release.groupsList.map(group => group.name) : state.groups,
  });
};

const updateReleasesFromVehicles = (state: ReleasesMap, vehicles: APIVehicle[]) => {
  vehicles.forEach(vehicle => {
    if (vehicle.release) {
      updateReleaseState(state, vehicle.release);
    }
  });
};

const updateReleasesFromFlights = (state: ReleasesMap, flights: APIFlight[]) => {
  flights.forEach(flight => {
    if (flight.release) {
      updateReleaseState(state, flight.release);
    }
  });
};

export const updateReleaseFile = (
  state: ReleaseFilesMap,
  { file = { ...initialFile }, ...releaseFile }: APIReleaseFile
) => {
  if (!(file.key in state)) {
    state.files[file.key] = { ...releaseFileInitialState };
  }
  Object.assign(state.files[file.key]!, file, releaseFile, {
    uploaded: file.uploaded ? moment(file.uploaded * 1000) : state.files[file.key]!.uploaded,
    s3Key: file.key,
  });
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "releases",
  initialState,
  reducers: {
    setReleasesQueryTimeout(state, { payload }: PayloadAction<number>) {
      state.dispatchTimeout = payload;
    },
    clearReleasesQueryTimeout(state) {
      state.dispatchTimeout = null;
    },
    updateReleaseField(state, { payload }: PayloadAction<FieldUpdatePayload>) {
      const release = state.releases[payload.key];
      if (_.isEqual(release![payload.name as keyof Release], payload.value)) {
        delete release!.modified[payload.name];
      } else {
        // @ts-ignore TS2322
        release.modified[payload.name] = payload.value;
      }
    },
    clearReleaseModifications(state, { payload }: PayloadAction<string>) {
      state.releases[payload]!.modified = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchFlights.fulfilled, (state, { payload }) => {
        updateReleasesFromFlights(state.releases, payload.flightsList);
      })
      .addCase(fetchFlight.fulfilled, (state, { payload }) => {
        updateReleasesFromFlights(state.releases, [payload]);
      })
      .addCase(fetchVehicles.fulfilled, (state, { payload }) => {
        updateReleasesFromVehicles(state.releases, payload.vehiclesList);
      })
      .addCase(fetchVehicle.fulfilled, (state, { payload }) => {
        updateReleasesFromVehicles(state.releases, [payload]);
      })
      .addCase(updateOverride.fulfilled, (state, { payload }) => {
        if (!(payload.releaseKey in state.releases)) {
          state.releases[payload.releaseKey] = { ...releaseInitialState };
        }
        Object.assign(state.releases[payload.releaseKey]!, {
          userReleaseOverridesList: payload.userReleaseOverridesList,
        });
      })
      .addCase(updateDeviceOverride.fulfilled, (state, { payload }) => {
        if (!(payload.releaseKey in state.releases)) {
          state.releases[payload.releaseKey] = { ...releaseInitialState };
        }
        Object.assign(state.releases[payload.releaseKey]!, {
          deviceReleaseOverridesList: payload.deviceReleaseOverridesList,
        });
      })
      .addCase(fetchReleases.fulfilled, (state, { payload }) => {
        state.requestedIds = payload.releasesList.map(({ releaseKey }) => releaseKey);
        payload.releasesList.forEach(release => {
          updateReleaseState(state.releases, release);
        });
        state.pagination = {
          maxPerPage: payload.pagination!.maxPerPage,
          currentPage: payload.pagination!.currentPage,
          totalPages: payload.pagination!.totalPages,
        };
      })
      .addCase(fetchReleases.rejected, state => {
        state.requestedIds = [];
      })
      .addCase(fetchRelease.fulfilled, (state, { payload }) => {
        updateReleaseState(state.releases, payload);
      })
      .addCase(updateRelease.fulfilled, (state, { payload }) => {
        updateReleaseState(state.releases, payload);
        state.releases[payload.releaseKey]!.modified = {};
      })
      .addCase(fetchReleaseFiles.fulfilled, (state, { payload, meta }) => {
        // the map in initialFileState needs to be deep copied
        if (!(meta.arg.toReleaseKey! in state.releaseFiles)) {
          state.releaseFiles[meta.arg.toReleaseKey!] = _.cloneDeep(initialFileState);
        }
        state.releaseFiles[meta.arg.toReleaseKey!]!.requestedIds = payload.releaseFilesList.map(
          ({ file }) => file!.key
        );
        payload.releaseFilesList.forEach(releaseFile => {
          updateReleaseFile(
            state.releaseFiles[meta.arg.toReleaseKey!] as ReleaseFilesMap,
            releaseFile
          );
        });
        state.releaseFiles[meta.arg.toReleaseKey!]!.pagination = {
          maxPerPage: payload.pagination!.maxPerPage,
          currentPage: payload.pagination!.currentPage,
          totalPages: payload.pagination!.totalPages,
        };
      }),
});

const { clearAction: clearReleaseRequest, clearableReducer } = createClearableIndexedRequestReducer(
  reduceReducers(
    createIndexedRequestReducerFromThunk(fetchRelease),
    createIndexedRequestReducerFromThunk(updateRelease, "key")
  ),
  "releases"
);

export const {
  clearAction: clearUserReleaseOverrideRequest,
  clearableReducer: clearableUserReleaseOverrideReducer,
} = createClearableIndexedRequestReducer(
  createIndexedRequestReducerFromThunk(updateOverride, "key"),
  "userReleaseOverrides"
);

export const {
  clearAction: clearDeviceReleaseOverrideRequest,
  clearableReducer: clearableDeviceReleaseOverrideReducer,
} = createClearableIndexedRequestReducer(
  createIndexedRequestReducerFromThunk(updateDeviceOverride, "key"),
  "deviceReleaseOverrides"
);

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    releases: createRequestReducerFromThunk(fetchReleases),
    release: clearableReducer,
    releaseFiles: createIndexedRequestReducerFromThunk(fetchReleaseFiles, "toReleaseKey"),
    userReleaseOverrides: clearableUserReleaseOverrideReducer,
    deviceReleaseOverrides: clearableDeviceReleaseOverrideReducer,
  }),
});
export type ReleasesState = ReturnType<typeof reducer>;

export const releaseActions = {
  ...actions,
  clearReleaseRequest,
  clearUserReleaseOverrideRequest,
  clearDeviceReleaseOverrideRequest,
};
export default reducer;
