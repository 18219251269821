import _ from "lodash";
import moment from "moment";

import type { Dictionary } from "lodash";

import "moment-duration-format";

import * as CLOUD_API from "@skydio/api_util/src/backends/cloud_api";
import { celsiusToKelvin, hectopascalsToInchesOfMercury } from "@skydio/math";
import { TakeoffSurface } from "@skydio/pbtypes/pbtypes/gen/dock_client/takeoff_surface_pb";

import type { WeatherStationData } from "@skydio/channels/src/weather_station_data_pb";
import type { client_type } from "__generated__/FlightsReportTable_organization.graphql";

// https://stackoverflow.com/questions/42999983/typescript-removing-readonly-modifier
// use with caution, intended use case is when you are deriving state by extending a read only graphQL type
export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export const forceStartCase = (str: string) => _.startCase(_.lowerCase(str));

export const getOrgPermissionKey = (perm: CLOUD_API.OrganizationPermission) =>
  CLOUD_API.modules.organizations.utils.getOrgPermissionKey(perm);

export type User = {
  id?: string | null;
  uuid?: string | null;
  displayName: string | null;
  email: string | null;
};

export function formatUserName(user: User | null | undefined): string {
  return user?.displayName || "";
}
export function formatPilotName(user?: User | null, takeoffClientType?: client_type): string {
  if (!user) {
    return "Pilot: Pending";
  }
  if (user.email === "unknown_user@vehicle.skyd.io") {
    if (takeoffClientType === "BEACON") {
      return "Beacon Flight";
    }
    if (takeoffClientType === "SCHEDULER" || takeoffClientType === "MISSION_CONDUCTOR") {
      return "Scheduled Flight";
    }
    return "Pilot Undetermined";
  }
  return formatUserName(user);
}

interface VehicleLike {
  vehicleId: string;
  skydioSerial?: string | null;
}

export const getVehicleName = ({ vehicleId, skydioSerial }: VehicleLike) =>
  skydioSerial || vehicleId;

export const generateANSISerialFromVehicleId = (vehicleId: string): string => {
  /*
    To generate ANSI serial, convert the vehicleId to uppercase, remove all periods,
    and prepend Skydio's static mfg code (1668) and the length code.
    The length code is a single character that corresponds to the number of characters in vehicleId.
    Length code characters range from 1 to 15 in the following way: 1, 2, ..., 9, A = 10, B = 11, ..., F = 15

    Ex. Vehicle ID "S2.0F.A.009T68"  => ANSI Serial "1668BS20FA009T68" (note that periods are not counted),
        Vehicle ID "269954914103954" => ANSI Serial "1668F269954914103954"
  */
  const SKYDIO_MFG_CODE = "1668";
  const normalizedVehicleId = vehicleId.toUpperCase().split(".").join("");
  let lengthCode = "";
  if (normalizedVehicleId.length >= 10) {
    lengthCode = String.fromCharCode("A".charCodeAt(0) + (normalizedVehicleId.length - 10));
  } else if (normalizedVehicleId.length >= 1 && vehicleId.length <= 9) {
    lengthCode = `${normalizedVehicleId.length}`;
  }
  return vehicleId ? SKYDIO_MFG_CODE + lengthCode + normalizedVehicleId : "";
};

export function toTitleCase(str: string) {
  // NOTE(Quentin): Not all of these functions are required but this is more robust to inputs
  // The first startCase call will split camel case into multiple words
  // The toLower is required because startCase will not modify letters that are already Upper
  return _.startCase(_.toLower(_.startCase(str)));
}

const formatSI = (value: number) => {
  const prefixes = ["", "K", "M", "G", "T"];
  let prefix_index = 0;
  while (value > 500 && prefix_index < prefixes.length - 1) {
    value = value / 1024;
    prefix_index += 1;
  }

  const prefix = prefixes[prefix_index];

  return { value, prefix };
};

export const formatVideoTime = (usec: number) => {
  const duration = moment.duration(usec / 1e6, "seconds");
  return duration.format("h:mm:ss", { stopTrim: "m" });
};

export const formatFileSize = (bytes: number) => {
  if (bytes <= 0) {
    return "0 kB";
  }
  const siBytes = formatSI(bytes);
  const units = siBytes.prefix + (siBytes.prefix ? "B" : "bytes");
  return siBytes.value.toFixed(2) + " " + units;
};

// users expect file rates in bits per second; convert it as so
export const formatFileRateBits = (bits: number) => {
  if (bits === 0) {
    return "0 Mbps";
  }
  const siBits = formatSI(bits);
  const units = siBits.prefix + "bps";
  return siBits.value.toFixed(2) + " " + units;
};

export const getOnlyChangedValues = <T extends Record<string, unknown>, O extends T>(
  target: T,
  original: O
) => _.omitBy(target, (val, key) => val === original[key as keyof T]);

export const boolToNum = (val: boolean) => (val ? 1 : 0);

export const firstAndLast = <T>(values: T[]): [T?, T?] => [_.first(values), _.last(values)];

// Slice off the first 4 digits so int is still short enough to serialize
export const getStarterNonce = () => parseInt(Date.now().toString().slice(4));

export const formatOrgPrefixedPath = (orgId: string, path = "/") => `/o/${orgId}${path}`;

// Simple method to check that this is a valid UUID
export const validateUuid = (uuid: string) => uuid.length === 32 || uuid.length === 36;

const batteryColors = {
  HIGH: "#52C41A",
  MID: "#FFBE18",
  LOW: "#FF0000",
};

export const getBatteryFillColor = (batteryPercent: number) =>
  batteryPercent > 0.4
    ? batteryColors.HIGH
    : batteryPercent > 0.1
      ? batteryColors.MID
      : batteryColors.LOW;

export const formatAlertTypeName = (alertName: string) => {
  return alertName
    .split("_")
    .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const dockTypeEnum = TakeoffSurface.Enum;

export const kMilliTol = 0.001;
export const kMicroTol = 0.000001;

export const formatValue = (
  value?: number,
  fractionDigits?: number,
  unitConverter?: (input: number) => number
) => {
  if (value === undefined) {
    return "-";
  } else {
    const convertedValue = unitConverter ? unitConverter(value) : value;
    return convertedValue.toFixed(fractionDigits ?? 2);
  }
};

// www.weather.gov/media/epz/wxcalc/densityAltitude.pdf
// https://github.com/Skydio/aircam/blob/master/infrastructure/drivers/weather_station/pbtypes/weather_station.proto
export const computeDensityAltitude = (weatherStationData: WeatherStationData.AsObject): number => {
  const pressureInInchesOfMercury = hectopascalsToInchesOfMercury(weatherStationData.pressure); // inHg;
  const pressureInMillibars = weatherStationData.pressure; //  1mb = 1hPa
  const dewpointInCelsius = weatherStationData.dewpoint; // Celsius
  const temperatureInKelvin = celsiusToKelvin(weatherStationData.temperature); // Kelvin;

  const vaporPressure =
    6.11 * Math.pow(10, (7.5 * dewpointInCelsius) / (237.7 + dewpointInCelsius));
  const virtualTemperatureInKelvin =
    temperatureInKelvin / (1 - (vaporPressure / pressureInMillibars) * (1 - 0.622)); // Kelvin

  const virtualTemperatureInRankine = (9 / 5) * (virtualTemperatureInKelvin - 273.15) + 32 + 459.69; // Rankine
  return (
    145366 *
    (1 - Math.pow((17.326 * pressureInInchesOfMercury) / virtualTemperatureInRankine, 0.235))
  );
};

export const getDirectionNameFromAngleDegrees = (
  angle: number,
  showOppositeDirection?: boolean
) => {
  const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
  const calcAngle = (showOppositeDirection ? angle + 180 : angle) % 360;
  const index = Math.round((calcAngle < 0 ? calcAngle + 360 : calcAngle) / 45) % 8;
  return directions[index];
};

/**
 * Given some Object T makes a copy of it where all keys with a float type get rounded to the
 * set number of decimal places. Useful for deep comparisons. Non-recursive.
 *
 * @param object some plain JS object containing float values
 * @param decimalPlaces how many decimal places to round to
 * @returns a copy of object with all float values rounded to decimalPlaces
 */
export function roundAllFloats<T extends object>(object: T, decimalPlaces: number): T {
  return _.mapValues(object, value => {
    if (typeof value === "number") {
      return parseFloat(value.toFixed(decimalPlaces));
    }
    return value;
  }) as T;
}

/**
 * Given two vectors, returns if the distance between the two vectors is less than a tolerance.
 * Also returns true if both vectors are undefined, but false if only one of them is undefined.
 *
 * @param v1 vector 1 | undefined
 * @param v2 vector 2 | undefined
 * @param tol tolerance, defaults to kMicroTol
 * @returns true if the distance between the two vectors is less than the given tolerance
 */
export const areVectorsApproximatelyEqual = (
  v1?: THREE.Vector3,
  v2?: THREE.Vector3,
  tol?: number
) => {
  if (v1 !== undefined && v2 !== undefined) {
    return v1.distanceTo(v2) < (tol ?? kMicroTol);
  } else {
    // If only one of them is undefined, they are not equal
    return typeof v1 === typeof v2;
  }
};

/**
 * Given two numbers, returns if the difference between the two numbers is less than a tolerance.
 * Also returns true if both numbers are undefined, but false if only one of them is undefined.
 *
 * @param n1 number 1 | undefined
 * @param n2 number 2 | undefined
 * @param tol tolerance, defaults to kMicroTol
 * @returns true if the difference between the two numbers is less than the given tolerance
 */
export const areNumbersApproximatelyEqual = (n1?: number, n2?: number, tol?: number) => {
  if (n1 !== undefined && n2 !== undefined) {
    return Math.abs(n1 - n2) < (tol ?? kMicroTol);
  } else {
    // If only one of them is undefined, they are not equal
    return typeof n1 === typeof n2;
  }
};
