// source: pbtypes/tools/cloud_api/feature_flags.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_tools_cloud_api_enums_pb = require('../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
goog.exportSymbol('proto.cloud_api.FeatureFlagRequest', null, global);
goog.exportSymbol('proto.cloud_api.FeatureFlags', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FeatureFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FeatureFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FeatureFlags.displayName = 'proto.cloud_api.FeatureFlags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FeatureFlagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FeatureFlagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FeatureFlagRequest.displayName = 'proto.cloud_api.FeatureFlagRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FeatureFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FeatureFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FeatureFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FeatureFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleTeleoperation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
automaticFileUploadPage: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
vehicleLicensesPage: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
liveStreaming: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
axonMediaIntegration: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
missionsPage: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
vehicleRemoteStreaming: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
mobileRemoteStreaming: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
multiViewerStreaming: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
axonRespondIntegration: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
viewOfflineSettings: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
configureObstacleMargin: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
droInternal: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
annotations: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
cloudInspectPage: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
missionMediaViewer: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
automaticCloudPhotogrammetry: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
dockLocationUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
disableTeleopLandingPrompt: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
droTeleopRunMissionJson: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
hideLandNowDuringRtd: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
remoteVideoApiAccess: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
allowTwilightFlight: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
allowBacktrackingRtx: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
cloudOta: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
teleopDebugMenu: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
qosSkybusChannelSetting: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
postRefactorMissionEditor: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
networkDebuggingTools: jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
fullHeightVideoEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 47, false),
navHeightAboveGroundEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 49, false),
internalThemeToggle: jspb.Message.getBooleanFieldWithDefault(msg, 51, false),
customerCustomMapboxMapStyles: jspb.Message.getBooleanFieldWithDefault(msg, 53, false),
enablePerDeviceSettings: jspb.Message.getBooleanFieldWithDefault(msg, 62, false),
showVehicleFaultsTeleop: jspb.Message.getBooleanFieldWithDefault(msg, 63, false),
showVpsInfo: jspb.Message.getBooleanFieldWithDefault(msg, 66, false),
teleopArV2: jspb.Message.getBooleanFieldWithDefault(msg, 67, false),
missionControlApi: jspb.Message.getBooleanFieldWithDefault(msg, 68, false),
scheduleMissionNowApi: jspb.Message.getBooleanFieldWithDefault(msg, 69, false),
surfaceScanMissions: jspb.Message.getBooleanFieldWithDefault(msg, 71, false),
showAprilTagSettings: jspb.Message.getBooleanFieldWithDefault(msg, 72, false),
showSkydioLinkSettings: jspb.Message.getBooleanFieldWithDefault(msg, 73, false),
whatsNew: jspb.Message.getBooleanFieldWithDefault(msg, 76, false),
showRangeLimitSettings: jspb.Message.getBooleanFieldWithDefault(msg, 80, false),
siteMapper: jspb.Message.getBooleanFieldWithDefault(msg, 85, false),
showBandSelectionSettings: jspb.Message.getBooleanFieldWithDefault(msg, 86, false),
flyNowNoAutomaticRunModeSwitching: jspb.Message.getBooleanFieldWithDefault(msg, 87, false),
arVideoSync: jspb.Message.getBooleanFieldWithDefault(msg, 88, false),
missionResults3dViewer: jspb.Message.getBooleanFieldWithDefault(msg, 89, false),
livestreamEgress: jspb.Message.getBooleanFieldWithDefault(msg, 91, false),
automaticNcpgPhotogrammetry: jspb.Message.getBooleanFieldWithDefault(msg, 92, false),
executiveActionHotkeys: jspb.Message.getBooleanFieldWithDefault(msg, 93, false),
vehicleTypeBasedAxonSessions: jspb.Message.getBooleanFieldWithDefault(msg, 94, false),
siteMissions: jspb.Message.getBooleanFieldWithDefault(msg, 98, false),
alwaysShowCameraSettings: jspb.Message.getBooleanFieldWithDefault(msg, 99, false),
teleopWaypointCommands: jspb.Message.getBooleanFieldWithDefault(msg, 101, false),
droRemoteId: jspb.Message.getBooleanFieldWithDefault(msg, 105, false),
propGuardsEnablement: jspb.Message.getBooleanFieldWithDefault(msg, 108, false),
remotePilotApiAccess: jspb.Message.getBooleanFieldWithDefault(msg, 111, false),
showViewFlightDeckButton: jspb.Message.getBooleanFieldWithDefault(msg, 112, false),
twilightModeFastFlight: jspb.Message.getBooleanFieldWithDefault(msg, 113, false),
standaloneMapPage: jspb.Message.getBooleanFieldWithDefault(msg, 114, false),
missionResume: jspb.Message.getBooleanFieldWithDefault(msg, 115, false),
lostCommsTimeoutSettingTeleop: jspb.Message.getBooleanFieldWithDefault(msg, 116, false),
teleopFastFlight: jspb.Message.getBooleanFieldWithDefault(msg, 117, false),
allowEditAdsbSettings: jspb.Message.getBooleanFieldWithDefault(msg, 119, false),
showSegmentedTeleopLatency: jspb.Message.getBooleanFieldWithDefault(msg, 120, false),
showTeleopIdleModal: jspb.Message.getBooleanFieldWithDefault(msg, 121, false),
skydioExtend: jspb.Message.getBooleanFieldWithDefault(msg, 122, false),
supportMenuV2: jspb.Message.getBooleanFieldWithDefault(msg, 123, false),
geofenceEnforcementInCloud: jspb.Message.getBooleanFieldWithDefault(msg, 125, false),
sensorDataViewClaim: jspb.Message.getBooleanFieldWithDefault(msg, 126, false),
teleopThirdPersonView: jspb.Message.getBooleanFieldWithDefault(msg, 129, false),
showTeleopCircularBatteryWidget: jspb.Message.getBooleanFieldWithDefault(msg, 132, false),
alertManagerWithAudioInTeleop: jspb.Message.getBooleanFieldWithDefault(msg, 133, false),
structureAnnotationsApi: jspb.Message.getBooleanFieldWithDefault(msg, 134, false),
moveLivestreamTagPosition: jspb.Message.getBooleanFieldWithDefault(msg, 135, false),
showActivePilotNameIfLivestreaming: jspb.Message.getBooleanFieldWithDefault(msg, 136, false),
moveShareLivestreamButtonToIcon: jspb.Message.getBooleanFieldWithDefault(msg, 137, false),
ownOrgGroups: jspb.Message.getBooleanFieldWithDefault(msg, 138, false),
teleopCommandeeringV2: jspb.Message.getBooleanFieldWithDefault(msg, 139, false),
mediaAvailableAlert: jspb.Message.getBooleanFieldWithDefault(msg, 140, false),
mediaAvailableForScanAlert: jspb.Message.getBooleanFieldWithDefault(msg, 141, false),
ncpgPhotogrammetryUseTensorRtFlowModel: jspb.Message.getBooleanFieldWithDefault(msg, 142, false),
showFaaMapboxStyles: jspb.Message.getBooleanFieldWithDefault(msg, 143, false),
allowSchedulingR47Missions: jspb.Message.getBooleanFieldWithDefault(msg, 144, false),
skyegressDataSessions: jspb.Message.getBooleanFieldWithDefault(msg, 145, false),
sitesStructureEditor: jspb.Message.getBooleanFieldWithDefault(msg, 147, false),
multiMissionFlightTester: jspb.Message.getBooleanFieldWithDefault(msg, 148, false),
telemetryAvailableAlert: jspb.Message.getBooleanFieldWithDefault(msg, 149, false),
ridScheduledApiMissions: jspb.Message.getBooleanFieldWithDefault(msg, 152, false),
teleopSettingsModalV3: jspb.Message.getBooleanFieldWithDefault(msg, 153, false),
x10EoCameraControlsParityInTeleop: jspb.Message.getBooleanFieldWithDefault(msg, 154, false),
noObstacleAvoidanceInTeleop: jspb.Message.getBooleanFieldWithDefault(msg, 155, false),
granularPermissions: jspb.Message.getBooleanFieldWithDefault(msg, 156, false),
livestreamStatusChangedAlert: jspb.Message.getBooleanFieldWithDefault(msg, 157, false),
liveMissionPlanning: jspb.Message.getBooleanFieldWithDefault(msg, 158, false),
flyToLaunchPointTeleop: jspb.Message.getBooleanFieldWithDefault(msg, 159, false),
showAdditionalWifiNetworks: jspb.Message.getBooleanFieldWithDefault(msg, 160, false),
waypointCameraSettings: jspb.Message.getBooleanFieldWithDefault(msg, 161, false),
vpsMapDatamodelTransitionReadFromNew: jspb.Message.getBooleanFieldWithDefault(msg, 162, false),
vpsMapDatamodelTransitionWriteToBoth: jspb.Message.getBooleanFieldWithDefault(msg, 163, false),
editOrgFlightPolicy: jspb.Message.getBooleanFieldWithDefault(msg, 164, false),
teleopWheelZoom: jspb.Message.getBooleanFieldWithDefault(msg, 165, false),
multizoneSiteGeofences: jspb.Message.getBooleanFieldWithDefault(msg, 166, false),
teleopBitrateSlider: jspb.Message.getBooleanFieldWithDefault(msg, 168, false),
x10ZoomAndFlashUi: jspb.Message.getBooleanFieldWithDefault(msg, 169, false),
ridFeatureDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 171, false),
removeFlightFromResourceGroup: jspb.Message.getBooleanFieldWithDefault(msg, 173, false),
safeLandingPoints: jspb.Message.getBooleanFieldWithDefault(msg, 174, false),
teleopBoostMode: jspb.Message.getBooleanFieldWithDefault(msg, 177, false),
teleopCrawlMode: jspb.Message.getBooleanFieldWithDefault(msg, 178, false),
enableFlyToPoiRfd: jspb.Message.getBooleanFieldWithDefault(msg, 179, false),
showOperationsAreaMapboxStyles: jspb.Message.getBooleanFieldWithDefault(msg, 180, false),
barometerHeightMode: jspb.Message.getBooleanFieldWithDefault(msg, 183, false),
sendZipFilesJobToTemporal: jspb.Message.getBooleanFieldWithDefault(msg, 184, false),
graphiqlViewer: jspb.Message.getBooleanFieldWithDefault(msg, 186, false),
enableOrbitToPoiRfd: jspb.Message.getBooleanFieldWithDefault(msg, 187, false),
disableCloudConfigSignatureVerification: jspb.Message.getBooleanFieldWithDefault(msg, 188, false),
isOnpremOrg: jspb.Message.getBooleanFieldWithDefault(msg, 189, false),
enablePointerLockDuringMissionsRfd: jspb.Message.getBooleanFieldWithDefault(msg, 190, false),
enableMapVehicleFollowiing: jspb.Message.getBooleanFieldWithDefault(msg, 192, false),
readyLinkSingleVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 193, false),
teleopTelemetryHeadingAndGimbalPitch: jspb.Message.getBooleanFieldWithDefault(msg, 194, false),
mediaPageKindDropdown: jspb.Message.getBooleanFieldWithDefault(msg, 195, false),
teleopActionsToolbar: jspb.Message.getBooleanFieldWithDefault(msg, 196, false),
editDockLedsOverride: jspb.Message.getBooleanFieldWithDefault(msg, 197, false),
enableDockLandingAr: jspb.Message.getBooleanFieldWithDefault(msg, 199, false),
gqlDeleteMediaDirectly: jspb.Message.getBooleanFieldWithDefault(msg, 200, false),
teleopNavSourceInfoHealth: jspb.Message.getBooleanFieldWithDefault(msg, 201, false),
enableAxonMarkersRfd: jspb.Message.getBooleanFieldWithDefault(msg, 202, false),
enableFpvOnMap: jspb.Message.getBooleanFieldWithDefault(msg, 203, false),
offlineUpdatesPage: jspb.Message.getBooleanFieldWithDefault(msg, 204, false),
enableImprovedConnectivityRfd: jspb.Message.getBooleanFieldWithDefault(msg, 205, false),
gimbalPitchResetShortcut: jspb.Message.getBooleanFieldWithDefault(msg, 206, false),
createMarkersExternalApi: jspb.Message.getBooleanFieldWithDefault(msg, 207, false),
r47ShowMissionPlanners: jspb.Message.getBooleanFieldWithDefault(msg, 208, false),
rfdControllerMaxSpeedParity: jspb.Message.getBooleanFieldWithDefault(msg, 209, false),
enableArStreetOverlays: jspb.Message.getBooleanFieldWithDefault(msg, 210, false),
x10CloudOta: jspb.Message.getBooleanFieldWithDefault(msg, 211, false),
enableCloudUiPublicSafetyLightsV2: jspb.Message.getBooleanFieldWithDefault(msg, 212, false),
enableRfdHudCompass: jspb.Message.getBooleanFieldWithDefault(msg, 213, false),
enableFlyToPoiR47: jspb.Message.getBooleanFieldWithDefault(msg, 214, false),
teleopRemoveReturnType: jspb.Message.getBooleanFieldWithDefault(msg, 215, false),
enableRfdHudAirspeedAltitude: jspb.Message.getBooleanFieldWithDefault(msg, 216, false),
enableSiteWideWeatherSensorData: jspb.Message.getBooleanFieldWithDefault(msg, 217, false),
enableSiteWideAdsbData: jspb.Message.getBooleanFieldWithDefault(msg, 218, false),
enableIncidentMarkersRfd: jspb.Message.getBooleanFieldWithDefault(msg, 219, false),
moveVehicleLaunchModalVideoFeed: jspb.Message.getBooleanFieldWithDefault(msg, 220, false),
enableLookAtFinalPoint: jspb.Message.getBooleanFieldWithDefault(msg, 221, false),
enableDockTransitPoint: jspb.Message.getBooleanFieldWithDefault(msg, 222, false),
enableDockPrecipitatonTag: jspb.Message.getBooleanFieldWithDefault(msg, 223, false),
enableViewDownloadRtkPpkFiles: jspb.Message.getBooleanFieldWithDefault(msg, 224, false),
publicVisibilityDashboard: jspb.Message.getBooleanFieldWithDefault(msg, 225, false),
enableX10AdsbRfd: jspb.Message.getBooleanFieldWithDefault(msg, 226, false),
usePathfinderFlyToPointRfd: jspb.Message.getBooleanFieldWithDefault(msg, 227, false),
mediaAvailableForFlightAlert: jspb.Message.getBooleanFieldWithDefault(msg, 228, false),
mediaWatermarks: jspb.Message.getBooleanFieldWithDefault(msg, 229, false),
emitRtspStreamsToDefaultEgress: jspb.Message.getBooleanFieldWithDefault(msg, 230, false),
alertsApi: jspb.Message.getBooleanFieldWithDefault(msg, 231, false),
dfrStockSettings: jspb.Message.getBooleanFieldWithDefault(msg, 232, false),
remotelyApplyX10ApnSettings: jspb.Message.getBooleanFieldWithDefault(msg, 233, false),
customer2CustomMapboxMapStyles: jspb.Message.getBooleanFieldWithDefault(msg, 234, false),
enableGimbalAttachmentUi: jspb.Message.getBooleanFieldWithDefault(msg, 235, false),
rfdConnectivitySettingsTab: jspb.Message.getBooleanFieldWithDefault(msg, 236, false),
missionEditorNightAutonomy: jspb.Message.getBooleanFieldWithDefault(msg, 237, false),
rfdParachuteAttachmentUi: jspb.Message.getBooleanFieldWithDefault(msg, 238, false),
showCloudSimulatorControls: jspb.Message.getBooleanFieldWithDefault(msg, 239, false),
missionSharingCustomerPortal: jspb.Message.getBooleanFieldWithDefault(msg, 240, false),
disableLinkLossAlertOnGround: jspb.Message.getBooleanFieldWithDefault(msg, 241, false),
useImprovedFlightStatusWorkflow: jspb.Message.getBooleanFieldWithDefault(msg, 243, false),
enableMarkersAr: jspb.Message.getBooleanFieldWithDefault(msg, 245, false),
ssoEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 246, false),
rfdTelemetryStats: jspb.Message.getBooleanFieldWithDefault(msg, 247, false),
showOperationsAreaInternalMapboxStyles: jspb.Message.getBooleanFieldWithDefault(msg, 248, false),
activateStrobeLightsOnLaunchRfd: jspb.Message.getBooleanFieldWithDefault(msg, 249, false),
missionDocumentsApi: jspb.Message.getBooleanFieldWithDefault(msg, 250, false),
fleetPageSdCardFormatting: jspb.Message.getBooleanFieldWithDefault(msg, 251, false),
enableMultidroneOperations: jspb.Message.getBooleanFieldWithDefault(msg, 252, false),
addLaunchPointRfd: jspb.Message.getBooleanFieldWithDefault(msg, 253, false),
controlKeyDragWaypointVertical: jspb.Message.getBooleanFieldWithDefault(msg, 254, false),
showTerrainAndBldgsToggleInFleetPage: jspb.Message.getBooleanFieldWithDefault(msg, 255, false),
manageFlyToPointHeightWithVehicleOverride: jspb.Message.getBooleanFieldWithDefault(msg, 256, false),
altKeyDragWaypointVertical: jspb.Message.getBooleanFieldWithDefault(msg, 257, false),
copyMarkersBetweenNypdOrgs: jspb.Message.getBooleanFieldWithDefault(msg, 258, false),
showOverlappingDockWifiChannelsFleetPage: jspb.Message.getBooleanFieldWithDefault(msg, 259, false),
ssoManagement: jspb.Message.getBooleanFieldWithDefault(msg, 260, false),
showInternalMapboxStyles3: jspb.Message.getBooleanFieldWithDefault(msg, 261, false),
fixedLocationRid: jspb.Message.getBooleanFieldWithDefault(msg, 262, false),
alertOnFlyToPointAltitudeChange: jspb.Message.getBooleanFieldWithDefault(msg, 263, false),
teleopPilotKeepAlive: jspb.Message.getBooleanFieldWithDefault(msg, 264, false),
downloadOnlyCloudConfigs: jspb.Message.getBooleanFieldWithDefault(msg, 265, false),
dockEstopDisplay: jspb.Message.getBooleanFieldWithDefault(msg, 266, false),
dockSafetySoundSettings: jspb.Message.getBooleanFieldWithDefault(msg, 267, false),
dockSafetyLightSettings: jspb.Message.getBooleanFieldWithDefault(msg, 268, false),
showFlightReportMediaSummary: jspb.Message.getBooleanFieldWithDefault(msg, 269, false),
ascendSolarMapCaptureDemo: jspb.Message.getBooleanFieldWithDefault(msg, 270, false),
useWorldTakeoffFrameFlyToPoint: jspb.Message.getBooleanFieldWithDefault(msg, 271, false),
enableRtkSupport: jspb.Message.getBooleanFieldWithDefault(msg, 272, false),
speakerMicRfd: jspb.Message.getBooleanFieldWithDefault(msg, 273, false),
cityCustomerMapboxMapStyles: jspb.Message.getBooleanFieldWithDefault(msg, 274, false),
vehicleSettingsCosmeticsAndAccessPoint: jspb.Message.getBooleanFieldWithDefault(msg, 275, false),
linkLossAlertLevelCaution: jspb.Message.getBooleanFieldWithDefault(msg, 276, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FeatureFlags}
 */
proto.cloud_api.FeatureFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FeatureFlags;
  return proto.cloud_api.FeatureFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FeatureFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FeatureFlags}
 */
proto.cloud_api.FeatureFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleTeleoperation(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticFileUploadPage(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleLicensesPage(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveStreaming(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAxonMediaIntegration(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissionsPage(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleRemoteStreaming(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMobileRemoteStreaming(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiViewerStreaming(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAxonRespondIntegration(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOfflineSettings(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigureObstacleMargin(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDroInternal(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnnotations(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCloudInspectPage(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissionMediaViewer(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticCloudPhotogrammetry(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDockLocationUpdate(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableTeleopLandingPrompt(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDroTeleopRunMissionJson(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideLandNowDuringRtd(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoteVideoApiAccess(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowTwilightFlight(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowBacktrackingRtx(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCloudOta(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopDebugMenu(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQosSkybusChannelSetting(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPostRefactorMissionEditor(value);
      break;
    case 46:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNetworkDebuggingTools(value);
      break;
    case 47:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullHeightVideoEnabled(value);
      break;
    case 49:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNavHeightAboveGroundEnabled(value);
      break;
    case 51:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInternalThemeToggle(value);
      break;
    case 53:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustomerCustomMapboxMapStyles(value);
      break;
    case 62:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablePerDeviceSettings(value);
      break;
    case 63:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowVehicleFaultsTeleop(value);
      break;
    case 66:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowVpsInfo(value);
      break;
    case 67:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopArV2(value);
      break;
    case 68:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissionControlApi(value);
      break;
    case 69:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScheduleMissionNowApi(value);
      break;
    case 71:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSurfaceScanMissions(value);
      break;
    case 72:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowAprilTagSettings(value);
      break;
    case 73:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowSkydioLinkSettings(value);
      break;
    case 76:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWhatsNew(value);
      break;
    case 80:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowRangeLimitSettings(value);
      break;
    case 85:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSiteMapper(value);
      break;
    case 86:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowBandSelectionSettings(value);
      break;
    case 87:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlyNowNoAutomaticRunModeSwitching(value);
      break;
    case 88:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArVideoSync(value);
      break;
    case 89:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissionResults3dViewer(value);
      break;
    case 91:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLivestreamEgress(value);
      break;
    case 92:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticNcpgPhotogrammetry(value);
      break;
    case 93:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExecutiveActionHotkeys(value);
      break;
    case 94:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleTypeBasedAxonSessions(value);
      break;
    case 98:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSiteMissions(value);
      break;
    case 99:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlwaysShowCameraSettings(value);
      break;
    case 101:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopWaypointCommands(value);
      break;
    case 105:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDroRemoteId(value);
      break;
    case 108:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPropGuardsEnablement(value);
      break;
    case 111:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemotePilotApiAccess(value);
      break;
    case 112:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowViewFlightDeckButton(value);
      break;
    case 113:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTwilightModeFastFlight(value);
      break;
    case 114:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStandaloneMapPage(value);
      break;
    case 115:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissionResume(value);
      break;
    case 116:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLostCommsTimeoutSettingTeleop(value);
      break;
    case 117:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopFastFlight(value);
      break;
    case 119:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowEditAdsbSettings(value);
      break;
    case 120:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowSegmentedTeleopLatency(value);
      break;
    case 121:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowTeleopIdleModal(value);
      break;
    case 122:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkydioExtend(value);
      break;
    case 123:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportMenuV2(value);
      break;
    case 125:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGeofenceEnforcementInCloud(value);
      break;
    case 126:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSensorDataViewClaim(value);
      break;
    case 129:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopThirdPersonView(value);
      break;
    case 132:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowTeleopCircularBatteryWidget(value);
      break;
    case 133:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlertManagerWithAudioInTeleop(value);
      break;
    case 134:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStructureAnnotationsApi(value);
      break;
    case 135:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMoveLivestreamTagPosition(value);
      break;
    case 136:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowActivePilotNameIfLivestreaming(value);
      break;
    case 137:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMoveShareLivestreamButtonToIcon(value);
      break;
    case 138:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwnOrgGroups(value);
      break;
    case 139:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopCommandeeringV2(value);
      break;
    case 140:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMediaAvailableAlert(value);
      break;
    case 141:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMediaAvailableForScanAlert(value);
      break;
    case 142:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNcpgPhotogrammetryUseTensorRtFlowModel(value);
      break;
    case 143:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowFaaMapboxStyles(value);
      break;
    case 144:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowSchedulingR47Missions(value);
      break;
    case 145:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkyegressDataSessions(value);
      break;
    case 147:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSitesStructureEditor(value);
      break;
    case 148:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiMissionFlightTester(value);
      break;
    case 149:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTelemetryAvailableAlert(value);
      break;
    case 152:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRidScheduledApiMissions(value);
      break;
    case 153:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopSettingsModalV3(value);
      break;
    case 154:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setX10EoCameraControlsParityInTeleop(value);
      break;
    case 155:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoObstacleAvoidanceInTeleop(value);
      break;
    case 156:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGranularPermissions(value);
      break;
    case 157:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLivestreamStatusChangedAlert(value);
      break;
    case 158:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveMissionPlanning(value);
      break;
    case 159:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlyToLaunchPointTeleop(value);
      break;
    case 160:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowAdditionalWifiNetworks(value);
      break;
    case 161:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaypointCameraSettings(value);
      break;
    case 162:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVpsMapDatamodelTransitionReadFromNew(value);
      break;
    case 163:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVpsMapDatamodelTransitionWriteToBoth(value);
      break;
    case 164:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditOrgFlightPolicy(value);
      break;
    case 165:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopWheelZoom(value);
      break;
    case 166:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultizoneSiteGeofences(value);
      break;
    case 168:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopBitrateSlider(value);
      break;
    case 169:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setX10ZoomAndFlashUi(value);
      break;
    case 171:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRidFeatureDisabled(value);
      break;
    case 173:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoveFlightFromResourceGroup(value);
      break;
    case 174:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSafeLandingPoints(value);
      break;
    case 177:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopBoostMode(value);
      break;
    case 178:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopCrawlMode(value);
      break;
    case 179:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableFlyToPoiRfd(value);
      break;
    case 180:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowOperationsAreaMapboxStyles(value);
      break;
    case 183:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBarometerHeightMode(value);
      break;
    case 184:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendZipFilesJobToTemporal(value);
      break;
    case 186:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGraphiqlViewer(value);
      break;
    case 187:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableOrbitToPoiRfd(value);
      break;
    case 188:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableCloudConfigSignatureVerification(value);
      break;
    case 189:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnpremOrg(value);
      break;
    case 190:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablePointerLockDuringMissionsRfd(value);
      break;
    case 192:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableMapVehicleFollowiing(value);
      break;
    case 193:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadyLinkSingleVehicle(value);
      break;
    case 194:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopTelemetryHeadingAndGimbalPitch(value);
      break;
    case 195:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMediaPageKindDropdown(value);
      break;
    case 196:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopActionsToolbar(value);
      break;
    case 197:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditDockLedsOverride(value);
      break;
    case 199:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDockLandingAr(value);
      break;
    case 200:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGqlDeleteMediaDirectly(value);
      break;
    case 201:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopNavSourceInfoHealth(value);
      break;
    case 202:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableAxonMarkersRfd(value);
      break;
    case 203:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableFpvOnMap(value);
      break;
    case 204:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOfflineUpdatesPage(value);
      break;
    case 205:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableImprovedConnectivityRfd(value);
      break;
    case 206:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGimbalPitchResetShortcut(value);
      break;
    case 207:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateMarkersExternalApi(value);
      break;
    case 208:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setR47ShowMissionPlanners(value);
      break;
    case 209:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRfdControllerMaxSpeedParity(value);
      break;
    case 210:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableArStreetOverlays(value);
      break;
    case 211:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setX10CloudOta(value);
      break;
    case 212:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableCloudUiPublicSafetyLightsV2(value);
      break;
    case 213:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableRfdHudCompass(value);
      break;
    case 214:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableFlyToPoiR47(value);
      break;
    case 215:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopRemoveReturnType(value);
      break;
    case 216:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableRfdHudAirspeedAltitude(value);
      break;
    case 217:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSiteWideWeatherSensorData(value);
      break;
    case 218:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSiteWideAdsbData(value);
      break;
    case 219:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableIncidentMarkersRfd(value);
      break;
    case 220:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMoveVehicleLaunchModalVideoFeed(value);
      break;
    case 221:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableLookAtFinalPoint(value);
      break;
    case 222:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDockTransitPoint(value);
      break;
    case 223:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDockPrecipitatonTag(value);
      break;
    case 224:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableViewDownloadRtkPpkFiles(value);
      break;
    case 225:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublicVisibilityDashboard(value);
      break;
    case 226:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableX10AdsbRfd(value);
      break;
    case 227:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsePathfinderFlyToPointRfd(value);
      break;
    case 228:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMediaAvailableForFlightAlert(value);
      break;
    case 229:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMediaWatermarks(value);
      break;
    case 230:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmitRtspStreamsToDefaultEgress(value);
      break;
    case 231:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlertsApi(value);
      break;
    case 232:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDfrStockSettings(value);
      break;
    case 233:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemotelyApplyX10ApnSettings(value);
      break;
    case 234:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustomer2CustomMapboxMapStyles(value);
      break;
    case 235:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableGimbalAttachmentUi(value);
      break;
    case 236:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRfdConnectivitySettingsTab(value);
      break;
    case 237:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissionEditorNightAutonomy(value);
      break;
    case 238:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRfdParachuteAttachmentUi(value);
      break;
    case 239:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowCloudSimulatorControls(value);
      break;
    case 240:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissionSharingCustomerPortal(value);
      break;
    case 241:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableLinkLossAlertOnGround(value);
      break;
    case 243:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseImprovedFlightStatusWorkflow(value);
      break;
    case 245:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableMarkersAr(value);
      break;
    case 246:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSsoEnabled(value);
      break;
    case 247:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRfdTelemetryStats(value);
      break;
    case 248:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowOperationsAreaInternalMapboxStyles(value);
      break;
    case 249:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivateStrobeLightsOnLaunchRfd(value);
      break;
    case 250:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissionDocumentsApi(value);
      break;
    case 251:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFleetPageSdCardFormatting(value);
      break;
    case 252:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableMultidroneOperations(value);
      break;
    case 253:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddLaunchPointRfd(value);
      break;
    case 254:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setControlKeyDragWaypointVertical(value);
      break;
    case 255:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowTerrainAndBldgsToggleInFleetPage(value);
      break;
    case 256:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManageFlyToPointHeightWithVehicleOverride(value);
      break;
    case 257:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAltKeyDragWaypointVertical(value);
      break;
    case 258:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCopyMarkersBetweenNypdOrgs(value);
      break;
    case 259:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowOverlappingDockWifiChannelsFleetPage(value);
      break;
    case 260:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSsoManagement(value);
      break;
    case 261:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowInternalMapboxStyles3(value);
      break;
    case 262:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFixedLocationRid(value);
      break;
    case 263:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlertOnFlyToPointAltitudeChange(value);
      break;
    case 264:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopPilotKeepAlive(value);
      break;
    case 265:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadOnlyCloudConfigs(value);
      break;
    case 266:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDockEstopDisplay(value);
      break;
    case 267:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDockSafetySoundSettings(value);
      break;
    case 268:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDockSafetyLightSettings(value);
      break;
    case 269:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowFlightReportMediaSummary(value);
      break;
    case 270:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAscendSolarMapCaptureDemo(value);
      break;
    case 271:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseWorldTakeoffFrameFlyToPoint(value);
      break;
    case 272:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableRtkSupport(value);
      break;
    case 273:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpeakerMicRfd(value);
      break;
    case 274:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCityCustomerMapboxMapStyles(value);
      break;
    case 275:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleSettingsCosmeticsAndAccessPoint(value);
      break;
    case 276:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLinkLossAlertLevelCaution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FeatureFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FeatureFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FeatureFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FeatureFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleTeleoperation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAutomaticFileUploadPage();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getVehicleLicensesPage();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLiveStreaming();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAxonMediaIntegration();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getMissionsPage();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getVehicleRemoteStreaming();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMobileRemoteStreaming();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getMultiViewerStreaming();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAxonRespondIntegration();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getViewOfflineSettings();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getConfigureObstacleMargin();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getDroInternal();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getAnnotations();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getCloudInspectPage();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getMissionMediaViewer();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getAutomaticCloudPhotogrammetry();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getDockLocationUpdate();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getDisableTeleopLandingPrompt();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getDroTeleopRunMissionJson();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getHideLandNowDuringRtd();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getRemoteVideoApiAccess();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getAllowTwilightFlight();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getAllowBacktrackingRtx();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getCloudOta();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getTeleopDebugMenu();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getQosSkybusChannelSetting();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getPostRefactorMissionEditor();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getNetworkDebuggingTools();
  if (f) {
    writer.writeBool(
      46,
      f
    );
  }
  f = message.getFullHeightVideoEnabled();
  if (f) {
    writer.writeBool(
      47,
      f
    );
  }
  f = message.getNavHeightAboveGroundEnabled();
  if (f) {
    writer.writeBool(
      49,
      f
    );
  }
  f = message.getInternalThemeToggle();
  if (f) {
    writer.writeBool(
      51,
      f
    );
  }
  f = message.getCustomerCustomMapboxMapStyles();
  if (f) {
    writer.writeBool(
      53,
      f
    );
  }
  f = message.getEnablePerDeviceSettings();
  if (f) {
    writer.writeBool(
      62,
      f
    );
  }
  f = message.getShowVehicleFaultsTeleop();
  if (f) {
    writer.writeBool(
      63,
      f
    );
  }
  f = message.getShowVpsInfo();
  if (f) {
    writer.writeBool(
      66,
      f
    );
  }
  f = message.getTeleopArV2();
  if (f) {
    writer.writeBool(
      67,
      f
    );
  }
  f = message.getMissionControlApi();
  if (f) {
    writer.writeBool(
      68,
      f
    );
  }
  f = message.getScheduleMissionNowApi();
  if (f) {
    writer.writeBool(
      69,
      f
    );
  }
  f = message.getSurfaceScanMissions();
  if (f) {
    writer.writeBool(
      71,
      f
    );
  }
  f = message.getShowAprilTagSettings();
  if (f) {
    writer.writeBool(
      72,
      f
    );
  }
  f = message.getShowSkydioLinkSettings();
  if (f) {
    writer.writeBool(
      73,
      f
    );
  }
  f = message.getWhatsNew();
  if (f) {
    writer.writeBool(
      76,
      f
    );
  }
  f = message.getShowRangeLimitSettings();
  if (f) {
    writer.writeBool(
      80,
      f
    );
  }
  f = message.getSiteMapper();
  if (f) {
    writer.writeBool(
      85,
      f
    );
  }
  f = message.getShowBandSelectionSettings();
  if (f) {
    writer.writeBool(
      86,
      f
    );
  }
  f = message.getFlyNowNoAutomaticRunModeSwitching();
  if (f) {
    writer.writeBool(
      87,
      f
    );
  }
  f = message.getArVideoSync();
  if (f) {
    writer.writeBool(
      88,
      f
    );
  }
  f = message.getMissionResults3dViewer();
  if (f) {
    writer.writeBool(
      89,
      f
    );
  }
  f = message.getLivestreamEgress();
  if (f) {
    writer.writeBool(
      91,
      f
    );
  }
  f = message.getAutomaticNcpgPhotogrammetry();
  if (f) {
    writer.writeBool(
      92,
      f
    );
  }
  f = message.getExecutiveActionHotkeys();
  if (f) {
    writer.writeBool(
      93,
      f
    );
  }
  f = message.getVehicleTypeBasedAxonSessions();
  if (f) {
    writer.writeBool(
      94,
      f
    );
  }
  f = message.getSiteMissions();
  if (f) {
    writer.writeBool(
      98,
      f
    );
  }
  f = message.getAlwaysShowCameraSettings();
  if (f) {
    writer.writeBool(
      99,
      f
    );
  }
  f = message.getTeleopWaypointCommands();
  if (f) {
    writer.writeBool(
      101,
      f
    );
  }
  f = message.getDroRemoteId();
  if (f) {
    writer.writeBool(
      105,
      f
    );
  }
  f = message.getPropGuardsEnablement();
  if (f) {
    writer.writeBool(
      108,
      f
    );
  }
  f = message.getRemotePilotApiAccess();
  if (f) {
    writer.writeBool(
      111,
      f
    );
  }
  f = message.getShowViewFlightDeckButton();
  if (f) {
    writer.writeBool(
      112,
      f
    );
  }
  f = message.getTwilightModeFastFlight();
  if (f) {
    writer.writeBool(
      113,
      f
    );
  }
  f = message.getStandaloneMapPage();
  if (f) {
    writer.writeBool(
      114,
      f
    );
  }
  f = message.getMissionResume();
  if (f) {
    writer.writeBool(
      115,
      f
    );
  }
  f = message.getLostCommsTimeoutSettingTeleop();
  if (f) {
    writer.writeBool(
      116,
      f
    );
  }
  f = message.getTeleopFastFlight();
  if (f) {
    writer.writeBool(
      117,
      f
    );
  }
  f = message.getAllowEditAdsbSettings();
  if (f) {
    writer.writeBool(
      119,
      f
    );
  }
  f = message.getShowSegmentedTeleopLatency();
  if (f) {
    writer.writeBool(
      120,
      f
    );
  }
  f = message.getShowTeleopIdleModal();
  if (f) {
    writer.writeBool(
      121,
      f
    );
  }
  f = message.getSkydioExtend();
  if (f) {
    writer.writeBool(
      122,
      f
    );
  }
  f = message.getSupportMenuV2();
  if (f) {
    writer.writeBool(
      123,
      f
    );
  }
  f = message.getGeofenceEnforcementInCloud();
  if (f) {
    writer.writeBool(
      125,
      f
    );
  }
  f = message.getSensorDataViewClaim();
  if (f) {
    writer.writeBool(
      126,
      f
    );
  }
  f = message.getTeleopThirdPersonView();
  if (f) {
    writer.writeBool(
      129,
      f
    );
  }
  f = message.getShowTeleopCircularBatteryWidget();
  if (f) {
    writer.writeBool(
      132,
      f
    );
  }
  f = message.getAlertManagerWithAudioInTeleop();
  if (f) {
    writer.writeBool(
      133,
      f
    );
  }
  f = message.getStructureAnnotationsApi();
  if (f) {
    writer.writeBool(
      134,
      f
    );
  }
  f = message.getMoveLivestreamTagPosition();
  if (f) {
    writer.writeBool(
      135,
      f
    );
  }
  f = message.getShowActivePilotNameIfLivestreaming();
  if (f) {
    writer.writeBool(
      136,
      f
    );
  }
  f = message.getMoveShareLivestreamButtonToIcon();
  if (f) {
    writer.writeBool(
      137,
      f
    );
  }
  f = message.getOwnOrgGroups();
  if (f) {
    writer.writeBool(
      138,
      f
    );
  }
  f = message.getTeleopCommandeeringV2();
  if (f) {
    writer.writeBool(
      139,
      f
    );
  }
  f = message.getMediaAvailableAlert();
  if (f) {
    writer.writeBool(
      140,
      f
    );
  }
  f = message.getMediaAvailableForScanAlert();
  if (f) {
    writer.writeBool(
      141,
      f
    );
  }
  f = message.getNcpgPhotogrammetryUseTensorRtFlowModel();
  if (f) {
    writer.writeBool(
      142,
      f
    );
  }
  f = message.getShowFaaMapboxStyles();
  if (f) {
    writer.writeBool(
      143,
      f
    );
  }
  f = message.getAllowSchedulingR47Missions();
  if (f) {
    writer.writeBool(
      144,
      f
    );
  }
  f = message.getSkyegressDataSessions();
  if (f) {
    writer.writeBool(
      145,
      f
    );
  }
  f = message.getSitesStructureEditor();
  if (f) {
    writer.writeBool(
      147,
      f
    );
  }
  f = message.getMultiMissionFlightTester();
  if (f) {
    writer.writeBool(
      148,
      f
    );
  }
  f = message.getTelemetryAvailableAlert();
  if (f) {
    writer.writeBool(
      149,
      f
    );
  }
  f = message.getRidScheduledApiMissions();
  if (f) {
    writer.writeBool(
      152,
      f
    );
  }
  f = message.getTeleopSettingsModalV3();
  if (f) {
    writer.writeBool(
      153,
      f
    );
  }
  f = message.getX10EoCameraControlsParityInTeleop();
  if (f) {
    writer.writeBool(
      154,
      f
    );
  }
  f = message.getNoObstacleAvoidanceInTeleop();
  if (f) {
    writer.writeBool(
      155,
      f
    );
  }
  f = message.getGranularPermissions();
  if (f) {
    writer.writeBool(
      156,
      f
    );
  }
  f = message.getLivestreamStatusChangedAlert();
  if (f) {
    writer.writeBool(
      157,
      f
    );
  }
  f = message.getLiveMissionPlanning();
  if (f) {
    writer.writeBool(
      158,
      f
    );
  }
  f = message.getFlyToLaunchPointTeleop();
  if (f) {
    writer.writeBool(
      159,
      f
    );
  }
  f = message.getShowAdditionalWifiNetworks();
  if (f) {
    writer.writeBool(
      160,
      f
    );
  }
  f = message.getWaypointCameraSettings();
  if (f) {
    writer.writeBool(
      161,
      f
    );
  }
  f = message.getVpsMapDatamodelTransitionReadFromNew();
  if (f) {
    writer.writeBool(
      162,
      f
    );
  }
  f = message.getVpsMapDatamodelTransitionWriteToBoth();
  if (f) {
    writer.writeBool(
      163,
      f
    );
  }
  f = message.getEditOrgFlightPolicy();
  if (f) {
    writer.writeBool(
      164,
      f
    );
  }
  f = message.getTeleopWheelZoom();
  if (f) {
    writer.writeBool(
      165,
      f
    );
  }
  f = message.getMultizoneSiteGeofences();
  if (f) {
    writer.writeBool(
      166,
      f
    );
  }
  f = message.getTeleopBitrateSlider();
  if (f) {
    writer.writeBool(
      168,
      f
    );
  }
  f = message.getX10ZoomAndFlashUi();
  if (f) {
    writer.writeBool(
      169,
      f
    );
  }
  f = message.getRidFeatureDisabled();
  if (f) {
    writer.writeBool(
      171,
      f
    );
  }
  f = message.getRemoveFlightFromResourceGroup();
  if (f) {
    writer.writeBool(
      173,
      f
    );
  }
  f = message.getSafeLandingPoints();
  if (f) {
    writer.writeBool(
      174,
      f
    );
  }
  f = message.getTeleopBoostMode();
  if (f) {
    writer.writeBool(
      177,
      f
    );
  }
  f = message.getTeleopCrawlMode();
  if (f) {
    writer.writeBool(
      178,
      f
    );
  }
  f = message.getEnableFlyToPoiRfd();
  if (f) {
    writer.writeBool(
      179,
      f
    );
  }
  f = message.getShowOperationsAreaMapboxStyles();
  if (f) {
    writer.writeBool(
      180,
      f
    );
  }
  f = message.getBarometerHeightMode();
  if (f) {
    writer.writeBool(
      183,
      f
    );
  }
  f = message.getSendZipFilesJobToTemporal();
  if (f) {
    writer.writeBool(
      184,
      f
    );
  }
  f = message.getGraphiqlViewer();
  if (f) {
    writer.writeBool(
      186,
      f
    );
  }
  f = message.getEnableOrbitToPoiRfd();
  if (f) {
    writer.writeBool(
      187,
      f
    );
  }
  f = message.getDisableCloudConfigSignatureVerification();
  if (f) {
    writer.writeBool(
      188,
      f
    );
  }
  f = message.getIsOnpremOrg();
  if (f) {
    writer.writeBool(
      189,
      f
    );
  }
  f = message.getEnablePointerLockDuringMissionsRfd();
  if (f) {
    writer.writeBool(
      190,
      f
    );
  }
  f = message.getEnableMapVehicleFollowiing();
  if (f) {
    writer.writeBool(
      192,
      f
    );
  }
  f = message.getReadyLinkSingleVehicle();
  if (f) {
    writer.writeBool(
      193,
      f
    );
  }
  f = message.getTeleopTelemetryHeadingAndGimbalPitch();
  if (f) {
    writer.writeBool(
      194,
      f
    );
  }
  f = message.getMediaPageKindDropdown();
  if (f) {
    writer.writeBool(
      195,
      f
    );
  }
  f = message.getTeleopActionsToolbar();
  if (f) {
    writer.writeBool(
      196,
      f
    );
  }
  f = message.getEditDockLedsOverride();
  if (f) {
    writer.writeBool(
      197,
      f
    );
  }
  f = message.getEnableDockLandingAr();
  if (f) {
    writer.writeBool(
      199,
      f
    );
  }
  f = message.getGqlDeleteMediaDirectly();
  if (f) {
    writer.writeBool(
      200,
      f
    );
  }
  f = message.getTeleopNavSourceInfoHealth();
  if (f) {
    writer.writeBool(
      201,
      f
    );
  }
  f = message.getEnableAxonMarkersRfd();
  if (f) {
    writer.writeBool(
      202,
      f
    );
  }
  f = message.getEnableFpvOnMap();
  if (f) {
    writer.writeBool(
      203,
      f
    );
  }
  f = message.getOfflineUpdatesPage();
  if (f) {
    writer.writeBool(
      204,
      f
    );
  }
  f = message.getEnableImprovedConnectivityRfd();
  if (f) {
    writer.writeBool(
      205,
      f
    );
  }
  f = message.getGimbalPitchResetShortcut();
  if (f) {
    writer.writeBool(
      206,
      f
    );
  }
  f = message.getCreateMarkersExternalApi();
  if (f) {
    writer.writeBool(
      207,
      f
    );
  }
  f = message.getR47ShowMissionPlanners();
  if (f) {
    writer.writeBool(
      208,
      f
    );
  }
  f = message.getRfdControllerMaxSpeedParity();
  if (f) {
    writer.writeBool(
      209,
      f
    );
  }
  f = message.getEnableArStreetOverlays();
  if (f) {
    writer.writeBool(
      210,
      f
    );
  }
  f = message.getX10CloudOta();
  if (f) {
    writer.writeBool(
      211,
      f
    );
  }
  f = message.getEnableCloudUiPublicSafetyLightsV2();
  if (f) {
    writer.writeBool(
      212,
      f
    );
  }
  f = message.getEnableRfdHudCompass();
  if (f) {
    writer.writeBool(
      213,
      f
    );
  }
  f = message.getEnableFlyToPoiR47();
  if (f) {
    writer.writeBool(
      214,
      f
    );
  }
  f = message.getTeleopRemoveReturnType();
  if (f) {
    writer.writeBool(
      215,
      f
    );
  }
  f = message.getEnableRfdHudAirspeedAltitude();
  if (f) {
    writer.writeBool(
      216,
      f
    );
  }
  f = message.getEnableSiteWideWeatherSensorData();
  if (f) {
    writer.writeBool(
      217,
      f
    );
  }
  f = message.getEnableSiteWideAdsbData();
  if (f) {
    writer.writeBool(
      218,
      f
    );
  }
  f = message.getEnableIncidentMarkersRfd();
  if (f) {
    writer.writeBool(
      219,
      f
    );
  }
  f = message.getMoveVehicleLaunchModalVideoFeed();
  if (f) {
    writer.writeBool(
      220,
      f
    );
  }
  f = message.getEnableLookAtFinalPoint();
  if (f) {
    writer.writeBool(
      221,
      f
    );
  }
  f = message.getEnableDockTransitPoint();
  if (f) {
    writer.writeBool(
      222,
      f
    );
  }
  f = message.getEnableDockPrecipitatonTag();
  if (f) {
    writer.writeBool(
      223,
      f
    );
  }
  f = message.getEnableViewDownloadRtkPpkFiles();
  if (f) {
    writer.writeBool(
      224,
      f
    );
  }
  f = message.getPublicVisibilityDashboard();
  if (f) {
    writer.writeBool(
      225,
      f
    );
  }
  f = message.getEnableX10AdsbRfd();
  if (f) {
    writer.writeBool(
      226,
      f
    );
  }
  f = message.getUsePathfinderFlyToPointRfd();
  if (f) {
    writer.writeBool(
      227,
      f
    );
  }
  f = message.getMediaAvailableForFlightAlert();
  if (f) {
    writer.writeBool(
      228,
      f
    );
  }
  f = message.getMediaWatermarks();
  if (f) {
    writer.writeBool(
      229,
      f
    );
  }
  f = message.getEmitRtspStreamsToDefaultEgress();
  if (f) {
    writer.writeBool(
      230,
      f
    );
  }
  f = message.getAlertsApi();
  if (f) {
    writer.writeBool(
      231,
      f
    );
  }
  f = message.getDfrStockSettings();
  if (f) {
    writer.writeBool(
      232,
      f
    );
  }
  f = message.getRemotelyApplyX10ApnSettings();
  if (f) {
    writer.writeBool(
      233,
      f
    );
  }
  f = message.getCustomer2CustomMapboxMapStyles();
  if (f) {
    writer.writeBool(
      234,
      f
    );
  }
  f = message.getEnableGimbalAttachmentUi();
  if (f) {
    writer.writeBool(
      235,
      f
    );
  }
  f = message.getRfdConnectivitySettingsTab();
  if (f) {
    writer.writeBool(
      236,
      f
    );
  }
  f = message.getMissionEditorNightAutonomy();
  if (f) {
    writer.writeBool(
      237,
      f
    );
  }
  f = message.getRfdParachuteAttachmentUi();
  if (f) {
    writer.writeBool(
      238,
      f
    );
  }
  f = message.getShowCloudSimulatorControls();
  if (f) {
    writer.writeBool(
      239,
      f
    );
  }
  f = message.getMissionSharingCustomerPortal();
  if (f) {
    writer.writeBool(
      240,
      f
    );
  }
  f = message.getDisableLinkLossAlertOnGround();
  if (f) {
    writer.writeBool(
      241,
      f
    );
  }
  f = message.getUseImprovedFlightStatusWorkflow();
  if (f) {
    writer.writeBool(
      243,
      f
    );
  }
  f = message.getEnableMarkersAr();
  if (f) {
    writer.writeBool(
      245,
      f
    );
  }
  f = message.getSsoEnabled();
  if (f) {
    writer.writeBool(
      246,
      f
    );
  }
  f = message.getRfdTelemetryStats();
  if (f) {
    writer.writeBool(
      247,
      f
    );
  }
  f = message.getShowOperationsAreaInternalMapboxStyles();
  if (f) {
    writer.writeBool(
      248,
      f
    );
  }
  f = message.getActivateStrobeLightsOnLaunchRfd();
  if (f) {
    writer.writeBool(
      249,
      f
    );
  }
  f = message.getMissionDocumentsApi();
  if (f) {
    writer.writeBool(
      250,
      f
    );
  }
  f = message.getFleetPageSdCardFormatting();
  if (f) {
    writer.writeBool(
      251,
      f
    );
  }
  f = message.getEnableMultidroneOperations();
  if (f) {
    writer.writeBool(
      252,
      f
    );
  }
  f = message.getAddLaunchPointRfd();
  if (f) {
    writer.writeBool(
      253,
      f
    );
  }
  f = message.getControlKeyDragWaypointVertical();
  if (f) {
    writer.writeBool(
      254,
      f
    );
  }
  f = message.getShowTerrainAndBldgsToggleInFleetPage();
  if (f) {
    writer.writeBool(
      255,
      f
    );
  }
  f = message.getManageFlyToPointHeightWithVehicleOverride();
  if (f) {
    writer.writeBool(
      256,
      f
    );
  }
  f = message.getAltKeyDragWaypointVertical();
  if (f) {
    writer.writeBool(
      257,
      f
    );
  }
  f = message.getCopyMarkersBetweenNypdOrgs();
  if (f) {
    writer.writeBool(
      258,
      f
    );
  }
  f = message.getShowOverlappingDockWifiChannelsFleetPage();
  if (f) {
    writer.writeBool(
      259,
      f
    );
  }
  f = message.getSsoManagement();
  if (f) {
    writer.writeBool(
      260,
      f
    );
  }
  f = message.getShowInternalMapboxStyles3();
  if (f) {
    writer.writeBool(
      261,
      f
    );
  }
  f = message.getFixedLocationRid();
  if (f) {
    writer.writeBool(
      262,
      f
    );
  }
  f = message.getAlertOnFlyToPointAltitudeChange();
  if (f) {
    writer.writeBool(
      263,
      f
    );
  }
  f = message.getTeleopPilotKeepAlive();
  if (f) {
    writer.writeBool(
      264,
      f
    );
  }
  f = message.getDownloadOnlyCloudConfigs();
  if (f) {
    writer.writeBool(
      265,
      f
    );
  }
  f = message.getDockEstopDisplay();
  if (f) {
    writer.writeBool(
      266,
      f
    );
  }
  f = message.getDockSafetySoundSettings();
  if (f) {
    writer.writeBool(
      267,
      f
    );
  }
  f = message.getDockSafetyLightSettings();
  if (f) {
    writer.writeBool(
      268,
      f
    );
  }
  f = message.getShowFlightReportMediaSummary();
  if (f) {
    writer.writeBool(
      269,
      f
    );
  }
  f = message.getAscendSolarMapCaptureDemo();
  if (f) {
    writer.writeBool(
      270,
      f
    );
  }
  f = message.getUseWorldTakeoffFrameFlyToPoint();
  if (f) {
    writer.writeBool(
      271,
      f
    );
  }
  f = message.getEnableRtkSupport();
  if (f) {
    writer.writeBool(
      272,
      f
    );
  }
  f = message.getSpeakerMicRfd();
  if (f) {
    writer.writeBool(
      273,
      f
    );
  }
  f = message.getCityCustomerMapboxMapStyles();
  if (f) {
    writer.writeBool(
      274,
      f
    );
  }
  f = message.getVehicleSettingsCosmeticsAndAccessPoint();
  if (f) {
    writer.writeBool(
      275,
      f
    );
  }
  f = message.getLinkLossAlertLevelCaution();
  if (f) {
    writer.writeBool(
      276,
      f
    );
  }
};


/**
 * optional bool vehicle_teleoperation = 3;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getVehicleTeleoperation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setVehicleTeleoperation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool automatic_file_upload_page = 4;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAutomaticFileUploadPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAutomaticFileUploadPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool vehicle_licenses_page = 5;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getVehicleLicensesPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setVehicleLicensesPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool live_streaming = 6;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getLiveStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setLiveStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool axon_media_integration = 7;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAxonMediaIntegration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAxonMediaIntegration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool missions_page = 8;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMissionsPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMissionsPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool vehicle_remote_streaming = 9;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getVehicleRemoteStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setVehicleRemoteStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool mobile_remote_streaming = 10;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMobileRemoteStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMobileRemoteStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool multi_viewer_streaming = 12;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMultiViewerStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMultiViewerStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool axon_respond_integration = 13;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAxonRespondIntegration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAxonRespondIntegration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool view_offline_settings = 14;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getViewOfflineSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setViewOfflineSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool configure_obstacle_margin = 15;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getConfigureObstacleMargin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setConfigureObstacleMargin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool dro_internal = 16;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDroInternal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDroInternal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool annotations = 21;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAnnotations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAnnotations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool cloud_inspect_page = 22;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getCloudInspectPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setCloudInspectPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool mission_media_viewer = 23;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMissionMediaViewer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMissionMediaViewer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool automatic_cloud_photogrammetry = 26;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAutomaticCloudPhotogrammetry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAutomaticCloudPhotogrammetry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool dock_location_update = 27;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDockLocationUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDockLocationUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool disable_teleop_landing_prompt = 29;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDisableTeleopLandingPrompt = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDisableTeleopLandingPrompt = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool dro_teleop_run_mission_json = 31;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDroTeleopRunMissionJson = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDroTeleopRunMissionJson = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool hide_land_now_during_rtd = 33;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getHideLandNowDuringRtd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setHideLandNowDuringRtd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool remote_video_api_access = 36;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRemoteVideoApiAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRemoteVideoApiAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool allow_twilight_flight = 38;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAllowTwilightFlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAllowTwilightFlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool allow_backtracking_rtx = 39;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAllowBacktrackingRtx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAllowBacktrackingRtx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool cloud_ota = 40;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getCloudOta = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setCloudOta = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool teleop_debug_menu = 41;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopDebugMenu = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopDebugMenu = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool qos_skybus_channel_setting = 42;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getQosSkybusChannelSetting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setQosSkybusChannelSetting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool post_refactor_mission_editor = 43;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getPostRefactorMissionEditor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setPostRefactorMissionEditor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional bool network_debugging_tools = 46;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getNetworkDebuggingTools = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 46, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setNetworkDebuggingTools = function(value) {
  return jspb.Message.setProto3BooleanField(this, 46, value);
};


/**
 * optional bool full_height_video_enabled = 47;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getFullHeightVideoEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 47, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setFullHeightVideoEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 47, value);
};


/**
 * optional bool nav_height_above_ground_enabled = 49;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getNavHeightAboveGroundEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 49, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setNavHeightAboveGroundEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 49, value);
};


/**
 * optional bool internal_theme_toggle = 51;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getInternalThemeToggle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 51, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setInternalThemeToggle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 51, value);
};


/**
 * optional bool customer_custom_mapbox_map_styles = 53;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getCustomerCustomMapboxMapStyles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 53, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setCustomerCustomMapboxMapStyles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 53, value);
};


/**
 * optional bool enable_per_device_settings = 62;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnablePerDeviceSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 62, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnablePerDeviceSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 62, value);
};


/**
 * optional bool show_vehicle_faults_teleop = 63;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowVehicleFaultsTeleop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 63, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowVehicleFaultsTeleop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 63, value);
};


/**
 * optional bool show_vps_info = 66;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowVpsInfo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 66, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowVpsInfo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 66, value);
};


/**
 * optional bool teleop_ar_v2 = 67;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopArV2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 67, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopArV2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 67, value);
};


/**
 * optional bool mission_control_api = 68;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMissionControlApi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 68, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMissionControlApi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 68, value);
};


/**
 * optional bool schedule_mission_now_api = 69;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getScheduleMissionNowApi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 69, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setScheduleMissionNowApi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 69, value);
};


/**
 * optional bool surface_scan_missions = 71;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSurfaceScanMissions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 71, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSurfaceScanMissions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 71, value);
};


/**
 * optional bool show_april_tag_settings = 72;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowAprilTagSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 72, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowAprilTagSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 72, value);
};


/**
 * optional bool show_skydio_link_settings = 73;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowSkydioLinkSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 73, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowSkydioLinkSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 73, value);
};


/**
 * optional bool whats_new = 76;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getWhatsNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 76, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setWhatsNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 76, value);
};


/**
 * optional bool show_range_limit_settings = 80;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowRangeLimitSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 80, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowRangeLimitSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 80, value);
};


/**
 * optional bool site_mapper = 85;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSiteMapper = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 85, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSiteMapper = function(value) {
  return jspb.Message.setProto3BooleanField(this, 85, value);
};


/**
 * optional bool show_band_selection_settings = 86;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowBandSelectionSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 86, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowBandSelectionSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 86, value);
};


/**
 * optional bool fly_now_no_automatic_run_mode_switching = 87;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getFlyNowNoAutomaticRunModeSwitching = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 87, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setFlyNowNoAutomaticRunModeSwitching = function(value) {
  return jspb.Message.setProto3BooleanField(this, 87, value);
};


/**
 * optional bool ar_video_sync = 88;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getArVideoSync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 88, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setArVideoSync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 88, value);
};


/**
 * optional bool mission_results_3d_viewer = 89;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMissionResults3dViewer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 89, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMissionResults3dViewer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 89, value);
};


/**
 * optional bool livestream_egress = 91;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getLivestreamEgress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 91, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setLivestreamEgress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 91, value);
};


/**
 * optional bool automatic_ncpg_photogrammetry = 92;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAutomaticNcpgPhotogrammetry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 92, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAutomaticNcpgPhotogrammetry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 92, value);
};


/**
 * optional bool executive_action_hotkeys = 93;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getExecutiveActionHotkeys = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 93, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setExecutiveActionHotkeys = function(value) {
  return jspb.Message.setProto3BooleanField(this, 93, value);
};


/**
 * optional bool vehicle_type_based_axon_sessions = 94;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getVehicleTypeBasedAxonSessions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 94, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setVehicleTypeBasedAxonSessions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 94, value);
};


/**
 * optional bool site_missions = 98;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSiteMissions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 98, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSiteMissions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 98, value);
};


/**
 * optional bool always_show_camera_settings = 99;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAlwaysShowCameraSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 99, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAlwaysShowCameraSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 99, value);
};


/**
 * optional bool teleop_waypoint_commands = 101;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopWaypointCommands = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 101, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopWaypointCommands = function(value) {
  return jspb.Message.setProto3BooleanField(this, 101, value);
};


/**
 * optional bool dro_remote_id = 105;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDroRemoteId = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 105, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDroRemoteId = function(value) {
  return jspb.Message.setProto3BooleanField(this, 105, value);
};


/**
 * optional bool prop_guards_enablement = 108;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getPropGuardsEnablement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 108, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setPropGuardsEnablement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 108, value);
};


/**
 * optional bool remote_pilot_api_access = 111;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRemotePilotApiAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 111, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRemotePilotApiAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 111, value);
};


/**
 * optional bool show_view_flight_deck_button = 112;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowViewFlightDeckButton = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 112, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowViewFlightDeckButton = function(value) {
  return jspb.Message.setProto3BooleanField(this, 112, value);
};


/**
 * optional bool twilight_mode_fast_flight = 113;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTwilightModeFastFlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 113, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTwilightModeFastFlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 113, value);
};


/**
 * optional bool standalone_map_page = 114;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getStandaloneMapPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 114, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setStandaloneMapPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 114, value);
};


/**
 * optional bool mission_resume = 115;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMissionResume = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 115, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMissionResume = function(value) {
  return jspb.Message.setProto3BooleanField(this, 115, value);
};


/**
 * optional bool lost_comms_timeout_setting_teleop = 116;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getLostCommsTimeoutSettingTeleop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 116, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setLostCommsTimeoutSettingTeleop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 116, value);
};


/**
 * optional bool teleop_fast_flight = 117;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopFastFlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 117, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopFastFlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 117, value);
};


/**
 * optional bool allow_edit_adsb_settings = 119;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAllowEditAdsbSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 119, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAllowEditAdsbSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 119, value);
};


/**
 * optional bool show_segmented_teleop_latency = 120;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowSegmentedTeleopLatency = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 120, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowSegmentedTeleopLatency = function(value) {
  return jspb.Message.setProto3BooleanField(this, 120, value);
};


/**
 * optional bool show_teleop_idle_modal = 121;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowTeleopIdleModal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 121, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowTeleopIdleModal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 121, value);
};


/**
 * optional bool skydio_extend = 122;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSkydioExtend = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 122, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSkydioExtend = function(value) {
  return jspb.Message.setProto3BooleanField(this, 122, value);
};


/**
 * optional bool support_menu_v2 = 123;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSupportMenuV2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 123, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSupportMenuV2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 123, value);
};


/**
 * optional bool geofence_enforcement_in_cloud = 125;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getGeofenceEnforcementInCloud = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 125, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setGeofenceEnforcementInCloud = function(value) {
  return jspb.Message.setProto3BooleanField(this, 125, value);
};


/**
 * optional bool sensor_data_view_claim = 126;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSensorDataViewClaim = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 126, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSensorDataViewClaim = function(value) {
  return jspb.Message.setProto3BooleanField(this, 126, value);
};


/**
 * optional bool teleop_third_person_view = 129;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopThirdPersonView = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 129, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopThirdPersonView = function(value) {
  return jspb.Message.setProto3BooleanField(this, 129, value);
};


/**
 * optional bool show_teleop_circular_battery_widget = 132;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowTeleopCircularBatteryWidget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 132, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowTeleopCircularBatteryWidget = function(value) {
  return jspb.Message.setProto3BooleanField(this, 132, value);
};


/**
 * optional bool alert_manager_with_audio_in_teleop = 133;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAlertManagerWithAudioInTeleop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 133, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAlertManagerWithAudioInTeleop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 133, value);
};


/**
 * optional bool structure_annotations_api = 134;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getStructureAnnotationsApi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 134, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setStructureAnnotationsApi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 134, value);
};


/**
 * optional bool move_livestream_tag_position = 135;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMoveLivestreamTagPosition = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 135, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMoveLivestreamTagPosition = function(value) {
  return jspb.Message.setProto3BooleanField(this, 135, value);
};


/**
 * optional bool show_active_pilot_name_if_livestreaming = 136;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowActivePilotNameIfLivestreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 136, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowActivePilotNameIfLivestreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 136, value);
};


/**
 * optional bool move_share_livestream_button_to_icon = 137;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMoveShareLivestreamButtonToIcon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 137, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMoveShareLivestreamButtonToIcon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 137, value);
};


/**
 * optional bool own_org_groups = 138;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getOwnOrgGroups = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 138, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setOwnOrgGroups = function(value) {
  return jspb.Message.setProto3BooleanField(this, 138, value);
};


/**
 * optional bool teleop_commandeering_v2 = 139;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopCommandeeringV2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 139, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopCommandeeringV2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 139, value);
};


/**
 * optional bool media_available_alert = 140;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMediaAvailableAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 140, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMediaAvailableAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 140, value);
};


/**
 * optional bool media_available_for_scan_alert = 141;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMediaAvailableForScanAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 141, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMediaAvailableForScanAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 141, value);
};


/**
 * optional bool ncpg_photogrammetry_use_tensor_rt_flow_model = 142;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getNcpgPhotogrammetryUseTensorRtFlowModel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 142, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setNcpgPhotogrammetryUseTensorRtFlowModel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 142, value);
};


/**
 * optional bool show_faa_mapbox_styles = 143;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowFaaMapboxStyles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 143, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowFaaMapboxStyles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 143, value);
};


/**
 * optional bool allow_scheduling_r47_missions = 144;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAllowSchedulingR47Missions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 144, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAllowSchedulingR47Missions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 144, value);
};


/**
 * optional bool skyegress_data_sessions = 145;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSkyegressDataSessions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 145, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSkyegressDataSessions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 145, value);
};


/**
 * optional bool sites_structure_editor = 147;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSitesStructureEditor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 147, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSitesStructureEditor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 147, value);
};


/**
 * optional bool multi_mission_flight_tester = 148;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMultiMissionFlightTester = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 148, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMultiMissionFlightTester = function(value) {
  return jspb.Message.setProto3BooleanField(this, 148, value);
};


/**
 * optional bool telemetry_available_alert = 149;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTelemetryAvailableAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 149, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTelemetryAvailableAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 149, value);
};


/**
 * optional bool rid_scheduled_api_missions = 152;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRidScheduledApiMissions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 152, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRidScheduledApiMissions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 152, value);
};


/**
 * optional bool teleop_settings_modal_v3 = 153;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopSettingsModalV3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 153, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopSettingsModalV3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 153, value);
};


/**
 * optional bool x10_eo_camera_controls_parity_in_teleop = 154;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getX10EoCameraControlsParityInTeleop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 154, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setX10EoCameraControlsParityInTeleop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 154, value);
};


/**
 * optional bool no_obstacle_avoidance_in_teleop = 155;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getNoObstacleAvoidanceInTeleop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 155, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setNoObstacleAvoidanceInTeleop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 155, value);
};


/**
 * optional bool granular_permissions = 156;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getGranularPermissions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 156, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setGranularPermissions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 156, value);
};


/**
 * optional bool livestream_status_changed_alert = 157;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getLivestreamStatusChangedAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 157, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setLivestreamStatusChangedAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 157, value);
};


/**
 * optional bool live_mission_planning = 158;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getLiveMissionPlanning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 158, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setLiveMissionPlanning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 158, value);
};


/**
 * optional bool fly_to_launch_point_teleop = 159;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getFlyToLaunchPointTeleop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 159, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setFlyToLaunchPointTeleop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 159, value);
};


/**
 * optional bool show_additional_wifi_networks = 160;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowAdditionalWifiNetworks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 160, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowAdditionalWifiNetworks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 160, value);
};


/**
 * optional bool waypoint_camera_settings = 161;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getWaypointCameraSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 161, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setWaypointCameraSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 161, value);
};


/**
 * optional bool vps_map_datamodel_transition_read_from_new = 162;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getVpsMapDatamodelTransitionReadFromNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 162, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setVpsMapDatamodelTransitionReadFromNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 162, value);
};


/**
 * optional bool vps_map_datamodel_transition_write_to_both = 163;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getVpsMapDatamodelTransitionWriteToBoth = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 163, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setVpsMapDatamodelTransitionWriteToBoth = function(value) {
  return jspb.Message.setProto3BooleanField(this, 163, value);
};


/**
 * optional bool edit_org_flight_policy = 164;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEditOrgFlightPolicy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 164, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEditOrgFlightPolicy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 164, value);
};


/**
 * optional bool teleop_wheel_zoom = 165;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopWheelZoom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 165, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopWheelZoom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 165, value);
};


/**
 * optional bool multizone_site_geofences = 166;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMultizoneSiteGeofences = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 166, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMultizoneSiteGeofences = function(value) {
  return jspb.Message.setProto3BooleanField(this, 166, value);
};


/**
 * optional bool teleop_bitrate_slider = 168;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopBitrateSlider = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 168, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopBitrateSlider = function(value) {
  return jspb.Message.setProto3BooleanField(this, 168, value);
};


/**
 * optional bool x10_zoom_and_flash_ui = 169;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getX10ZoomAndFlashUi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 169, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setX10ZoomAndFlashUi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 169, value);
};


/**
 * optional bool rid_feature_disabled = 171;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRidFeatureDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 171, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRidFeatureDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 171, value);
};


/**
 * optional bool remove_flight_from_resource_group = 173;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRemoveFlightFromResourceGroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 173, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRemoveFlightFromResourceGroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 173, value);
};


/**
 * optional bool safe_landing_points = 174;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSafeLandingPoints = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 174, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSafeLandingPoints = function(value) {
  return jspb.Message.setProto3BooleanField(this, 174, value);
};


/**
 * optional bool teleop_boost_mode = 177;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopBoostMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 177, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopBoostMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 177, value);
};


/**
 * optional bool teleop_crawl_mode = 178;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopCrawlMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 178, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopCrawlMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 178, value);
};


/**
 * optional bool enable_fly_to_poi_rfd = 179;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableFlyToPoiRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 179, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableFlyToPoiRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 179, value);
};


/**
 * optional bool show_operations_area_mapbox_styles = 180;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowOperationsAreaMapboxStyles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 180, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowOperationsAreaMapboxStyles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 180, value);
};


/**
 * optional bool barometer_height_mode = 183;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getBarometerHeightMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 183, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setBarometerHeightMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 183, value);
};


/**
 * optional bool send_zip_files_job_to_temporal = 184;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSendZipFilesJobToTemporal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 184, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSendZipFilesJobToTemporal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 184, value);
};


/**
 * optional bool graphiql_viewer = 186;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getGraphiqlViewer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 186, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setGraphiqlViewer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 186, value);
};


/**
 * optional bool enable_orbit_to_poi_rfd = 187;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableOrbitToPoiRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 187, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableOrbitToPoiRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 187, value);
};


/**
 * optional bool disable_cloud_config_signature_verification = 188;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDisableCloudConfigSignatureVerification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 188, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDisableCloudConfigSignatureVerification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 188, value);
};


/**
 * optional bool is_onprem_org = 189;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getIsOnpremOrg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 189, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setIsOnpremOrg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 189, value);
};


/**
 * optional bool enable_pointer_lock_during_missions_rfd = 190;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnablePointerLockDuringMissionsRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 190, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnablePointerLockDuringMissionsRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 190, value);
};


/**
 * optional bool enable_map_vehicle_followiing = 192;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableMapVehicleFollowiing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 192, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableMapVehicleFollowiing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 192, value);
};


/**
 * optional bool ready_link_single_vehicle = 193;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getReadyLinkSingleVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 193, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setReadyLinkSingleVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 193, value);
};


/**
 * optional bool teleop_telemetry_heading_and_gimbal_pitch = 194;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopTelemetryHeadingAndGimbalPitch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 194, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopTelemetryHeadingAndGimbalPitch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 194, value);
};


/**
 * optional bool media_page_kind_dropdown = 195;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMediaPageKindDropdown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 195, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMediaPageKindDropdown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 195, value);
};


/**
 * optional bool teleop_actions_toolbar = 196;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopActionsToolbar = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 196, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopActionsToolbar = function(value) {
  return jspb.Message.setProto3BooleanField(this, 196, value);
};


/**
 * optional bool edit_dock_leds_override = 197;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEditDockLedsOverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 197, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEditDockLedsOverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 197, value);
};


/**
 * optional bool enable_dock_landing_ar = 199;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableDockLandingAr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 199, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableDockLandingAr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 199, value);
};


/**
 * optional bool gql_delete_media_directly = 200;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getGqlDeleteMediaDirectly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 200, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setGqlDeleteMediaDirectly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 200, value);
};


/**
 * optional bool teleop_nav_source_info_health = 201;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopNavSourceInfoHealth = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 201, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopNavSourceInfoHealth = function(value) {
  return jspb.Message.setProto3BooleanField(this, 201, value);
};


/**
 * optional bool enable_axon_markers_rfd = 202;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableAxonMarkersRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 202, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableAxonMarkersRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 202, value);
};


/**
 * optional bool enable_fpv_on_map = 203;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableFpvOnMap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 203, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableFpvOnMap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 203, value);
};


/**
 * optional bool offline_updates_page = 204;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getOfflineUpdatesPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 204, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setOfflineUpdatesPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 204, value);
};


/**
 * optional bool enable_improved_connectivity_rfd = 205;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableImprovedConnectivityRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 205, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableImprovedConnectivityRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 205, value);
};


/**
 * optional bool gimbal_pitch_reset_shortcut = 206;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getGimbalPitchResetShortcut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 206, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setGimbalPitchResetShortcut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 206, value);
};


/**
 * optional bool create_markers_external_api = 207;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getCreateMarkersExternalApi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 207, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setCreateMarkersExternalApi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 207, value);
};


/**
 * optional bool r47_show_mission_planners = 208;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getR47ShowMissionPlanners = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 208, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setR47ShowMissionPlanners = function(value) {
  return jspb.Message.setProto3BooleanField(this, 208, value);
};


/**
 * optional bool rfd_controller_max_speed_parity = 209;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRfdControllerMaxSpeedParity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 209, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRfdControllerMaxSpeedParity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 209, value);
};


/**
 * optional bool enable_ar_street_overlays = 210;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableArStreetOverlays = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 210, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableArStreetOverlays = function(value) {
  return jspb.Message.setProto3BooleanField(this, 210, value);
};


/**
 * optional bool x10_cloud_ota = 211;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getX10CloudOta = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 211, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setX10CloudOta = function(value) {
  return jspb.Message.setProto3BooleanField(this, 211, value);
};


/**
 * optional bool enable_cloud_ui_public_safety_lights_v2 = 212;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableCloudUiPublicSafetyLightsV2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 212, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableCloudUiPublicSafetyLightsV2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 212, value);
};


/**
 * optional bool enable_rfd_hud_compass = 213;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableRfdHudCompass = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 213, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableRfdHudCompass = function(value) {
  return jspb.Message.setProto3BooleanField(this, 213, value);
};


/**
 * optional bool enable_fly_to_poi_r47 = 214;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableFlyToPoiR47 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 214, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableFlyToPoiR47 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 214, value);
};


/**
 * optional bool teleop_remove_return_type = 215;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopRemoveReturnType = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 215, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopRemoveReturnType = function(value) {
  return jspb.Message.setProto3BooleanField(this, 215, value);
};


/**
 * optional bool enable_rfd_hud_airspeed_altitude = 216;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableRfdHudAirspeedAltitude = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 216, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableRfdHudAirspeedAltitude = function(value) {
  return jspb.Message.setProto3BooleanField(this, 216, value);
};


/**
 * optional bool enable_site_wide_weather_sensor_data = 217;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableSiteWideWeatherSensorData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 217, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableSiteWideWeatherSensorData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 217, value);
};


/**
 * optional bool enable_site_wide_adsb_data = 218;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableSiteWideAdsbData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 218, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableSiteWideAdsbData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 218, value);
};


/**
 * optional bool enable_incident_markers_rfd = 219;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableIncidentMarkersRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 219, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableIncidentMarkersRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 219, value);
};


/**
 * optional bool move_vehicle_launch_modal_video_feed = 220;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMoveVehicleLaunchModalVideoFeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 220, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMoveVehicleLaunchModalVideoFeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 220, value);
};


/**
 * optional bool enable_look_at_final_point = 221;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableLookAtFinalPoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 221, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableLookAtFinalPoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 221, value);
};


/**
 * optional bool enable_dock_transit_point = 222;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableDockTransitPoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 222, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableDockTransitPoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 222, value);
};


/**
 * optional bool enable_dock_precipitaton_tag = 223;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableDockPrecipitatonTag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 223, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableDockPrecipitatonTag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 223, value);
};


/**
 * optional bool enable_view_download_rtk_ppk_files = 224;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableViewDownloadRtkPpkFiles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 224, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableViewDownloadRtkPpkFiles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 224, value);
};


/**
 * optional bool public_visibility_dashboard = 225;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getPublicVisibilityDashboard = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 225, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setPublicVisibilityDashboard = function(value) {
  return jspb.Message.setProto3BooleanField(this, 225, value);
};


/**
 * optional bool enable_x10_adsb_rfd = 226;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableX10AdsbRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 226, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableX10AdsbRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 226, value);
};


/**
 * optional bool use_pathfinder_fly_to_point_rfd = 227;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getUsePathfinderFlyToPointRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 227, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setUsePathfinderFlyToPointRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 227, value);
};


/**
 * optional bool media_available_for_flight_alert = 228;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMediaAvailableForFlightAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 228, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMediaAvailableForFlightAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 228, value);
};


/**
 * optional bool media_watermarks = 229;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMediaWatermarks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 229, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMediaWatermarks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 229, value);
};


/**
 * optional bool emit_rtsp_streams_to_default_egress = 230;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEmitRtspStreamsToDefaultEgress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 230, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEmitRtspStreamsToDefaultEgress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 230, value);
};


/**
 * optional bool alerts_api = 231;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAlertsApi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 231, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAlertsApi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 231, value);
};


/**
 * optional bool dfr_stock_settings = 232;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDfrStockSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 232, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDfrStockSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 232, value);
};


/**
 * optional bool remotely_apply_x10_apn_settings = 233;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRemotelyApplyX10ApnSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 233, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRemotelyApplyX10ApnSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 233, value);
};


/**
 * optional bool customer_2_custom_mapbox_map_styles = 234;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getCustomer2CustomMapboxMapStyles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 234, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setCustomer2CustomMapboxMapStyles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 234, value);
};


/**
 * optional bool enable_gimbal_attachment_ui = 235;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableGimbalAttachmentUi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 235, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableGimbalAttachmentUi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 235, value);
};


/**
 * optional bool rfd_connectivity_settings_tab = 236;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRfdConnectivitySettingsTab = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 236, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRfdConnectivitySettingsTab = function(value) {
  return jspb.Message.setProto3BooleanField(this, 236, value);
};


/**
 * optional bool mission_editor_night_autonomy = 237;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMissionEditorNightAutonomy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 237, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMissionEditorNightAutonomy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 237, value);
};


/**
 * optional bool rfd_parachute_attachment_ui = 238;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRfdParachuteAttachmentUi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 238, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRfdParachuteAttachmentUi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 238, value);
};


/**
 * optional bool show_cloud_simulator_controls = 239;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowCloudSimulatorControls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 239, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowCloudSimulatorControls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 239, value);
};


/**
 * optional bool mission_sharing_customer_portal = 240;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMissionSharingCustomerPortal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 240, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMissionSharingCustomerPortal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 240, value);
};


/**
 * optional bool disable_link_loss_alert_on_ground = 241;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDisableLinkLossAlertOnGround = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 241, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDisableLinkLossAlertOnGround = function(value) {
  return jspb.Message.setProto3BooleanField(this, 241, value);
};


/**
 * optional bool use_improved_flight_status_workflow = 243;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getUseImprovedFlightStatusWorkflow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 243, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setUseImprovedFlightStatusWorkflow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 243, value);
};


/**
 * optional bool enable_markers_ar = 245;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableMarkersAr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 245, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableMarkersAr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 245, value);
};


/**
 * optional bool sso_enabled = 246;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSsoEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 246, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSsoEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 246, value);
};


/**
 * optional bool rfd_telemetry_stats = 247;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getRfdTelemetryStats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 247, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setRfdTelemetryStats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 247, value);
};


/**
 * optional bool show_operations_area_internal_mapbox_styles = 248;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowOperationsAreaInternalMapboxStyles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 248, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowOperationsAreaInternalMapboxStyles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 248, value);
};


/**
 * optional bool activate_strobe_lights_on_launch_rfd = 249;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getActivateStrobeLightsOnLaunchRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 249, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setActivateStrobeLightsOnLaunchRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 249, value);
};


/**
 * optional bool mission_documents_api = 250;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getMissionDocumentsApi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 250, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setMissionDocumentsApi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 250, value);
};


/**
 * optional bool fleet_page_sd_card_formatting = 251;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getFleetPageSdCardFormatting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 251, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setFleetPageSdCardFormatting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 251, value);
};


/**
 * optional bool enable_multidrone_operations = 252;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableMultidroneOperations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 252, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableMultidroneOperations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 252, value);
};


/**
 * optional bool add_launch_point_rfd = 253;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAddLaunchPointRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 253, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAddLaunchPointRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 253, value);
};


/**
 * optional bool control_key_drag_waypoint_vertical = 254;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getControlKeyDragWaypointVertical = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 254, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setControlKeyDragWaypointVertical = function(value) {
  return jspb.Message.setProto3BooleanField(this, 254, value);
};


/**
 * optional bool show_terrain_and_bldgs_toggle_in_fleet_page = 255;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowTerrainAndBldgsToggleInFleetPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 255, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowTerrainAndBldgsToggleInFleetPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 255, value);
};


/**
 * optional bool manage_fly_to_point_height_with_vehicle_override = 256;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getManageFlyToPointHeightWithVehicleOverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 256, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setManageFlyToPointHeightWithVehicleOverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 256, value);
};


/**
 * optional bool alt_key_drag_waypoint_vertical = 257;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAltKeyDragWaypointVertical = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 257, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAltKeyDragWaypointVertical = function(value) {
  return jspb.Message.setProto3BooleanField(this, 257, value);
};


/**
 * optional bool copy_markers_between_nypd_orgs = 258;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getCopyMarkersBetweenNypdOrgs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 258, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setCopyMarkersBetweenNypdOrgs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 258, value);
};


/**
 * optional bool show_overlapping_dock_wifi_channels_fleet_page = 259;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowOverlappingDockWifiChannelsFleetPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 259, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowOverlappingDockWifiChannelsFleetPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 259, value);
};


/**
 * optional bool sso_management = 260;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSsoManagement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 260, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSsoManagement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 260, value);
};


/**
 * optional bool show_internal_mapbox_styles_3 = 261;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowInternalMapboxStyles3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 261, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowInternalMapboxStyles3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 261, value);
};


/**
 * optional bool fixed_location_rid = 262;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getFixedLocationRid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 262, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setFixedLocationRid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 262, value);
};


/**
 * optional bool alert_on_fly_to_point_altitude_change = 263;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAlertOnFlyToPointAltitudeChange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 263, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAlertOnFlyToPointAltitudeChange = function(value) {
  return jspb.Message.setProto3BooleanField(this, 263, value);
};


/**
 * optional bool teleop_pilot_keep_alive = 264;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getTeleopPilotKeepAlive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 264, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setTeleopPilotKeepAlive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 264, value);
};


/**
 * optional bool download_only_cloud_configs = 265;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDownloadOnlyCloudConfigs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 265, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDownloadOnlyCloudConfigs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 265, value);
};


/**
 * optional bool dock_estop_display = 266;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDockEstopDisplay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 266, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDockEstopDisplay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 266, value);
};


/**
 * optional bool dock_safety_sound_settings = 267;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDockSafetySoundSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 267, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDockSafetySoundSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 267, value);
};


/**
 * optional bool dock_safety_light_settings = 268;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getDockSafetyLightSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 268, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setDockSafetyLightSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 268, value);
};


/**
 * optional bool show_flight_report_media_summary = 269;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getShowFlightReportMediaSummary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 269, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setShowFlightReportMediaSummary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 269, value);
};


/**
 * optional bool ascend_solar_map_capture_demo = 270;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getAscendSolarMapCaptureDemo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 270, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setAscendSolarMapCaptureDemo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 270, value);
};


/**
 * optional bool use_world_takeoff_frame_fly_to_point = 271;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getUseWorldTakeoffFrameFlyToPoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 271, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setUseWorldTakeoffFrameFlyToPoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 271, value);
};


/**
 * optional bool enable_rtk_support = 272;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getEnableRtkSupport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 272, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setEnableRtkSupport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 272, value);
};


/**
 * optional bool speaker_mic_rfd = 273;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getSpeakerMicRfd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 273, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setSpeakerMicRfd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 273, value);
};


/**
 * optional bool city_customer_mapbox_map_styles = 274;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getCityCustomerMapboxMapStyles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 274, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setCityCustomerMapboxMapStyles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 274, value);
};


/**
 * optional bool vehicle_settings_cosmetics_and_access_point = 275;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getVehicleSettingsCosmeticsAndAccessPoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 275, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setVehicleSettingsCosmeticsAndAccessPoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 275, value);
};


/**
 * optional bool link_loss_alert_level_caution = 276;
 * @return {boolean}
 */
proto.cloud_api.FeatureFlags.prototype.getLinkLossAlertLevelCaution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 276, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FeatureFlags} returns this
 */
proto.cloud_api.FeatureFlags.prototype.setLinkLossAlertLevelCaution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 276, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FeatureFlagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FeatureFlagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FeatureFlagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FeatureFlagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
organizationPermission: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FeatureFlagRequest}
 */
proto.cloud_api.FeatureFlagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FeatureFlagRequest;
  return proto.cloud_api.FeatureFlagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FeatureFlagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FeatureFlagRequest}
 */
proto.cloud_api.FeatureFlagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (reader.readEnum());
      msg.setOrganizationPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FeatureFlagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FeatureFlagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FeatureFlagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FeatureFlagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.cloud_api.FeatureFlagRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FeatureFlagRequest} returns this
 */
proto.cloud_api.FeatureFlagRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgPermission.PermEnum organization_permission = 2;
 * @return {!proto.cloud_api.OrgPermission.PermEnum}
 */
proto.cloud_api.FeatureFlagRequest.prototype.getOrganizationPermission = function() {
  return /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.OrgPermission.PermEnum} value
 * @return {!proto.cloud_api.FeatureFlagRequest} returns this
 */
proto.cloud_api.FeatureFlagRequest.prototype.setOrganizationPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.cloud_api);
