import { findKey } from "lodash";

import { parseSearchState, makeQuerySerializable } from "@skydio/uri_util/src/query";
import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";

import { TokensQueryState, TokensQueryParams } from "./types";

export const tokensQueryInitialState: TokensQueryState = {
  uuid: "",
  name: "",
  enabled: true,
  organizationId: "",
  scope: enums_pb.ApiTokenScope.ScopeEnum.UNKNOWN,
};

export const parseTokensQueryState = (searchString: string) =>
  parseSearchState(searchString, tokensQueryInitialState, (params: TokensQueryParams) => params);

export const prepareTokensRequest = (state: TokensQueryState) =>
  makeQuerySerializable(state, tokensQueryInitialState, state => state);

export const getTokenScopeName = (scope: enums_pb.ApiTokenScope.ScopeEnum) =>
  findKey(enums_pb.ApiTokenScope.ScopeEnum, o => o === scope);
