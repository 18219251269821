import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";
import {
  fetchReleases,
  fetchRelease,
  updateRelease,
  fetchReleaseFiles,
  updateOverride,
  updateDeviceOverride,
} from "./asyncThunks";
import {
  isReleasesRequestActive,
  isReleaseRequestActive,
  getReleaseModifications,
  isReleaseFilesRequestActive,
  getReleasesQueryTimeout,
} from "./selectors";
import { releaseActions, ReleasesState } from "./slice";
import { prepareReleasesRequest } from "./utils";

import { ThunkCreator, AsyncThunkCreator } from "@skydio/redux_util/src";
import { ReleaseFilesQueryState, ReleasesQueryState } from "./types";

const { clearReleasesQueryTimeout, setReleasesQueryTimeout } = releaseActions;

export const updateReleasesQuery: ThunkCreator<ReleasesState, [ReleasesQueryState]> =
  fields => (dispatch, getState) => {
    const state = getState();

    clearTimeout(getReleasesQueryTimeout(state)!);
    dispatch(clearReleasesQueryTimeout());

    const newArgs = prepareReleasesRequest(fields);
    const timeout = window?.setTimeout(() => dispatch(fetchReleases(newArgs)), QUERY_CHANGE_DELAY);
    dispatch(setReleasesQueryTimeout(timeout));

    dispatch(setSearchParams(newArgs));
  };

export const updateReleaseFilesQuery: ThunkCreator<ReleasesState, [ReleaseFilesQueryState]> =
  fields => dispatch => {
    dispatch(fetchReleaseFiles({ toReleaseKey: fields.toReleaseKey, args: fields }));
  };

export const fetchReleasesIfAble: AsyncThunkCreator<ReleasesState, [ReleasesQueryState]> =
  fields => async (dispatch, getState) => {
    const state = getState();
    if (!isReleasesRequestActive(state)) {
      dispatch(fetchReleases(prepareReleasesRequest(fields)));
    }
  };

export const fetchReleaseIfAble: AsyncThunkCreator<ReleasesState, [string]> =
  key => async (dispatch, getState) => {
    if (!isReleaseRequestActive(getState(), key)) {
      dispatch(fetchRelease(key));
    }
  };

export const updateReleaseIfAble: AsyncThunkCreator<ReleasesState, [string]> =
  key => async (dispatch, getState) => {
    const state = getState();
    if (!isReleaseRequestActive(state, key)) {
      dispatch(updateRelease({ key, update: getReleaseModifications(state, key) }));
    }
  };

export const fetchReleaseFilesIfAble: AsyncThunkCreator<ReleasesState, [ReleaseFilesQueryState]> =
  fields => async (dispatch, getState) => {
    if (!isReleaseFilesRequestActive(getState(), fields.toReleaseKey)) {
      dispatch(fetchReleaseFiles({ toReleaseKey: fields.toReleaseKey, args: fields }));
    }
  };

export const updateUserReleaseOverride: AsyncThunkCreator<
  ReleasesState,
  [string, string, number?, boolean?]
> =
  (email, key, validDuration = 0, removeOverride = false) =>
  async (dispatch, getState) => {
    if (!isReleaseRequestActive(getState(), key)) {
      dispatch(updateOverride({ key, email, validDuration, removeOverride }));
    }
  };

export const updateDeviceReleaseOverride: AsyncThunkCreator<
  ReleasesState,
  [string, string, number?, boolean?]
> =
  (deviceId, key, validDuration = 0, removeOverride = false) =>
  async (dispatch, getState) => {
    if (!isReleaseRequestActive(getState(), key)) {
      dispatch(updateDeviceOverride({ key, deviceId, validDuration, removeOverride }));
    }
  };
