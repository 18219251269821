import { tv } from "tailwind-variants";

import type { VariantProps } from "tailwind-variants";
import type { SlotsToClasses } from "../../utils/types";

export const alertVariants = tv({
  slots: {
    base: "relative flex justify-between rounded-md border border-solid",
    icon: "absolute",
    content: "flex gap-2",
    actions: "-my-1 flex gap-1.5",
    title: "",
    description: "leading-none",
    // TODO(sam): fix button background when fixing ghost button variant
    closeButton: "absolute hover:bg-transparent",
  },
  variants: {
    status: {
      default: {
        base: "border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900",
        icon: "text-gray-900 dark:text-white",
      },
      info: {
        base: "border-blue",
        icon: "text-blue",
      },
      warning: {
        base: "border-yellow",
        icon: "text-yellow",
      },
      success: {
        base: "border-green",
        icon: "text-green",
      },
      error: {
        base: "border-red-400",
        icon: "text-red-400",
      },
    },
    variant: {
      default: {},
      light: {
        base: "bg-white dark:bg-gray-900",
      },
    },
    layout: {
      vertical: {
        content: "flex-col items-start",
      },
      horizontal: {
        content: "flex-row items-center",
      },
    },
    size: {
      sm: {
        base: "min-h-[34px] p-2",
        title: "inline-flex items-center leading-none",
        icon: "left-2 top-2 h-4 w-4 text-base leading-none",
        closeButton: "right-1 top-0.5",
      },
      md: {
        base: "min-h-[46px] p-3",
        title: "leading-tight",
        icon: "left-3 top-3 h-5 w-5 text-xl leading-none",
        closeButton: "right-2 top-2",
      },
    },
    hasIcon: {
      true: {},
      false: {},
    },
    hasCloseButton: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      size: "sm",
      hasIcon: true,
      className: {
        base: "pl-8",
      },
    },
    {
      size: "md",
      hasIcon: true,
      className: {
        base: "pl-11",
      },
    },
    {
      size: "sm",
      hasCloseButton: true,
      className: {
        base: "pr-10",
      },
    },
    {
      size: "md",
      hasCloseButton: true,
      className: {
        base: "pr-11",
      },
    },
    {
      variant: "default",
      status: "info",
      className: {
        base: "bg-blue/10",
      },
    },
    {
      variant: "light",
      status: "info",
      className: {
        title: "text-blue dark:text-blue",
        description: "text-blue dark:text-blue",
      },
    },
    {
      variant: "default",
      status: "warning",
      className: {
        base: "bg-yellow/10",
      },
    },
    {
      variant: "light",
      status: "warning",
      className: {
        title: "text-yellow dark:text-yellow",
        description: "text-yellow dark:text-yellow",
      },
    },
    {
      variant: "default",
      status: "error",
      className: {
        base: "bg-red/10",
      },
    },
    {
      variant: "light",
      status: "error",
      className: {
        title: "text-red-400 dark:text-red-400",
        description: "text-red-400 dark:text-red-400",
      },
    },
    {
      variant: "default",
      status: "success",
      className: {
        base: "bg-green/10",
      },
    },
    {
      variant: "light",
      status: "success",
      className: {
        title: "text-green dark:text-green",
        description: "text-green dark:text-green",
      },
    },
  ],
  defaultVariants: {
    variant: "default",
    status: "default",
    size: "md",
    layout: "vertical",
  },
});

export type AlertVariantProps = Omit<
  VariantProps<typeof alertVariants>,
  "hasIcon" | "hasCloseButton"
>;
export type AlertSlots = ReturnType<typeof alertVariants>;
export type AlertClassNames = SlotsToClasses<keyof AlertSlots>;
