import useResizeObserver from "@react-hook/resize-observer";
import { useListState } from "@react-stately/list";
import { forwardRef, useLayoutEffect, useMemo, useRef, useState } from "react";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { OverflowItem, OverflowItemInternal, overflowItemVariants } from "./OverflowItem";

import type { CollectionBase, Key, Node } from "@react-types/shared";
import type {
  FC,
  ForwardedRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";

export interface OverflowProps<T>
  extends Omit<HTMLAttributes<HTMLDivElement>, "children">,
    CollectionBase<T> {
  /* Callback to render the "rest" node at the end when items overflow */
  renderRest: (omittedItems: Node<T>[]) => ReactNode;
  /* Optional content that will always render at the end of the container */
  endContent?: ReactNode;
}

const OverflowImpl = <T extends object>(
  { className, renderRest, endContent, ...props }: OverflowProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const domRef = useDomRef(ref);

  const listState = useListState(props);

  const [containerWidth, setContainerWidth] = useState(0);

  const [itemWidths, setItemWidths] = useState(new Map<Key, number>());

  const [prevRestWidth, setPrevRestWidth] = useState(0);
  const [restWidth, setRestWidth] = useState(0);

  // Use the maximum of these to avoid flickering
  const mergedRestWidth = Math.max(prevRestWidth, restWidth);

  const [endContentWidth, setEndContentWidth] = useState(0);
  const [endContentStartPosition, setEndContentStartPosition] = useState<number | null>(null);

  const [displayCount, setDisplayCount] = useState(0);

  const items = useMemo(() => [...listState.collection], [listState.collection]);

  const omittedItems = useMemo(() => items.slice(displayCount), [items, displayCount]);

  const [readyToShowRest, setReadyToShowRest] = useState(false);

  const showRest = readyToShowRest && !!omittedItems.length;

  const registerWidth = (key: Key, width: number | null) => {
    setItemWidths(prev => {
      const next = new Map(prev);
      if (width === null) {
        next.delete(key);
      } else {
        next.set(key, width);
      }
      return next;
    });
  };

  const registerRestWidth = (width: number) => {
    setRestWidth(width);
    setPrevRestWidth(restWidth);
  };

  const registerEndContentWidth = (width: number) => {
    setEndContentWidth(width);
  };

  useResizeObserver(domRef, ({ contentRect }) => {
    setContainerWidth(contentRect.width);
  });

  // TODO(sam): Fix flickering when new items are added
  useLayoutEffect(() => {
    if (!items.length) {
      setEndContentStartPosition(null);
    }

    const updateDisplayCount = (
      newDisplayCount: number,
      newEndContentStartPosition?: number | null,
      ready = true
    ) => {
      setDisplayCount(newDisplayCount);
      if (ready) {
        setReadyToShowRest(newDisplayCount < items.length);
      }

      if (newEndContentStartPosition !== undefined) {
        setEndContentStartPosition(newEndContentStartPosition);
      }
    };

    if (containerWidth && mergedRestWidth && items.length) {
      let totalWidth = endContentWidth;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const lastIndex = items.length - 1;

        const currentItemWidth = itemWidths.get(item.key);
        if (currentItemWidth === undefined) {
          // Not ready, bail out
          updateDisplayCount(i - 1, undefined, false);
          break;
        }
        totalWidth += currentItemWidth;

        if (items.length === 1 && totalWidth <= containerWidth) {
          // Only one item and it fits so we're good
          updateDisplayCount(items.length, null);
          break;
        } else if (
          i === lastIndex - 1 &&
          totalWidth + itemWidths.get(items[lastIndex].key)! <= containerWidth
        ) {
          // Second to last item, if we can also fit the last item then we're good
          updateDisplayCount(items.length, null);
          break;
        } else if (totalWidth + mergedRestWidth > containerWidth) {
          // We don't have enough space to fit this item, so update the count to only include the
          // previous items
          updateDisplayCount(i, totalWidth - currentItemWidth - endContentWidth + restWidth);
          break;
        }
      }

      if (endContent && itemWidths.get(items[0].key)! + endContentWidth > containerWidth) {
        setEndContentStartPosition(null);
      }
    }
  }, [containerWidth, mergedRestWidth, endContentWidth, itemWidths, items]);

  return (
    <div ref={domRef} {...props} className={cn(className, "relative flex overflow-hidden")}>
      {[...listState.collection].map((item, index) => (
        <OverflowItemInternal
          key={item.key}
          item={item}
          state={listState}
          registerWidth={width => registerWidth(item.key, width)}
          hidden={index >= displayCount}
          order={index}
          {...item.props}
        />
      ))}
      <OverflowRest
        order={showRest ? displayCount : Number.MAX_SAFE_INTEGER}
        hidden={!showRest}
        registerWidth={registerRestWidth}
      >
        {renderRest(omittedItems)}
      </OverflowRest>
      {endContent && (
        <EndContentWrapper
          order={displayCount}
          startPosition={endContentStartPosition}
          registerWidth={registerEndContentWidth}
        >
          {endContent}
        </EndContentWrapper>
      )}
    </div>
  );
};

const OverflowWrapped = forwardRef(OverflowImpl) as <T = object>(
  props: OverflowProps<T> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReactElement;

/**
 * This component renders a horizontal list of items, overflowing into a "rest" item if there are
 * too many items to fit in the container.
 */
export const Overflow = OverflowWrapped as typeof OverflowWrapped & {
  displayName: string;
  Item: typeof OverflowItem;
};

Overflow.displayName = "Overflow";
Overflow.Item = OverflowItem;

interface OverflowRestProps<T> extends PropsWithChildren {
  order: number;
  hidden: boolean;
  registerWidth: (width: number) => void;
}

const OverflowRest = <T extends object>({
  order,
  hidden,
  registerWidth,
  children,
}: OverflowRestProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  useResizeObserver(ref, ({ contentRect }) => {
    registerWidth(contentRect.width);
  });

  return (
    <div ref={ref} style={{ order }} className={overflowItemVariants({ hidden })}>
      {children}
    </div>
  );
};

type EndContentWrapperProps = PropsWithChildren<{
  startPosition: number | null;
  order: number;
  registerWidth: (width: number) => void;
}>;

const EndContentWrapper: FC<EndContentWrapperProps> = ({
  startPosition,
  order,
  registerWidth,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useResizeObserver(ref, ({ contentRect }) => {
    registerWidth(contentRect.width);
  });

  return (
    <div
      ref={ref}
      style={
        startPosition !== null
          ? { order, left: startPosition, top: 0, bottom: 0, position: "absolute" }
          : { order }
      }
    >
      {children}
    </div>
  );
};
