import { createSlice, combineReducers, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  createRequestReducerFromThunk,
  createIndexedRequestReducerFromThunk,
  reduceReducers,
} from "@skydio/redux_util/src";

import { fetchVehicles, fetchVehicle, updateVehicle } from "./asyncThunks";

import { VehiclesPrimaryState, Vehicle, APIVehicle, VehiclesMap } from "./types";
import { APIPagination } from "../pagination/types";
import { initialDeviceReleaseOverride } from "../releases/slice";
import { DockVehiclePairing } from "@skydio/pbtypes/pbtypes/vehicle/dock_client/dock_client_pb";

const defaultPagination: APIPagination = {
  maxPerPage: 25,
  currentPage: 1,
  totalPages: 1,
};

const initialState: VehiclesPrimaryState = {
  vehicles: {},
  dispatchTimeout: null,
  requestedIds: [],
  pagination: defaultPagination,
};

export const vehicleInitialState: Vehicle = {
  displayName: "",
  vehicleId: "",
  vehicleType: 0,
  skydioSerial: "",
  releaseKey: "",
  disabled: false,
  userEmails: [],
  organizationId: "",
  organizationFlightCount: 0,
  teleopEnabled: false,
  remoteIdExempt: false,
  imei: "",
  iccid: "",
  linkedDockId: "",
  linkedAccessPointSerial: "",
  linkedDockType: DockVehiclePairing.DockType.UNKNOWN,
  scheduledMissionsDisabled: false,
  releaseOverride: initialDeviceReleaseOverride,
  isSimulator: false,
};

const updateVehicleState = (state: VehiclesMap, vehicle: APIVehicle) => {
  if (!(vehicle.vehicleId in state)) {
    state[vehicle.vehicleId] = { ...vehicleInitialState };
  }
  Object.assign(
    state[vehicle.vehicleId]!,
    _.pick(vehicle, [
      "vehicleId",
      "vehicleType",
      "skydioSerial",
      "disabled",
      "organizationId",
      "teleopEnabled",
      "remoteIdExempt",
      "imei",
      "iccid",
      "releaseOverride",
    ]),
    {
      releaseKey: vehicle.release ? vehicle.release.releaseKey : "",
      userEmails: vehicle.userEmailsList,
      organizationFlightCount:
        vehicle.organizationFlightCount || state[vehicle.vehicleId]!.organizationFlightCount,
    }
  );
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setVehiclesQueryTimeout(state, { payload }: PayloadAction<number>) {
      state.dispatchTimeout = payload;
    },
    clearVehiclesQueryTimeout(state) {
      state.dispatchTimeout = null;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchVehicles.fulfilled, (state, { payload }) => {
        payload.vehiclesList.forEach(vehicle => {
          updateVehicleState(state.vehicles, vehicle);
        });
        state.requestedIds = payload.vehiclesList.map(({ vehicleId }) => vehicleId);
        state.pagination = {
          maxPerPage: payload.pagination!.maxPerPage,
          currentPage: payload.pagination!.currentPage,
          totalPages: payload.pagination!.totalPages,
        };
      })
      .addCase(fetchVehicles.rejected, state => {
        state.requestedIds = [];
      })
      .addCase(fetchVehicle.fulfilled, (state, { payload }) => {
        updateVehicleState(state.vehicles, payload);
      })
      .addCase(updateVehicle.fulfilled, (state, { payload }) => {
        updateVehicleState(state.vehicles, payload);
      }),
});

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    vehicles: createRequestReducerFromThunk(fetchVehicles),
    vehicle: reduceReducers(
      createIndexedRequestReducerFromThunk(fetchVehicle),
      createIndexedRequestReducerFromThunk(updateVehicle, "vehicleId")
    ),
  }),
});
export type VehiclesState = ReturnType<typeof reducer>;

export { actions as vehicleActions };
export default reducer;
