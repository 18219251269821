import { Moment } from "moment";

import simulator_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/simulator_pb";

import { Overwrite } from "@skydio/common_util/src";
import { SearchableState } from "../../../common/types";
import { APIPagination, PaginationQueryState, StringifyPagination } from "../pagination/types";

export const NEW_SIM_PLACEHOLDER_ID = "NEW_SIM_PLACEHOLDER_ID";

export type APISimulator = simulator_pb.Simulator.AsObject;

export type SimulatorUpdate = Partial<Omit<APISimulator, "uuid" | "lastPooledAssignmentTime">>;

export interface NewSimulator {
  name: string;
  vehicleId: string;
  hostname: string;
  host: string;
  httpPort: number;
  udpPort: number;
  defaultRunmode: string;
  region: string;
  pooled: boolean;
  imageTag: string;
}
export interface Simulator
  extends Omit<
    APISimulator,
    "lastPooledAssignmentTime" | "region" | "userEmailsList" | "credentials"
  > {
  lastPooledAssignmentTime: Moment | null;
  region: string | null;
  userEmails: string[];
  modified: SimulatorUpdate;
}

export interface SimulatorsMap {
  [uuid: string]: Simulator;
}

export interface SimulatorsPrimaryState extends SearchableState {
  simulators: SimulatorsMap;
  pagination: APIPagination;
}

export interface SimulatorsQueryState extends PaginationQueryState {
  userEmail: string;
  host: string;
  region: string;
  pooled?: boolean;
  limit?: number;
}

export interface SimulatorsRequest extends Partial<Omit<SimulatorsQueryState, "pooled">> {
  pooled?: string;
}

export type SimulatorsQueryParams = Overwrite<
  StringifyPagination<SimulatorsRequest>,
  {
    limit?: string;
  }
>;

export interface SimulatorRequest {
  path: { uuid: string };
}
export interface AddOrUpdateSimulatorRequest extends SimulatorRequest {
  protobuf?: simulator_pb.UpdateSimulatorRequest;
}
