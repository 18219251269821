/**
 * NOTE(sam): The overall structure of this component is heavily inspired by the AutoComplete
 * component from NextUI. Since that component doesn't support multiselect I've rewired it to use
 * our multiselect hooks and a tag list to show selected options.
 */

import { useFilter } from "@react-aria/i18n";
import { chain } from "@react-aria/utils";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { useMultiSelectComboBox } from "./useMultiSelectComboBox";
import { useMultiSelectComboBoxState } from "./useMultiSelectComboBoxState";

import type { FilterFn } from "@react-stately/combobox";
import type { AsyncLoadable } from "@react-types/shared";
import type { ComponentPropsWithoutRef, MouseEvent, ReactNode, RefObject } from "react";
import type { VariantProps } from "tailwind-variants";
import type { OverlayPlacement } from "../../utils/overlay";
import type { ReactRef } from "../../utils/types";
import type { ButtonProps } from "../button/Button";
import type { InputProps } from "../input/Input";
import type { ListBoxProps } from "../listbox/ListBox";
import type { UsePopoverProps } from "../popover/usePopover";
import type { AriaMultiSelectComboBoxProps, MultiSelectComboBoxProps } from "./types";

const comboboxVariants = tv({
  slots: {
    base: "group inline-flex flex-col",
    listboxWrapper: "max-h-64 w-full scroll-py-6",
    listbox: "",
    popoverContent: "w-full overflow-hidden p-1",
    endContentWrapper: "relative -mr-2 flex h-full items-center",
    clearButton: [
      "translate-x-1",
      "hidden",
      "!text-gray-500",
      "dark:hover:!text-gray-300",
      "group-data-[invalid=true]:text-red",
      "data-[visible=true]:block",
    ],
    selectorButton: "text-xs !text-gray-500 dark:hover:!text-gray-300",
    inputWrapper: "relative inline-block h-full",
    inputWidthSetter: "invisible inline-block min-w-8 px-0.5",
    input: "absolute inset-0 placeholder:text-transparent",
    tags: "pe-1.5 h-full w-full",
    placeholder: "absolute top-1/2 inline-block -translate-y-1/2 px-0.5 text-gray-500",
    selectedValue:
      "w-full flex-grow self-center truncate px-0.5 group-data-[open=true]:text-gray-500 dark:text-white",
  },
  variants: {
    isClearable: {
      true: {},
      false: {
        clearButton: "hidden",
      },
    },
    disableAnimation: {
      true: {
        selectorButton: "transition-none",
      },
      false: {
        selectorButton: "ease transition-transform duration-150 motion-reduce:transition-none",
      },
    },
    disableSelectorIconRotation: {
      true: {},
      false: {
        selectorButton: "data-[open=true]:rotate-180",
      },
    },
    fullWidth: {
      true: {
        base: "w-full",
      },
      false: {},
    },
  },
  defaultVariants: {
    disableAnimation: false,
    isClearable: true,
    disableSelectorIconRotation: false,
    fullWidth: false,
  },
});

type ComboBoxVariantProps = VariantProps<typeof comboboxVariants>;

export interface BaseComboBoxProps<T>
  extends Omit<ComponentPropsWithoutRef<"input">, keyof AriaMultiSelectComboBoxProps<T>> {
  ref?: ReactRef<HTMLInputElement>;
  /**
   * The ref to the scroll element. Useful when having async loading of items.
   */
  scrollRef?: ReactRef<HTMLDivElement | null>;

  triggerRef?: RefObject<HTMLElement>;

  listboxProps?: Partial<ListBoxProps<T>>;
  /**
   * The icon that represents the autocomplete open state. Usually a chevron icon.
   */
  selectorIcon?: ReactNode;
  /**
   * The icon that represents the clear button. Usually a cross icon.
   */
  clearIcon?: ReactNode;
  /**
   * Whether to display a top and bottom arrow indicators when the listbox is scrollable.
   * @default true
   */
  showScrollIndicators?: boolean;
  /**
   * The filter options to use when filtering items based on user input.
   * @default {sensitivity: 'base'}
   */
  filterOptions?: Intl.CollatorOptions;
  /**
   * Whether the autocomplete allows the menu to be open when the collection is empty.
   * @default true
   */
  allowsEmptyCollection?: boolean;
  /**
   * Whether the autocomplete menu should close on blur.
   * @default true
   * */
  shouldCloseOnBlur?: boolean;
  /**
   * The filter function used to determine if a option should be included in the autocomplete list.
   * */
  defaultFilter?: FilterFn;
  /**
   * Callback fired when the select menu is closed.
   */
  onClose?: () => void;
}

export type UseComboBoxProps<T> = BaseComboBoxProps<T> &
  Omit<
    InputProps,
    "children" | "value" | "isClearable" | "defaultValue" | "classNames" | "onFocus"
  > &
  MultiSelectComboBoxProps<T> &
  AsyncLoadable &
  ComboBoxVariantProps;

export const useComboBox = <T extends object>(originalProps: UseComboBoxProps<T>) => {
  const disableAnimation = originalProps.disableAnimation ?? false;

  const {
    ref,
    label,
    isLoading,
    menuTrigger = "focus",
    filterOptions = {
      sensitivity: "base",
    },
    selectionMode = "single",
    children,
    selectorIcon,
    clearIcon,
    triggerRef,
    scrollRef: scrollRefProp,
    listboxProps: listboxPropsProp = {},
    defaultFilter,
    endContent,
    isClearable,
    fullWidth,
    allowsEmptyCollection = true,
    shouldCloseOnBlur = true,
    showScrollIndicators = true,
    allowsCustomValue = false,
    className,
    placeholder,
    onOpenChange,
    onClose,
    onClear: onClearProp,
    ...otherProps
  } = originalProps;

  // Setup filter function and state.
  const { contains } = useFilter(filterOptions);

  const state = useMultiSelectComboBoxState({
    ...originalProps,
    selectionMode,
    children,
    menuTrigger,
    shouldCloseOnBlur,
    allowsEmptyCollection,
    defaultFilter: defaultFilter && typeof defaultFilter === "function" ? defaultFilter : contains,
    onOpenChange: (open, menuTrigger) => {
      onOpenChange?.(open, menuTrigger);
      if (!open) {
        onClose?.();
      }
    },
  });

  // Setup refs and get props for child elements.
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const tagGroupRef = useRef<HTMLDivElement>(null);
  const inputRef = useDomRef<HTMLInputElement>(ref);
  const scrollShadowRef = useDomRef<HTMLDivElement>(scrollRefProp);

  const isOpen = allowsCustomValue ? state.isOpen && !!state.collection.size : state.isOpen;

  // unfocus the input when the popover closes & there's no selected item & no allows custom value
  useEffect(() => {
    if (!isOpen && !state.selectedItems?.length && inputRef.current && !allowsCustomValue) {
      inputRef.current.blur();
    }
  }, [isOpen, state.selectedItems?.length, inputRef, allowsCustomValue]);

  const {
    buttonProps,
    inputProps: ariaInputProps,
    listBoxProps: ariaListBoxProps,
  } = useMultiSelectComboBox(
    {
      ...originalProps,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
      tagGroupRef,
    },
    state
  );

  const slots = useMemo(
    () =>
      comboboxVariants({
        isClearable,
        disableAnimation,
        fullWidth,
        className,
      }),
    [isClearable, disableAnimation, fullWidth, className]
  );

  const onClickInput = useCallback(() => {
    if (!state.isOpen && state.selectedItems?.length) {
      state.open();
    }
  }, [state.isOpen, state.selectedItems?.length, state]);

  const onClear = useCallback(() => {
    state.setInputValue("");
    state.setSelectedKeys(new Set());
    onClearProp?.();
  }, [state, onClearProp]);

  const onFocus = useCallback(
    (isFocused: boolean) => {
      inputRef.current?.focus();
      state.setFocused(isFocused);
    },
    [state, inputRef]
  );

  const baseProps = useMemo(
    () => ({
      "data-invalid": originalProps?.isInvalid,
      "data-open": state.isOpen,
      className: slots.base({ className }),
    }),
    [slots, state.isOpen, className, originalProps?.isInvalid]
  );

  const selectorButtonProps = useMemo(
    () =>
      ({
        ...buttonProps,
        ref: buttonRef,
        isLoading,
        size: "sm",
        variant: "light",
        radius: "full",
        ghost: true,
        isIconOnly: true,
        disableAnimation,
        "data-open": state.isOpen,
        className: slots.selectorButton(),
      }) as ButtonProps,
    [buttonProps, isLoading, disableAnimation, slots, state.isOpen]
  );

  const clearButtonProps = useMemo(
    () =>
      ({
        ...buttonProps,
        size: "sm",
        variant: "light",
        radius: "full",
        ghost: true,
        isIconOnly: true,
        disableAnimation,
        onPress: () => {
          if (state.selectedItems?.length) {
            onClear();
          } else {
            const inputFocused = inputRef.current === document.activeElement;

            allowsCustomValue && state.setInputValue("");
            !inputFocused && onFocus(true);
          }
        },
        "data-visible": !!state.selectedItems?.length || state.inputValue?.length > 0,
        className: slots.clearButton(),
      }) as ButtonProps,
    [
      buttonProps,
      disableAnimation,
      state.selectedItems?.length,
      state.inputValue,
      allowsCustomValue,
      onFocus,
      onClear,
      slots,
      state,
      inputRef,
    ]
  );

  const inputProps = useMemo(
    () => ({
      label,
      ref: inputRef,
      wrapperRef: inputWrapperRef,
      isClearable: false,
      disableAnimation,
      placeholder: state.selectedKeys.size > 0 ? "" : placeholder,
      ...otherProps,
      ...ariaInputProps,
      onClick: chain(onClickInput, otherProps.onClick),
      classNames: {
        inputWrapper: cn(
          state.selectionMode === "multiple" && state.selectedKeys.size > 0 && "pl-1"
        ),
        input: slots.input(),
        innerWrapper: "relative",
      },
    }),
    [
      label,
      disableAnimation,
      placeholder,
      onClickInput,
      otherProps,
      ariaInputProps,
      slots,
      state.selectionMode,
      state.selectedKeys.size,
      inputRef,
    ]
  ) as InputProps;

  const listBoxProps = useMemo(
    () => ({
      state,
      ref: listBoxRef,
      shouldHighlightOnFocus: true,
      children: [],
      hideEmptyContent: allowsCustomValue,
      emptyContent: "No results found.",
      disableAnimation,
      isLoading,
      ...ariaListBoxProps,
      ...listboxPropsProp,
    }),
    [state, isLoading, allowsCustomValue, disableAnimation, ariaListBoxProps, listboxPropsProp]
  );

  const popoverProps = useMemo(
    () => ({
      state,
      ref: popoverRef,
      triggerRef: triggerRef ?? inputWrapperRef,
      scrollRef: listBoxRef,
      triggerType: "listbox" as UsePopoverProps["triggerType"],
      offset: 5,
      placement: "bottom" as OverlayPlacement,
      triggerScaleOnOpen: false,
      disableAnimation,
      classNames: {
        content: slots.popoverContent(),
      },
    }),
    [state, disableAnimation, slots, triggerRef]
  );

  const listBoxWrapperProps = useMemo(
    () => ({
      ref: scrollShadowRef,
      isEnabled: showScrollIndicators ?? true,
      hideScrollBar: true,
      offset: 15,
      className: slots.listboxWrapper(),
    }),
    [showScrollIndicators, slots, scrollShadowRef]
  );

  const endContentWrapperProps = useMemo(
    () => ({
      className: slots.endContentWrapper(),
      onClick: (e: MouseEvent<HTMLDivElement>) => {
        const inputFocused = inputRef.current === document.activeElement;

        if (!inputFocused && !state.isFocused && e.currentTarget === e.target) {
          onFocus(true);
        }
      },
    }),
    [slots, state.isFocused, onFocus, inputRef]
  );

  const tagGroupProps = useMemo(
    () => ({
      className: slots.tags(),
      state,
      ref: tagGroupRef,
      onClick: (e: MouseEvent) => {
        if (inputRef.current && e.currentTarget === e.target) {
          inputRef.current.focus();
        }
      },
    }),
    [slots, state, inputRef]
  );

  return {
    inputRef,
    inputWrapperRef,
    popoverRef,
    isOpen,
    isLoading,
    disableAnimation,
    allowsCustomValue,
    selectionMode,
    state,
    slots,
    endContent,
    selectorIcon,
    baseProps,
    selectorButtonProps,
    clearButtonProps,
    inputProps,
    listBoxProps,
    listBoxWrapperProps,
    popoverProps,
    endContentWrapperProps,
    tagGroupProps,
  };
};
