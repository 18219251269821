import { faChevronDown, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence } from "framer-motion";
import { forwardRef, useEffect, useMemo } from "react";
import { mergeProps } from "react-aria";
import { FormProvider, useForm } from "react-hook-form";

import { selectedKeysFromFormValue } from "../../utils/misc";
import { mergeRefs } from "../../utils/refs";
import { Button } from "../button/Button";
import { ScrollShadow } from "../extra/ScrollShadow";
import { useControllerSafe } from "../form/useControllerSafe";
import { Input } from "../input/Input";
import { ListBox } from "../listbox/ListBox";
import StandalonePopover from "../popover/StandalonePopover";
import { MultiSelectTags } from "../tag/MultiSelectTags";
import { useComboBox } from "./useComboBox";

import type { Key } from "@react-types/shared";
import type { ForwardedRef, ReactElement, ReactNode } from "react";
import type { UseComboBoxProps } from "./useComboBox";

// ComboBox renders an input internally but we don't want it to hook into the form context
// since we're already managing the form state ourselves. We block the inner input from seeing
// the actual form context by inserting a blank one in between
const FormContextBarrier = ({ children }: { children: ReactNode }) => {
  const dummyForm = useForm();

  return <FormProvider {...dummyForm}>{children}</FormProvider>;
};

export type ComboBoxProps<T> = UseComboBoxProps<T>;

const ComboBoxImpl = <T extends object>(
  props: ComboBoxProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const formController = useControllerSafe({ name: props.name ?? "" });

  if (formController && !props.name) {
    throw new Error("ComboBox used inside a Form component must have a name prop");
  }

  const { error: formError, invalid: formInvalid } = formController?.fieldState ?? {};

  const formComboBoxProps = useMemo(
    () =>
      formController?.field
        ? {
            onSelectionChange: (keys: Set<Key>) => {
              const keysArray = Array.from(keys);
              const value = props.selectionMode === "multiple" ? keysArray : keysArray[0];
              formController.field.onChange(value);
            },
            selectedKeys: selectedKeysFromFormValue(
              formController.field.value,
              props.selectionMode ?? "single"
            ),
            name: formController.field.name,
            onClose: formController.field.onBlur,
            isDisabled: formController.field.disabled,
            isInvalid: formInvalid,
            errorMessage: formError?.message,
          }
        : undefined,
    [formController?.field, formError, formInvalid, props.selectionMode]
  );

  const {
    inputRef,
    inputWrapperRef,
    popoverRef,
    isOpen,
    disableAnimation,
    allowsCustomValue,
    selectionMode,
    state,
    slots,
    endContent,
    baseProps,
    selectorButtonProps,
    clearButtonProps,
    inputProps,
    listBoxProps,
    listBoxWrapperProps,
    popoverProps,
    endContentWrapperProps,
    tagGroupProps,
  } = useComboBox({
    ...(formComboBoxProps ? mergeProps(props, formComboBoxProps) : props),
    ref: formController?.field ? mergeRefs(ref, formController.field.ref) : ref,
  });

  // apply the same width to the popover as the select
  useEffect(() => {
    if (isOpen && popoverRef.current && inputWrapperRef.current) {
      const rect = inputWrapperRef.current.getBoundingClientRect();

      const popover = popoverRef.current;

      popover.style.width = rect.width + "px";
    }
  }, [isOpen]);

  // unfocus the input when the popover closes & there's no selected item & no allows custom value
  useEffect(() => {
    if (!isOpen && !state.selectedItems?.length && inputRef.current && !allowsCustomValue) {
      inputRef.current.blur();
    }
  }, [isOpen, allowsCustomValue]);

  const popoverContent = isOpen ? (
    <StandalonePopover {...popoverProps} state={state}>
      <ScrollShadow {...listBoxWrapperProps}>
        <ListBox {...listBoxProps} />
      </ScrollShadow>
    </StandalonePopover>
  ) : null;

  const showPlaceholder = !(state.inputValue.length > 0 || state.selectedKeys.size > 0);
  const showSelectedValue = state.inputValue.length === 0;

  const input = (
    <Input
      {...inputProps}
      endContent={
        <div {...endContentWrapperProps}>
          {endContent || (
            <Button {...clearButtonProps}>
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          )}
          <Button {...selectorButtonProps}>
            <FontAwesomeIcon icon={faChevronDown} />
          </Button>
        </div>
      }
    >
      {showPlaceholder && <span className={slots.placeholder()}>{inputProps.placeholder}</span>}
      {selectionMode === "multiple" ? (
        <MultiSelectTags
          {...tagGroupProps}
          /**
           * We want the input to only take up as much space as it needs so that the tag list can
           * fill the rest of the available space. To accomplish this we have a hidden span tag
           * that will drive the width of the input element based on the current text value.
           */
          endContent={
            <div className={slots.inputWrapper()}>
              <span className={slots.inputWidthSetter()}>{state.inputValue}</span>
              <Input.Input />
            </div>
          }
        />
      ) : (
        <div className={slots.inputWrapper({ className: "flex flex-grow" })}>
          <Input.Input />
          {showSelectedValue && (
            <span className={slots.selectedValue()}>{state.selectedItems?.[0]?.textValue}</span>
          )}
        </div>
      )}
    </Input>
  );

  return (
    <div {...baseProps}>
      {formController ? <FormContextBarrier>{input}</FormContextBarrier> : input}
      {disableAnimation ? popoverContent : <AnimatePresence>{popoverContent}</AnimatePresence>}
    </div>
  );
};

// Need to do this manually to get the generic type which doesn't play super well with forwardRef
const WrappedComboBox = forwardRef(ComboBoxImpl) as <T = object>(
  props: ComboBoxProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReactElement;

/**
 * A text input combined with a selection menu, allowing users to filter longer lists to only the
 * options matching a query.
 */
export const ComboBox = WrappedComboBox as typeof WrappedComboBox & {
  displayName: "ComboBox";
  Item: typeof ListBox.Item;
  Section: typeof ListBox.Section;
};
ComboBox.displayName = "ComboBox";
ComboBox.Item = ListBox.Item;
ComboBox.Section = ListBox.Section;

export type { ListBoxItemProps as ComboBoxItemProps } from "../listbox/ListBoxItem";
export type { ListBoxSectionProps as ComboBoxSectionProps } from "../listbox/ListBoxSection";
