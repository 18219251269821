// source: pbtypes/gen/surface_scan/settings_manager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_scan_planner_structure_column_type_pb = require('../../../pbtypes/gen/scan_planner/structure_column_type_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_structure_column_type_pb);
var pbtypes_gen_surface_scan_scan_mode_pb = require('../../../pbtypes/gen/surface_scan/scan_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_scan_mode_pb);
var pbtypes_gen_surface_scan_specification_selection_pb = require('../../../pbtypes/gen/surface_scan/specification_selection_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_specification_selection_pb);
var pbtypes_gen_surface_scan_user_controls_pb = require('../../../pbtypes/gen/surface_scan/user_controls_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_user_controls_pb);
var pbtypes_gen_surface_scan_waypoint_execution_mode_pb = require('../../../pbtypes/gen/surface_scan/waypoint_execution_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_waypoint_execution_mode_pb);
var pbtypes_gen_user_camera_auto_region_request_pb = require('../../../pbtypes/gen/user_camera/auto_region_request_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_auto_region_request_pb);
var pbtypes_gen_user_camera_autofocus_mode_pb = require('../../../pbtypes/gen/user_camera/autofocus_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_autofocus_mode_pb);
var pbtypes_gen_user_camera_awb_mode_pb = require('../../../pbtypes/gen/user_camera/awb_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_awb_mode_pb);
var pbtypes_gen_user_camera_exposure_mode_pb = require('../../../pbtypes/gen/user_camera/exposure_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_exposure_mode_pb);
var pbtypes_gen_user_camera_recording_mode_pb = require('../../../pbtypes/gen/user_camera/recording_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_recording_mode_pb);
goog.exportSymbol('proto.surface_scan.SettingsManager', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.surface_scan.SettingsManager = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.surface_scan.SettingsManager.repeatedFields_, null);
};
goog.inherits(proto.surface_scan.SettingsManager, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.surface_scan.SettingsManager.displayName = 'proto.surface_scan.SettingsManager';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.surface_scan.SettingsManager.repeatedFields_ = [202];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.surface_scan.SettingsManager.prototype.toObject = function(opt_includeInstance) {
  return proto.surface_scan.SettingsManager.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.surface_scan.SettingsManager} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.surface_scan.SettingsManager.toObject = function(includeInstance, msg) {
  var f, obj = {
scanModeLcm: jspb.Message.getFieldWithDefault(msg, 1, 0),
runAreaScan: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
runPerimeterScan: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
runOrbitScan: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
runMeshScan: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
runContourZScan: jspb.Message.getBooleanFieldWithDefault(msg, 76, false),
runContourYScan: jspb.Message.getBooleanFieldWithDefault(msg, 75, false),
runContourXScan: jspb.Message.getBooleanFieldWithDefault(msg, 74, false),
runContourCustomScan: jspb.Message.getBooleanFieldWithDefault(msg, 137, false),
runManualScan: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
runColumnScan: jspb.Message.getBooleanFieldWithDefault(msg, 101, false),
runVertexColumnScan: jspb.Message.getBooleanFieldWithDefault(msg, 102, false),
runStructureColumnInspectionScan: jspb.Message.getBooleanFieldWithDefault(msg, 154, false),
stopForPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 78, false),
ignoreTranslationMotionBlurConstraint: jspb.Message.getBooleanFieldWithDefault(msg, 188, false),
overlapPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
distanceToSurface: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
showArCoverageMesh: jspb.Message.getBooleanFieldWithDefault(msg, 77, false),
awbMode: jspb.Message.getFieldWithDefault(msg, 68, 0),
exposureMode: jspb.Message.getFieldWithDefault(msg, 69, 0),
autofocusRegion: (f = msg.getAutofocusRegion()) && pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.toObject(includeInstance, f),
autofocusMode: jspb.Message.getFieldWithDefault(msg, 151, 0),
eoRecordingMode: jspb.Message.getFieldWithDefault(msg, 166, 0),
geofenceStrictCeiling: jspb.Message.getBooleanFieldWithDefault(msg, 94, false),
geofenceCeilOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
geofenceStrictFloor: jspb.Message.getBooleanFieldWithDefault(msg, 95, false),
geofenceFloorOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
geofenceStrictLateral: jspb.Message.getBooleanFieldWithDefault(msg, 96, false),
geofenceLateralOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 97, 0.0),
geofenceOffsetFloorFromPolygonPrismCeiling: jspb.Message.getBooleanFieldWithDefault(msg, 152, false),
minDistanceFromSurface: jspb.Message.getFloatingPointFieldWithDefault(msg, 92, 0.0),
useGeofenceArea: jspb.Message.getBooleanFieldWithDefault(msg, 126, false),
exteriorCaptureCeil: jspb.Message.getBooleanFieldWithDefault(msg, 98, false),
exteriorCaptureFloor: jspb.Message.getBooleanFieldWithDefault(msg, 99, false),
exteriorCaptureLateral: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
scanVolumeInside: jspb.Message.getBooleanFieldWithDefault(msg, 140, false),
scanVolumeExtendCaptureDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 141, 0.0),
areaScanHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
areaOverlap: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
areaSidelap: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
areaGimbalPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 173, 0.0),
enableCrossHatch: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
crossHatchGimbalAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
enableCustomFlightLegDirection: jspb.Message.getBooleanFieldWithDefault(msg, 170, false),
customFlightLegDirection: jspb.Message.getFloatingPointFieldWithDefault(msg, 171, 0.0),
enableCustomCameraHeading: jspb.Message.getBooleanFieldWithDefault(msg, 174, false),
ccwPerpendicularCameraHeading: jspb.Message.getBooleanFieldWithDefault(msg, 175, false),
perimeterOverlap: jspb.Message.getFloatingPointFieldWithDefault(msg, 88, 0.0),
perimeterGimbalPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
perimeterNumHeights: jspb.Message.getFieldWithDefault(msg, 35, 0),
perimeterMinDistanceToScanPlane: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
rollingShutterCorrection: jspb.Message.getBooleanFieldWithDefault(msg, 189, false),
isLookupScan: jspb.Message.getBooleanFieldWithDefault(msg, 107, false),
orbitSidelap: jspb.Message.getFloatingPointFieldWithDefault(msg, 124, 0.0),
orbitGimbalPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 108, 0.0),
orbitUseHelix: jspb.Message.getBooleanFieldWithDefault(msg, 128, false),
numColumns: jspb.Message.getFieldWithDefault(msg, 145, 0),
columnOverlap: jspb.Message.getFloatingPointFieldWithDefault(msg, 115, 0.0),
columnGimbalPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 117, 0.0),
contourDesiredRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 71, 0.0),
contourWaypointOverlapPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 73, 0.0),
contourSliceOverlapPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 72, 0.0),
contourZLookdownAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 81, 0.0),
avoidRedundantCoverage: jspb.Message.getBooleanFieldWithDefault(msg, 90, false),
adjustUnreachablePhotos: jspb.Message.getBooleanFieldWithDefault(msg, 91, false),
contourNumPasses: jspb.Message.getFieldWithDefault(msg, 121, 0),
contourPassMultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 122, 0.0),
contourSlicePitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 135, 0.0),
contourSliceYaw: jspb.Message.getFloatingPointFieldWithDefault(msg, 136, 0.0),
contourSmallGapsDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 146, 0.0),
contourLongRange: jspb.Message.getBooleanFieldWithDefault(msg, 149, false),
verticalOverlapFractionPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 103, 0.0),
gimbalPitchDeg: jspb.Message.getFloatingPointFieldWithDefault(msg, 104, 0.0),
lengthTower: jspb.Message.getFloatingPointFieldWithDefault(msg, 105, 0.0),
upAndOverHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 106, 0.0),
standoffDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 161, 0.0),
explorationOrbitRadius: jspb.Message.getFloatingPointFieldWithDefault(msg, 165, 0.0),
explorationOrbitHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 169, 0.0),
towerHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 181, 0.0),
towerWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 159, 0.0),
towerDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 160, 0.0),
nadirExecutionMode: jspb.Message.getFieldWithDefault(msg, 182, 0),
nadirFillImage: jspb.Message.getFloatingPointFieldWithDefault(msg, 187, 0.0),
overviewExecutionMode: jspb.Message.getFieldWithDefault(msg, 186, 0),
overviewGimbalPitchDown: jspb.Message.getFloatingPointFieldWithDefault(msg, 176, 0.0),
insulatorExecutionMode: jspb.Message.getFieldWithDefault(msg, 178, 0),
columnPlanType: jspb.Message.getFieldWithDefault(msg, 162, 0),
useSemanticInspectionGimbalPitchUp: jspb.Message.getBooleanFieldWithDefault(msg, 157, false),
semanticInspectionGimbalPitchUp: jspb.Message.getFloatingPointFieldWithDefault(msg, 155, 0.0),
useSemanticInspectionGimbalPitchStraight: jspb.Message.getBooleanFieldWithDefault(msg, 183, false),
useSemanticInspectionGimbalPitchDown: jspb.Message.getBooleanFieldWithDefault(msg, 158, false),
semanticInspectionGimbalPitchDown: jspb.Message.getFloatingPointFieldWithDefault(msg, 156, 0.0),
towerBodyExecutionMode: jspb.Message.getFieldWithDefault(msg, 179, 0),
towerBodyEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 190, false),
towerBodyTakePhoto: jspb.Message.getBooleanFieldWithDefault(msg, 191, false),
towerBodyPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 185, 0.0),
towerBodyOverlap: jspb.Message.getFloatingPointFieldWithDefault(msg, 184, 0.0),
spanExecutionMode: jspb.Message.getFieldWithDefault(msg, 180, 0),
spanEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 192, false),
spanTakePhoto: jspb.Message.getBooleanFieldWithDefault(msg, 193, false),
autonomousTowerAlignment: jspb.Message.getBooleanFieldWithDefault(msg, 172, false),
useSemanticAim: jspb.Message.getBooleanFieldWithDefault(msg, 168, false),
numberPitchAngles: jspb.Message.getFieldWithDefault(msg, 195, 0),
nadirTakePhoto: jspb.Message.getBooleanFieldWithDefault(msg, 196, false),
overviewEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 197, false),
overviewTakePhoto: jspb.Message.getBooleanFieldWithDefault(msg, 198, false),
insulatorEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 199, false),
insulatorTakePhoto: jspb.Message.getBooleanFieldWithDefault(msg, 200, false),
semanticInspectionGimbalPitchStraight: jspb.Message.getFloatingPointFieldWithDefault(msg, 201, 0.0),
columnIndicesList: (f = jspb.Message.getRepeatedField(msg, 202)) == null ? undefined : f,
useExplorationPhase: jspb.Message.getBooleanFieldWithDefault(msg, 61, false),
useMeshExplore: jspb.Message.getBooleanFieldWithDefault(msg, 147, false),
useLongRangeExplore: jspb.Message.getBooleanFieldWithDefault(msg, 148, false),
useOrbitExploration: jspb.Message.getBooleanFieldWithDefault(msg, 163, false),
globalMaximumSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 127, 0.0),
globalSupportOutsideGeofencePoints: jspb.Message.getBooleanFieldWithDefault(msg, 142, false),
splitExplorationIsEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 138, false),
splitExplorationIsCumulative: jspb.Message.getBooleanFieldWithDefault(msg, 132, false),
splitExplorationIsTopToBottom: jspb.Message.getBooleanFieldWithDefault(msg, 133, false),
splitExplorationSliceHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 139, 0.0),
specificationSelection: jspb.Message.getFieldWithDefault(msg, 41, 0),
usePillarCursor: jspb.Message.getBooleanFieldWithDefault(msg, 56, false),
usePillarCursorMarker: jspb.Message.getBooleanFieldWithDefault(msg, 84, false),
useExtremeCursor: jspb.Message.getBooleanFieldWithDefault(msg, 82, false),
lockAwbAtFirstWaypoint: jspb.Message.getBooleanFieldWithDefault(msg, 194, false),
useGpsScanPose: jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
lostCommsWaitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
lostCommsRallyWaitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 51, 0.0),
numPhotosPerOrbit: jspb.Message.getFieldWithDefault(msg, 37, 0),
lookOutwards: jspb.Message.getBooleanFieldWithDefault(msg, 143, false),
orbitCounterClockwise: jspb.Message.getBooleanFieldWithDefault(msg, 144, false),
takeIrPhotos: jspb.Message.getBooleanFieldWithDefault(msg, 153, false),
takeRawPhotos: jspb.Message.getBooleanFieldWithDefault(msg, 167, false),
userControls: (f = msg.getUserControls()) && pbtypes_gen_surface_scan_user_controls_pb.UserControls.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.surface_scan.SettingsManager}
 */
proto.surface_scan.SettingsManager.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.surface_scan.SettingsManager;
  return proto.surface_scan.SettingsManager.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.surface_scan.SettingsManager} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.surface_scan.SettingsManager}
 */
proto.surface_scan.SettingsManager.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.surface_scan.ScanMode.Enum} */ (reader.readEnum());
      msg.setScanModeLcm(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunAreaScan(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunPerimeterScan(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunOrbitScan(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunMeshScan(value);
      break;
    case 76:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunContourZScan(value);
      break;
    case 75:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunContourYScan(value);
      break;
    case 74:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunContourXScan(value);
      break;
    case 137:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunContourCustomScan(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunManualScan(value);
      break;
    case 101:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunColumnScan(value);
      break;
    case 102:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunVertexColumnScan(value);
      break;
    case 154:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunStructureColumnInspectionScan(value);
      break;
    case 78:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStopForPhoto(value);
      break;
    case 188:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreTranslationMotionBlurConstraint(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverlapPercentage(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistanceToSurface(value);
      break;
    case 77:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowArCoverageMesh(value);
      break;
    case 68:
      var value = /** @type {!proto.user_camera.AwbMode.Enum} */ (reader.readEnum());
      msg.setAwbMode(value);
      break;
    case 69:
      var value = /** @type {!proto.user_camera.ExposureMode.Enum} */ (reader.readEnum());
      msg.setExposureMode(value);
      break;
    case 150:
      var value = new pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest;
      reader.readMessage(value,pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.deserializeBinaryFromReader);
      msg.setAutofocusRegion(value);
      break;
    case 151:
      var value = /** @type {!proto.user_camera.AutofocusMode.Enum} */ (reader.readEnum());
      msg.setAutofocusMode(value);
      break;
    case 166:
      var value = /** @type {!proto.user_camera.RecordingMode.Enum} */ (reader.readEnum());
      msg.setEoRecordingMode(value);
      break;
    case 94:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGeofenceStrictCeiling(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGeofenceCeilOffset(value);
      break;
    case 95:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGeofenceStrictFloor(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGeofenceFloorOffset(value);
      break;
    case 96:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGeofenceStrictLateral(value);
      break;
    case 97:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGeofenceLateralOffset(value);
      break;
    case 152:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGeofenceOffsetFloorFromPolygonPrismCeiling(value);
      break;
    case 92:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinDistanceFromSurface(value);
      break;
    case 126:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseGeofenceArea(value);
      break;
    case 98:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExteriorCaptureCeil(value);
      break;
    case 99:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExteriorCaptureFloor(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExteriorCaptureLateral(value);
      break;
    case 140:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScanVolumeInside(value);
      break;
    case 141:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScanVolumeExtendCaptureDistance(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAreaScanHeight(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAreaOverlap(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAreaSidelap(value);
      break;
    case 173:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAreaGimbalPitch(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableCrossHatch(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCrossHatchGimbalAngle(value);
      break;
    case 170:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableCustomFlightLegDirection(value);
      break;
    case 171:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCustomFlightLegDirection(value);
      break;
    case 174:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableCustomCameraHeading(value);
      break;
    case 175:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCcwPerpendicularCameraHeading(value);
      break;
    case 88:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPerimeterOverlap(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPerimeterGimbalPitch(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setPerimeterNumHeights(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPerimeterMinDistanceToScanPlane(value);
      break;
    case 189:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRollingShutterCorrection(value);
      break;
    case 107:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLookupScan(value);
      break;
    case 124:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOrbitSidelap(value);
      break;
    case 108:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOrbitGimbalPitch(value);
      break;
    case 128:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrbitUseHelix(value);
      break;
    case 145:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumColumns(value);
      break;
    case 115:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setColumnOverlap(value);
      break;
    case 117:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setColumnGimbalPitch(value);
      break;
    case 71:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContourDesiredRange(value);
      break;
    case 73:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContourWaypointOverlapPercentage(value);
      break;
    case 72:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContourSliceOverlapPercentage(value);
      break;
    case 81:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContourZLookdownAngle(value);
      break;
    case 90:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvoidRedundantCoverage(value);
      break;
    case 91:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdjustUnreachablePhotos(value);
      break;
    case 121:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setContourNumPasses(value);
      break;
    case 122:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContourPassMultiplier(value);
      break;
    case 135:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContourSlicePitch(value);
      break;
    case 136:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContourSliceYaw(value);
      break;
    case 146:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContourSmallGapsDistance(value);
      break;
    case 149:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContourLongRange(value);
      break;
    case 103:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVerticalOverlapFractionPercentage(value);
      break;
    case 104:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGimbalPitchDeg(value);
      break;
    case 105:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLengthTower(value);
      break;
    case 106:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpAndOverHeight(value);
      break;
    case 161:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStandoffDistance(value);
      break;
    case 165:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setExplorationOrbitRadius(value);
      break;
    case 169:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setExplorationOrbitHeight(value);
      break;
    case 181:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTowerHeight(value);
      break;
    case 159:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTowerWidth(value);
      break;
    case 160:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTowerDepth(value);
      break;
    case 182:
      var value = /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (reader.readEnum());
      msg.setNadirExecutionMode(value);
      break;
    case 187:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNadirFillImage(value);
      break;
    case 186:
      var value = /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (reader.readEnum());
      msg.setOverviewExecutionMode(value);
      break;
    case 176:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverviewGimbalPitchDown(value);
      break;
    case 178:
      var value = /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (reader.readEnum());
      msg.setInsulatorExecutionMode(value);
      break;
    case 162:
      var value = /** @type {!proto.scan_planner.StructureColumnType.Enum} */ (reader.readEnum());
      msg.setColumnPlanType(value);
      break;
    case 157:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSemanticInspectionGimbalPitchUp(value);
      break;
    case 155:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSemanticInspectionGimbalPitchUp(value);
      break;
    case 183:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSemanticInspectionGimbalPitchStraight(value);
      break;
    case 158:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSemanticInspectionGimbalPitchDown(value);
      break;
    case 156:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSemanticInspectionGimbalPitchDown(value);
      break;
    case 179:
      var value = /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (reader.readEnum());
      msg.setTowerBodyExecutionMode(value);
      break;
    case 190:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTowerBodyEnabled(value);
      break;
    case 191:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTowerBodyTakePhoto(value);
      break;
    case 185:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTowerBodyPitch(value);
      break;
    case 184:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTowerBodyOverlap(value);
      break;
    case 180:
      var value = /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (reader.readEnum());
      msg.setSpanExecutionMode(value);
      break;
    case 192:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpanEnabled(value);
      break;
    case 193:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpanTakePhoto(value);
      break;
    case 172:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutonomousTowerAlignment(value);
      break;
    case 168:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSemanticAim(value);
      break;
    case 195:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setNumberPitchAngles(value);
      break;
    case 196:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNadirTakePhoto(value);
      break;
    case 197:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverviewEnabled(value);
      break;
    case 198:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverviewTakePhoto(value);
      break;
    case 199:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsulatorEnabled(value);
      break;
    case 200:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsulatorTakePhoto(value);
      break;
    case 201:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSemanticInspectionGimbalPitchStraight(value);
      break;
    case 202:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addColumnIndices(values[i]);
      }
      break;
    case 61:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseExplorationPhase(value);
      break;
    case 147:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseMeshExplore(value);
      break;
    case 148:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseLongRangeExplore(value);
      break;
    case 163:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseOrbitExploration(value);
      break;
    case 127:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGlobalMaximumSpeed(value);
      break;
    case 142:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGlobalSupportOutsideGeofencePoints(value);
      break;
    case 138:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSplitExplorationIsEnabled(value);
      break;
    case 132:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSplitExplorationIsCumulative(value);
      break;
    case 133:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSplitExplorationIsTopToBottom(value);
      break;
    case 139:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSplitExplorationSliceHeight(value);
      break;
    case 41:
      var value = /** @type {!proto.surface_scan.SpecificationSelection.Enum} */ (reader.readEnum());
      msg.setSpecificationSelection(value);
      break;
    case 56:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsePillarCursor(value);
      break;
    case 84:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsePillarCursorMarker(value);
      break;
    case 82:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseExtremeCursor(value);
      break;
    case 194:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLockAwbAtFirstWaypoint(value);
      break;
    case 46:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseGpsScanPose(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLostCommsWaitTime(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLostCommsRallyWaitTime(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setNumPhotosPerOrbit(value);
      break;
    case 143:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLookOutwards(value);
      break;
    case 144:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrbitCounterClockwise(value);
      break;
    case 153:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTakeIrPhotos(value);
      break;
    case 167:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTakeRawPhotos(value);
      break;
    case 164:
      var value = new pbtypes_gen_surface_scan_user_controls_pb.UserControls;
      reader.readMessage(value,pbtypes_gen_surface_scan_user_controls_pb.UserControls.deserializeBinaryFromReader);
      msg.setUserControls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.surface_scan.SettingsManager.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.surface_scan.SettingsManager.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.surface_scan.SettingsManager} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.surface_scan.SettingsManager.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanModeLcm();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRunAreaScan();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRunPerimeterScan();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRunOrbitScan();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRunMeshScan();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getRunContourZScan();
  if (f) {
    writer.writeBool(
      76,
      f
    );
  }
  f = message.getRunContourYScan();
  if (f) {
    writer.writeBool(
      75,
      f
    );
  }
  f = message.getRunContourXScan();
  if (f) {
    writer.writeBool(
      74,
      f
    );
  }
  f = message.getRunContourCustomScan();
  if (f) {
    writer.writeBool(
      137,
      f
    );
  }
  f = message.getRunManualScan();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getRunColumnScan();
  if (f) {
    writer.writeBool(
      101,
      f
    );
  }
  f = message.getRunVertexColumnScan();
  if (f) {
    writer.writeBool(
      102,
      f
    );
  }
  f = message.getRunStructureColumnInspectionScan();
  if (f) {
    writer.writeBool(
      154,
      f
    );
  }
  f = message.getStopForPhoto();
  if (f) {
    writer.writeBool(
      78,
      f
    );
  }
  f = message.getIgnoreTranslationMotionBlurConstraint();
  if (f) {
    writer.writeBool(
      188,
      f
    );
  }
  f = message.getOverlapPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getDistanceToSurface();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getShowArCoverageMesh();
  if (f) {
    writer.writeBool(
      77,
      f
    );
  }
  f = message.getAwbMode();
  if (f !== 0.0) {
    writer.writeEnum(
      68,
      f
    );
  }
  f = message.getExposureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      69,
      f
    );
  }
  f = message.getAutofocusRegion();
  if (f != null) {
    writer.writeMessage(
      150,
      f,
      pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.serializeBinaryToWriter
    );
  }
  f = message.getAutofocusMode();
  if (f !== 0.0) {
    writer.writeEnum(
      151,
      f
    );
  }
  f = message.getEoRecordingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      166,
      f
    );
  }
  f = message.getGeofenceStrictCeiling();
  if (f) {
    writer.writeBool(
      94,
      f
    );
  }
  f = message.getGeofenceCeilOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getGeofenceStrictFloor();
  if (f) {
    writer.writeBool(
      95,
      f
    );
  }
  f = message.getGeofenceFloorOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
  f = message.getGeofenceStrictLateral();
  if (f) {
    writer.writeBool(
      96,
      f
    );
  }
  f = message.getGeofenceLateralOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      97,
      f
    );
  }
  f = message.getGeofenceOffsetFloorFromPolygonPrismCeiling();
  if (f) {
    writer.writeBool(
      152,
      f
    );
  }
  f = message.getMinDistanceFromSurface();
  if (f !== 0.0) {
    writer.writeFloat(
      92,
      f
    );
  }
  f = message.getUseGeofenceArea();
  if (f) {
    writer.writeBool(
      126,
      f
    );
  }
  f = message.getExteriorCaptureCeil();
  if (f) {
    writer.writeBool(
      98,
      f
    );
  }
  f = message.getExteriorCaptureFloor();
  if (f) {
    writer.writeBool(
      99,
      f
    );
  }
  f = message.getExteriorCaptureLateral();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getScanVolumeInside();
  if (f) {
    writer.writeBool(
      140,
      f
    );
  }
  f = message.getScanVolumeExtendCaptureDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      141,
      f
    );
  }
  f = message.getAreaScanHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      27,
      f
    );
  }
  f = message.getAreaOverlap();
  if (f !== 0.0) {
    writer.writeFloat(
      28,
      f
    );
  }
  f = message.getAreaSidelap();
  if (f !== 0.0) {
    writer.writeFloat(
      29,
      f
    );
  }
  f = message.getAreaGimbalPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      173,
      f
    );
  }
  f = message.getEnableCrossHatch();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getCrossHatchGimbalAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      31,
      f
    );
  }
  f = message.getEnableCustomFlightLegDirection();
  if (f) {
    writer.writeBool(
      170,
      f
    );
  }
  f = message.getCustomFlightLegDirection();
  if (f !== 0.0) {
    writer.writeFloat(
      171,
      f
    );
  }
  f = message.getEnableCustomCameraHeading();
  if (f) {
    writer.writeBool(
      174,
      f
    );
  }
  f = message.getCcwPerpendicularCameraHeading();
  if (f) {
    writer.writeBool(
      175,
      f
    );
  }
  f = message.getPerimeterOverlap();
  if (f !== 0.0) {
    writer.writeFloat(
      88,
      f
    );
  }
  f = message.getPerimeterGimbalPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      34,
      f
    );
  }
  f = message.getPerimeterNumHeights();
  if (f !== 0) {
    writer.writeSint64(
      35,
      f
    );
  }
  f = message.getPerimeterMinDistanceToScanPlane();
  if (f !== 0.0) {
    writer.writeFloat(
      36,
      f
    );
  }
  f = message.getRollingShutterCorrection();
  if (f) {
    writer.writeBool(
      189,
      f
    );
  }
  f = message.getIsLookupScan();
  if (f) {
    writer.writeBool(
      107,
      f
    );
  }
  f = message.getOrbitSidelap();
  if (f !== 0.0) {
    writer.writeFloat(
      124,
      f
    );
  }
  f = message.getOrbitGimbalPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      108,
      f
    );
  }
  f = message.getOrbitUseHelix();
  if (f) {
    writer.writeBool(
      128,
      f
    );
  }
  f = message.getNumColumns();
  if (f !== 0) {
    writer.writeUint32(
      145,
      f
    );
  }
  f = message.getColumnOverlap();
  if (f !== 0.0) {
    writer.writeFloat(
      115,
      f
    );
  }
  f = message.getColumnGimbalPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      117,
      f
    );
  }
  f = message.getContourDesiredRange();
  if (f !== 0.0) {
    writer.writeFloat(
      71,
      f
    );
  }
  f = message.getContourWaypointOverlapPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      73,
      f
    );
  }
  f = message.getContourSliceOverlapPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      72,
      f
    );
  }
  f = message.getContourZLookdownAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      81,
      f
    );
  }
  f = message.getAvoidRedundantCoverage();
  if (f) {
    writer.writeBool(
      90,
      f
    );
  }
  f = message.getAdjustUnreachablePhotos();
  if (f) {
    writer.writeBool(
      91,
      f
    );
  }
  f = message.getContourNumPasses();
  if (f !== 0) {
    writer.writeSint64(
      121,
      f
    );
  }
  f = message.getContourPassMultiplier();
  if (f !== 0.0) {
    writer.writeFloat(
      122,
      f
    );
  }
  f = message.getContourSlicePitch();
  if (f !== 0.0) {
    writer.writeFloat(
      135,
      f
    );
  }
  f = message.getContourSliceYaw();
  if (f !== 0.0) {
    writer.writeFloat(
      136,
      f
    );
  }
  f = message.getContourSmallGapsDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      146,
      f
    );
  }
  f = message.getContourLongRange();
  if (f) {
    writer.writeBool(
      149,
      f
    );
  }
  f = message.getVerticalOverlapFractionPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      103,
      f
    );
  }
  f = message.getGimbalPitchDeg();
  if (f !== 0.0) {
    writer.writeFloat(
      104,
      f
    );
  }
  f = message.getLengthTower();
  if (f !== 0.0) {
    writer.writeFloat(
      105,
      f
    );
  }
  f = message.getUpAndOverHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      106,
      f
    );
  }
  f = message.getStandoffDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      161,
      f
    );
  }
  f = message.getExplorationOrbitRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      165,
      f
    );
  }
  f = message.getExplorationOrbitHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      169,
      f
    );
  }
  f = message.getTowerHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      181,
      f
    );
  }
  f = message.getTowerWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      159,
      f
    );
  }
  f = message.getTowerDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      160,
      f
    );
  }
  f = message.getNadirExecutionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      182,
      f
    );
  }
  f = message.getNadirFillImage();
  if (f !== 0.0) {
    writer.writeFloat(
      187,
      f
    );
  }
  f = message.getOverviewExecutionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      186,
      f
    );
  }
  f = message.getOverviewGimbalPitchDown();
  if (f !== 0.0) {
    writer.writeFloat(
      176,
      f
    );
  }
  f = message.getInsulatorExecutionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      178,
      f
    );
  }
  f = message.getColumnPlanType();
  if (f !== 0.0) {
    writer.writeEnum(
      162,
      f
    );
  }
  f = message.getUseSemanticInspectionGimbalPitchUp();
  if (f) {
    writer.writeBool(
      157,
      f
    );
  }
  f = message.getSemanticInspectionGimbalPitchUp();
  if (f !== 0.0) {
    writer.writeFloat(
      155,
      f
    );
  }
  f = message.getUseSemanticInspectionGimbalPitchStraight();
  if (f) {
    writer.writeBool(
      183,
      f
    );
  }
  f = message.getUseSemanticInspectionGimbalPitchDown();
  if (f) {
    writer.writeBool(
      158,
      f
    );
  }
  f = message.getSemanticInspectionGimbalPitchDown();
  if (f !== 0.0) {
    writer.writeFloat(
      156,
      f
    );
  }
  f = message.getTowerBodyExecutionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      179,
      f
    );
  }
  f = message.getTowerBodyEnabled();
  if (f) {
    writer.writeBool(
      190,
      f
    );
  }
  f = message.getTowerBodyTakePhoto();
  if (f) {
    writer.writeBool(
      191,
      f
    );
  }
  f = message.getTowerBodyPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      185,
      f
    );
  }
  f = message.getTowerBodyOverlap();
  if (f !== 0.0) {
    writer.writeFloat(
      184,
      f
    );
  }
  f = message.getSpanExecutionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      180,
      f
    );
  }
  f = message.getSpanEnabled();
  if (f) {
    writer.writeBool(
      192,
      f
    );
  }
  f = message.getSpanTakePhoto();
  if (f) {
    writer.writeBool(
      193,
      f
    );
  }
  f = message.getAutonomousTowerAlignment();
  if (f) {
    writer.writeBool(
      172,
      f
    );
  }
  f = message.getUseSemanticAim();
  if (f) {
    writer.writeBool(
      168,
      f
    );
  }
  f = message.getNumberPitchAngles();
  if (f !== 0) {
    writer.writeSint32(
      195,
      f
    );
  }
  f = message.getNadirTakePhoto();
  if (f) {
    writer.writeBool(
      196,
      f
    );
  }
  f = message.getOverviewEnabled();
  if (f) {
    writer.writeBool(
      197,
      f
    );
  }
  f = message.getOverviewTakePhoto();
  if (f) {
    writer.writeBool(
      198,
      f
    );
  }
  f = message.getInsulatorEnabled();
  if (f) {
    writer.writeBool(
      199,
      f
    );
  }
  f = message.getInsulatorTakePhoto();
  if (f) {
    writer.writeBool(
      200,
      f
    );
  }
  f = message.getSemanticInspectionGimbalPitchStraight();
  if (f !== 0.0) {
    writer.writeFloat(
      201,
      f
    );
  }
  f = message.getColumnIndicesList();
  if (f.length > 0) {
    writer.writePackedSint32(
      202,
      f
    );
  }
  f = message.getUseExplorationPhase();
  if (f) {
    writer.writeBool(
      61,
      f
    );
  }
  f = message.getUseMeshExplore();
  if (f) {
    writer.writeBool(
      147,
      f
    );
  }
  f = message.getUseLongRangeExplore();
  if (f) {
    writer.writeBool(
      148,
      f
    );
  }
  f = message.getUseOrbitExploration();
  if (f) {
    writer.writeBool(
      163,
      f
    );
  }
  f = message.getGlobalMaximumSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      127,
      f
    );
  }
  f = message.getGlobalSupportOutsideGeofencePoints();
  if (f) {
    writer.writeBool(
      142,
      f
    );
  }
  f = message.getSplitExplorationIsEnabled();
  if (f) {
    writer.writeBool(
      138,
      f
    );
  }
  f = message.getSplitExplorationIsCumulative();
  if (f) {
    writer.writeBool(
      132,
      f
    );
  }
  f = message.getSplitExplorationIsTopToBottom();
  if (f) {
    writer.writeBool(
      133,
      f
    );
  }
  f = message.getSplitExplorationSliceHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      139,
      f
    );
  }
  f = message.getSpecificationSelection();
  if (f !== 0.0) {
    writer.writeEnum(
      41,
      f
    );
  }
  f = message.getUsePillarCursor();
  if (f) {
    writer.writeBool(
      56,
      f
    );
  }
  f = message.getUsePillarCursorMarker();
  if (f) {
    writer.writeBool(
      84,
      f
    );
  }
  f = message.getUseExtremeCursor();
  if (f) {
    writer.writeBool(
      82,
      f
    );
  }
  f = message.getLockAwbAtFirstWaypoint();
  if (f) {
    writer.writeBool(
      194,
      f
    );
  }
  f = message.getUseGpsScanPose();
  if (f) {
    writer.writeBool(
      46,
      f
    );
  }
  f = message.getLostCommsWaitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      49,
      f
    );
  }
  f = message.getLostCommsRallyWaitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      51,
      f
    );
  }
  f = message.getNumPhotosPerOrbit();
  if (f !== 0) {
    writer.writeSint64(
      37,
      f
    );
  }
  f = message.getLookOutwards();
  if (f) {
    writer.writeBool(
      143,
      f
    );
  }
  f = message.getOrbitCounterClockwise();
  if (f) {
    writer.writeBool(
      144,
      f
    );
  }
  f = message.getTakeIrPhotos();
  if (f) {
    writer.writeBool(
      153,
      f
    );
  }
  f = message.getTakeRawPhotos();
  if (f) {
    writer.writeBool(
      167,
      f
    );
  }
  f = message.getUserControls();
  if (f != null) {
    writer.writeMessage(
      164,
      f,
      pbtypes_gen_surface_scan_user_controls_pb.UserControls.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanMode.Enum scan_mode_lcm = 1;
 * @return {!proto.surface_scan.ScanMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getScanModeLcm = function() {
  return /** @type {!proto.surface_scan.ScanMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.surface_scan.ScanMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setScanModeLcm = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool run_area_scan = 2;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunAreaScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunAreaScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool run_perimeter_scan = 3;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunPerimeterScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunPerimeterScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool run_orbit_scan = 4;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunOrbitScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunOrbitScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool run_mesh_scan = 6;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunMeshScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunMeshScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool run_contour_z_scan = 76;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunContourZScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 76, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunContourZScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 76, value);
};


/**
 * optional bool run_contour_y_scan = 75;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunContourYScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 75, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunContourYScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 75, value);
};


/**
 * optional bool run_contour_x_scan = 74;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunContourXScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 74, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunContourXScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 74, value);
};


/**
 * optional bool run_contour_custom_scan = 137;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunContourCustomScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 137, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunContourCustomScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 137, value);
};


/**
 * optional bool run_manual_scan = 7;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunManualScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunManualScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool run_column_scan = 101;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunColumnScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 101, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunColumnScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 101, value);
};


/**
 * optional bool run_vertex_column_scan = 102;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunVertexColumnScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunVertexColumnScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 102, value);
};


/**
 * optional bool run_structure_column_inspection_scan = 154;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRunStructureColumnInspectionScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 154, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRunStructureColumnInspectionScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 154, value);
};


/**
 * optional bool stop_for_photo = 78;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getStopForPhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 78, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setStopForPhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 78, value);
};


/**
 * optional bool ignore_translation_motion_blur_constraint = 188;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getIgnoreTranslationMotionBlurConstraint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 188, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setIgnoreTranslationMotionBlurConstraint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 188, value);
};


/**
 * optional float overlap_percentage = 10;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getOverlapPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOverlapPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float distance_to_surface = 11;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getDistanceToSurface = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setDistanceToSurface = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool show_ar_coverage_mesh = 77;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getShowArCoverageMesh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 77, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setShowArCoverageMesh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 77, value);
};


/**
 * optional user_camera.AwbMode.Enum awb_mode = 68;
 * @return {!proto.user_camera.AwbMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getAwbMode = function() {
  return /** @type {!proto.user_camera.AwbMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {!proto.user_camera.AwbMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAwbMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 68, value);
};


/**
 * optional user_camera.ExposureMode.Enum exposure_mode = 69;
 * @return {!proto.user_camera.ExposureMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getExposureMode = function() {
  return /** @type {!proto.user_camera.ExposureMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 69, 0));
};


/**
 * @param {!proto.user_camera.ExposureMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setExposureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 69, value);
};


/**
 * optional user_camera.AutoRegionRequest autofocus_region = 150;
 * @return {?proto.user_camera.AutoRegionRequest}
 */
proto.surface_scan.SettingsManager.prototype.getAutofocusRegion = function() {
  return /** @type{?proto.user_camera.AutoRegionRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest, 150));
};


/**
 * @param {?proto.user_camera.AutoRegionRequest|undefined} value
 * @return {!proto.surface_scan.SettingsManager} returns this
*/
proto.surface_scan.SettingsManager.prototype.setAutofocusRegion = function(value) {
  return jspb.Message.setWrapperField(this, 150, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.clearAutofocusRegion = function() {
  return this.setAutofocusRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.hasAutofocusRegion = function() {
  return jspb.Message.getField(this, 150) != null;
};


/**
 * optional user_camera.AutofocusMode.Enum autofocus_mode = 151;
 * @return {!proto.user_camera.AutofocusMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getAutofocusMode = function() {
  return /** @type {!proto.user_camera.AutofocusMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 151, 0));
};


/**
 * @param {!proto.user_camera.AutofocusMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAutofocusMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 151, value);
};


/**
 * optional user_camera.RecordingMode.Enum eo_recording_mode = 166;
 * @return {!proto.user_camera.RecordingMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getEoRecordingMode = function() {
  return /** @type {!proto.user_camera.RecordingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 166, 0));
};


/**
 * @param {!proto.user_camera.RecordingMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setEoRecordingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 166, value);
};


/**
 * optional bool geofence_strict_ceiling = 94;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getGeofenceStrictCeiling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 94, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGeofenceStrictCeiling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 94, value);
};


/**
 * optional float geofence_ceil_offset = 22;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getGeofenceCeilOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGeofenceCeilOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional bool geofence_strict_floor = 95;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getGeofenceStrictFloor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 95, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGeofenceStrictFloor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 95, value);
};


/**
 * optional float geofence_floor_offset = 24;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getGeofenceFloorOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGeofenceFloorOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional bool geofence_strict_lateral = 96;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getGeofenceStrictLateral = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 96, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGeofenceStrictLateral = function(value) {
  return jspb.Message.setProto3BooleanField(this, 96, value);
};


/**
 * optional float geofence_lateral_offset = 97;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getGeofenceLateralOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 97, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGeofenceLateralOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 97, value);
};


/**
 * optional bool geofence_offset_floor_from_polygon_prism_ceiling = 152;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getGeofenceOffsetFloorFromPolygonPrismCeiling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 152, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGeofenceOffsetFloorFromPolygonPrismCeiling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 152, value);
};


/**
 * optional float min_distance_from_surface = 92;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getMinDistanceFromSurface = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 92, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setMinDistanceFromSurface = function(value) {
  return jspb.Message.setProto3FloatField(this, 92, value);
};


/**
 * optional bool use_geofence_area = 126;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseGeofenceArea = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 126, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseGeofenceArea = function(value) {
  return jspb.Message.setProto3BooleanField(this, 126, value);
};


/**
 * optional bool exterior_capture_ceil = 98;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getExteriorCaptureCeil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 98, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setExteriorCaptureCeil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 98, value);
};


/**
 * optional bool exterior_capture_floor = 99;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getExteriorCaptureFloor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 99, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setExteriorCaptureFloor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 99, value);
};


/**
 * optional bool exterior_capture_lateral = 100;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getExteriorCaptureLateral = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setExteriorCaptureLateral = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


/**
 * optional bool scan_volume_inside = 140;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getScanVolumeInside = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 140, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setScanVolumeInside = function(value) {
  return jspb.Message.setProto3BooleanField(this, 140, value);
};


/**
 * optional float scan_volume_extend_capture_distance = 141;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getScanVolumeExtendCaptureDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 141, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setScanVolumeExtendCaptureDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 141, value);
};


/**
 * optional float area_scan_height = 27;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getAreaScanHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAreaScanHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional float area_overlap = 28;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getAreaOverlap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAreaOverlap = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional float area_sidelap = 29;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getAreaSidelap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAreaSidelap = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional float area_gimbal_pitch = 173;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getAreaGimbalPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 173, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAreaGimbalPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 173, value);
};


/**
 * optional bool enable_cross_hatch = 30;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getEnableCrossHatch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setEnableCrossHatch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional float cross_hatch_gimbal_angle = 31;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getCrossHatchGimbalAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setCrossHatchGimbalAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional bool enable_custom_flight_leg_direction = 170;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getEnableCustomFlightLegDirection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 170, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setEnableCustomFlightLegDirection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 170, value);
};


/**
 * optional float custom_flight_leg_direction = 171;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getCustomFlightLegDirection = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 171, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setCustomFlightLegDirection = function(value) {
  return jspb.Message.setProto3FloatField(this, 171, value);
};


/**
 * optional bool enable_custom_camera_heading = 174;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getEnableCustomCameraHeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 174, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setEnableCustomCameraHeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 174, value);
};


/**
 * optional bool ccw_perpendicular_camera_heading = 175;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getCcwPerpendicularCameraHeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 175, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setCcwPerpendicularCameraHeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 175, value);
};


/**
 * optional float perimeter_overlap = 88;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getPerimeterOverlap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 88, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setPerimeterOverlap = function(value) {
  return jspb.Message.setProto3FloatField(this, 88, value);
};


/**
 * optional float perimeter_gimbal_pitch = 34;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getPerimeterGimbalPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setPerimeterGimbalPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional sint64 perimeter_num_heights = 35;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getPerimeterNumHeights = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setPerimeterNumHeights = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional float perimeter_min_distance_to_scan_plane = 36;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getPerimeterMinDistanceToScanPlane = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setPerimeterMinDistanceToScanPlane = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional bool rolling_shutter_correction = 189;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getRollingShutterCorrection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 189, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setRollingShutterCorrection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 189, value);
};


/**
 * optional bool is_lookup_scan = 107;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getIsLookupScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 107, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setIsLookupScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 107, value);
};


/**
 * optional float orbit_sidelap = 124;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getOrbitSidelap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 124, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOrbitSidelap = function(value) {
  return jspb.Message.setProto3FloatField(this, 124, value);
};


/**
 * optional float orbit_gimbal_pitch = 108;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getOrbitGimbalPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 108, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOrbitGimbalPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 108, value);
};


/**
 * optional bool orbit_use_helix = 128;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getOrbitUseHelix = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 128, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOrbitUseHelix = function(value) {
  return jspb.Message.setProto3BooleanField(this, 128, value);
};


/**
 * optional uint32 num_columns = 145;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getNumColumns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 145, 0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setNumColumns = function(value) {
  return jspb.Message.setProto3IntField(this, 145, value);
};


/**
 * optional float column_overlap = 115;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getColumnOverlap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 115, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setColumnOverlap = function(value) {
  return jspb.Message.setProto3FloatField(this, 115, value);
};


/**
 * optional float column_gimbal_pitch = 117;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getColumnGimbalPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 117, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setColumnGimbalPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 117, value);
};


/**
 * optional float contour_desired_range = 71;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourDesiredRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 71, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourDesiredRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 71, value);
};


/**
 * optional float contour_waypoint_overlap_percentage = 73;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourWaypointOverlapPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 73, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourWaypointOverlapPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 73, value);
};


/**
 * optional float contour_slice_overlap_percentage = 72;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourSliceOverlapPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 72, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourSliceOverlapPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 72, value);
};


/**
 * optional float contour_z_lookdown_angle = 81;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourZLookdownAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 81, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourZLookdownAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 81, value);
};


/**
 * optional bool avoid_redundant_coverage = 90;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getAvoidRedundantCoverage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 90, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAvoidRedundantCoverage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 90, value);
};


/**
 * optional bool adjust_unreachable_photos = 91;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getAdjustUnreachablePhotos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 91, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAdjustUnreachablePhotos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 91, value);
};


/**
 * optional sint64 contour_num_passes = 121;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourNumPasses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 121, 0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourNumPasses = function(value) {
  return jspb.Message.setProto3IntField(this, 121, value);
};


/**
 * optional float contour_pass_multiplier = 122;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourPassMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 122, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourPassMultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 122, value);
};


/**
 * optional float contour_slice_pitch = 135;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourSlicePitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 135, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourSlicePitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 135, value);
};


/**
 * optional float contour_slice_yaw = 136;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourSliceYaw = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 136, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourSliceYaw = function(value) {
  return jspb.Message.setProto3FloatField(this, 136, value);
};


/**
 * optional float contour_small_gaps_distance = 146;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getContourSmallGapsDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 146, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourSmallGapsDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 146, value);
};


/**
 * optional bool contour_long_range = 149;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getContourLongRange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 149, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setContourLongRange = function(value) {
  return jspb.Message.setProto3BooleanField(this, 149, value);
};


/**
 * optional float vertical_overlap_fraction_percentage = 103;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getVerticalOverlapFractionPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 103, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setVerticalOverlapFractionPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 103, value);
};


/**
 * optional float gimbal_pitch_deg = 104;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getGimbalPitchDeg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 104, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGimbalPitchDeg = function(value) {
  return jspb.Message.setProto3FloatField(this, 104, value);
};


/**
 * optional float length_tower = 105;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getLengthTower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 105, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setLengthTower = function(value) {
  return jspb.Message.setProto3FloatField(this, 105, value);
};


/**
 * optional float up_and_over_height = 106;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getUpAndOverHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 106, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUpAndOverHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 106, value);
};


/**
 * optional float standoff_distance = 161;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getStandoffDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 161, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setStandoffDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 161, value);
};


/**
 * optional float exploration_orbit_radius = 165;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getExplorationOrbitRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 165, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setExplorationOrbitRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 165, value);
};


/**
 * optional float exploration_orbit_height = 169;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getExplorationOrbitHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 169, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setExplorationOrbitHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 169, value);
};


/**
 * optional float tower_height = 181;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getTowerHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 181, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTowerHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 181, value);
};


/**
 * optional float tower_width = 159;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getTowerWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 159, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTowerWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 159, value);
};


/**
 * optional float tower_depth = 160;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getTowerDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 160, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTowerDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 160, value);
};


/**
 * optional WaypointExecutionMode.Enum nadir_execution_mode = 182;
 * @return {!proto.surface_scan.WaypointExecutionMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getNadirExecutionMode = function() {
  return /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 182, 0));
};


/**
 * @param {!proto.surface_scan.WaypointExecutionMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setNadirExecutionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 182, value);
};


/**
 * optional float nadir_fill_image = 187;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getNadirFillImage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 187, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setNadirFillImage = function(value) {
  return jspb.Message.setProto3FloatField(this, 187, value);
};


/**
 * optional WaypointExecutionMode.Enum overview_execution_mode = 186;
 * @return {!proto.surface_scan.WaypointExecutionMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getOverviewExecutionMode = function() {
  return /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 186, 0));
};


/**
 * @param {!proto.surface_scan.WaypointExecutionMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOverviewExecutionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 186, value);
};


/**
 * optional float overview_gimbal_pitch_down = 176;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getOverviewGimbalPitchDown = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 176, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOverviewGimbalPitchDown = function(value) {
  return jspb.Message.setProto3FloatField(this, 176, value);
};


/**
 * optional WaypointExecutionMode.Enum insulator_execution_mode = 178;
 * @return {!proto.surface_scan.WaypointExecutionMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getInsulatorExecutionMode = function() {
  return /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 178, 0));
};


/**
 * @param {!proto.surface_scan.WaypointExecutionMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setInsulatorExecutionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 178, value);
};


/**
 * optional scan_planner.StructureColumnType.Enum column_plan_type = 162;
 * @return {!proto.scan_planner.StructureColumnType.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getColumnPlanType = function() {
  return /** @type {!proto.scan_planner.StructureColumnType.Enum} */ (jspb.Message.getFieldWithDefault(this, 162, 0));
};


/**
 * @param {!proto.scan_planner.StructureColumnType.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setColumnPlanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 162, value);
};


/**
 * optional bool use_semantic_inspection_gimbal_pitch_up = 157;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseSemanticInspectionGimbalPitchUp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 157, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseSemanticInspectionGimbalPitchUp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 157, value);
};


/**
 * optional float semantic_inspection_gimbal_pitch_up = 155;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getSemanticInspectionGimbalPitchUp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 155, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSemanticInspectionGimbalPitchUp = function(value) {
  return jspb.Message.setProto3FloatField(this, 155, value);
};


/**
 * optional bool use_semantic_inspection_gimbal_pitch_straight = 183;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseSemanticInspectionGimbalPitchStraight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 183, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseSemanticInspectionGimbalPitchStraight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 183, value);
};


/**
 * optional bool use_semantic_inspection_gimbal_pitch_down = 158;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseSemanticInspectionGimbalPitchDown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 158, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseSemanticInspectionGimbalPitchDown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 158, value);
};


/**
 * optional float semantic_inspection_gimbal_pitch_down = 156;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getSemanticInspectionGimbalPitchDown = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 156, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSemanticInspectionGimbalPitchDown = function(value) {
  return jspb.Message.setProto3FloatField(this, 156, value);
};


/**
 * optional WaypointExecutionMode.Enum tower_body_execution_mode = 179;
 * @return {!proto.surface_scan.WaypointExecutionMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getTowerBodyExecutionMode = function() {
  return /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 179, 0));
};


/**
 * @param {!proto.surface_scan.WaypointExecutionMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTowerBodyExecutionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 179, value);
};


/**
 * optional bool tower_body_enabled = 190;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getTowerBodyEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 190, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTowerBodyEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 190, value);
};


/**
 * optional bool tower_body_take_photo = 191;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getTowerBodyTakePhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 191, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTowerBodyTakePhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 191, value);
};


/**
 * optional float tower_body_pitch = 185;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getTowerBodyPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 185, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTowerBodyPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 185, value);
};


/**
 * optional float tower_body_overlap = 184;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getTowerBodyOverlap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 184, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTowerBodyOverlap = function(value) {
  return jspb.Message.setProto3FloatField(this, 184, value);
};


/**
 * optional WaypointExecutionMode.Enum span_execution_mode = 180;
 * @return {!proto.surface_scan.WaypointExecutionMode.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getSpanExecutionMode = function() {
  return /** @type {!proto.surface_scan.WaypointExecutionMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 180, 0));
};


/**
 * @param {!proto.surface_scan.WaypointExecutionMode.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSpanExecutionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 180, value);
};


/**
 * optional bool span_enabled = 192;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getSpanEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 192, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSpanEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 192, value);
};


/**
 * optional bool span_take_photo = 193;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getSpanTakePhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 193, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSpanTakePhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 193, value);
};


/**
 * optional bool autonomous_tower_alignment = 172;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getAutonomousTowerAlignment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 172, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setAutonomousTowerAlignment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 172, value);
};


/**
 * optional bool use_semantic_aim = 168;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseSemanticAim = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 168, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseSemanticAim = function(value) {
  return jspb.Message.setProto3BooleanField(this, 168, value);
};


/**
 * optional sint32 number_pitch_angles = 195;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getNumberPitchAngles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 195, 0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setNumberPitchAngles = function(value) {
  return jspb.Message.setProto3IntField(this, 195, value);
};


/**
 * optional bool nadir_take_photo = 196;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getNadirTakePhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 196, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setNadirTakePhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 196, value);
};


/**
 * optional bool overview_enabled = 197;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getOverviewEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 197, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOverviewEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 197, value);
};


/**
 * optional bool overview_take_photo = 198;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getOverviewTakePhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 198, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOverviewTakePhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 198, value);
};


/**
 * optional bool insulator_enabled = 199;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getInsulatorEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 199, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setInsulatorEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 199, value);
};


/**
 * optional bool insulator_take_photo = 200;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getInsulatorTakePhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 200, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setInsulatorTakePhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 200, value);
};


/**
 * optional float semantic_inspection_gimbal_pitch_straight = 201;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getSemanticInspectionGimbalPitchStraight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 201, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSemanticInspectionGimbalPitchStraight = function(value) {
  return jspb.Message.setProto3FloatField(this, 201, value);
};


/**
 * repeated sint32 column_indices = 202;
 * @return {!Array<number>}
 */
proto.surface_scan.SettingsManager.prototype.getColumnIndicesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 202));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setColumnIndicesList = function(value) {
  return jspb.Message.setField(this, 202, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.addColumnIndices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 202, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.clearColumnIndicesList = function() {
  return this.setColumnIndicesList([]);
};


/**
 * optional bool use_exploration_phase = 61;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseExplorationPhase = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 61, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseExplorationPhase = function(value) {
  return jspb.Message.setProto3BooleanField(this, 61, value);
};


/**
 * optional bool use_mesh_explore = 147;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseMeshExplore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 147, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseMeshExplore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 147, value);
};


/**
 * optional bool use_long_range_explore = 148;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseLongRangeExplore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 148, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseLongRangeExplore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 148, value);
};


/**
 * optional bool use_orbit_exploration = 163;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseOrbitExploration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 163, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseOrbitExploration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 163, value);
};


/**
 * optional float global_maximum_speed = 127;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getGlobalMaximumSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 127, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGlobalMaximumSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 127, value);
};


/**
 * optional bool global_support_outside_geofence_points = 142;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getGlobalSupportOutsideGeofencePoints = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 142, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setGlobalSupportOutsideGeofencePoints = function(value) {
  return jspb.Message.setProto3BooleanField(this, 142, value);
};


/**
 * optional bool split_exploration_is_enabled = 138;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getSplitExplorationIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 138, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSplitExplorationIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 138, value);
};


/**
 * optional bool split_exploration_is_cumulative = 132;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getSplitExplorationIsCumulative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 132, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSplitExplorationIsCumulative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 132, value);
};


/**
 * optional bool split_exploration_is_top_to_bottom = 133;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getSplitExplorationIsTopToBottom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 133, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSplitExplorationIsTopToBottom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 133, value);
};


/**
 * optional float split_exploration_slice_height = 139;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getSplitExplorationSliceHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 139, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSplitExplorationSliceHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 139, value);
};


/**
 * optional SpecificationSelection.Enum specification_selection = 41;
 * @return {!proto.surface_scan.SpecificationSelection.Enum}
 */
proto.surface_scan.SettingsManager.prototype.getSpecificationSelection = function() {
  return /** @type {!proto.surface_scan.SpecificationSelection.Enum} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {!proto.surface_scan.SpecificationSelection.Enum} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setSpecificationSelection = function(value) {
  return jspb.Message.setProto3EnumField(this, 41, value);
};


/**
 * optional bool use_pillar_cursor = 56;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUsePillarCursor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 56, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUsePillarCursor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 56, value);
};


/**
 * optional bool use_pillar_cursor_marker = 84;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUsePillarCursorMarker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 84, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUsePillarCursorMarker = function(value) {
  return jspb.Message.setProto3BooleanField(this, 84, value);
};


/**
 * optional bool use_extreme_cursor = 82;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseExtremeCursor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 82, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseExtremeCursor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 82, value);
};


/**
 * optional bool lock_awb_at_first_waypoint = 194;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getLockAwbAtFirstWaypoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 194, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setLockAwbAtFirstWaypoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 194, value);
};


/**
 * optional bool use_gps_scan_pose = 46;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getUseGpsScanPose = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 46, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setUseGpsScanPose = function(value) {
  return jspb.Message.setProto3BooleanField(this, 46, value);
};


/**
 * optional float lost_comms_wait_time = 49;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getLostCommsWaitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setLostCommsWaitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional float lost_comms_rally_wait_time = 51;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getLostCommsRallyWaitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 51, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setLostCommsRallyWaitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 51, value);
};


/**
 * optional sint64 num_photos_per_orbit = 37;
 * @return {number}
 */
proto.surface_scan.SettingsManager.prototype.getNumPhotosPerOrbit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setNumPhotosPerOrbit = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional bool look_outwards = 143;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getLookOutwards = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 143, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setLookOutwards = function(value) {
  return jspb.Message.setProto3BooleanField(this, 143, value);
};


/**
 * optional bool orbit_counter_clockwise = 144;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getOrbitCounterClockwise = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 144, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setOrbitCounterClockwise = function(value) {
  return jspb.Message.setProto3BooleanField(this, 144, value);
};


/**
 * optional bool take_ir_photos = 153;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getTakeIrPhotos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 153, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTakeIrPhotos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 153, value);
};


/**
 * optional bool take_raw_photos = 167;
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.getTakeRawPhotos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 167, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.setTakeRawPhotos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 167, value);
};


/**
 * optional UserControls user_controls = 164;
 * @return {?proto.surface_scan.UserControls}
 */
proto.surface_scan.SettingsManager.prototype.getUserControls = function() {
  return /** @type{?proto.surface_scan.UserControls} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_surface_scan_user_controls_pb.UserControls, 164));
};


/**
 * @param {?proto.surface_scan.UserControls|undefined} value
 * @return {!proto.surface_scan.SettingsManager} returns this
*/
proto.surface_scan.SettingsManager.prototype.setUserControls = function(value) {
  return jspb.Message.setWrapperField(this, 164, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.surface_scan.SettingsManager} returns this
 */
proto.surface_scan.SettingsManager.prototype.clearUserControls = function() {
  return this.setUserControls(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.surface_scan.SettingsManager.prototype.hasUserControls = function() {
  return jspb.Message.getField(this, 164) != null;
};


goog.object.extend(exports, proto.surface_scan);
